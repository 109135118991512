import { CREATE_LIFF,GET_LIFF_LIST } from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  LiffList: [],
  totalLiff: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_LIFF: {
      let updatedList = state.LiffList;
      let updatedCount = state.totalLiff;
      updatedList = [action.payload, ...updatedList];
      updatedCount = updatedCount + 1;
      return {
        ...state,
        LiffList: updatedList,
        totalLiff: updatedCount,
      };
    }

    case GET_LIFF_LIST: {
      // console.log('reducer')
      return {
        ...state,
        departmentsList: action.payload,
      };
    }

    default:
      return state;
  }
};
