import {
  fetchError,
  fetchStart,
  fetchSuccess,
  setNotification
} from "../../../redux/actions";
import {
  setAuthUser,
  setForgetPassMailSent,
  setResetPassword,
  updateLoadUser
} from "../../../redux/actions/Auth";

// import React from 'react';
import axios from "./config";
import {
  setStorageAuthenUser,
  setStorageRemember,
  clearAllStorage,
  setStorageToken,
  setStorageRefreshToken,
  setStorageNotification
} from "../../Storage.service";

const JWTAuth = {
  onRegister: data => {
    data.clientUrl = window.location.origin;
    return dispatch => {
      dispatch(fetchStart());
      // console.log(data)
      axios
        .post("register/create", data)
        .then(result => {
          // console.log(result);
          if (result && result.status === 201) {
            dispatch(
              fetchSuccess("Register Successfully, Please Check Your Email.")
            );
            setTimeout(() => {
              window.location.href = "/auth/signin";
            }, 1000);
          } else {
            dispatch(fetchError(result.data.message));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onConfirmRegister: (tokenId, callbackFun = null) => {
    // console.log(tokenId);
    return dispatch => {
      dispatch(fetchStart());
      axios
        .get("register/activate/" + tokenId)
        .then(({ data }) => {
          // console.log(data);
          if (data && data.message) {
            // console.log(data.message)
            dispatch(fetchSuccess());
            callbackFun(data);
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ userName, password, rememberMe, uid }, callbackFun = null) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post("auth/login", {
            username: userName,
            password: password,
            uid: uid
          })
          .then(({ data }) => {
            if (data.data) {
              setStorageToken(data.access_token);
              setStorageRefreshToken(data.refresh_token);
              const remember = {
                rememberMe: rememberMe,
                userName: userName,
                password: password
              };
              setStorageRemember(remember);
              // console.log(remember);
              setStorageAuthenUser(data);
              dispatch(setAuthUser(data));
              setStorageNotification(data.noti);
              dispatch(setNotification(data.noti));
              // console.log("data.noti", data.noti);
              dispatch(fetchSuccess());
              callbackFun(data);
              // console.log("Login Success", data);
            } else {
              dispatch(fetchError(data.message));
              // console.log("Login Fail", data);
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post("auth/logout")
        .then(({ data }) => {
          if (data.status === 200) {
            clearAllStorage();
            dispatch(setAuthUser(null));
            window.location.href = "/auth/signin";
            dispatch(fetchSuccess());
            // window.location.reload();
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .get("auth")
        .then(({ data }) => {
          // console.log(data.passport.user);
          if (data && data.passport) {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data.passport.user));
            setStorageAuthenUser(data.passport.user);
          } else {
            dispatch(updateLoadUser(true));
            // console.log("getAuthUser else");
            dispatch(fetchSuccess());
          }
        })
        .catch(function(error) {
          dispatch(updateLoadUser(true));
        });
    };
  },

  onForgotPassword: email => {
    const user = {
      email,
      clientUrl: window.location.origin
    };
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post("register/forgotPassword", user)
        .then(({ data }) => {
          console.log(data);
          if (data && data.status) {
            setTimeout(() => {
              dispatch(setForgetPassMailSent(true));
              dispatch(fetchSuccess());
            }, 300);
          } else {
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onResetPassword: (id, password, callbackFun = null) => {
    const data = {
      id,
      password
    };
    console.log("onResetPassword", data);
    return dispatch => {
      dispatch(fetchStart());
      axios
        .put("register/resetPassword", data)
        .then(({ data }) => {
          console.log(data);
          if (data) {
            setTimeout(() => {
              dispatch(setResetPassword(true));
              dispatch(fetchSuccess());
              if (callbackFun) {
                callbackFun();
              }
            }, 300);
          } else {
            dispatch(fetchError(data.message));
            if (data.message === "Reset password link was expired.") {
              setTimeout(() => {
                window.location.href = "/auth/signin";
              }, 1500);
            }
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  sendEmailUserInfomation: (email, callbackFun = null) => {
    const data = {
      email
    };
    // console.log("sendEmailUserInfomation", data);
    return dispatch => {
      dispatch(fetchStart());
      axios
        .put("register/sendEmailUserInfomation", data)
        .then(({ data }) => {
          console.log(data);
          if (data && data.status) {
            setTimeout(() => {
              dispatch(fetchSuccess("Send Email Successfully."));
              if (callbackFun) {
                callbackFun(data);
              }
            }, 300);
          } else {
            dispatch(fetchError(data.message));
            if (data.message === "Reset password link was expired.") {
              setTimeout(() => {
                window.location.href = "/auth/signin";
              }, 1500);
            }
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  }
};

export default JWTAuth;
