import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    height: "100%",
    boxShadow: "none !important"
  },
  form: {
    width: "100%"
  },
  textPoint: {
    color: "#02D2F7",
    fontWeight: "bold",
    "& .MuiInputBase-input": {
      color: "#02D2F7",
      fontWeight: "bold"
    }
  },
  searchBar: { flex: "1", marginTop: theme.spacing(2) },
  gridHeader: {
    "& .MuiDataGrid-main": {
      borderRadius: "5px !important"
    },
    "& 	.MuiDataGrid-columnHeaders": {
      backgroundColor: theme.mainColor,
      color: "#fbfbf9",
      "& 	.MuiSvgIcon-root": {
        color: "#fbfbf9"
      }
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold"
    },
    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: 0
    }
  },
  itemsContainer: {
    maxWidth: "582px",
    width: "60%",
    [theme.breakpoints.up("xl")]: {
      width: "40%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "70%"
    },
    margin: "auto"
  },
  giftBox: {
    borderRadius: theme.spacing(2),
    backgroundColor: "#f4f6f7c9",
    height: "170px",
    margin: "auto",
    padding: theme.spacing(2),
    width: "100%",
    maxWidth: "170px",
    minWidth: "170px",
    cursor: "pointer"
  },
  dialog: {
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: theme.spacing(1)
    },
    "& .MuiPaper-root": {
      maxWidth: "500px",
      width: "100%"
    }
  },
  boxDialog: {
    margin: "auto",
    maxWidth: "300px",
    padding: theme.spacing(2)
  },
  textarea: {
    border: "1px solid #ccc",
    width: "100%"
    // [theme.breakpoints.down("xs")]: {
    //   width: "260px"
    // }
  },
  avatarPoint: {
    width: '20px !important',
    height: '20px !important',
    marginLeft: 1
  }
}));

export default useStyles;
