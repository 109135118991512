import React from 'react'
import Typography from '@mui/material/Typography';
import Address from './address';
import { AccordionSummary } from '@mui/material';
import CompContact from './contact';
import useStyles from 'routes/Pages/Company/index.style';

const TabAddress = ({ propsAddress }) => {

    const classes = useStyles();

    return (
        <>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography variant='h6' className={classes.title}>Preference Contact</Typography>
            </AccordionSummary>
            <CompContact props={propsAddress} />
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography variant='h6' className={classes.title}>Company Address</Typography>
            </AccordionSummary>
            <Address props={propsAddress} />
        </>
    )
}

export default TabAddress