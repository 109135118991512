import { alpha, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    authThumb: {
        backgroundColor: alpha(theme.palette.primary.main, 0.12),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        [theme.breakpoints.up('md')]: {
            width: '50%',
            order: 2,
        },
    },
    parentImg: {
        textAlign: 'center',
    },
    img: {
        height: '50px',
    },
    authContent: {
        padding: 30,
        [theme.breakpoints.up('md')]: {
            width: props => (props.variant === 'default' ? '50%' : '100%'),
            order: 1,
        },
        [theme.breakpoints.up('xl')]: {
            padding: 50,
        },
        '& .MuiGrid-item': {
            paddingBottom: '2px',
            [theme.breakpoints.up('md')]: {
                paddingBottom: theme.spacing(3),
            },
        }
    },
    titleRoot: {
        marginBottom: 14,
        color: theme.palette.text.primary,
    },
    textFieldRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.common.dark, 0.12),
        },
    },
    textCapital: {
        textTransform: 'capitalize',
    },
    textAcc: {
        marginTop: 3,
        textAlign: 'center',
        '& a': {
            marginLeft: 4,
        },
    },
    alrTextRoot: {
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
        },
    },
    title: {
        paddingRight: '2px !important',
    },
    firstName: {
        paddingLeft: '2px !important',
    },
    btnRegister: {
        width: '100%',
    },
}));

export default useStyles;