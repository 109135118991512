import React from "react";
// import { DatePicker, LocalizationProvider } from "@mui/lab";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import useStyles from "./index.style";
import clsx from "clsx";

const DateTimePickerUC = ({
  value,
  name,
  handleDateChange,
  infoViewOnlyMode,
  shouldDisableDate,
  ...res
}) => {
  const classes = useStyles();

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disabled={infoViewOnlyMode}
          // format="dd/MM/yyyy"
          inputFormat="dd/MM/yyyy"
          views={["year", "month", "day"]}
          value={value}
          onChange={handleDateChange(name)}
          name={name}
          shouldDisableDate={shouldDisableDate}
          renderInput={params => (
            <TextField
              fullWidth
              className={clsx(classes.formControlRoot, "form-control")}
              {...params}
            />
          )}
          {...res}
        />
      </LocalizationProvider>
    </>
  );
};

export default DateTimePickerUC;
