import {
    CREATE_REGISTER,
    ACTIVATE_REGISTER,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    access_token: '',
    register: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTIVATE_REGISTER: {
            return {
                ...state,
                access_token: action.payload,
            };
        }

        case CREATE_REGISTER: {
            return {
                ...state,
                register: action.payload,
            };
        }

        default:
            return state;
    }
};
