import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useStyles from "./index.style";
import Employment from "./employment";
import { useTranslation } from "react-i18next";

export default function TabEmployment({ props }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" className={classes.title}>
            {t("profile.employment.employment")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Employment props={props} />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
