import React, { useEffect, useState } from "react";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import { useDispatch } from "react-redux";
import { setCurrentMenu } from "redux/actions/Menu";
import { setCurrentCustomer } from "redux/actions/Customer";
import { setStorageControllerName } from "services/Storage.service";
import { onDelete } from "redux/actions/Default";
import CustomerList from "./customer-list";
import CustomerInfo from "./customer-info";

const Customer = () => {
  const dispatch = useDispatch();
  const [openInfo, setOpenInfo] = useState(false);

  useEffect(() => {
    setStorageControllerName("customer");
    dispatch(setCurrentMenu("customer"));
  }, [dispatch]);

  const InfoViewClick = event => {
    console.log("Info " + event);
    if (event) {
      switch (event.action) {
        case "delete":
          handleDeleteSeleted(event.value);
          break;
        case "save":
          dispatch(setCurrentCustomer(null));
          break;
        default:
          setOpenInfo(false);
          break;
      }
    } else {
      dispatch(setCurrentCustomer(null));
      setOpenInfo(false);
    }
  };

  const handleDeleteSeleted = selected => {
    dispatch(onDelete(selected));
  };

  const listViewClick = event => {
    console.log("list " + event);
    switch (event.action) {
      case "add":
        setOpenInfo(true);
        break;
      case "edit":
        setOpenInfo(true);
        break;
      default:
        setOpenInfo(false);
        break;
    }
  };
  return (
    <>
      <PageContainer>
        <CustomerList listViewClick={listViewClick} />
        {openInfo && (
          <CustomerInfo openInfo={openInfo} handleDialog={InfoViewClick} />
        )}
      </PageContainer>
    </>
  );
};

export default Customer;
