import React from "react";
import { Slide } from "@material-ui/core";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
// import { Alert } from "@material-ui/lab";
import {
  useDispatch,
  useSelector
} from "react-redux";
import PageLoader from "../PageComponents/PageLoader";
import { fetchError } from "../../../redux/actions";

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContentLoader = () => {
  const { error, loading, message } = useSelector(({ common }) => common);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(fetchError(""));
  //   }, 3000);
  // }, [dispatch, error, message]);
  // console.log('ContentLoader-loading', loading)
  // console.log('ContentLoader-error', error)
  // console.log('ContentLoader-message', message)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(fetchError(''));
  };

  // console.log('ContentLoader-loading', loading)
  return (
    <React.Fragment>
      {loading && <PageLoader open={loading} />}
      {
        <Snackbar
          open={Boolean(error)}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
          TransitionComponent={SlideTransition}
        >
          <Alert
            variant="filled"
            severity="error"
            onClose={handleClose}
            style={{ whiteSpace: "pre-line" }}
          >
            {error}
          </Alert>
        </Snackbar>
      }
      {
        <Snackbar
          autoHideDuration={5000}
          open={Boolean(message)}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          TransitionComponent={SlideTransition}
        >
          <Alert
            variant="filled"
            severity="success"
            onClose={handleClose}
            style={{ whiteSpace: "pre-line" }}
          >
            {message}
          </Alert>
        </Snackbar>
      }
    </React.Fragment>
  );
};

export default ContentLoader;
