import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "./index.style";
import Grid from "@material-ui/core/Grid";
import LabelUC from "routes/shared/uc/label-uc";
import TextInputUC from "routes/shared/uc/text-input-uc";
import GridContainer from "@jumbo/components/GridContainer";
import InputThaiAddress from "thai-address-autocomplete-react";
import "./index.css";
import { map, LongdoMap, longdo } from "longdo-map/LongdoMap";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import PinDropIcon from "@mui/icons-material/PinDrop";

const AddressUC = ({ infoViewOnlyMode, address, setAddress, mapKey }) => {
  const classes = useStyles();
  const [displayMap, setDisplayMap] = useState(true);
  const { t, i18n } = useTranslation();

  const initMap = () => {
    // console.log(address)
    if (address.longitude && address.latitude) {
      map.location({ lon: address.longitude, lat: address.latitude }, true);
    } else if (map) {
      map.location(longdo.LocationMode.Geolocation);
      setAddressDetailData(map.location().lat, map.location().lon);
    }

    map.Event.bind("drop", function() {
      // console.log('current : ', map.location().lat, map.location().lon);
      setAddressDetailData(map.location().lat, map.location().lon);
    });

    setEnableMap();
    // console.log(map)
  };

  useEffect(() => {
    if (map) {
      initMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  const setEnableMap = useCallback(() => {
    map.Ui.Geolocation.visible(!infoViewOnlyMode);
    map.Ui.Keyboard.enableInertia(!infoViewOnlyMode);
    map.Ui.Keyboard.enable(!infoViewOnlyMode);
    map.Ui.Mouse.enableClick(!infoViewOnlyMode);
    map.Ui.Mouse.enableDrag(!infoViewOnlyMode);
    map.Ui.Mouse.enableWheel(!infoViewOnlyMode);
    map.Ui.Mouse.enableInertia(!infoViewOnlyMode);
  }, [infoViewOnlyMode]);

  useEffect(() => {
    if (map) {
      console.log("set disable map : current", infoViewOnlyMode);
      setEnableMap();
    }
  }, [infoViewOnlyMode, setEnableMap]);

  const setAddressDetailData = (lat, lon) => {
    const lang = i18n.language;
    const url = `https://api.longdo.com/map/services/address?lon=${lon}&lat=${lat}&locale=${lang}&noelevation=1&key=${mapKey}`;
    axios.get(url).then(response => {
      if (!response.data.error) {
        const map = response.data;
        let addressDetail = "";
        if (map.aoi) {
          addressDetail = addressDetail.concat(map.aoi);
        }
        addressDetail = addressDetail.concat(" " + map.subdistrict);
        addressDetail = addressDetail.concat(" " + map.district);
        if (map.road) {
          addressDetail = addressDetail.concat(" " + map.road);
        }
        addressDetail = addressDetail.concat(" " + map.province);
        addressDetail = addressDetail.concat(" " + map.postcode);
        addressDetail = addressDetail.concat(" " + map.country);

        setAddress({
          ...address,
          subDistrict: map.subdistrict,
          district: map.district,
          province: map.province,
          postalCode: map.postcode,
          country: map.country,
          addressDetail: addressDetail,
          latitude: lat,
          longitude: lon
        });
      }
    });
  };

  const onSelect = addresses => {
    const { subdistrict, district, province, zipcode } = addresses;
    setAddress({
      ...address,
      subDistrict: subdistrict,
      district: district,
      province: province,
      postalCode: zipcode,
      country: "Thailand"
    });
    // console.log(subdistrict)
  };

  const handleChange = name => value => {
    // console.log(name, value)
    setAddress({ ...address, [name]: value });
  };

  const handleInputChange = e => {
    setAddress({ ...address, [e.target.name]: e.target.value });
    // console.log(address)
  };

  const mapId = "longdo-map-" + address.addressType;
  return (
    <>
      <Box
        p={2}
        className={classes.map}
        sx={{
          height: "300px",
          zIndex: 9999,
          display: displayMap ? "block" : "none"
        }}
      >
        <LongdoMap id={mapId} mapKey={mapKey} callback={initMap} />
      </Box>

      <Box
        sx={{ display: "flex", width: "100%", padding: 2, paddingBottom: 0 }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        {/* Address Detail*/}
        <GridContainer>
          <Grid item xs={12} sm={2} alignItems="center" container>
            <LabelUC label={t("address.lblAddressDetail")} />
          </Grid>
          <Grid item xs={12} sm={10}>
            <TextInputUC
              disabled={infoViewOnlyMode}
              placeholder="Address Detail"
              value={address.addressDetail}
              name="addressDetail"
              onChange={e => handleInputChange(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="show map"
                      onClick={() => setDisplayMap(!displayMap)}
                      edge="end"
                    >
                      <PinDropIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </GridContainer>
      </Box>
      <Box
        sx={{ display: "flex", width: "100%", padding: 2, paddingBottom: 0 }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        {/* Sub District */}
        <GridContainer>
          <Grid item xs={12} sm={2} alignItems="center" container>
            <LabelUC label={t("address.lblSubDistrict")} />
          </Grid>
          <Grid item xs={12} sm={4}>
            {infoViewOnlyMode ? (
              <TextInputUC
                disabled={true}
                placeholder="subDistrict"
                value={address.subDistrict}
                name="subDistrict"
              />
            ) : (
              <InputThaiAddress
                field={"subdistrict"}
                value={address.subDistrict}
                name="subDistrict"
                onChange={handleChange("subDistrict")}
                onSelect={onSelect}
                disabled={infoViewOnlyMode}
              />
            )}
          </Grid>
          {/* District */}
          <Grid item xs={12} sm={2} alignItems="center" container>
            <LabelUC label={t("address.lblDistrict")} />
          </Grid>
          <Grid item xs={12} sm={4}>
            {infoViewOnlyMode ? (
              <TextInputUC
                disabled={true}
                placeholder="district"
                value={address.district}
                name="district"
              />
            ) : (
              <InputThaiAddress
                field={"district"}
                value={address.district}
                onChange={handleChange("district")}
                onSelect={onSelect}
                name="district"
              />
            )}
          </Grid>
        </GridContainer>
      </Box>
      <Box
        sx={{ display: "flex", width: "100%", padding: 2, paddingBottom: 0 }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        {/* จังหวัด */}
        <GridContainer>
          <Grid item xs={12} sm={2} alignItems="center" container>
            <LabelUC label={t("address.lblProvince")} />
          </Grid>
          <Grid item xs={12} sm={4}>
            {infoViewOnlyMode ? (
              <TextInputUC
                disabled={true}
                placeholder="province"
                value={address.province}
                name="province"
              />
            ) : (
              <InputThaiAddress
                field={"province"}
                value={address.province}
                onChange={handleChange("province")}
                onSelect={onSelect}
                name="province"
              />
            )}
          </Grid>
          {/* Postal Code */}
          <Grid item xs={12} sm={2} alignItems="center" container>
            <LabelUC label={t("address.lblPostalCode")} />
          </Grid>
          <Grid item xs={12} sm={4}>
            {infoViewOnlyMode ? (
              <TextInputUC
                disabled={true}
                placeholder="postalCode"
                value={address.postalCode}
                name="postalCode"
              />
            ) : (
              <InputThaiAddress
                field={"zipcode"}
                value={address.postalCode}
                onChange={handleChange("postalCode")}
                onSelect={onSelect}
                name="postalCode"
              />
            )}
          </Grid>
        </GridContainer>
      </Box>
      <Box
        sx={{ display: "flex", width: "100%", padding: 2, paddingBottom: 0 }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        {/* Sub District */}
        <GridContainer>
          <Grid item xs={12} sm={2} alignItems="center" container>
            <LabelUC label={t("address.lblCountry")} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInputUC
              disabled={true}
              placeholder="Country"
              value={address.country}
              name="country"
            />
          </Grid>
          {/* District */}
          <Grid item xs={12} sm={2} alignItems="center" container>
            <LabelUC label={t("address.lblAddressType")} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInputUC
              disabled={true}
              placeholder="Address Type"
              value={address.addressType}
              name="addressType"
            />
          </Grid>
        </GridContainer>
      </Box>
      <Box
        sx={{ display: "flex", width: "100%", padding: 2 }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <GridContainer>
          {/* Latitude */}
          <Grid item xs={12} sm={2} alignItems="center" container>
            <LabelUC label={t("address.lblLatitude")} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInputUC
              disabled={infoViewOnlyMode}
              placeholder={t("address.lblLatitude")}
              value={address.latitude}
              onChange={handleInputChange}
              name="latitude"
            />
          </Grid>
          {/* Longitude */}
          <Grid item xs={12} sm={2} alignItems="center" container>
            <LabelUC label={t("address.lblLongitude")} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInputUC
              disabled={infoViewOnlyMode}
              placeholder={t("address.lblLongitude")}
              value={address.longitude}
              onChange={handleInputChange}
              name="longitude"
            />
          </Grid>
        </GridContainer>
      </Box>
    </>
  );
};

export default AddressUC;
