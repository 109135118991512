import React from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
// import { useLocation } from "react-router-dom";
import Error404 from "./Pages/404";
import Employee from "./Pages/Personnel/employee";
import TimeStamp from "./Pages/TimeAttendance/time-stamp";
import Shift from "./Pages/TimeAttendance/shift";
import Approve from "./Pages/Approve";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import ForgotPasswordPage from "./Auth/ForgotPassword";
import Home from "./Pages/Home";
import CompanyInformation from "./Pages/Company/CompanyInfo";
import Department from "./Pages/Company/Department";
import Position from "./Pages/Company/Position";
import {
  getStorageAuthenUser,
  getStorageLiff,
  setStorageAuthenUser,
  setStorageLiff,
  setStorageNotification,
  setStorageToken,
  setStorageRefreshToken
} from "services/Storage.service";
import ConfirmRegister from "./ExtraPages/confirm-register";
import Location from "./Pages/TimeAttendance/location";
import SetTimeStamp from "./Pages/TimeAttendance/set-time-stamp";
import ResetPasswordPage from "./Auth/ResetPassword";
import ClockInOut from "./Pages/TimeAttendance/time-stamp";
import Profile from "./Pages/Profile";
import PointSetting from "./Pages/Point/point-setting";
import EmployeePoint from "./Pages/Point/employee-point";
import MyPoint from "./Pages/Point/my-point";
import ClockInOutReport from "./Pages/Report/clock-in-out-report";
import LiffRegister from "./Line/liff-register";
import Customer from "./Line/customer";
import LiffAuthen from "./Line/liff-authen";
import liff from "@line/liff";
import config from "../services/helpers.js";
import * as Session from "../services/Session.service";
import { setStorageControllerName } from "../services/Storage.service";
import axios from "../services/auth/jwt/config";
import { setAuthUser } from "redux/actions/Auth";
import { setNotification } from "redux/actions";
import { useDispatch } from "react-redux";

const RestrictedRoute = ({ component: Component, ...rest }) => {
  // const { authUser } = useSelector(({ auth }) => auth);
  const authUser = getStorageAuthenUser();
  const liffClient = liff.isInClient();
  const { dispatch } = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const mobileLogin = async userId => {
    axios
      .post("auth/loginMobile", {
        uid: userId
      })
      .then(({ data }) => {
        console.log("after loginMobile : ===>", data.data);
        if (data.data) {
          setStorageToken(data.access_token);
          setStorageAuthenUser(data);
          setAuthUser(data);
          setStorageNotification(data.noti);
          // dispatch(setNotification(data.noti));
        }
      });
  };

  if (liffClient) {
    // const uid = Session.getLiff().uid;
    const liffStorage = getStorageLiff();
    if (liffStorage?.uid) {
      try {
        console.log("before loginMobile");
        mobileLogin(liffStorage?.uid);
      } catch (error) { }
    } else {
      if (config.dev) {
        setStorageLiff({
          uid: config.UID,
          pictureUrl: null
        });
        // Session.setLiff({
        //   uid: config.UID,
        //   pictureUrl: null
        // });
      } else if (!config.dev && liff.isInClient()) {
        setStorageControllerName("liff");
        let LiffConfig = "";
        switch (location.pathname.toLowerCase()) {
          case "/profile":
            LiffConfig = config.liffProfile;
            break;
          case "/mypoint":
            LiffConfig = config.liffPoint;
            break;
          case "/timestamp":
            LiffConfig = config.liffCheck;
            break;
          case "/liffauthen":
            LiffConfig = config.liffAuth;
            break;
          default:
            LiffConfig = config.liffId;
            break;
        }

        console.log("LiffConfig ===>", LiffConfig, location.pathname);
        //console.log(location.pathname.toLowerCase())
        try {
          liff.init(
            { liffId: LiffConfig },
            async () => {
              console.log("before liff.isLoggedIn()");
              if (liff.isLoggedIn()) {
                console.log("after check liff.isLoggedIn()", await liff.getProfile());
                //console.log('check 2');
                const uid = await liff.getProfile();
                console.log("after liff.getProfile()", uid);
                setStorageLiff({
                  uid: uid.userId,
                  pictureUrl: uid.pictureUrl
                });
                // Session.setLiff({
                //   uid: uid.userId,
                //   pictureUrl: uid.pictureUrl
                // });
                await mobileLogin(uid?.userId);

                console.log("uid", uid?.userId);
                const profile = await axios.get(
                  "employee/getByUid/" + uid?.userId
                );
                console.log("routes | index.js | line:137 | after getByUid:", profile);
                if (!profile?.data?.id) {
                  if (
                    location.pathname.toLowerCase() === "/profile" ||
                    location.pathname.toLowerCase() === "/mypoint" ||
                    location.pathname.toLowerCase() === "/timestamp"
                  ) {
                    console.log('routes | index.js | line:144 | history.push("/liffauthen")')
                    history.push("/liffauthen");
                  }
                } else if (location.pathname.toLowerCase() === "/liffauthen") {
                  console.log('routes | index.js | line:148 | liff.closeWindow()')
                  liff.closeWindow();
                }
              } else {
                console.log('routes | index.js | line:152 | liff.login()')
                liff.login();
              }
            },
            err => console.error(err)
          );
        } catch (error) {
          console.log('error liff.init', error)
        }
      }
    }
  }

  return (
    <Route
      {...rest}
      render={props =>
        liffClient || authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth/signin",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  // setStorageControllerName("liff");
  return (
    <React.Fragment>
      <Switch>
        {/* Line Liff */}
        <Route exact path="/confirmregister" component={ConfirmRegister} />
        <Route exact path="/liffregister" component={LiffRegister} />
        <Route exact path="/liffauthen" component={LiffAuthen} />
        <RestrictedRoute path="/home" exact component={Home} />
        {/* Company */}
        <RestrictedRoute
          path="/company/company"
          exact
          component={CompanyInformation}
        />
        <RestrictedRoute
          path="/company/department"
          exact
          component={Department}
        />
        <RestrictedRoute path="/company/position" exact component={Position} />
        {/* Personnel */}
        <RestrictedRoute
          path="/personnel/employee"
          exact
          component={Employee}
        />
        {/* Time */}
        <RestrictedRoute
          path="/timeattendance/timestamp"
          exact
          component={TimeStamp}
        />
        <RestrictedRoute path="/timeattendance/shift" exact component={Shift} />
        <RestrictedRoute
          path="/timeattendance/setTimeStamp"
          exact
          component={SetTimeStamp}
        />
        <RestrictedRoute
          path="/timeattendance/location"
          exact
          component={Location}
        />
        <RestrictedRoute path="/timestamp" exact component={ClockInOut} />
        {/* Approve */}
        <RestrictedRoute path="/approve" exact component={Approve} />
        {/* Auth */}
        <Route path="/auth/signin" exact component={Login} />
        <Route path="/auth/signup" exact component={Register} />
        <Route
          path="/auth/forgotpassword"
          exact
          component={ForgotPasswordPage}
        />
        <Route
          path="/auth/resetpassword/:id"
          exact
          component={ResetPasswordPage}
        />
        <RestrictedRoute path="/profile" exact component={Profile} />
        <Route path="/confirmregister/:id" exact component={ConfirmRegister} />
        {/* Point */}
        <RestrictedRoute
          path="/point/pointSetting"
          exact
          component={PointSetting}
        />
        <RestrictedRoute
          path="/point/employeePoint"
          exact
          component={EmployeePoint}
        />
        <RestrictedRoute path="/myPoint" exact component={MyPoint} />
        {/* Report */}
        <RestrictedRoute
          path="/report/clockInOutReport"
          exact
          component={ClockInOutReport}
        />
        {/* Customer */}
        <Route path="/line/customer" exact component={Customer} />
        <RestrictedRoute path="/" exact component={Home} />
        <RestrictedRoute component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
