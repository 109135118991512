import { Grid, Box, Avatar } from "@mui/material";
import React from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import useStyles from "./index.style";

const GivePointItem = ({
  handleClickItem,
  handleClickCustom,
  item,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        item
        xs={12}
        sm={4}
        alignItems="center"
        container
        onClick={() => handleClickItem(item)}
        {...rest}
      >
        <Box className={classes.giftBox}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center"
            }}
          >
            {item.type !== "custom" && (
              <>
                <span className={classes.textPoint}>{item.point}</span>
                <Avatar
                  src={"images/point/star.png"}
                  sx={{ width: 24, height: 24, marginLeft: 1 }}
                />
              </>
            )}
            {item.type === "custom" && (
              <MoreHorizIcon
                sx={{ width: 24, height: 24, color: "#02D2F7" }}
                onClick={() => handleClickCustom()}
              />
            )}
          </Box>

          <Box p={2} sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={item.image}
              sx={{ width: 60, height: 60, borderRadius: "unset" }}
            />
          </Box>
          <Box
            mt={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold"
            }}
          >
            {item.label}
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default GivePointItem;
