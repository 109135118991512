import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import InfoUC from "routes/shared/uc/info-uc/info-uc-dialog";
import detectStateChange from "services/detectStateChange";
import { onCreate, onUpdate } from "redux/actions/Default";
import { Box, Grid } from "@mui/material";
// import useStyles from '../index.style';
import GridContainer from "@jumbo/components/GridContainer";
import LabelUC from "routes/shared/uc/label-uc";
import { useTranslation } from "react-i18next";
import TextInputUC from "routes/shared/uc/text-input-uc";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AutoCompleteUC from "routes/shared/uc/autocomplete-uc";

const PositionInfo = ({ handleDialog, openInfo }) => {
  // const classes = useStyles();
  const { currentPosition, mainPosition } = useSelector(
    ({ positions }) => positions
  );
  const { infoViewOnlyMode } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isLangEn = i18n.language === "en";

  const pos = currentPosition ? currentPosition : {};
  const [position, setPosition] = useState({
    id: pos.id || null,
    positionCode: pos.positionCode || "",
    positionName: pos.positionName || "",
    positionNameEn: pos.positionNameEn || "",
    mainPositionId: pos.mainPositionId || null,
    description: pos.description || "",
    jobDescription: pos.jobDescription || "",
    companyId: pos.companyId || "",
    createdDate: pos.createdDate || new Date(),
    createdBy: pos.createdBy || "",
    modifiedDate: pos.modifiedDate || new Date(),
    modifiedBy: pos.modifiedBy || ""
  });

  const [positionBind, setPositionBind] = useState(null);
  let positionDataList = [];
  if (mainPosition) {
    positionDataList = mainPosition.map(item => ({
      id: item.id,
      title: `${(isLangEn ? item.positionNameEn : item.positionName) || ""} `,
      description: item.positionCode
    }));
  }

  useEffect(() => {
    if (positionDataList) {
      const data = positionDataList.filter(
        item => item.id === position.mainPositionId
      );
      console.log("data", data);
      setPositionBind(data && data.length > 0 ? data[0] : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  detectStateChange([position]);

  const handleChange = e => {
    setPosition({ ...position, [e.target.name]: e.target.value });
  };

  const handleChangeMainPosition = value => {
    setPositionBind(value);
    setPosition({ ...position, mainPositionId: value ? value.id : null });
  };

  const handleInfoDialog = action => {
    console.log("action dialog :" + action);
    switch (action) {
      case "save":
        handleSubmit();
        break;
      case "delete":
        console.log("delete from info.");
        if (currentPosition) {
          handleDialog({ action: "delete", value: [currentPosition.id] });
        }
        break;
      default:
        handleDialog();
        break;
    }
  };

  const handleSubmit = () => {
    console.log("handleSubmit");
    if (!position.positionName || !position.positionCode) {
      setPosition({
        ...position,
        positionCodeError: !position.positionCode ? t("common.isRequired") : "",
        positionNameError: !position.positionName ? t("common.isRequired") : ""
      });
    } else {
      const positionSave = {
        positionCode: position.positionCode,
        positionName: position.positionName,
        positionNameEn: position.positionNameEn,
        mainPositionId: position.mainPositionId,
        description: position.description,
        jobDescription: position.jobDescription,
        createdDate: position.createdDate,
        createdBy: position.createdBy,
        modifiedDate: position.modifiedDate,
        modifiedBy: position.modifiedBy
      };
      console.log(positionSave);
      if (currentPosition && currentPosition.id) {
        dispatch(onUpdate({ ...currentPosition, ...positionSave }));
        console.log("Update success");
      } else {
        dispatch(onCreate(positionSave));
        console.log("Insert Success");
      }
      handleDialog({ action: "save", value: "" });
    }
  };

  //create form
  const positionInfoForm = () => {
    return (
      <>
        <Box
          sx={{ display: "flex", width: "100%" }}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box sx={{ padding: 2, width: { xs: "100%" } }} m="auto">
            {/* Position Code */}
            <GridContainer>
              <Grid item xs={12} sm={5} alignItems="center" container>
                <LabelUC
                  label={t("position.lblPositionCode")}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextInputUC
                  disabled={infoViewOnlyMode}
                  placeholder={t("position.lblPositionCodePlaceholder")}
                  value={position.positionCode}
                  name="positionCode"
                  onChange={e => handleChange(e)}
                  helperText={position.positionCodeError}
                />
              </Grid>
            </GridContainer>
            {/* Position Name */}
            <GridContainer>
              <Grid item xs={12} sm={5} alignItems="center" container>
                <LabelUC
                  label={t("position.lblPositionName")}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextInputUC
                  disabled={infoViewOnlyMode}
                  placeholder={t("position.lblPositionNamePlaceholder")}
                  value={position.positionName}
                  name="positionName"
                  onChange={e => handleChange(e)}
                  helperText={position.positionNameError}
                />
              </Grid>
            </GridContainer>
            {/* Position Name EN*/}
            <GridContainer>
              <Grid item xs={12} sm={5} alignItems="center" container>
                <LabelUC label={t("position.lblPositionNameEn")} />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextInputUC
                  disabled={infoViewOnlyMode}
                  placeholder={t("position.lblPositionNameEnPlaceholder")}
                  value={position.positionNameEn}
                  name="positionNameEn"
                  onChange={e => handleChange(e)}
                />
              </Grid>
            </GridContainer>
            {/* Main Position */}
            <GridContainer>
              <Grid item xs={12} sm={5} alignItems="center" container>
                <LabelUC label={t("position.lblMainPosition")} />
              </Grid>
              <Grid item xs={12} sm={7}>
                <AutoCompleteUC
                  datas={positionDataList}
                  infoViewOnlyMode={infoViewOnlyMode}
                  isMultipleLine={true}
                  name="mainPositionId"
                  value={positionBind}
                  handleChange={handleChangeMainPosition}
                  placeholder={t("position.lblMainPositionPlaceholder")}
                />
              </Grid>
            </GridContainer>
            {/* Description */}
            <GridContainer>
              <Grid item xs={12} sm={5}>
                <LabelUC label={t("position.lblPositionDes")} />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextareaAutosize
                  disabled={infoViewOnlyMode}
                  aria-label="minimum height"
                  minRows={3}
                  value={position.description}
                  maxLength={500}
                  name="description"
                  placeholder={t("position.lblPositionDesPlaceholder")}
                  style={{ width: "100%" }}
                  onChange={e => handleChange(e)}
                />
              </Grid>
            </GridContainer>
            {/* Job Description */}
            <GridContainer>
              <Grid item xs={12} sm={5}>
                <LabelUC label={t("position.lblJobPositionDes")} />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextareaAutosize
                  disabled={infoViewOnlyMode}
                  aria-label="minimum height"
                  minRows={3}
                  value={position.jobDescription}
                  maxLength={500}
                  name="jobDescription"
                  placeholder={t("position.lblJobPositionDesPlaceholder")}
                  style={{ width: "100%" }}
                  onChange={e => handleChange(e)}
                />
              </Grid>
            </GridContainer>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <InfoUC
        open={openInfo}
        handleDialog={handleInfoDialog}
        children={positionInfoForm()}
      />
    </>
  );
};

export default PositionInfo;

PositionInfo.propType = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func,
  selectedposition: PropTypes.object
};

PositionInfo.defaultProps = {
  selectedposition: null
};
