import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import AppSwitch from "@jumbo/components/Common/formElements/AppSwitch";

const SetTimeStampAttachPhoto = ({ props }) => {
  const { infoViewOnlyMode, data, handleChangeCheckbox } = props;
  const { t } = useTranslation();

  return (
    <>
      <Box pl={{ xs: 2, sm: 6 }} mt={3}>
        <AppSwitch
          name="isPhoto"
          label={t("setTimeStamp.lblIsPhoto")}
          labelPlacement="end"
          onChange={handleChangeCheckbox}
          checked={data.isPhoto}
          disabled={infoViewOnlyMode || !data.isAllowTimeStamp}
        />
      </Box>
    </>
  );
};

export default SetTimeStampAttachPhoto;
