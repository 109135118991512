import React from "react";
import Box from "@material-ui/core/Box";
import CmtAvatar from "../../../../../@coremat/CmtAvatar";
import Typography from "@material-ui/core/Typography";
import useStyles from "./EmployeeCell.style";
import PropTypes from "prop-types";
// import { useTranslation } from 'react-i18next';
import MoreOptions from "./MoreOptions";

const EmployeeCell = ({ employee, onShowEmployeeDetail, onEdit }) => {
  const classes = useStyles();
  // const { t } = useTranslation();
  const {
    empCode,
    firstName,
    lastName,
    image,
    positionName,
    orgUnitName
  } = employee;
  return (
    <Box
      className={classes.gridEmployeeCell}
      onClick={() => onShowEmployeeDetail(employee.id)}
    >
      <Box
        className={classes.gridEmployeellHeader}
        display="flex"
        mb={3}
        sx={{ position: "absolute", top: "-40px" }}
      >
        <CmtAvatar size={80} src={image} alt={firstName} />
      </Box>
      <Box className={classes.gridEmployeellHeader} display="flex" mb={3}>
        <Box ml={{ sm: "auto" }} onClick={e => e.stopPropagation()}>
          <MoreOptions employee={employee} onEdit={onEdit} />
        </Box>
      </Box>
      <Box>
        <Typography className={classes.titleRoot} component="div" variant="h1">
          {empCode}
        </Typography>
        <Typography className={classes.titleRoot} component="div" variant="h4">
          {/* {title ? t('enum.' + title) : ''} */}
          {firstName} {lastName}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography className={classes.subTitleRoot}>{positionName}</Typography>
        <Typography className={classes.subTitleRoot}>{orgUnitName}</Typography>
      </Box>
    </Box>
  );
};

export default EmployeeCell;

EmployeeCell.prototype = {
  employee: PropTypes.object.isRequired,
  onShowEmployeeDetail: PropTypes.func,
  onEdit: PropTypes.func
};
