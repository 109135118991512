import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Avatar, useTheme, Popover, Box } from "@material-ui/core";
import {
  componentColors,
  getSeparatorStyle
} from "../../../../../../../src/@coremat/CmtHelpers/JssHelper";
import useStyles from "./index.style";
import CmtCardContent from "../../../../../../@coremat/CmtCard/CmtCardContent";
import CmtCard from "../../../../../../@coremat/CmtCard";
import Typography from "@material-ui/core/Typography";
import CmtCardFooter from "@coremat/CmtCard/CmtCardFooter";
import {
  AccountCircleRounded,
  PowerSettingsNewRounded
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { AuhMethods } from "services/auth";
import { CurrentAuthMethod } from "@jumbo/constants/AppConstants";
import { NavLink, useHistory } from "react-router-dom";
import LangSwitcher from "../../LangSwitcher";
import { getStorageAuthenUser } from "services/Storage.service";

const colorOptions = [...componentColors, "random"];

const getRandomColor = () => {
  return colorOptions[Math.floor(Math.random() * 11)];
};

const CmtAvatar = React.forwardRef(
  (
    { src, alt, className, color, size, phCharLength, children, ...rest },
    ref
  ) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const separator = {
      color: theme.palette.borderColor.dark,
      borderWidth: 1,
      borderStyle: "solid"
    };
    const separatorStyles = getSeparatorStyle(separator);
    const classes = useStyles({
      size: typeof size === "number" ? size : 0,
      separatorStyles
    });
    const colorClass =
      color === "random" ? classes[getRandomColor()] : classes[color];
    const classNames = clsx(
      classes.root,
      colorClass,
      "Cmt-avatar-size",
      classes.small,
      className && className
    );
    const cardContentClassName = clsx(
      classes.cardContent,
      separator.color ? "Cmt-separator" : ""
    );
    const placeHolderChar =
      alt && phCharLength > 0
        ? alt.substr(0, phCharLength).toUpperCase()
        : null;
    const history = useHistory();

    // pop over
    const [anchorEl, setAnchorEl] = React.useState(null);
    const onOpenPopOver = event => {
      setAnchorEl(event.currentTarget);
    };
    const onClosePopOver = () => {
      setAnchorEl(null);
    };

    const onItemClick = item => {
      if (item === "logout") {
        dispatch(AuhMethods[CurrentAuthMethod].onLogout());
        // history.push("/");
        console.log("logout");
      } else if (item === "profile") {
        history.push("/profile");
      }
      onClosePopOver();
    };

    const { authUser } = useSelector(({ auth }) => auth);
    useEffect(() => {}, [authUser]);
    const data = getStorageAuthenUser();
    const user = data && data.data;
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <>
        <Avatar
          onClick={onOpenPopOver}
          ref={ref}
          className={classNames}
          src={user ? user.img : src}
          alt={alt}
          {...rest}
        >
          {!src && !children && alt ? placeHolderChar : children}
        </Avatar>
        <Popover
          className={classes.popoverRoot}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={onClosePopOver}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent className={cardContentClassName}>
              <Avatar
                className={classes.popoverAvatar}
                src={user ? user.img : src}
                alt={alt}
              />
              <Typography className={classes.userName} variant="body2">
                {user ? user.userName : ""}
              </Typography>
              <Typography className={classes.userRole} variant="body2">
                {user ? user.role : ""}
              </Typography>
              {user && (
                <Box p={3} pb={0} className={classes.imgContent}>
                  <AccountCircleRounded
                    titleAccess="Profile"
                    onClick={() => onItemClick("profile")}
                    className={classes.iconRounded}
                  />
                  <PowerSettingsNewRounded
                    titleAccess="Log Out"
                    onClick={() => onItemClick("logout")}
                    sx={{ color: "red", marginLeft: 1 }}
                    className={classes.iconRounded}
                  />
                </Box>
              )}
              {!user && (
                <Box p={3} pb={0} className={classes.imgContent}>
                  <Typography className={classes.userName}>
                    <NavLink to="/">Sign In</NavLink> Or{" "}
                    <NavLink to="/auth/signup">Sign up</NavLink>
                  </Typography>
                </Box>
              )}
            </CmtCardContent>
            <CmtCardFooter>
              <LangSwitcher />
            </CmtCardFooter>
          </CmtCard>
        </Popover>
      </>
    );
  }
);

CmtAvatar.propTypes = {
  color: PropTypes.oneOf(colorOptions),
  phCharLength: PropTypes.number,
  size: PropTypes.oneOfType([
    PropTypes.oneOf(["small", "medium", "large"]),
    PropTypes.number
  ])
};

CmtAvatar.defaultProps = {
  color: "grey",
  phCharLength: 1,
  size: "medium"
};

export default CmtAvatar;
