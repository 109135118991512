import { makeStyles } from '@material-ui/core';
// import { hexToRgba } from '../CmtHelpers/JssHelper';
// import { amber, blue, brown, deepOrange, green, grey, purple, red, yellow } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    map: {
        '& .ldmap_placeholder': {
            borderRadius: theme.spacing(2),
        }
    },
}))

export default useStyles;