import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export default function TabHeader(props) {
  const [value, setValue] = useState("general");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="fullWidth"
          >
            <Tab label="General" value="general" />
            <Tab label="Time" value="time" />
          </TabList>
        </Box>
        <TabPanel value="general">{props.tabGeneral}</TabPanel>
        <TabPanel value="time">{props.tabTime}</TabPanel>
      </TabContext>
    </Box>
  );
}
