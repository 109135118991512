import {
  CREATE_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  GET_DEPARTMENT_LIST,
  SET_CURRENT_DEPARTMENT,
  SET_MAIN_DEPARTMENT
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  searchText: "",
  viewBy: "",
  departmentsList: [],
  mainDepartment: [],
  currentDepartment: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEPARTMENT_LIST: {
      // console.log('reducer')
      return {
        ...state,
        departmentsList: action.payload,
      };
    }

    case SET_CURRENT_DEPARTMENT: {
      return {
        ...state,
        currentDepartment: action.payload
        // currentEmpAddress: action.payload ? action.payload.currentEmpAddress : [],
      };
    }

    case SET_MAIN_DEPARTMENT: {
      return {
        ...state,
        mainDepartment: action.payload
      };
    }

    case CREATE_DEPARTMENT: {
      let updatedList = state.departmentsList;
      // console.log(state)
      updatedList = [action.payload, ...updatedList];
      return {
        ...state,
        departmentsList: updatedList
      };
    }

    case UPDATE_DEPARTMENT: {
      return {
        ...state,
        departmentsList: state.departmentsList.map(item =>
          item.id === action.payload.id ? action.payload : item
        )
      };
    }

    case DELETE_DEPARTMENT: {
      let updatedList = state.departmentsList;
      updatedList = updatedList.filter(
        department => !action.payload.includes(department.id)
      );
      return {
        ...state,
        departmentsList: updatedList
      };
    }

    default:
      return state;
  }
};
