import {
    CREATE_POSITION,
    UPDATE_POSITION,
    DELETE_POSITION,
    GET_POSITION_LIST,
    SET_CURRENT_POSITION,
    SET_MAIN_POSITION,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    searchText: '',
    viewBy: '',
    positionsList: [],
    mainPosition: [],
    currentPosition: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_POSITION_LIST: {
            console.log('reducer position')
            return {
                ...state,
                positionsList: action.payload,
            };
        }

        case SET_CURRENT_POSITION: {
            return {
                ...state,
                currentPosition: action.payload,
            };
        }

        case SET_MAIN_POSITION: {
            return {
                ...state,
                mainPosition: action.payload,
            };
        }

        case CREATE_POSITION: {
            let updatedList = state.positionsList;
            console.log(state)
            updatedList = [action.payload, ...updatedList];
            return {
                ...state,
                positionsList: updatedList
            };
        }

        case UPDATE_POSITION: {
            return {
                ...state,
                positionsList: state.positionsList.map(item => (item.id === action.payload.id ? action.payload : item)),
            };
        }

        case DELETE_POSITION: {
            let updatedList = state.positionsList;
            updatedList = updatedList.filter(position => !action.payload.includes(position.id));
            return {
                ...state,
                positionsList: updatedList
            };
        }

        default:
            return state;
    }
};