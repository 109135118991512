import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { AuhMethods } from "../../../../services/auth";
import ContentLoader from "../../ContentLoader";
import CmtImage from "../../../../@coremat/CmtImage";
import Typography from "@material-ui/core/Typography";
import { CurrentAuthMethod } from "../../../constants/AppConstants";
import AuthWrapper from "./AuthWrapper";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LangSwitcher from "@jumbo/components/AppLayout/partials/LangSwitcher";
import useStyles from "./signUp.style";
import GridContainer from "@jumbo/components/GridContainer";
import { Grid, IconButton, InputAdornment, Link } from "@mui/material";
import LabelUC from "routes/shared/uc/label-uc";
import TextInputUC from "routes/shared/uc/text-input-uc";
import AppSelectBox from "../formElements/AppSelectBox";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TermsAndCondition from "routes/ExtraPages/term-and-condition";
import PrivacyPolicy from "routes/ExtraPages/privacy-policy";
import { getEnum } from "redux/actions/Default";
import { validate as validateEmail } from "react-email-validator";
import InputMask from "react-input-mask";
import clsx from "clsx";

const SignUp = ({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default"
}) => {
  const [companyName, setCompanyName] = useState(""); //'Demo Company'
  const [numberOfEmp, setNumberOfEmp] = useState(0); //15
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState(""); //'Prayuth'
  const [lastName, setLastName] = useState(""); //'Chan-o-rose'
  const [phone, setPhone] = useState(""); //'0987654321'
  const [email, setEmail] = useState(""); //'pongpat.w@undefined.co.th'
  const [password, setPassword] = useState(""); //'123456789'
  const [confirmPassword, setConfirmPassword] = useState(""); //'123456789'

  const [companyNameErr, setCompanyNameErr] = useState("");
  const [numberOfEmpErr, setNumberOfEmpErr] = useState("");
  const [titleErr, setTitleErr] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [openTerms, setOpenTerms] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [enableSignUp, setEnableSignUp] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const requiredMessage = t("common.isRequired");

  const [nameTitle, setNameTitle] = useState([]);

  useEffect(() => {
    dispatch(
      getEnum("title", enumTitle => {
        setNameTitle(enumTitle);
        setTitle(enumTitle.find(e => e.name === "mr").id);
      })
    );
  }, [dispatch]);

  const onSubmit = () => {
    if (validate()) {
      const formValue = {
        companyName: companyName,
        numberOfEmp: numberOfEmp,
        titleName: title,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
        password: password,
        registerDate: new Date()
      };
      dispatch(AuhMethods[method].onRegister(formValue));
    }
  };

  const validate = () => {
    setCompanyNameErr(!companyName ? requiredMessage : "");
    setNumberOfEmpErr(!numberOfEmp ? requiredMessage : "");
    setTitleErr(!title ? requiredMessage : "");
    setFirstNameErr(!firstName ? requiredMessage : "");
    setLastNameErr(!lastName ? requiredMessage : "");
    setPhoneErr(!phone ? requiredMessage : "");
    setEmailErr(
      !email
        ? requiredMessage
        : validateEmail(email)
          ? ""
          : t("signUp.emailNotValid")
    );
    setPasswordErr(!password ? requiredMessage : "");
    setConfirmPasswordErr(
      !confirmPassword
        ? requiredMessage
        : password !== confirmPassword
          ? t("signUp.passwordNotMatch")
          : ""
    );
    if (
      !companyName ||
      !numberOfEmp ||
      !title ||
      !firstName ||
      !lastName ||
      !phone ||
      !email ||
      !password ||
      !confirmPassword ||
      password !== confirmPassword
    ) {
      return false;
    } else {
      return true;
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const openTermsClick = e => {
    e.stopPropagation();
    setOpenTerms(true);
  };

  const handleCloseTerms = () => {
    setOpenTerms(false);
  };

  const openPrivacyClick = e => {
    e.stopPropagation();
    setOpenPrivacy(true);
  };

  const handleClosePrivacy = () => {
    setOpenPrivacy(false);
  };

  const handleCheck = e => {
    setEnableSignUp(e.target.checked);
  };

  const handleChangeConfirmPassword = value => {
    setConfirmPassword(value);
    if (value !== password) {
      setConfirmPasswordErr(t("signUp.passwordNotMatch"));
    } else {
      setConfirmPasswordErr("");
    }
  };
  return (
    <>
      <AuthWrapper variant={wrapperVariant} maxWidth={"1330px"}>
        <Box className={classes.authContent}>
          <Box mb={7} className={classes.parentImg}>
            <CmtImage
              className={classes.img}
              src={"/images/unf-logo-black.png"}
            />
          </Box>
          <Typography
            component="div"
            variant="h1"
            className={classes.titleRoot}
          >
            {t("signUp.register")}
          </Typography>
          <form>
            <Box
              sx={{ display: "flex", width: "100%" }}
              flexDirection={{ xs: "column", md: "row" }}
            >
              {/* Company Name */}
              <GridContainer>
                <Grid item xs={12} sm={2} alignItems="center" container>
                  <LabelUC label={t("signUp.companyName")} isRequired={true} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInputUC
                    placeholder={t("signUp.companyName")}
                    value={companyName}
                    name="companyName"
                    onChange={e => setCompanyName(e.target.value)}
                    maxLength={200}
                    helperText={companyNameErr}
                  />
                </Grid>
                {/* Number Of Employee */}
                <Grid item xs={12} sm={2} alignItems="center" container>
                  <LabelUC label={t("signUp.numberOfEmp")} isRequired={true} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInputUC
                    placeholder={t("signUp.numberOfEmp")}
                    value={numberOfEmp}
                    name="numberOfEmp"
                    type="number"
                    onChange={(e) => setNumberOfEmp(e.target.value.substring(0, 4))}
                    helperText={numberOfEmpErr}
                  />
                </Grid>
              </GridContainer>
            </Box>
            <Box
              sx={{ display: "flex", width: "100%" }}
              mt={{ xs: 3, sm: 0 }}
              pt={{ xs: 3, sm: 0 }}
              flexDirection={{ xs: "column", md: "row" }}
            >
              {/* first name */}
              <GridContainer>
                <Grid item xs={12} sm={2} alignItems="center" container>
                  <LabelUC
                    label={t("employee.lblFirstName")}
                    isRequired={true}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={1}
                  sx={{ paddingRight: { xs: 3, sm: "2px !important" } }}
                >
                  <AppSelectBox
                    fullWidth
                    data={nameTitle}
                    variant="outlined"
                    value={title}
                    name="title"
                    onChange={e => setTitle(e.target.value)}
                    helperText={titleErr}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  sx={{ paddingLeft: { xs: 3, sm: "2px !important" } }}
                >
                  <TextInputUC
                    placeholder={t("employee.lblFirstName")}
                    value={firstName}
                    name="firstName"
                    onChange={e => setFirstName(e.target.value)}
                    helperText={firstNameErr}
                  />
                </Grid>
                {/* Lastname */}
                <Grid item xs={12} sm={2} alignItems="center" container>
                  <LabelUC
                    label={t("employee.lblLastName")}
                    isRequired={true}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInputUC
                    placeholder={t("employee.lblLastName")}
                    value={lastName}
                    name="lastName"
                    onChange={e => setLastName(e.target.value)}
                    helperText={lastNameErr}
                  />
                </Grid>
              </GridContainer>
            </Box>
            <Box
              sx={{ display: "flex", width: "100%" }}
              mt={{ xs: 3, sm: 0 }}
              pt={{ xs: 3, sm: 0 }}
              flexDirection={{ xs: "column", md: "row" }}
            >
              {/* phone */}
              <GridContainer>
                <Grid item xs={12} sm={2} alignItems="center" container>
                  <LabelUC label={t("signUp.phone")} isRequired={true} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    mask="099-999-9999"
                    value={phone}
                    maskChar=" "
                    name="phone"
                    placeholder={t("signUp.phone")}
                    onChange={e => setPhone(e.target.value)}
                    helperText={phoneErr}
                  >
                    {params => (
                      <TextInputUC
                        fullWidth
                        className={clsx(classes.textFieldRoot, "form-control")}
                        {...params}
                      />
                    )}
                  </InputMask>
                </Grid>
                {/* email */}
                <Grid item xs={12} sm={2} alignItems="center" container>
                  <LabelUC label={t("signUp.email")} isRequired={true} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInputUC
                    placeholder={t("signUp.email")}
                    value={email}
                    name="email"
                    onChange={e => setEmail(e.target.value)}
                    helperText={emailErr}
                  />
                </Grid>
              </GridContainer>
            </Box>
            <Box
              sx={{ display: "flex", width: "100%" }}
              mt={{ xs: 3, sm: 0 }}
              pt={{ xs: 3, sm: 0 }}
              flexDirection={{ xs: "column", md: "row" }}
            >
              {/* password */}
              <GridContainer>
                <Grid item xs={12} sm={2} alignItems="center" container>
                  <LabelUC label={t("signUp.password")} isRequired={true} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInputUC
                    placeholder={t("signUp.password")}
                    value={password}
                    name="password"
                    onChange={e => setPassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    helperText={passwordErr}
                  />
                </Grid>
                {/* confirm password */}
                <Grid item xs={12} sm={2} alignItems="center" container>
                  <LabelUC
                    label={t("signUp.confirmPassword")}
                    isRequired={true}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInputUC
                    placeholder={t("signUp.confirmPassword")}
                    value={confirmPassword}
                    name="confirmPassword"
                    onChange={e => handleChangeConfirmPassword(e.target.value)}
                    type={showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    helperText={confirmPasswordErr}
                  />
                </Grid>
              </GridContainer>
            </Box>
            <Box
              textAlign="center"
              mt={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <FormControlLabel
                control={<Checkbox onChange={handleCheck} />}
                label="I agree to the"
                sx={{ marginRight: 1 }}
              />
              <Link
                to="/"
                onClick={e => openTermsClick(e)}
                className="pointer"
                mr={1}
              >
                terms and conditions
              </Link>
              and
              <Link
                to="/"
                onClick={e => openPrivacyClick(e)}
                className="pointer"
                ml={1}
              >
                privacy policy.
              </Link>
            </Box>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ sm: "center" }}
              justifyContent={{ sm: "center" }}
              pt={{ xs: 3, sm: 0 }}
              mt={3}
              mb={3}
            >
              <Box mb={{ xs: 2, sm: 0 }} width={{ xs: "100%", md: "30%" }}>
                <Button
                  onClick={onSubmit}
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.btnRegister}
                  disabled={!enableSignUp}
                >
                  {t("signUp.register")}
                </Button>
              </Box>
            </Box>
          </form>

          <Typography className={classes.textAcc}>
            Have an account?
            <NavLink to="/auth/signin">Sign In</NavLink>
          </Typography>
          <ContentLoader />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={3}
          >
            <LangSwitcher />
          </Box>
        </Box>
      </AuthWrapper>
      {openTerms && (
        <TermsAndCondition open={openTerms} handleClose={handleCloseTerms} />
      )}
      {openPrivacy && (
        <PrivacyPolicy open={openPrivacy} handleClose={handleClosePrivacy} />
      )}
    </>
  );
};

export default SignUp;
