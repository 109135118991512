import GridContainer from "@jumbo/components/GridContainer";
import { Grid, Box } from "@mui/material";
import React from "react";
import LabelUC from "routes/shared/uc/label-uc";
import TextInputUC from "routes/shared/uc/text-input-uc";
import { useTranslation } from "react-i18next";
import Map from "./map";

const LocationInfo = ({ props }) => {
  const { location, infoViewOnlyMode, handleChange } = props;
  const { t } = useTranslation();

  return (
    <Box
      sx={{ display: "flex", width: "100%" }}
      flexDirection={{ xs: "column", md: "row" }}
    >
      <Box
        sx={{
          padding: 2,
          width: "100%" /*width: { xs: "100%", lg: "100%" } */
        }}
      >
        {/* Location Code */}
        <GridContainer>
          <Grid item xs={12} sm={2} alignItems="center" container>
            <LabelUC label={t("location.lblLocationCode")} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInputUC
              disabled={infoViewOnlyMode}
              placeholder={t("location.lblLocationCode")}
              value={location.locationCode}
              name="locationCode"
              onChange={handleChange}
            />
          </Grid>
        </GridContainer>
        {/* Location Name */}
        <GridContainer>
          <Grid item xs={12} sm={2} alignItems="center" container>
            <LabelUC label={t("location.lblLocationName")} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInputUC
              disabled={infoViewOnlyMode}
              placeholder={t("location.lblLocationName")}
              value={location.locationName}
              name="locationName"
              onChange={handleChange}
            />
          </Grid>
        </GridContainer>
        {/* Location Name En*/}
        <GridContainer>
          <Grid item xs={12} sm={2} alignItems="center" container>
            <LabelUC label={t("location.lblLocationNameEn")} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInputUC
              disabled={infoViewOnlyMode}
              placeholder={t("location.lblLocationNameEn")}
              value={location.locationNameEn}
              name="locationNameEn"
              onChange={handleChange}
            />
          </Grid>
        </GridContainer>
        <Map props={props} />
      </Box>
    </Box>
  );
};

export default LocationInfo;
