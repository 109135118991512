import { useEffect, useRef } from 'react'
import { setIsDataModified } from 'redux/actions';
import { useDispatch } from 'react-redux';

const DetectStateChange = (deps = []) => {
    const firstUpdate = useRef(true);
    const dispatch = useDispatch();
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        // console.log('State Changed');
        dispatch(setIsDataModified(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps, [dispatch]);
}

export default DetectStateChange