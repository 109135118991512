import { Box, Paper } from "@mui/material";
import React from "react";
import useStyles from "./index.style";
import TabTimeClockIn from "./tab-time-clock-in";
import TabTimeClockOut from "./tab-time-clock-out";
import TabTimeNotAttendance from "./tab-time-not-attendance";

const TabTime = ({ props }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Box
        sx={{ display: "flex", width: "100%" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <form className={classes.form}>
          <TabTimeClockIn props={props} />
          <TabTimeClockOut props={props} />
          <TabTimeNotAttendance props={props} />
        </form>
      </Box>
    </Paper>
  );
};

export default TabTime;
