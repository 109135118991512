import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tableCellAction: {
    minWidth: 150,
  },
  gridEmployeeCell: {
    border: `1px solid ${theme.palette.borderColor.main}`,
    borderRadius: 4,
    margin: 6,
    padding: 16,
    color: theme.palette.text.secondary,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
    marginTop: '40px',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)',
      '& .action-option': {
        opacity: 1,
        visibility: 'visible',
        transform: 'translateX(0)',
      },
      '& .star-view': {
        transform: 'translateX(0)',
      },
    },
  },
  gridEmployeeCellHeader: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    '@media screen and (min-width: 1280px) and (max-width: 1399px)': {
      flexDirection: 'column',
    },
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
  subTitleRoot: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.secondary,
  },
  textTruncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
}));

export default useStyles;
