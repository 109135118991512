import { SET_CURRENT_CUSTOMER } from "../../@jumbo/constants/ActionTypes";

export const setCurrentCustomer = customer => {
    return dispatch => {
        dispatch({
            type: SET_CURRENT_CUSTOMER,
            payload: customer
        });
    };
};

export const setCustomerById = (data, callbackFun = null) => {
    return dispatch => {
        dispatch({ type: SET_CURRENT_CUSTOMER, payload: data.data });
        if (callbackFun) callbackFun(data.data);
    };
};