import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import GridContainer from "@jumbo/components/GridContainer";
import { Grid } from "@material-ui/core";
import { Box, TextField } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";
import LabelUC from "routes/shared/uc/label-uc";
import TextInputUC from "routes/shared/uc/text-input-uc";
import useStyles from "../index.style";

const ContactInfo = ({ props }) => {
  const { emp, handleChange, infoViewOnlyMode } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { empEnum } = useSelector(({ employees }) => employees);
  const enumRelation = empEnum.filter(
    e => e.type === "relation" || e.type === "none"
  );
  // console.log('enum', empEnum);
  // console.log('relate', enumRelation);
  return (
    <>
      <Box
        sx={{ display: "flex", width: "100%" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        {/* info left */}
        <Box sx={{ padding: 2, width: { xs: "100%", lg: "50%" } }}>
          {/* Mobile */}
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("employee.lblPhone")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              {/* <TextInputUC
                                disabled={infoViewOnlyMode}
                                placeholder={t("employee.lblPhone")}
                                value={emp.phone}
                                name='phone'
                                onChange={handleChange}
                            /> */}
              <InputMask
                mask="099-999-9999"
                value={emp.phone}
                maskChar=" "
                name="phone"
                placeholder={t("employee.lblPhone")}
                onChange={handleChange}
                disabled={infoViewOnlyMode}
              >
                {params => (
                  <TextField
                    fullWidth
                    disabled={infoViewOnlyMode}
                    className={clsx(classes.formControlRoot, "form-control")}
                    {...params}
                  />
                )}
              </InputMask>
            </Grid>
          </GridContainer>
          {/* Email */}
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("employee.lblEmail")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextInputUC
                disabled={infoViewOnlyMode}
                placeholder={t("employee.lblEmail")}
                value={emp.email}
                name="email"
                onChange={handleChange}
              />
            </Grid>
          </GridContainer>

          {/* Line ID */}
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("employee.lblLineId")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextInputUC
                disabled={infoViewOnlyMode}
                placeholder={t("employee.lblLineId")}
                value={emp.lineId}
                name="lineId"
                onChange={handleChange}
              />
            </Grid>
          </GridContainer>
        </Box>
        {/* info right */}
        <Box sx={{ padding: 2, width: { xs: "100%", lg: "50%" } }}>
          {/* Emergency Contact */}
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("employee.lblEmergencyName")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextInputUC
                disabled={infoViewOnlyMode}
                placeholder={t("employee.lblEmergencyName")}
                value={emp.emergencyName}
                name="emergencyName"
                onChange={handleChange}
              />
            </Grid>
          </GridContainer>
          {/* Emergency Relation */}
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("employee.lblEmergencyRelation")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <AppSelectBox
                fullWidth
                data={enumRelation}
                variant="outlined"
                disabled={infoViewOnlyMode}
                value={emp.emergencyRelate}
                name="emergencyRelate"
                onChange={handleChange}
              />
            </Grid>
          </GridContainer>
          {/* Emergency Call */}
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("employee.lblEmergencyPhone")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              {/* <TextInputUC
                                disabled={infoViewOnlyMode}
                                placeholder={t("employee.lblEmergencyPhone")}
                                value={emp.emergencyPhone}
                                name='emergencyPhone'
                                onChange={handleChange}
                            /> */}
              <InputMask
                mask="099-999-9999"
                value={emp.emergencyPhone}
                maskChar=" "
                name="emergencyPhone"
                placeholder={t("employee.lblEmergencyPhone")}
                onChange={handleChange}
                disabled={infoViewOnlyMode}
              >
                {params => (
                  <TextField
                    fullWidth
                    disabled={infoViewOnlyMode}
                    className={clsx(classes.formControlRoot, "form-control")}
                    {...params}
                  />
                )}
              </InputMask>
            </Grid>
          </GridContainer>
        </Box>
      </Box>
    </>
  );
};

export default ContactInfo;
