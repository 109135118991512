import React from "react";
import {
  Business,
  ArrowForward,
  Person
  // QueryBuilder
  // AssignmentTurnedIn
} from "@material-ui/icons";
import {
  // Home,
  AccessTime,
  Stars,
  ReceiptLong,
  Star,
  Group
} from "@mui/icons-material";

export const sidebarNavs = [
  {
    name: "sidebar.main",
    type: "section",
    role: ["technician", "manager", "employee", "hr", "sale"],
    children: [
      // {
      //   name: "sidebar.home",
      //   icon: <Home />,
      //   type: "item",
      //   link: "/home",
      //   role: ["technician", "manager", "employee", "hr"]
      // },      
      {
        name: "sidebar.menu.timeStamp",
        icon: <AccessTime />,
        type: "item",
        link: "/timeStamp",
        role: ["technician", "manager", "employee", "hr", "sale"]
      },
      {
        name: "sidebar.profile",
        icon: <Person />,
        type: "item",
        link: "/profile",
        role: ["technician", "manager", "employee", "hr", "sale"]
      },
      {
        name: "sidebar.menu.myPoint",
        icon: <Stars />,
        type: "item",
        link: "/myPoint",
        role: ["technician", "manager", "employee", "hr", "sale"]
      },
      {
        name: "sidebar.company",
        icon: <Business />,
        type: "collapse",
        role: ["technician", "hr"],
        children: [
          {
            name: "sidebar.company_section",
            type: "section",
            role: ["technician", "hr"],
            children: [
              {
                name: "sidebar.menu.company",
                icon: <ArrowForward />,
                type: "item",
                link: "/company/company",
                role: ["technician", "hr"]
              },
              {
                name: "sidebar.menu.department",
                icon: <ArrowForward />,
                type: "item",
                link: "/company/department",
                role: ["technician", "hr"]
              },
              {
                name: "sidebar.menu.position",
                icon: <ArrowForward />,
                type: "item",
                link: "/company/position",
                role: ["technician", "hr"]
              }
            ]
          },
          {
            name: "sidebar.timeAttendance",
            type: "section",
            role: ["technician", "hr"],
            children: [
              {
                name: "sidebar.menu.shift",
                icon: <ArrowForward />,
                type: "item",
                link: "/timeAttendance/shift",
                role: ["technician", "hr"]
              },
              {
                name: "sidebar.menu.location",
                icon: <ArrowForward />,
                type: "item",
                link: "/timeAttendance/location",
                role: ["technician", "hr"]
              },
              {
                name: "sidebar.menu.setTimeStamp",
                icon: <ArrowForward />,
                type: "item",
                link: "/timeAttendance/setTimeStamp",
                role: ["technician", "hr"]
              }
            ]
          },
          {
            name: "sidebar.point",
            type: "section",
            role: ["technician", "hr"],
            children: [
              {
                name: "sidebar.menu.pointSetting",
                icon: <ArrowForward />,
                type: "item",
                link: "/point/pointSetting",
                role: ["technician", "hr"]
              }
            ]
          }
        ]
      },
      {
        name: "sidebar.personnel",
        icon: <Person />,
        type: "collapse",
        role: ["manager", "hr"],
        children: [
          {
            name: "sidebar.menu.employee",
            icon: <ArrowForward />,
            type: "item",
            link: "/personnel/employee",
            role: ["manager", "hr"]
          }
        ]
      },
      {
        name: "sidebar.point",
        icon: <Star />,
        type: "collapse",
        role: ["administrator", "hr"],
        children: [
          {
            name: "sidebar.menu.employeePoint",
            icon: <ArrowForward />,
            type: "item",
            link: "/point/employeePoint",
            role: ["hr"]
          }
        ]
      },
      {
        name: "sidebar.report",
        icon: <ReceiptLong />,
        type: "collapse",
        role: ["manager", "hr"],
        children: [
          {
            name: "sidebar.menu.clockInOutReport",
            icon: <ArrowForward />,
            type: "item",
            link: "/report/clockInOutReport",
            role: ["manager", "hr"]
          }
        ]
      },
      {
        name: "sidebar.menu.customer",
        icon: <Group />,
        type: "item",
        link: "/line/customer",
        role: ["sale"]
      }
    ]
  }
];
