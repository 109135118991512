import React from "react";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";

const TextInputUC = ({
  disabled = false,
  placeholder,
  value,
  onChange,
  helperText,
  name,
  maxLength,
  ...rest
}) => {
  return (
    <>
      <AppTextInput
        name={name}
        disabled={disabled}
        variant="outlined"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        helperText={helperText}
        InputLabelProps={{ shrink: false }}
        maxLength={maxLength}
        {...rest}
      />
    </>
  );
};

export default TextInputUC;
