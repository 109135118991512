import React from "react";
import WorkingInfo from "./workingInfo";
import WorkingTime from "./workingTime";

const Employment = ({ props }) => {
  return (
    <>
      <WorkingInfo props={props} />
      <WorkingTime props={props} />
    </>
  );
};

export default Employment;
