import {
  CREATE_SHIFT,
  UPDATE_SHIFT,
  DELETE_SHIFT,
  GET_SHIFT_LIST,
  SET_CURRENT_SHIFT,
  SET_ALL_SHIFT_DETAIL
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  searchText: "",
  viewBy: "",
  shiftsList: [],
  allShiftDetail: [],
  currentShift: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SHIFT_LIST: {
      return {
        ...state,
        shiftsList: action.payload
      };
    }

    case SET_CURRENT_SHIFT: {
      return {
        ...state,
        currentShift: action.payload
      };
    }

    case SET_ALL_SHIFT_DETAIL: {
      return {
        ...state,
        allShiftDetail: action.payload
      };
    }

    case CREATE_SHIFT: {
      let updatedList = state.shiftsList;
      updatedList = [action.payload, ...updatedList];
      return {
        ...state,
        shiftsList: updatedList
      };
    }

    case UPDATE_SHIFT: {
      return {
        ...state,
        shiftsList: state.shiftsList.map(item =>
          item.id === action.payload.id ? action.payload : item
        )
      };
    }

    case DELETE_SHIFT: {
      let updatedList = state.shiftsList;
      updatedList = updatedList.filter(
        shift => !action.payload.includes(shift.id)
      );
      return {
        ...state,
        shiftsList: updatedList
      };
    }

    default:
      return state;
  }
};
