import { Box, Button, Link, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import useStyles from "./index.style";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

function ClockButton({ props }) {
  const { nextStamp, counter, shift, onStamp, companyHoliday } = props;
  const classes = useStyles();
  const clockText = "CLOCK";
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Box sx={{ margin: "auto", textAlign: "center" }}>
      {!shift ? (
        <Box sx={{ textAlign: "center" }} mt={20}>
          <Typography className={classes.textWarning} mb={3}>
            <ErrorOutlineIcon
              style={{ verticalAlign: "middle", marginRight: "5px" }}
            />{" "}
            {t("timeStamp.noShift")}
          </Typography>
          <Link href="#" onClick={() => history.push("/profile")}>
            {t("timeStamp.clickToProfile")}
          </Link>
        </Box>
      ) : companyHoliday ? (
        <Box sx={{ textAlign: "center" }} mt={20}>
          <Typography className={classes.textWarning} mb={3}>
            <ErrorOutlineIcon
              style={{ verticalAlign: "middle", marginRight: "5px" }}
            />
            {t("timeStamp.toDayHoliday")}
          </Typography>
        </Box>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.btnClock}
          onClick={onStamp}
          disabled={!nextStamp || counter > 0}
        >
          {!nextStamp && counter > 0 && (
            <span className={classes.nextClock}>Next Clock In {counter}</span>
          )}
          {(nextStamp || counter === 0) && shift.id && <>{clockText}</>}
          {counter === 0 && !shift.id && (
            <span className={classes.nextClock}>Loading...</span>
          )}
        </Button>
      )}
    </Box>
  );
}

export default ClockButton;
