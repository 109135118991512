import React from "react";
import { Paper, Typography } from "@mui/material";

const Home = () => {
  // const date = new Date();
  // const day = date.toLocaleString("en-us", { weekday: "long" });
  // let imgUrl = "";
  // switch (day) {
  //   case "Monday":
  //     imgUrl = "https://i.giphy.com/media/pi2pWMN3oJLu8/giphy.webp";
  //     break;
  //   case "Tuesday":
  //     imgUrl = "https://i.giphy.com/media/jsIa0hyKnzhQRDYObr/200w.webp";
  //     break;
  //   case "Wednesday":
  //     imgUrl = "https://i.giphy.com/media/3o7btSzFewnrEOAnXq/200w.webp";
  //     break;
  //   case "Thursday":
  //     imgUrl = "https://i.giphy.com/media/4Y7sZtI4VCgkkUsPFK/giphy.webp";
  //     break;
  //   case "Friday":
  //     imgUrl = "https://i.giphy.com/media/pOLspHmrKmQmc/giphy.webp";
  //     break;
  //   case "Saturday":
  //     imgUrl = "https://i.giphy.com/media/jsIa0hyKnzhQRDYObr/200w.webp";
  //     break;
  //   case "Sunday":
  //     imgUrl = "https://i.giphy.com/media/Ub8XEam5vXbMY/200w.webp";
  //     break;
  //   default:
  //     break;
  // }
  return (
    <Paper
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        textAlign: "center",
        backgroundColor: "darkslategrey"
        // justifyContent: "center"
      }}
    >
      <Typography variant="h1" style={{ margin: "auto", color: "lightblue" }}>
        Home Page
      </Typography>
      {/* <img
        // src="https://img.freepik.com/free-vector/gradient-ui-ux-elements-collection_79603-1923.jpg?t=st=1650343340~exp=1650343940~hmac=1ae6cefc790bb2ae1b779af2038a1ae2f8cb78a51b0894003347e5127285d8c2&w=1380"
        src={imgUrl}
        style={{ width: "100%", objectFit: "contain", maxWidth: "500px" }}
        alt="logo"
      /> */}
    </Paper>
  );
};

export default Home;
