import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Avatar } from "@mui/material";

export default function TabHeader(props) {
  const [value, setValue] = useState("history");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ width: "100%", typography: "body1", borderTop: "1px solid #eee" }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="My Points"
            variant="fullWidth"
          >
            <Tab
              icon={
                <Avatar
                  src="/images/point/history.png"
                  sx={{ width: 20, height: 20, borderRadius: "unset" }}
                />
              }
              label="History"
              value="history"
            />
            <Tab
              icon={
                <Avatar
                  src="/images/point/gift.png"
                  sx={{ width: 20, height: 20, borderRadius: "unset" }}
                />
              }
              label="Give Point"
              value="givepoint"
            />
            {/* <Tab
              icon={
                <Avatar
                  src="/images/point/reward.png"
                  sx={{ width: 20, height: 20, borderRadius: "unset" }}
                />
              }
              label="Reward"
              value="reward"
            /> */}
          </TabList>
        </Box>
        <TabPanel value="history">{props.tabHistory}</TabPanel>
        <TabPanel value="givepoint">{props.tabGivePoint}</TabPanel>
        <TabPanel value="reward">{props.tabReward}</TabPanel>
      </TabContext>
    </Box>
  );
}
