import React from "react";
import ListUC from "routes/shared/uc/list-uc";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { setCurrentShift } from "redux/actions/Shift";
import { getById } from "redux/actions/Default";

const ShiftList = ({ listViewClick }) => {
  const dispatch = useDispatch();
  const { shiftsList } = useSelector(({ shifts }) => shifts);
  const { t } = useTranslation();

  const handleOnEdit = id => {
    const currentShift = shiftsList.filter(e => e.id === id);
    if (currentShift) {
      dispatch(
        getById(currentShift[0].id, currentShift[0].companyId, () => {
          listViewClick({ action: "edit", id: id });
        })
      );
    }
  };

  const handleOnAdd = () => {
    dispatch(setCurrentShift(null));
    listViewClick({ action: "add", id: "" });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "shiftCode",
      headerName: t("shift.list.shiftCode"),
      width: 160
    },
    {
      field: "shiftName",
      headerName: t("shift.list.shiftName"),
      width: 220,
      flex: 1
    },
    {
      field: "periodTimes",
      headerName: t("shift.list.periodTimes"),
      width: 220
    }
  ];

  return (
    <>
      <ListUC
        datas={shiftsList}
        cols={columns}
        onAdd={handleOnAdd}
        onEdit={handleOnEdit}
      />
    </>
  );
};

ShiftList.propTypes = {
  listViewClick: PropTypes.func
};

export default React.memo(ShiftList);
