import {
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  GET_EMPLOYEE_COUNTS,
  GET_EMPLOYEE_LIST,
  SET_CURRENT_EMPLOYEE,
  SET_EMP_ENUM,
  SET_EMP_POSITION,
  SET_EMP_DEPARTMENT,
  SET_EMP_SUPERVISOR,
  SET_EMP_SHIFT,
  SET_EMP_LOCATION,
  SET_EMP_USER
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  searchText: "",
  viewBy: "",
  employeesList: [],
  empAddressList: [],
  currentEmployee: null,
  // currentEmpAddress: [],
  totalEmployees: null,
  counter: null,
  empEnum: [],
  positionList: [],
  departmentList: [],
  supervisorList: [],
  shiftList: [],
  locationList: [],
  empUser: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMPLOYEE_LIST: {
      // console.log(action.payload)
      return {
        ...state,
        employeesList: action.payload,
        empAddressList: action.payload.empAddressList,
        totalEmployees: action.payload.length
      };
    }

    case SET_CURRENT_EMPLOYEE: {
      return {
        ...state,
        currentEmployee: action.payload
        // currentEmpAddress: action.payload ? action.payload.currentEmpAddress : [],
      };
    }

    case SET_EMP_ENUM: {
      return { ...state, empEnum: action.payload };
    }

    case SET_EMP_POSITION: {
      return { ...state, positionList: action.payload };
    }

    case SET_EMP_DEPARTMENT: {
      return { ...state, departmentList: action.payload };
    }

    case SET_EMP_SUPERVISOR: {
      return { ...state, supervisorList: action.payload };
    }

    case SET_EMP_SHIFT: {
      return { ...state, shiftList: action.payload };
    }

    case SET_EMP_LOCATION: {
      return { ...state, locationList: action.payload };
    }

    case SET_EMP_USER: {
      return { ...state, empUser: action.payload };
    }

    case CREATE_EMPLOYEE: {
      let updatedList = state.employeesList;
      let updatedCount = state.totalEmployees;
      updatedList = [action.payload, ...updatedList];
      updatedCount = updatedCount + 1;
      return {
        ...state,
        employeesList: updatedList,
        totalEmployees: updatedCount
      };
    }

    case UPDATE_EMPLOYEE: {
      return {
        ...state,
        employeesList: state.employeesList.map(item =>
          item.id === action.payload.id ? action.payload : item
        )
      };
    }

    case DELETE_EMPLOYEE: {
      let updatedList = state.employeesList;
      let updatedCount = state.totalEmployees;
      updatedList = updatedList.filter(
        employee => !action.payload.includes(employee.id)
      );
      updatedCount = updatedCount - action.payload.length;
      return {
        ...state,
        employeesList: updatedList,
        totalEmployees: updatedCount
      };
    }

    case GET_EMPLOYEE_COUNTS: {
      return {
        ...state,
        counter: action.payload
      };
    }

    default:
      return state;
  }
};
