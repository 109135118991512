import React from "react";
import GridContainer from "@jumbo/components/GridContainer";
import { Grid } from "@mui/material";
import LabelUC from "routes/shared/uc/label-uc";
import { useTranslation } from "react-i18next";
import TextInputUC from "routes/shared/uc/text-input-uc";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import { numberToTime } from "services/default.service";

const ShiftDetail = ({ props }) => {
  const { infoViewOnlyMode, shiftDetail, handleTimeChangeDetail } = props;
  const { t } = useTranslation();
  const timesStamp = [
    { id: 2, name: 2, caption: 2, captionEn: 2 },
    { id: 4, name: 4, caption: 4, captionEn: 4 }
  ];

  return (
    <>
      {/* Times Stamp */}
      <GridContainer>
        <Grid item xs={12} sm={3} alignItems="center" container>
          <LabelUC label={t("shift.lblTimesStamp")} isRequired={true} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <AppSelectBox
            fullWidth
            data={timesStamp}
            variant="outlined"
            name="timesStamp"
            value={shiftDetail.timesStamp}
            onChange={handleTimeChangeDetail}
            disabled={infoViewOnlyMode}
            helperText={shiftDetail.timesStampError}
          />
        </Grid>
      </GridContainer>
      {/* Time In/Out 1 */}
      <GridContainer>
        <Grid item xs={12} sm={3} alignItems="center" container>
          <LabelUC label={t("shift.lblStartTime") + " 1"} isRequired={true} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextInputUC
            disabled={infoViewOnlyMode}
            placeholder={t("shift.lblStartTime")}
            value={numberToTime(shiftDetail.in1, ":")}
            name="in1"
            type="time"
            onChange={handleTimeChangeDetail}
            helperText={shiftDetail.in1Error}
          />
        </Grid>
        <Grid item xs={12} sm={3} alignItems="center" container>
          <LabelUC label={t("shift.lblEndTime") + " 1"} isRequired={true} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextInputUC
            disabled={infoViewOnlyMode}
            placeholder={t("shift.lblEndTime") + " 1"}
            value={numberToTime(shiftDetail.out1, ":")}
            name="out1"
            type="time"
            onChange={handleTimeChangeDetail}
            helperText={shiftDetail.out1Error}
          />
        </Grid>
      </GridContainer>
      {/* Break*/}
      <GridContainer>
        <Grid item xs={12} sm={3} alignItems="center" container>
          <LabelUC label={t("shift.lblStartBreakTime")} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextInputUC
            disabled={infoViewOnlyMode}
            placeholder={t("shift.lblStartBreakTime")}
            value={numberToTime(shiftDetail.breakStart, ":")}
            name="breakStart"
            type="time"
            onChange={handleTimeChangeDetail}
            helperText={shiftDetail.breakStartError}
          />
        </Grid>
        <Grid item xs={12} sm={3} alignItems="center" container>
          <LabelUC label={t("shift.lblEndBreakTime")} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextInputUC
            disabled={infoViewOnlyMode}
            placeholder={t("shift.lblEndBreakTime")}
            value={numberToTime(shiftDetail.breakEnd, ":")}
            name="breakEnd"
            type="time"
            onChange={handleTimeChangeDetail}
            helperText={shiftDetail.breakEndError}
          />
        </Grid>
      </GridContainer>
      {/* Time In/Out 2 */}
      {shiftDetail.timesStamp === 4 && (
        <GridContainer>
          <Grid item xs={12} sm={3} alignItems="center" container>
            <LabelUC label={t("shift.lblStartTime") + " 2"} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextInputUC
              disabled={infoViewOnlyMode || shiftDetail.timesStamp === 2}
              placeholder={t("shift.lblStartTime") + " 2"}
              value={numberToTime(shiftDetail.in2, ":")}
              name="in2"
              type="time"
              onChange={handleTimeChangeDetail}
              helperText={shiftDetail.in2Error}
            />
          </Grid>
          <Grid item xs={12} sm={3} alignItems="center" container>
            <LabelUC label={t("shift.lblEndTime") + " 2"} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextInputUC
              disabled={infoViewOnlyMode || shiftDetail.timesStamp === 2}
              placeholder={t("shift.lblEndTime") + " 2"}
              value={numberToTime(shiftDetail.out2, ":")}
              name="out2"
              type="time"
              onChange={handleTimeChangeDetail}
              helperText={shiftDetail.out2Error}
            />
          </Grid>
        </GridContainer>
      )}
      {/* Work Hour */}
      <GridContainer>
        <Grid item xs={12} sm={3} alignItems="center" container>
          <LabelUC label={t("shift.lblWorkHour")} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextInputUC
            disabled={true}
            placeholder={t("shift.lblWorkHour")}
            value={numberToTime(shiftDetail.workHour, ".")}
            name="workHour"
          />
        </Grid>
      </GridContainer>
      {/* Break Hour */}
      <GridContainer>
        <Grid item xs={12} sm={3} alignItems="center" container>
          <LabelUC label={t("shift.lblBreakHour")} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextInputUC
            disabled={true}
            placeholder={t("shift.lblBreakHour")}
            value={numberToTime(shiftDetail.breakHour, ".")}
            name="breakHour"
          />
        </Grid>
      </GridContainer>
    </>
  );
};

export default ShiftDetail;
