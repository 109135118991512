import React from "react";
// import Chip from '@mui/material/Chip';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import DoneIcon from "@mui/icons-material/Done";

const useStyles = makeStyles(theme => ({
  formControlRoot: {
    "& .MuiOutlinedInput-root": {
      paddingBottom: "1px !important",
      paddingTop: "1px !important",
      paddingLeft: "5px !important",
      "& .MuiChip-root": {
        maxWidth: "70%"
      }
    }
  }
}));

export default function MultipleSelect(props) {
  const {
    datas,
    isMultipleLine,
    placeholder,
    infoViewOnlyMode,
    isCanAdd,
    limitTags,
    filterSelected,
    setValue,
    value
    // maxLength
  } = props;

  const classes = useStyles();
  // const [value, setValue] = useState([]);

  const handleChange = (e, targetValue) => {
    setValue(targetValue);
    // console.log('MultipleSelect', value)
  };
  return (
    <Autocomplete
      value={value}
      className={classes.formControlRoot}
      disabled={infoViewOnlyMode}
      multiple
      limitTags={limitTags}
      filterSelectedOptions={filterSelected}
      disableCloseOnSelect
      options={datas}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(e, value) => handleChange(e, value)}
      getOptionLabel={option => (option.title ? option.title : option)}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Box
            component={DoneIcon}
            sx={{ width: 17, height: 17, mr: "5px", ml: "-2px" }}
            style={{
              visibility: selected ? "visible" : "hidden"
            }}
          />
          <Box
            sx={{
              flexGrow: 1,
              "& span": {
                color: "#8b949e"
              }
            }}
          >
            {option.title}
            <br />
            {isMultipleLine && <span>{option.description}</span>}
          </Box>
        </li>
      )}
      freeSolo={isCanAdd}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          disabled={infoViewOnlyMode}
          placeholder={placeholder}
          maxLength="100"
        />
      )}
    />
  );
}

MultipleSelect.propType = {
  datas: PropTypes.array.isRequired,
  infoViewOnlyMode: PropTypes.bool,
  isCanAdd: PropTypes.bool,
  isMultipleLine: PropTypes.bool,
  limitTags: PropTypes.number,
  alwayShowSelected: PropTypes.bool
};

MultipleSelect.defaultProps = {
  infoViewOnlyMode: false,
  isCanAdd: false,
  placeholder: "Select Item",
  isMultipleLine: false,
  limitTags: -1,
  filterSelected: false
};
