import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import InfoUC from "routes/shared/uc/info-uc/info-uc-dialog";
import detectStateChange from "services/detectStateChange";
import { onCreate, onUpdate } from "redux/actions/Default";
import { Box, Grid } from "@mui/material";
import GridContainer from "@jumbo/components/GridContainer";
import LabelUC from "routes/shared/uc/label-uc";
import TextInputUC from "routes/shared/uc/text-input-uc";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import { format } from "date-fns";

const CustomerInfo = ({ handleDialog, openInfo }) => {
    const { currentCustomer } = useSelector(({ customers }) => customers);
    const { infoViewOnlyMode } = useSelector(({ common }) => common);
    const dispatch = useDispatch();
    const [title, setTitle] = useState("นาย");

    const titleList = [
        { id: "นาย", caption: "นาย", captionEn: "นาย", name: "นาย" },
        { id: "นาง", caption: "นาง", captionEn: "นาง", name: "นาง" },
        { id: "นางสาว", caption: "นางสาว", captionEn: "นางสาว", name: "นางสาว" }
    ];

    console.log('currentCustomer', currentCustomer)
    const cust = currentCustomer ? currentCustomer : {};
    const [customer, setCustomer] = useState({
        id: cust.id || null,
        companyName: cust.companyName || "",
        title: cust.title || "",
        firstName: cust.firstName || null,
        lastName: cust.lastName || "",
        phone: cust.phone || "",
        email: cust.email || "",
        registerDate: cust.registerDate || new Date(),
        activateDate: cust.activateDate || new Date(),
        expireDate: cust.expireDate || new Date(),
        createdDate: cust.createdDate || new Date(),
        createdBy: cust.createdBy || "",
        modifiedDate: cust.modifiedDate || new Date(),
        modifiedBy: cust.modifiedBy || ""
    });

    detectStateChange([customer]);

    const handleChange = e => {
        setCustomer({ ...customer, [e.target.name]: e.target.value });
    };

    const handleInfoDialog = action => {
        console.log("action dialog :" + action);
        switch (action) {
            case "save":
                handleSubmit();
                break;
            default:
                handleDialog();
                break;
        }
    };

    const handleSubmit = () => {
        if (!customer.companyName || !customer.firstName || !customer.lastName || !customer.email || !customer.phone) {
            setCustomer({
                ...customer,
                companyNameError: !customer.companyName ? "กรุณากรอกข้อมูล" : "",
                firstNameError: !customer.firstName ? "กรุณากรอกข้อมูล" : "",
                lastNameError: !customer.lastName ? "กรุณากรอกข้อมูล" : "",
                emailError: !customer.email ? "กรุณากรอกข้อมูล" : "",
                phoneError: !customer.phone ? "กรุณากรอกข้อมูล" : ""
            });
        } else {
            const customerSave = {
                companyName: customer.companyName,
                title: customer.title,
                firstName: customer.firstName,
                lastName: customer.lastName,
                phone: customer.phone,
                email: customer.email,
                registerDate: customer.registerDate,
                expireDate: customer.expireDate,
                createdDate: customer.createdDate,
                createdBy: customer.createdBy,
                modifiedDate: customer.modifiedDate,
                modifiedBy: customer.modifiedBy
            }
            if (currentCustomer && currentCustomer.id) {
                dispatch(onUpdate({ ...currentCustomer, ...customerSave }));
                console.log("Update success");
            } else {
                dispatch(onCreate(customerSave));
                console.log("Insert Success");
            }
            handleDialog({ action: "save", value: "" });
        }
    }
    console.log('cust', cust)
    const customerInfoForm = () => {
        return (
            <>
                <Box
                    sx={{ display: "flex", width: "100%" }}
                    flexDirection={{ xs: "column", md: "row" }}
                >
                    <Box sx={{ padding: 2, width: { xs: "100%" } }} m="auto">
                        {/* Company Name */}
                        <GridContainer>
                            <Grid item xs={12} sm={5} alignItems="center" container>
                                <LabelUC
                                    label={"ชื่อบริษัท"}
                                    isRequired={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <TextInputUC
                                    disabled={infoViewOnlyMode}
                                    placeholder="ชื่อบริษัท"
                                    value={customer.companyName}
                                    name="companyName"
                                    onChange={e => handleChange(e)}
                                    helperText={customer.companyNameError}
                                />
                            </Grid>
                        </GridContainer>
                        {/* First Name */}
                        <GridContainer>
                            <Grid item xs={12} sm={5} alignItems="center" container>
                                <LabelUC
                                    label={"ชื่อ"}
                                    isRequired={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} alignItems="center">
                                <AppSelectBox
                                    fullWidth
                                    data={titleList}
                                    variant="outlined"
                                    value={title}
                                    name="title"
                                    onChange={e => setTitle(e.target.value)}
                                // className={classes.titleRoot}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextInputUC
                                    disabled={infoViewOnlyMode}
                                    placeholder="ชื่อ"
                                    value={customer.firstName}
                                    name="firstName"
                                    onChange={e => handleChange(e)}
                                    helperText={customer.firstNameError}
                                />
                            </Grid>
                        </GridContainer>
                        {/* Last Name */}
                        <GridContainer>
                            <Grid item xs={12} sm={5} alignItems="center" container>
                                <LabelUC
                                    label={"นามสกุล"}
                                    isRequired={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <TextInputUC
                                    disabled={infoViewOnlyMode}
                                    placeholder="นามสกุล"
                                    value={customer.lastName}
                                    name="lastName"
                                    onChange={e => handleChange(e)}
                                    helperText={customer.lastNameError}
                                />
                            </Grid>
                        </GridContainer>
                        {/* Email */}
                        <GridContainer>
                            <Grid item xs={12} sm={5} alignItems="center" container>
                                <LabelUC
                                    label={"Email"}
                                    isRequired={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <TextInputUC
                                    disabled={infoViewOnlyMode}
                                    placeholder="Email"
                                    value={customer.email}
                                    name="email"
                                    onChange={e => handleChange(e)}
                                    helperText={customer.emailError}
                                />
                            </Grid>
                        </GridContainer>
                        {/* Phone */}
                        <GridContainer>
                            <Grid item xs={12} sm={5} alignItems="center" container>
                                <LabelUC
                                    label={"เบอร์โทรศัพท์"}
                                    isRequired={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <TextInputUC
                                    disabled={infoViewOnlyMode}
                                    placeholder="เบอร์โทรศัพท์"
                                    value={customer.phone}
                                    name="phone"
                                    onChange={e => handleChange(e)}
                                    helperText={customer.phoneError}
                                />
                            </Grid>
                        </GridContainer>
                        {/* Register Date */}
                        <GridContainer>
                            <Grid item xs={12} sm={5} alignItems="center" container>
                                <LabelUC
                                    label={"วันที่ลงทะเบียน"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <TextInputUC
                                    disabled={true}
                                    value={format(new Date(customer.createdDate), "dd/MM/yyyy")}
                                    name="createdDate"
                                    onChange={e => handleChange(e)}
                                />
                            </Grid>
                        </GridContainer>
                    </Box>
                </Box>
            </>
        )
    }
    return (
        <>
            <InfoUC
                open={openInfo}
                handleDialog={handleInfoDialog}
                children={customerInfoForm()}
            />
        </>
    );
}

export default CustomerInfo;

CustomerInfo.propType = {
    open: PropTypes.bool.isRequired,
    handleDialog: PropTypes.func
};