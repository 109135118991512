import {
  SET_CURRENT_POINT_SETTING,
  SET_CURRENT_EMPPOINT
} from "../../@jumbo/constants/ActionTypes";

export const setCurrentPointSetting = data => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_POINT_SETTING,
      payload: data
    });
  };
};

export const setPointSettingById = (data, callbackFun = null) => {
  // console.log("setPointSettingById", data);
  return dispatch => {
    dispatch({ type: SET_CURRENT_POINT_SETTING, payload: data.data });
    if (callbackFun) callbackFun(data.data);
  };
};

export const setCurrentEmpPoint = data => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_EMPPOINT,
      payload: data
    });
  };
};

export const setEmpPointById = (data, callbackFun = null) => {
  return dispatch => {
    dispatch({ type: SET_CURRENT_EMPPOINT, payload: data.data });
    if (callbackFun) callbackFun(data.data);
  };
};
