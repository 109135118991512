import React from "react";
import ListUC from "routes/shared/uc/list-uc";
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { setCurrentCompany } from "redux/actions/Company";
import { getById } from "redux/actions/Default";
import { Avatar } from "@material-ui/core";

const CompanyList = ({ listViewClick }) => {
    const dispatch = useDispatch();
    const { companysList } = useSelector(({ companys }) => companys);
    const { t, i18n } = useTranslation();
    const isLangEn = i18n.language === "en";

    const handleOnEdit = (id) => {
        const currentCompany = companysList.filter(e => e.id === id);
        if (currentCompany) {
            dispatch(
                getById(currentCompany[0].id, currentCompany[0].id, () => {
                    listViewClick({ action: "edit", id: id });
                })
            );
        }
    }

    const handleOnAdd = () => {
        dispatch(
            getById(0, 0, () => {
                dispatch(setCurrentCompany(null));
                listViewClick({ action: "add", id: "" });
            })
        );
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'image',
            headerName: t('company.list.image'),
            width: 80,
            align: 'center',
            renderCell: (params) => {
                // console.log(params);
                return (
                    <>
                        <Avatar src={params.value} />
                    </>
                );
            }
        },
        { field: 'companyCode', headerName: t('company.list.companyCode'), width: 160 },
        {
            field: 'companyName',
            headerName: t('company.list.companyName'),
            width: 200,
            flex: 1,
            valueGetter: params =>
                `${(isLangEn ? params.row.companyNameEn : params.row.companyName) || ""} `
        },
        {
            field: 'companyTypeName',
            headerName: t('company.list.type'),
            width: 200,
            valueGetter: params =>
                `${(isLangEn ? params.row.companyTypeNameEn : params.row.companyTypeName) || ""} `
        },
        // { field: 'numOfEmp', headerName: t('company.list.numOfEmp'), width: 160 },
        // { field: 'status', headerName: t('company.list.status'), width: 100 }
    ]

    return (
        <ListUC
            datas={companysList}
            cols={columns}
            onAdd={handleOnAdd}
            onEdit={handleOnEdit}
        />
    )
}

CompanyList.propTypes = {
    listViewClick: PropTypes.func,
};

export default React.memo(CompanyList);