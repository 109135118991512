import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import PropTypes from "prop-types";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
// import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  formControlRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[400]
    }
  }
}));

const AppSelectBox = props => {
  const classes = useStyles(props);
  const { i18n } = useTranslation();
  // const { locale } = useContext(AppContext);

  const {
    data,
    renderRow,
    placeholder,
    name,
    id,
    fullWidth,
    displayEmpty,
    size,
    value,
    required,
    onChange,
    helperText,
    variant,
    error,
    valueKey,
    labelKey = i18n.language === "en"
      ? name === "title" ? "caption" : "captionEn"
      : name === "titleEn"
        ? "captionEn"
        : "caption",
    backgroundColor,
    label,
    ...rest
  } = props;

  return (
    <FormControl
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      required={required}
      className={clsx(classes.formControlRoot, "form-control")}
    >
      {label && <InputLabel id={"label-" + id || name}>{label}</InputLabel>}
      <Select
        {...rest}
        name={name}
        labelId={"label-" + id || name}
        id={id || name}
        value={value}
        onChange={onChange}
        // label={label ? (required ? label + ' *' : label) : null}
        error={error || helperText !== ""}
        displayEmpty={displayEmpty}
      >
        {placeholder && displayEmpty && (
          <MenuItem value="">{placeholder}</MenuItem>
        )}
        {data.map((item, index) =>
          renderRow ? (
            renderRow(item, index)
          ) : (
            <MenuItem key={index} value={valueKey ? item[valueKey] : item}>
              {item[labelKey]}
            </MenuItem>
          )
        )}
      </Select>
      {helperText && (
        <FormHelperText error={error || helperText !== ""}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

AppSelectBox.prototype = {
  data: PropTypes.array.isRequired,
  renderRow: PropTypes.node,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
  backgroundColor: PropTypes.string
};

AppSelectBox.defaultProps = {
  data: [],
  fullWidth: true,
  displayEmpty: true,
  required: false,
  size: "small",
  error: false,
  helperText: "",
  valueKey: "id",
  // labelKey: 'captionEn',
  backgroundColor: "transparent"
};

export default AppSelectBox;
