import { Paper } from "@mui/material";
import React from "react";

const TabReward = () => {
  return (
    <Paper
      style={{
        padding: "2rem",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center"
      }}
    >
      <img
        src="https://mbayeco.com/wp-content/uploads/2019/04/img-under-construction.png"
        style={{ width: "300px", height: "300px", objectFit: "contain" }}
        alt="logo"
      />
    </Paper>
  );
};

export default TabReward;
