import { alpha, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "& .MuiFormControl-root": {
      width: "15%",
      paddingLeft: "5px !important"
    },
    "& .MuiSvgIcon-root": {
      color: theme.mainColor
    }
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: alpha(theme.palette.secondary.light, 0.15)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  paper: {
    padding: theme.spacing(3),
    height: "100%"
  },
  topBar: {
    // justifyContent: 'end',
    "& .MuiTypography-root": {
      fontWeight: "bold",
      fontSize: "1.2rem",
      flex: 1,
      color: theme.mainColor
    },
    "& .MuiSvgIcon-root": {
      color: theme.mainColor
    }
  },
  searchBar: { flex: "1" },
  viewBy: {
    marginLeft: theme.spacing(1)
  },
  gridHeader: {
    "& .MuiDataGrid-main": {
      borderRadius: "5px !important"
    },
    "& 	.MuiDataGrid-columnHeaders": {
      backgroundColor: theme.mainColor,
      color: "#fbfbf9",
      "& 	.MuiSvgIcon-root": {
        color: "#fbfbf9"
      }
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold"
    },
    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: 0
    }
  },
  title: {
    fontSize: "20px"
  },
  chipsRoot: {
    display: "flex",
    justifyContent: "center",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    "& > *": {
      margin: [theme.spacing(0.5), "!important"]
    }
  }
}));

export default useStyles;
