import React, { useState } from "react";
import GridContainer from "@jumbo/components/GridContainer";
import { Grid } from "@material-ui/core";
import { Box, Button } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import LabelUC from "routes/shared/uc/label-uc";
import { useTranslation } from "react-i18next";
import useStyles from "routes/Pages/Company/index.style";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import HolidayDialog from "./holiday-dialog";
import AddIcon from "@material-ui/icons/Add";
import { uid } from "react-uid";
import { format } from "date-fns";
import { setIsDataModified } from "redux/actions";
import { useDispatch } from "react-redux";

const Holiday = ({ props }) => {
  const {
    companyHoliday,
    setCompanyHoliday,
    infoViewOnlyMode,
    yearList
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const yearData = yearList.map(item => ({
    name: item,
    caption: item,
    captionEn: item
  }));

  const _defaultYear =
    yearData && yearData.some(e => e.name === new Date().getFullYear())
      ? new Date().getFullYear()
      : yearData && yearData.length > 0
      ? yearData[0].name
      : "";

  const [year, setYear] = useState(_defaultYear);
  // const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [rowEdit, setRowEdit] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = e => {
    setYear(e.target.value);
  };

  const editRow = React.useCallback(
    row => () => {
      setRowEdit(row);
      setTimeout(() => {
        setIsOpen(true);
      }, 100);
    },
    []
  );

  const deleteRow = React.useCallback(
    id => () => {
      setSelected(id);
      setOpenConfirmDialog(true);
    },
    []
  );

  const handleConfirmDelete = () => {
    setCompanyHoliday(
      companyHoliday.map(item =>
        item.id === selected ? { ...item, isDeleted: true } : item
      )
    );
    setOpenConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setSelected(null);
  };

  const handleDialog = (action, value) => {
    if (action === "save") {
      if (
        value.rowState === "new" &&
        !companyHoliday.some(item => item.id === value.id)
      ) {
        setCompanyHoliday([...companyHoliday, value]);
        // console.log([...companyHoliday, value]);
      } else {
        const dataEdit = companyHoliday.map(item => {
          if (item.id === value.id) {
            console.log("handleDialog", value);
            return value;
          }
          return item;
        });
        setCompanyHoliday(dataEdit);
      }
      dispatch(setIsDataModified(true));
    }
    setIsOpen(false);
  };

  const openHolidayDialog = () => {
    const row = {
      id: uid(new Date()),
      name: "",
      nameEn: "",
      date: new Date(),
      companyId: null,
      createdBy: 0,
      createdDate: new Date(),
      modifiedBy: 0,
      modifiedDate: new Date(),
      isDeleted: false,
      rowState: "new"
    };
    setRowEdit(row);
    setTimeout(() => {
      setIsOpen(true);
    }, 100);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "date",
      headerName: t("company.holiday.list.date"),
      width: 200,
      type: "date",
      valueGetter: ({ value }) => value && format(new Date(value), "dd/MM/yyyy")
    },
    {
      field: "name",
      headerName: t("company.holiday.list.name"),
      width: 300,
      flex: 1
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 100,
      getActions: params => [
        <GridActionsCellItem
          icon={!infoViewOnlyMode ? <EditIcon /> : <></>}
          label="Edit"
          onClick={!infoViewOnlyMode ? editRow(params.row) : () => {}}
        />,
        <GridActionsCellItem
          icon={!infoViewOnlyMode ? <DeleteIcon /> : <></>}
          label="Delete"
          onClick={!infoViewOnlyMode ? deleteRow(params.id) : () => {}}
        />
      ]
    }
  ];

  const _datas =
    companyHoliday && companyHoliday.length > 0
      ? companyHoliday.filter(
          row => !row.isDeleted && new Date(row.date).getFullYear() === year
        )
      : [];

  const datas = (_datas.filter(
    row => row.rowState !== "new" && row.companyId !== null
  ).length > 0
    ? //edit
      _datas.filter(row => row.rowState !== "new" && row.companyId !== null)
    : //default holiday
      _datas.filter(row => row.rowState !== "new" && !row.companyId)
  )
    .concat(
      //new holiday
      _datas.filter(row => row.rowState === "new")
    )
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  const disableDateList = _datas.map(item => {
    return format(new Date(item.date), "yyyy-MM-dd");
  });

  return (
    <>
      <Box
        sx={{ display: "flex", width: "100%" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Box sx={{ padding: 2, width: { xs: "100%", lg: "50%" } }}>
          {/* Holiday Year*/}
          <GridContainer>
            <Grid item xs={12} sm={4} alignItems="center" container>
              <LabelUC label={t("company.lblHolidayYear")} />
            </Grid>
            <Grid item xs={12} sm={8} alignItems="center" container>
              <AppSelectBox
                width="50%"
                data={yearData}
                valueKey="name"
                variant="outlined"
                value={year}
                onChange={e => handleChange(e)}
                // disabled={infoViewOnlyMode}
              />
            </Grid>
          </GridContainer>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", width: "100%" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        {/* <div style={{ height: 265, width: '70%' }}  > */}
        <Box
          sx={{
            padding: 2,
            width: { xs: "100%", lg: "70%" },
            marginLeft: { xs: "0%", lg: "15%" },
            height: 260
          }}
        >
          <DataGrid
            className={classes.gridHeader}
            rows={datas}
            columns={columns}
            disableColumnMenu={true}
            hideFooter={true}
            initialState={
              companyHoliday
                ? {
                    ...companyHoliday.initialState,
                    columns: {
                      columnVisibilityModel: {
                        id: false
                      }
                    }
                  }
                : {}
            }
          />
        </Box>
        <Box sx={{ padding: 2, width: { xs: "100%", lg: "30%" } }}>
          <Grid item xs={12} sm={3}>
            <Button
              // variant="contained" color="primary" fullWidth sx={{ fontWeight: 'bold' }}
              //     onClick={openHolidayDialog}
              variant="contained"
              startIcon={<AddIcon />}
              color="success"
              onClick={openHolidayDialog}
              disabled={infoViewOnlyMode}
              sx={{ fontWeight: "bold" }}
            >
              {t("common.add")}
            </Button>
          </Grid>
        </Box>
        {/* </div> */}
        <ConfirmDialog
          open={openConfirmDialog}
          title={`Confirm delete`}
          content={"Are you sure, you want to  delete selected?"}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      </Box>
      {isOpen && (
        <HolidayDialog
          handleDialog={handleDialog}
          openInfo={isOpen}
          rowEdit={rowEdit}
          disableDateList={disableDateList}
        />
      )}
    </>
  );
};

export default Holiday;
