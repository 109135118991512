import { IsNullOrEmpty } from "./default.service";

const name = "hr";

export const setStorageLanguage = lang => {
  localStorage.setItem(name + "-language", lang);
};

export const getStorageLanguage = () => {
  let lang = localStorage.getItem(name + "-language");
  lang = IsNullOrEmpty(lang) ? "en" : lang;
  return lang;
};

export const removeLanguageStorage = () => {
  localStorage.removeItem(name + "-language");
};

export const setStorageRemember = data => {
  localStorage.setItem(name + "-remember", JSON.stringify(data));
};

export const getStorageRemember = () => {
  let remember = localStorage.getItem(name + "-remember");
  if (!IsNullOrEmpty(remember)) {
    remember = JSON.parse(remember);
  } else {
    remember = null;
  }
  return remember;
};

export const removeRememberStorage = () => {
  localStorage.removeItem(name + "-remember");
};

export const setStorageEnum = (data, _name) => {
  localStorage.setItem(name + "-enum-" + _name, JSON.stringify(data));
};

export const getStorageEnum = _name => {
  let remember = localStorage.getItem(name + "-enum-" + _name);
  if (!IsNullOrEmpty(remember)) {
    remember = JSON.parse(remember);
  } else {
    remember = "";
  }
  return remember;
};

export const removeEnumStorage = _name => {
  localStorage.removeItem(name + "-enum-" + _name);
};

export const setStorageAuthenUser = data => {
  localStorage.setItem(name + "-userAuthen", JSON.stringify(data));
};

export const getStorageAuthenUser = () => {
  let authen = localStorage.getItem(name + "-userAuthen");
  if (!IsNullOrEmpty(authen)) {
    authen = JSON.parse(authen);
  } else {
    authen = "";
  }
  return authen;
};

export const removeAuthenUserStorage = () => {
  localStorage.removeItem(name + "-userAuthen");
};

export const setStorageToken = data => {
  localStorage.setItem(name + "-token", data);
};

export const setStorageRefreshToken = data => {
  localStorage.setItem(name + "-refreshToken", data);
};

export const getStorageToken = () => {
  let authen = localStorage.getItem(name + "-token");
  // console.log('get Token')
  if (IsNullOrEmpty(authen)) {
    authen = "";
  }
  return authen;
};

export const removeTokenStorage = () => {
  localStorage.removeItem(name + "-token");
};

export const removeRefreshTokenStorage = () => {
  localStorage.removeItem(name + "-refreshToken");
};

export const setStorageControllerName = controller => {
  localStorage.setItem(name + "-controller", controller);
};

export const getStorageControllerName = () => {
  const controller = localStorage.getItem(name + "-controller");
  return controller;
};

export const removeControllerNameStorage = () => {
  localStorage.removeItem(name + "-controller");
};

export const setStorageNotification = data => {
  localStorage.setItem(name + "-notification", JSON.stringify(data));
};

export const getStorageNotification = () => {
  let notification = localStorage.getItem(name + "-notification");
  if (!IsNullOrEmpty(notification)) {
    notification = JSON.parse(notification);
  } else {
    notification = "";
  }
  return notification;
};

export const removeNotificationStorage = () => {
  localStorage.removeItem(name + "-notification");
};

export const setStorageLiff = profile => {
  localStorage.setItem(name + "-Liff", JSON.stringify(profile));
};
export const getStorageLiff = () => {
  let profile = localStorage.getItem(name + "-Liff");
  profile = IsNullOrEmpty(profile) ? "" : JSON.parse(profile);
  return profile;
};
export const removeStorageLiff = () => {
  localStorage.removeItem(name + "-Liff");
};

export const clearAllStorage = () => {
  removeLanguageStorage();
  removeAuthenUserStorage();
  removeTokenStorage();
  removeRefreshTokenStorage();
  removeControllerNameStorage();
  removeNotificationStorage();
  removeStorageLiff();
  // if (getStorageRemember()) {
  //   removeRememberStorage();
  // }
  console.log("Clear All Storage");
};
