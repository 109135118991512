import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: theme.mainColor,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "60%",
      order: 1,
      borderTopLeftRadius: theme.spacing(1),
      borderBottomLeftRadius: theme.spacing(1)
    },
    [theme.breakpoints.down("xs")]: {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1)
    }
  },
  authContent: {
    // padding: 30,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      order: 1
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    "& .MuiFormLabel-root": {
      padding: "0 !important"
    },
    "& .MuiOutlinedInput-input": {
      padding: "18.5px 14px !important"
    }
    // '& .MuiOutlinedInput-notchedOutline': {
    //     borderColor: alpha(theme.palette.common.dark, 0.12),
    // },
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 10
      }
    }
  },
  navLink: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 10
    }
  },
  signUpBtn: {
    width: "80%",
    border: "1px solid #e0e0e0"
  },
  btnSignIn: {
    width: "100%"
  },
  textAcc: {
    marginTop: 3,
    textAlign: "center",
    "& a": {
      marginLeft: 4
    }
  }
}));

export default useStyles;
