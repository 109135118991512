import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    paper: {
        width: "100%"
    },
    boxTitle: {
        backgroundColor: theme.mainColor
    },
    title: {
        color: "#fff",
        fontWeight: "bold",
        fontSize: "18px !important",
    },
    label: {
        color: theme.mainColor,
        fontWeight: "bold",
        fontSize: "16px !important"
    },
    formControlRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[400],
        },
    },
    textFieldRoot: {
        "& .MuiFormLabel-root": {
            padding: "0 !important",
            color: "rgba(0, 0, 0, 0.3)"
        },
        "& .MuiOutlinedInput-input": {
            padding: "16.5px 14px !important"
        },
        marginTop: "0 !important",
        marginBottom: "16px !important"
    },
    btnStart: {
        width: "100%",
        border: "1px solid #e0e0e0",
        borderRadius: "50px !important",
        fontWeight: "bold !important"
    },
}));

export default useStyles;
