import React, { useContext } from "react";
import Hidden from "@material-ui/core/Hidden";
import { Box } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import CmtImage from "../../../../@coremat/CmtImage";
import LayoutContext from "@coremat/CmtLayouts/LayoutContext";

const Logo = ({ color, logoSize, ...props }) => {
  const logoUrl = "/images/logo.png";
  const logoSymbolUrl = "/images/logo-white-symbol.png";

  const { isSidebarOpen } = useContext(LayoutContext);
  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <NavLink to="/timeStamp">
          <CmtImage src={isSidebarOpen ? logoUrl : logoSymbolUrl} alt="logo" height="72px" />
        </NavLink>
      </Hidden>
      <Hidden smUp>
        <NavLink to="/timeStamp">
          <CmtImage src={logoUrl} alt="logo" height="72px" />
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default Logo;
