import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  parentImg: {
    textAlign: "center"
  },
  img: {
    height: "50px"
  }
}));

export default useStyles;
