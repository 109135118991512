import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import InfoUC from "routes/shared/uc/info-uc/info-uc-dialog";
import detectStateChange from "services/detectStateChange";
import { onCreate, onUpdate } from "redux/actions/Default";
import { Box, Grid } from "@mui/material";
import GridContainer from "@jumbo/components/GridContainer";
import LabelUC from "routes/shared/uc/label-uc";
import { useTranslation } from "react-i18next";
import TextInputUC from "routes/shared/uc/text-input-uc";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AutoCompleteUC from "routes/shared/uc/autocomplete-uc";

const DepartmentInfo = ({ handleDialog, openInfo }) => {
  // const classes = useStyles();
  const { currentDepartment, mainDepartment } = useSelector(
    ({ departments }) => departments
  );
  const { infoViewOnlyMode } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isLangEn = i18n.language === "en";

  const depart = currentDepartment ? currentDepartment : {};
  const [department, setDepartment] = useState({
    id: depart.id || null,
    departmentCode: depart.departmentCode || "",
    departmentName: depart.departmentName || "",
    departmentNameEn: depart.departmentNameEn || "",
    mainDepartmentId: depart.mainDepartmentId || null,
    description: depart.description || "",
    companyId: depart.companyId || "",
    createdDate: depart.createdDate || new Date(),
    createdBy: depart.createdBy || "",
    modifiedDate: depart.modifiedDate || "",
    modifiedBy: depart.modifiedBy || new Date()
  });

  const [departmentBind, setDepartmentBind] = useState(null);
  let departmentDataList = [];
  if (mainDepartment) {
    departmentDataList = mainDepartment.map(item => ({
      id: item.id,
      title: `${(isLangEn ? item.departmentNameEn : item.departmentName) ||
        ""} `,
      description: item.departmentCode
    }));
  }

  useEffect(() => {
    if (departmentDataList) {
      const data = departmentDataList.filter(
        item => item.id === department.mainDepartmentId
      );
      setDepartmentBind(data && data.length > 0 ? data[0] : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  detectStateChange([department]);

  const handleChange = e => {
    setDepartment({ ...department, [e.target.name]: e.target.value });
  };

  const handleChangeMainDepartment = value => {
    setDepartmentBind(value);
    setDepartment({ ...department, mainDepartmentId: value ? value.id : null });
  };

  const handleInfoDialog = action => {
    console.log("action dialog :" + action);
    switch (action) {
      case "save":
        handleSubmit();
        break;
      case "delete":
        console.log("delete from info.");
        if (currentDepartment) {
          handleDialog({ action: "delete", value: [currentDepartment.id] });
        }
        break;
      default:
        handleDialog();
        break;
    }
  };

  const handleSubmit = () => {
    console.log("handleSubmit");
    if (!department.departmentName || !department.departmentCode) {
      setDepartment({
        ...department,
        departmentCodeError: !department.departmentCode
          ? t("common.isRequired")
          : "",
        departmentNameError: !department.departmentName
          ? t("common.isRequired")
          : ""
      });
    } else {
      const departmentSave = {
        id: department.id,
        departmentCode: department.departmentCode,
        departmentName: department.departmentName,
        departmentNameEn: department.departmentNameEn,
        mainDepartmentId: department.mainDepartmentId,
        description: department.description,
        companyId: department.companyId,
        createdDate: department.createdDate,
        createdBy: department.createdBy,
        modifiedDate: department.modifiedDate,
        modifiedBy: department.modifiedBy
      };
      console.log("currentDepartment", currentDepartment);
      if (currentDepartment && currentDepartment.id) {
        dispatch(onUpdate({ ...currentDepartment, ...departmentSave }));
        console.log("Update success");
      } else {
        dispatch(onCreate(departmentSave));
        console.log("Insert Success");
      }
      handleDialog({ action: "save", value: "" });
    }
  };

  //create form
  const departmentInfoForm = () => {
    return (
      <>
        <Box
          sx={{ display: "flex", width: "100%" }}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box sx={{ padding: 2, width: { xs: "100%" } }} m="auto">
            {/* Department Code */}
            <GridContainer>
              <Grid item xs={12} sm={5} alignItems="center" container>
                <LabelUC
                  label={t("department.lblDeparmentCode")}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextInputUC
                  disabled={infoViewOnlyMode}
                  placeholder={t("department.lblDeparmentCode")}
                  value={department.departmentCode}
                  name="departmentCode"
                  onChange={e => handleChange(e)}
                  helperText={department.departmentCodeError}
                />
              </Grid>
            </GridContainer>
            {/* Department Name */}
            <GridContainer>
              <Grid item xs={12} sm={5} alignItems="center" container>
                <LabelUC
                  label={t("department.lblDeparmentName")}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextInputUC
                  disabled={infoViewOnlyMode}
                  placeholder={t("department.lblDeparmentName")}
                  value={department.departmentName}
                  name="departmentName"
                  onChange={e => handleChange(e)}
                  helperText={department.departmentNameError}
                />
              </Grid>
            </GridContainer>
            {/* Department Name EN*/}
            <GridContainer>
              <Grid item xs={12} sm={5} alignItems="center" container>
                <LabelUC label={t("department.lblDeparmentNameEn")} />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextInputUC
                  disabled={infoViewOnlyMode}
                  placeholder={t("department.lblDeparmentNameEn")}
                  value={department.departmentNameEn}
                  name="departmentNameEn"
                  onChange={e => handleChange(e)}
                />
              </Grid>
            </GridContainer>
            {/* Main Department */}
            <GridContainer>
              <Grid item xs={12} sm={5} alignItems="center" container>
                <LabelUC label={t("department.lblMainDepartment")} />
              </Grid>
              <Grid item xs={12} sm={7}>
                <AutoCompleteUC
                  datas={departmentDataList}
                  infoViewOnlyMode={infoViewOnlyMode}
                  isMultipleLine={true}
                  name="mainDepartmentId"
                  value={departmentBind}
                  handleChange={handleChangeMainDepartment}
                />
              </Grid>
            </GridContainer>
            {/* Description */}
            <GridContainer>
              <Grid item xs={12} sm={5} container>
                <LabelUC
                  sx={{ alignItems: "start" }}
                  label={t("department.lblDepartmentDes")}
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextareaAutosize
                  disabled={infoViewOnlyMode}
                  value={department.description}
                  maxLength={500}
                  aria-label="minimum height"
                  name="description"
                  minRows={5}
                  placeholder={t("department.lblDepartmentDes")}
                  style={{ width: "100%" }}
                  onChange={e => handleChange(e)}
                />
              </Grid>
            </GridContainer>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <InfoUC
        open={openInfo}
        // title={'sidebar.menu.company'}
        handleDialog={handleInfoDialog}
        children={departmentInfoForm()}
        // isShowDelete={false}
      />
    </>
  );
};

export default DepartmentInfo;

DepartmentInfo.propType = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func,
  selectedDepartment: PropTypes.object
};

DepartmentInfo.defaultProps = {
  selectedDepartment: null
};
