import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import InfoUC from "routes/shared/uc/info-uc/";
import { onUpdate, onCreate } from "redux/actions/Default";
import TabHeader from "./tab-header";
import TabGeneral from "./tab-general";
import TabAddress from "./tab-address";
import TabWorkingDay from "./tab-workingday";
import { useTranslation } from "react-i18next";
import detectStateChange from "services/detectStateChange";
import { timeToNumber } from "services/default.service";
import { getStorageAuthenUser } from "services/Storage.service";

const CompanyInfo = ({ handleDialog }) => {
  // console.log('company info')
  const {
    currentCompany,
    companyEnum,
    yearList,
    companyHolidayList
  } = useSelector(({ companys }) => companys);
  const { infoViewOnlyMode } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const _companyTypeId = companyEnum.find(
    e => e.type === "companyType" && e.name === "headOffice"
  ).id;
  // console.log('CompanyInfo', currentCompany)
  //tabGeneral
  const _company = currentCompany || {};
  const [company, setCompany] = useState({
    id: _company.id || null,
    companyCode: _company.companyCode || "",
    companyName: _company.companyName || "",
    // companyNameError: '',
    companyNameEn: _company.companyNameEn || "",
    companyType: _company.companyType || _companyTypeId,
    businessType: _company.businessType || "",
    programStartDate: _company.programStartDate || new Date(),
    image: _company.image || "",
    taxNo: _company.taxNo || "",
    taxBranchNo: _company.taxBranchNo || "",
    // numofEmployee: comp.numofEmployee || 0,
    isActive: _company.isActive ?? true,
    createdDate: _company.createdDate || new Date(),
    createdBy: _company.createdBy || "",
    modifiedDate: _company.modifiedDate || new Date(),
    modifiedBy: _company.modifiedBy || ""
  });

  //tabAddress
  const address =
    _company && _company.companyAddress ? _company.companyAddress[0] : {};
  const [companyAddress, setCompanyAddress] = useState({
    companyId: _company.id || null,
    addressDetail: address.addressDetail || "",
    subDistrict: address.subDistrict || "",
    district: address.district || "",
    province: address.province || "",
    postalCode: address.postalCode || "",
    country: address.country || "",
    latitude: address.latitude || 0,
    longitude: address.longitude || 0,
    addressType: "Current",
    phone: address.phone || "",
    email: address.email || "",
    fax: address.fax || "",
    website: address.website || "",
    createdDate: address.createdDate || new Date(),
    createdBy: address.createdBy || "",
    modifiedDate: address.modifiedDate || new Date(),
    modifiedBy: address.modifiedBy || ""
  });

  //tabWorkingday
  const workingDay =
    _company && _company.companyWorkingDay ? _company.companyWorkingDay[0] : {};
  const [companyWorkingDay, setCompanyWorkingDay] = useState({
    companyId: _company.id || null,
    startTime: workingDay.startTime || "",
    // startTimeError: '',
    endTime: workingDay.endTime || "",
    // endTimeError: '',
    isMonday: workingDay.isMonday ?? true,
    isTuesday: workingDay.isTuesday ?? true,
    isWednesday: workingDay.isWednesday ?? true,
    isThursday: workingDay.isThursday ?? true,
    isFriday: workingDay.isFriday ?? true,
    isSaturday: workingDay.isSaturday ?? false,
    isSunday: workingDay.isSunday ?? false
  });

  //Holiday
  const [companyHoliday, setCompanyHoliday] = useState(
    companyHolidayList || []
  );

  useEffect(() => {
    setCompanyHoliday(companyHolidayList);
  }, [companyHolidayList]);

  const handleDateChangeCompany = name => value => {
    setCompany({ ...company, [name]: value });
  };

  const handleChangeCheckbox = event => {
    setCompany({ ...company, [event.target.name]: event.target.checked });
  };

  const handleTimeChangeWorking = e => {
    setCompanyWorkingDay({
      ...companyWorkingDay,
      [e.target.name]: timeToNumber(e.target.value, ":")
    });
  };

  const handleCheckChange = e => {
    setCompanyWorkingDay({
      ...companyWorkingDay,
      [e.target.name]: e.target.checked
    });
  };

  const handleDateChangeHoliday = name => value => {
    setCompanyHoliday({ ...companyHoliday, [name]: value });
  };

  const propsGeneral = {
    infoViewOnlyMode,
    company,
    setCompany,
    handleDateChangeCompany,
    handleChangeCheckbox
  };

  const propsAddress = {
    infoViewOnlyMode,
    companyAddress,
    setCompanyAddress
  };

  const propsWorkingDay = {
    infoViewOnlyMode,
    companyWorkingDay,
    setCompanyWorkingDay,
    handleTimeChangeWorking,
    handleCheckChange
  };

  const propsHoliDay = {
    yearList,
    infoViewOnlyMode,
    companyHoliday,
    setCompanyHoliday,
    handleDateChangeHoliday
  };

  detectStateChange([
    company,
    companyAddress,
    companyWorkingDay
    // companyHoliday
  ]);

  const handleInfoDialog = action => {
    // console.log('action dialog :' + action)
    switch (action) {
      case "save":
        handleSubmit();
        break;
      case "delete":
        // console.log('delete from info.');
        if (currentCompany) {
          handleDialog({ action: "delete", value: [currentCompany.id] });
        }
        break;
      default:
        handleDialog();
        break;
    }
  };

  const handleSubmit = () => {
    // console.log('handleSubmit');
    if (!company.companyName) {
      setCompany({
        ...company,
        companyNameError: !company.companyName
          ? t("company.lblCompanyNameRequired")
          : ""
      });
      setCompanyAddress({
        ...companyAddress
      });
      setCompanyWorkingDay({
        ...companyWorkingDay
      });
      setCompanyHoliday({
        ...companyHoliday
      });
    } else {
      // Except new item && deleted
      let _companyHoliday = companyHoliday.filter(item => {
        return !item.isDelete || (item.isDelete && item.rowState !== "new");
      });
      const isExist = companyHoliday.filter(
        item =>
          new Date(item.date).getFullYear() === new Date().getFullYear() &&
          item.rowState !== "new" &&
          item.rowState !== "default" &&
          !item.isDeleted
      );
      if (isExist.length > 0) {
        // กรณีมีวันหยุดทีกำหนดอยู่ ณ ปีปัจจุบัน ไม่ต้อง save ค่าวันหยุด default
        _companyHoliday = companyHoliday.filter(
          item => item.rowState !== "default"
        );
        // console.log("isExist", _companyHoliday);
      }
      // console.log("handleSubmit", _companyHoliday);
      const authUser = getStorageAuthenUser();
      const companySave = {
        companyCode: company.companyCode,
        companyName: company.companyName,
        companyNameEn: company.companyNameEn,
        companyType: company.companyType,
        businessType: company.businessType,
        programStartDate: company.programStartDate,
        image: company.image,
        taxNo: company.taxNo,
        taxBranchNo: company.taxBranchNo,
        numofEmployee: company.numofEmployee,
        isActive: company.isActive,
        createdDate: company.createdDate,
        createdBy: company.createdBy,
        modifiedDate: company.modifiedDate,
        modifiedBy: company.modifiedBy,
        companyAddress: [companyAddress],
        companyWorkingDay: [companyWorkingDay],
        registerId: authUser.data.registerId,
        companyHoliday: _companyHoliday
      };
      // console.log('save', companySave)

      if (currentCompany) {
        dispatch(onUpdate({ ...currentCompany, ...companySave }));
        console.log("Update success");
      } else {
        dispatch(onCreate(companySave));
        console.log("Insert Success");
      }
      handleDialog({ action: "save", value: "" });
    }
  };

  //create form
  const companyInfoForm = () => {
    return (
      <>
        <TabHeader
          tabGeneral={<TabGeneral propsGeneral={propsGeneral} />}
          tabAddress={<TabAddress propsAddress={propsAddress} />}
          tabWorkingDay={
            <TabWorkingDay
              propsWorkingDay={propsWorkingDay}
              propsHoliday={propsHoliDay}
            />
          }
        />
      </>
    );
  };

  return (
    <>
      <InfoUC handleDialog={handleInfoDialog} children={companyInfoForm()} />
    </>
  );
};

export default CompanyInfo;

CompanyInfo.propType = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func,
  selectedCompany: PropTypes.object
};

CompanyInfo.defaultProps = {
  selectedCompany: null
};
