import {
  SET_ALL_SHIFT_DETAIL,
  SET_CURRENT_SHIFT
} from "../../@jumbo/constants/ActionTypes";

export const setCurrentShift = shift => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_SHIFT,
      payload: shift
    });
  };
};

export const setShiftById = (data, callbackFun = null) => {
  let shift = null;
  return dispatch => {
    shift = data.data.shift;
    if (data.data.shiftDetail) {
      shift.shiftDetail = data.data.shiftDetail;
    }

    if (data.data.allShiftDetail) {
      dispatch({
        type: SET_ALL_SHIFT_DETAIL,
        payload: data.data.allShiftDetail
      });
    }

    dispatch({ type: SET_CURRENT_SHIFT, payload: shift });
    if (callbackFun) callbackFun(shift);
  };
};
