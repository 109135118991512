import { Box, Paper, Button, Grid, TextField } from "@mui/material";
import { Typography } from "antd";
import React, { useState } from "react";
import useStyles from "./index.style";
import { AuhMethods } from "services/auth";
import { CurrentAuthMethod } from "@jumbo/constants/AppConstants";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import liff from "@line/liff";
import * as Session from "services/Session.service";
import { getStorageLiff } from "services/Storage.service";

const LiffAuthen = () => {
  const classes = useStyles();
  const [userName, setUserName] = useState(""); //'weatherzilla@gmail.com'
  const [userNameError, setUserNameError] = useState(""); //'weatherzilla@gmail.com'
  const [password, setPassword] = useState(""); //'123456789'
  const [passwordError, setPasswordError] = useState(""); //'123456789'
  const dispatch = useDispatch();
  const method = CurrentAuthMethod;
  const rememberMe = true;

  const onSubmit = () => {
    setUserNameError(!userName ? "กรุณากรอกข้อมูล" : "");
    setPasswordError(!password ? "กรุณากรอกข้อมูล" : "");
    // let uid = Session.getLiff().uid;
    const liffStorage = getStorageLiff();

    if (userName && password) {
      dispatch(
        AuhMethods[method].onLogin(
          { userName, password, rememberMe, uid: liffStorage?.uid },
          data => {
            if (data.data) {
              Swal.fire({
                title: "",
                text:
                  "ยินดีต้อนรับคุณ " +
                  data.data.firstName +
                  " " +
                  data.data.lastName +
                  " สามารถเข้าใช้งานระบบได้",
                icon: "success",
                confirmButtonText: "OK",
                showClass: {
                  popup: false
                }
              }).then(() => {
                liff.closeWindow();
              });
            } else {
              Swal.fire({
                title: "",
                text:
                  data.message === "Password do not match"
                    ? "Password ไม่ถูกต้อง"
                    : "ตรวจสอบไม่พบ UserName กรุณาติดต่อ HR หรือ Admin เพื่อตรวจสอบ",
                icon: "error",
                confirmButtonText: "OK",
                showClass: {
                  popup: false
                }
              });
            }
          }
        )
      );
    }
  };

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box
        p={2}
        sx={{ display: "flex", justifyContent: "center", width: "100%" }}
        className={classes.boxTitle}
      >
        <Typography variant="h1" className={classes.title}>
          ลงทะเบียนใช้งาน
        </Typography>
      </Box>
      <Box
        p={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          flexDirection: "column"
        }}
      >
        <Box pb={4} sx={{ width: "100%" }}>
          <Typography variant="h1" className={classes.label}>
            กรุณากรอกข้อมูล User
          </Typography>
        </Box>
        <Box sx={{ display: "flex", width: "100%", flexDirection: "row" }}>
          <Grid alignItems="center" container mr={1}>
            <TextField
              label={"ชื่อผู้ใช้"}
              fullWidth
              className={classes.textFieldRoot}
              // value={emp.email}
              name="email"
              maxLength={200}
              variant="outlined"
              onChange={event => setUserName(event.target.value)}
              helperText={userNameError}
              error={!!userNameError}
            />
          </Grid>
        </Box>
        <Box
          pb={2}
          sx={{ display: "flex", width: "100%", flexDirection: "row" }}
        >
          <Grid alignItems="center" container mr={1}>
            <TextField
              label={"รหัสผ่าน"}
              fullWidth
              className={classes.textFieldRoot}
              // value={user.password || ""}
              name="password"
              onChange={event => setPassword(event.target.value)}
              type="password"
              maxLength={200}
              variant="outlined"
              helperText={passwordError}
              error={!!passwordError}
            />
          </Grid>
        </Box>
        <Box
          mt={3}
          sx={{ display: "flex", width: "100%", flexDirection: "center" }}
        >
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            size="large"
            className={classes.btnStart}
          >
            {"เริ่มใช้งาน"}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default LiffAuthen;
