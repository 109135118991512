const config = {
  dev: false,
  UID: "Ub30d976b84f30085a895bdb13228cf3a",
  liffId: "2005820531-XWoL4a9v", //register
  liffAuth: "2005820531-pKxMkKR5", //authen
  liffPoint: "2005820531-o4APBbv6", //Point
  liffCheck: "2005820531-qaO0QBDa", // Check In Out,
  liffProfile: "2005820531-RjMEqW3a", //Profile
  _baseURL: "https://hr-th-api.undefined.co.th:4439/api/"
};
export default config;
