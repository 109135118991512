import React from "react";
import ListUC from "routes/shared/uc/list-uc";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Avatar } from "@material-ui/core";
import PropTypes from "prop-types";
import useStyles from "../my-point/index.style";


const EmployeePointList = ({ listViewClick }) => {
    const { empPointList } = useSelector(({ point }) => point);
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const isLangEn = i18n.language === "en";

    const handleOnEdit = id => {
        const currentEmpPoint = empPointList.filter(e => e.id === id);
        console.log('currentEmpPoint', currentEmpPoint);
        if (currentEmpPoint) {
            listViewClick({ action: "edit", id: id, data: currentEmpPoint[0] });
        }
    };

    const columns = [
        { field: "id", headerName: "ID", width: 70 },
        {
            field: "image",
            headerName: t("point.list.image"),
            width: 80,
            align: "center",
            renderCell: params => {
                return (
                    <>
                        <Avatar src={params.value} />
                    </>
                );
            }
        },
        {
            field: "empCode",
            headerName: t("point.list.empCode"),
            width: 160
        },
        {
            field: "empName",
            headerName: t("point.list.empFullName"),
            width: 220,
            flex: 1,
            valueGetter: params =>
                `${(isLangEn ? params.row.empNameEn : params.row.empName) || ""} `
        },
        {
            field: "point",
            headerName: t("point.list.point"),
            width: 100,
            align: "right",
            renderCell: params => {
                const value = params.row.point.toString();
                return (
                    <>
                        <span className={classes.textPoint}>
                            {value}
                        </span>
                        <Avatar className={classes.avatarPoint}
                            src="/images/time/star.png"
                        />
                    </>
                );
            }
        },
    ];

    return (
        <ListUC
            datas={empPointList}
            cols={columns}
            isShowAdd={false}
            onAdd={() => { }}
            onEdit={handleOnEdit}
            isShowDelete={false}
        />
    );
}

EmployeePointList.propTypes = {
    listViewClick: PropTypes.func
};

export default React.memo(EmployeePointList);
