import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getById } from "redux/actions/Default";
import {
  getStorageAuthenUser,
  setStorageControllerName
} from "services/Storage.service";
import EmpInfo from "../Personnel/employee/emp-info";

function Profile() {
  const authen = getStorageAuthenUser();
  const dispatch = useDispatch();
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    if (authen) {
      setStorageControllerName("employee");
      dispatch(
        getById(authen.data.empId, authen.data.companyId, () => {
          setIsLoad(true);
        })
      );
    }
  }, [authen, dispatch]);

  const InfoViewClick = event => {
    if (event) {
      switch (event.action) {
        case "save":
          // dispatch(setCurrentEmployee(event.value));
          break;
        default:
          break;
      }
    }
  };

  return (
    <PageContainer>
      {isLoad && <EmpInfo handleDialog={InfoViewClick} isShowClose={false} roleUser={authen.data.role} />}
    </PageContainer>
  );
}

export default Profile;
