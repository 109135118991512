import Resizer from "react-image-file-resizer";

export const IsNullOrEmpty = obj => {
  if ("undefined" === typeof obj || obj === null) {
    return true;
  } else if (
    typeof obj != "undefined" &&
    obj != null &&
    obj.length != null &&
    obj.length === 0
  ) {
    return true; //array
  } else if ("number" === typeof obj) {
    return false; //NaN
  } else if ("string" === typeof obj) {
    return obj.length < 1 ? true : false;
  } else {
    return false;
  }
};
// DateTime To yyyy-MM-dd
export const ConverDateTimeToString = date => {
  let str = "";
  if (!IsNullOrEmpty(date)) {
    const MM =
      date.getMonth() < 10 ? "0" + (date.getMonth() + 1) : date.getMonth();
    const DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    str = date.getFullYear() + "-" + MM + "-" + DD;
  }
  return str;
};

export const ConverDateTimeToDatePicker = date => {
  let str = "";
  if (!IsNullOrEmpty(date)) {
    const MM =
      date.getMonth() < 10 ? "0" + (date.getMonth() + 1) : date.getMonth();
    const DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    str = MM + "-" + DD + "-" + date.getFullYear();
  }
  return str;
};

export const DifferenceDate = (date1, date2) => {
  var result = {
    years: date2.getYear() - date1.getYear(),
    months: date2.getMonth() - date1.getMonth(),
    days: date2.getDate() - date1.getDate(),
    hours: date2.getHours() - date1.getHours()
  };
  if (result.hours < 0) {
    result.days--;
    result.hours += 24;
  }
  if (result.days < 0) {
    result.months--;
    // days = days left in date1's month,
    //   plus days that have passed in date2's month
    var copy1 = new Date(date1.getTime());
    copy1.setDate(32);
    result.days = 32 - date1.getDate() - copy1.getDate() + date2.getDate();
  }
  if (result.months < 0) {
    result.years--;
    result.months += 12;
  }
  return result;
};

export const numberToTime = (num, seperate) => {
  // console.log('numberToTime', num)
  if (IsNullOrEmpty(num)) return "";
  let hours = Math.floor(num / 60);
  let minutes = num % 60;
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  return hours + seperate + minutes;
};

export const timeToNumber = (value, seperate) => {
  let time = value.split(seperate);
  let hours = parseInt(time[0]);
  let minutes = parseInt(time[1]);
  const total = hours * 60 + minutes;
  return total <= 0 ? "" : total;
};

export const onFilterSearchList = (cols, data, searchValue) => {
  if (data && data.length > 0) {
    return data.filter(query => {
      return Object.keys(query).some(
        key =>
          ((typeof query[key] === "string" &&
            query[key].toLowerCase().includes(searchValue.toLowerCase())) ||
            (typeof query[key] === "number" &&
              query[key] === Number(searchValue))) &&
          key !== "action" &&
          key !== "id" &&
          key !== "companyId" &&
          key !== "image" &&
          key !== "isDeleted" &&
          cols.some(x => x.field === key)
      );
    });
  } else {
    return [];
  }
};

export const resizeFile = file =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      uri => {
        resolve(uri);
      },
      "base64"
    );
  });
