import React from "react";
import ListUC from "routes/shared/uc/list-uc";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { setCurrentPosition } from "redux/actions/Position";
import { getById } from "redux/actions/Default";

const PositionList = ({ listViewClick }) => {
  const dispatch = useDispatch();
  const { positionsList } = useSelector(({ positions }) => positions);
  const { t, i18n } = useTranslation();
  const isLangEn = i18n.language === "en";

  const handleOnEdit = id => {
    const currentPosition = positionsList.filter(e => e.id === id);
    if (currentPosition) {
      dispatch(
        getById(currentPosition[0].id, currentPosition[0].companyId, () => {
          listViewClick({ action: "edit", id: id });
        })
      );
    }
  };

  const handleOnAdd = () => {
    dispatch(
      getById(0, 0, () => {
        dispatch(setCurrentPosition(null));
        listViewClick({ action: "add", id: "" });
      })
    );
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "positionCode",
      headerName: t("position.list.positionCode"),
      width: 160
    },
    {
      field: "positionName",
      headerName: t("position.list.positionName"),
      width: 220,
      flex: 1,
      valueGetter: params =>
        `${(isLangEn ? params.row.positionNameEn : params.row.positionName) || ""} `
    }
  ];

  return (
    <ListUC
      datas={positionsList}
      cols={columns}
      onAdd={handleOnAdd}
      onEdit={handleOnEdit}
    />
  );
};

PositionList.propTypes = {
  listViewClick: PropTypes.func
};

export default React.memo(PositionList);
