import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getById, onCreate, onUpdate } from "redux/actions/Default";
import InfoUC from "routes/shared/uc/info-uc";
import TabGeneral from "./tab-general";
import TabHeader from "./tab-header";
import TabTime from "./tab-time";
import {
  getStorageAuthenUser,
  setStorageControllerName
} from "services/Storage.service";
import DetectStateChange from "services/detectStateChange";

const PointSetting = () => {
  const authen = getStorageAuthenUser();
  const { infoViewOnlyMode } = useSelector(({ common }) => common);
  const { currentPoint, currentPointTime } = useSelector(({ point }) => point);
  const dispatch = useDispatch();

  const _data = currentPoint || {};
  const [point, setPoint] = useState({
    id: _data.id || null,
    startPoint: _data.startPoint || "",
    birthDayPoint: _data.birthDayPoint || "",
    companyId: _data.companyId || null,
    createdBy: _data.createdBy || null,
    createdDate: _data.createdDate || null,
    modifiedBy: _data.modifiedBy || null,
    modifiedDate: _data.modifiedDate || null,
    isDeleted: _data.isDeleted || null
  });

  const _pointTime = currentPointTime || {};
  const [pointTime, setPointTime] = useState({
    id: _pointTime.id || null,
    isInOnTime: _pointTime.isInOnTime || false,
    inOnTimePoint: _pointTime.inOnTimePoint || 0,
    isInLate: _pointTime.isInLate || false,
    inLatePoint: _pointTime.inLatePoint || 0,
    isOutOnTime: _pointTime.isOutOnTime || false,
    outOnTimePoint: _pointTime.outOnTimePoint || 0,
    isOutBefore: _pointTime.isOutBefore || false,
    outBeforePoint: _pointTime.outBeforePoint || 0,
    isNotAttendance: _pointTime.isNotAttendance || false,
    notAttendancePoint: _pointTime.notAttendancePoint || 0,
    companyId: _pointTime.companyId || null,
    createdBy: _pointTime.createdBy || null,
    createdDate: _pointTime.createdDate || null,
    modifiedBy: _pointTime.modifiedBy || null,
    modifiedDate: _pointTime.modifiedDate || null,
    isDeleted: _pointTime.isDeleted || null
  });

  useEffect(() => {
    if (authen) {
      setStorageControllerName("pointSetting");
      dispatch(
        getById(authen.data.empId, authen.data.companyId, data => {
          //   console.log("getById", data);
          if (data.point) {
            setPoint(data.point);
          }
          if (data.pointTime) {
            setPointTime(data.pointTime);
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInfoDialog = action => {
    // console.log("action dialog :" + action);
    switch (action) {
      case "save":
        handleSubmit();
        break;
      case "delete":
        console.log("delete from info.");
        break;
      default:
        // handleDialog();
        break;
    }
  };

  const handleSubmit = () => {
    // console.log("submit from info.");
    const pointSave = {
      ...point,
      pointSettingTime: [pointTime]
    };

    if (point && point.id) {
      dispatch(onUpdate(pointSave));
    } else {
      dispatch(onCreate(pointSave));
    }
  };

  const handleChangeStartPoint = (value) => {
    setPoint({ ...point, startPoint: value });
  };

  const handleChangeBirthPoint = (value) => {
    setPoint({ ...point, birthDayPoint: value });
  };

  const handleChangeCheckbox = event => {
    setPointTime({ ...pointTime, [event.target.name]: event.target.checked });
  };

  const propsGeneral = {
    infoViewOnlyMode,
    point,
    handleChangeStartPoint,
    handleChangeBirthPoint
  };

  const propsTime = {
    infoViewOnlyMode,
    pointTime,
    setPointTime,
    handleChangeCheckbox
  };

  const pointSettingForm = () => {
    return (
      <TabHeader
        tabGeneral={<TabGeneral props={propsGeneral} />}
        tabTime={<TabTime props={propsTime} />}
      />
    );
  };

  DetectStateChange([point, pointTime]);

  return (
    <InfoUC
      handleDialog={handleInfoDialog}
      children={pointSettingForm()}
      isShowDelete={false}
      isShowClose={false}
    />
  );
};

export default PointSetting;
