import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    height: "100%",
    maxHeight: "-webkit-fill-available",
    "& video": {
      borderRadius: theme.spacing(2)
    }
  },
  homeIcon: {
    color: theme.mainColor,
    cursor: "pointer"
  },
  clock: {
    textAlign: "center",
    padding: theme.spacing(5),
    // margin: theme.spacing(2),
    borderRadius: theme.spacing(2),
    backgroundColor: "rgba(1, 93, 100, 0.08)",
    "& time": {
      fontSize: "3rem",
      fontFamily: "Orbitron !important"
    }
  },
  textDate: {
    marginTop: "1rem !important",
    textAlign: "center"
  },
  clockWith: {
    textAlign: "center",
    color: theme.mainColor
  },
  btnClock: {
    width: "160px",
    height: "160px",
    fontSize: "30px !important",
    fontWeight: "bold !important",
    borderRadius: "100% !important",
    marginTop: "1rem !important",
    backgroundColor: theme.mainColor
    // color: "white !important",
  },
  stampHistory: {
    padding: theme.spacing(2),
    margin: "auto",
    maxHeight: "255px",
    // height: '400px',
    // marginTop: '1rem !important',
    // marginBottom: '2rem !important',
    overflowY: "hidden"
  },
  timesStamp: {
    border: "1px solid #e0e0e0",
    // height: '250px',
    borderRadius: theme.spacing(2),
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)"
  },
  divider: {
    marginTop: "10px !important",
    marginBottom: "10px !important"
  },
  nextClock: {
    fontSize: "20px !important"
  },
  textWarning: {
    color: theme.mainColor,
    fontWeight: "bold !important",
    fontSize: "16px !important"
  },
  controlTakePhoto: {
    position: "fixed",
    display: "flex",
    right: 0,
    bottom: 0,
    width: "100%",
    minWidth: "130px",
    minHeight: "130px",
    height: "20%",
    // background: 'rgba(0, 0, 0, 0.8)',
    zIndex: 10,
    alignItems: "center",
    padding: "50px",
    boxSizing: "border-box",
    justifyContent: "center",
    flexDirection: "column-reverse"
  },
  takePhotoBtn: {
    padding: "15px",
    backgroundColor: "transparent",
    height: "60px",
    width: "60px",
    borderRadius: "50%",
    color: "#fff",
    border: "1px solid #fff"
  }
}));

export default useStyles;
