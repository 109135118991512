import CmtSearch from "@coremat/CmtSearch";
import React, { useEffect, useState } from "react";
import useStyles from "./index.style";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { Avatar, Box } from "@mui/material";
import { format } from "date-fns";
import { useSelector } from "react-redux";

const TabHistory = ({ props }) => {
  const { infoViewOnlyMode, setTotalPoints } = props;
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const { t } = useTranslation();
  const [filterDatas, setFilterDatas] = useState([]);
  const [datas, setDatas] = useState([]);
  const { myPointList } = useSelector(({ point }) => point);

  let pointList = [];
  if (myPointList && myPointList.length > 0) {
    pointList = myPointList.sort((a, b) =>
      new Date(b.date) > new Date(a.date) ? 1 : -1
    );
  }

  useEffect(() => {
    if (pointList && pointList.length > 0) {
      const pointData = pointList.map((item, index) => ({
        id: item.id,
        no: index + 1,
        type: item.type,
        detail: item.detail,
        points: item.point,
        date: item.date,
        remark: item.remark
      }));
      setDatas(pointData);
      setFilterDatas(pointData);
      setTotalPoints(pointData.reduce((a, b) => (a = a + b.points), 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointList]);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "no",
      headerName: "No.",
      width: 70
    },
    {
      field: "type",
      headerName: t("point.lblType"),
      width: 180,
      renderCell: params => {
        const type = "inOnTime,outOnTime".includes(params.row.type)
          ? "onTime"
          : params.row.type;
        const path =
          "/images/point/" + (type === "early" ? "late" : type) + ".png";
        return (
          <>
            <span>{getPointTypeLabel(type)}</span>
            {"like,kiss,thankyou,adore,love,custom,late,early,inontime,outonTime,notattendance".includes(
              type.toLowerCase()
            ) && (
              <Avatar
                src={path}
                sx={{
                  width: 20,
                  height: 20,
                  marginLeft: 1,
                  borderRadius: "unset"
                }}
              />
            )}
          </>
        );
      }
    },
    {
      field: "detail",
      headerName: t("point.lblDetail"),
      width: 200,
      minWidth: 200
    },
    {
      field: "points",
      headerName: t("point.lblPoints"),
      width: 100,
      align: "right",
      renderCell: params => {
        // console.log(params);
        const value = params?.row?.points?.toString();
        return (
          <>
            <span className={classes.textPoint}>
              {(!value?.includes("-") ? "+" : "") + (value ?? "")}
            </span>
            <Avatar
              src="/images/time/star.png"
              sx={{ width: 20, height: 20, marginLeft: 1 }}
            />
          </>
        );
      }
    },
    {
      field: "date",
      headerName: t("point.lblDate"),
      width: 150,
      valueGetter: ({ value }) => value && format(new Date(value), "dd/MM/yyyy")
    },
    {
      field: "remark",
      headerName: t("point.lblRemark"),
      width: 200,
      minWidth: 200,
      flex: 1
    }
  ];

  const getPointTypeLabel = type => {
    switch (type) {
      case "like":
        return "Like";
      case "kiss":
        return "Kiss";
      case "thankyou":
        return "Thank You";
      case "adore":
        return "Adore";
      case "love":
        return "Love";
      case "custom":
        return "Custom";
      case "late":
        return "Late";
      case "early":
        return "Early";
      case "inOnTime":
        return "Clock In On Time";
      case "outOnTime":
        return "Clock Out On Time";
      case "notAttendance":
        return "Not Attendance";
      default:
        return type;
    }
  };
  const onSearch = text => {
    if (text) {
      setFilterDatas(
        datas.filter(data => {
          return (
            data.type.toLowerCase().includes(text.toLowerCase()) ||
            data.detail.toLowerCase().includes(text.toLowerCase()) ||
            data.remark.toLowerCase().includes(text.toLowerCase()) ||
            data.points
              .toString()
              .toLowerCase()
              .includes(text.toLowerCase()) ||
            data.date.toLowerCase().includes(text.toLowerCase())
          );
        })
      );
    } else {
      setFilterDatas(datas);
    }
    setSearchText(text);
  };

  return (
    <>
      <CmtSearch
        className={classes.searchBar}
        onChange={e => onSearch(e.target.value)}
        border={false}
        value={searchText}
      />
      <Box flex sx={{ height: 370, width: "100%" }} mt={1}>
        <DataGrid
          className={classes.gridHeader}
          rows={filterDatas || []}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableColumnMenu={true}
          checkboxSelection={!infoViewOnlyMode}
          disableSelectionOnClick
          initialState={
            datas
              ? {
                  ...datas.initialState,
                  columns: {
                    columnVisibilityModel: {
                      id: false
                    }
                  }
                }
              : {}
          }
        />
      </Box>
    </>
  );
};

export default TabHistory;
