import React, { useEffect, useState } from "react";
import GridContainer from "@jumbo/components/GridContainer";
import { Grid } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Box, Button, Stack } from "@mui/material";
import LabelUC from "routes/shared/uc/label-uc";
import AutoCompleteMultiUC from "routes/shared/uc/autocomplete-uc/multiple";
import { useTranslation } from "react-i18next";
import useStyles from "./index.style";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import { useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";

const EmployeeLocation = ({ props }) => {
  const { empLocation, setEmpLocation, infoViewOnlyMode } = props;

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { allEmpLocation } = useSelector(({ locations }) => locations);
  const [empList, setEmpList] = useState([]);

  useEffect(() => {
    const emp = allEmpLocation.map(item => ({
      id: item.id,
      title: item.empCode,
      description:
        i18n.language === "th"
          ? item.firstName + " " + item.lastName
          : item.firstNameEn + " " + item.lastNameEn
    }));
    setEmpList(emp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteClick = () => {
    setOpenConfirmDialog(true);
  };

  const onAddClick = () => {
    selectedEmp.map(item => {
      const emp = allEmpLocation.find(l => l.id === item.id);
      setEmpLocation(empLocation => [
        ...empLocation,
        {
          id: emp.id,
          empCode: emp.empCode,
          title: emp.title,
          firstName: emp.firstName,
          lastName: emp.lastName,
          titleEn: emp.titleEn,
          firstNameEn: emp.firstNameEn,
          lastNameEn: emp.lastNameEn,
          isDeleted: false,
          companyId: emp.companyId,
          rowStatus: "new"
        }
      ]);
      return item;
    });

    setSelectedEmp([]);
  };

  const deleteRow = React.useCallback(
    id => () => {
      setSelected([id]);
      setOpenConfirmDialog(true);
    },
    []
  );

  const handleConfirmDelete = () => {
    setEmpLocation(
      empLocation.map(data => {
        if (selected.includes(data.id)) {
          return {
            ...data,
            isDeleted: true
          };
        }
        return data;
      })
    );
    setSelected([]);
    setOpenConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setSelected([]);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "empCode", headerName: t("employee.lblEmpCode"), width: 200 },
    {
      field: "empName",
      headerName: t("employee.list.fullName"),
      width: 300,
      flex: 1,
      valueGetter: params => `${params.row.firstName} ${params.row.lastName}`
    },
    {
      field: "empNameEn",
      headerName: t("employee.list.fullName"),
      width: 300,
      flex: 1,
      valueGetter: params =>
        `${params.row.firstNameEn} ${params.row.lastNameEn}`
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 100,
      getActions: params => [
        <GridActionsCellItem
          icon={
            selected.length === 0 && !infoViewOnlyMode ? <DeleteIcon /> : <></>
          }
          label="Delete"
          onClick={
            selected.length === 0 && !infoViewOnlyMode
              ? deleteRow(params.id)
              : () => { }
          }
        />
      ]
    }
  ];

  return (
    <>
      <Box xs={12} sm={8} padding={2} flex>
        <GridContainer mb={2}>
          <Grid item xs={12} sm={2} alignItems="center" container>
            <LabelUC label={t("location.lblEmployee")} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <AutoCompleteMultiUC
              datas={empList.filter(
                item => !empLocation.find(l => l.id === item.id && !l.isDeleted)
              )}
              infoViewOnlyMode={infoViewOnlyMode}
              isMultipleLine={true}
              filterSelected={true}
              value={selectedEmp}
              setValue={setSelectedEmp}
              placeholder={t("location.lblSelectEmp")}
            />
          </Grid>
          <Grid item xs={12} sm={3} m="auto">
            <Stack direction="row" spacing={2} alignContent="center">
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                color="success"
                onClick={onAddClick}
                disabled={infoViewOnlyMode || selectedEmp.length === 0}
                sx={{ fontWeight: "bold", width: "50%" }}
              >
                {t("common.add")}
              </Button>
              <Button
                variant="contained"
                startIcon={<DeleteIcon />}
                color="error"
                onClick={onDeleteClick}
                disabled={infoViewOnlyMode || selected.length === 0}
                sx={{ fontWeight: "bold", width: "50%" }}
              >
                {t("common.delete")}
              </Button>
            </Stack>
          </Grid>
        </GridContainer>
      </Box>

      <Box
        sx={{
          padding: 2,
          // width: { xs: "100%", lg: "50%" },
          // marginLeft: { xs: "0%", lg: "15%" },
          height: 260
        }}
        xs={12}
        sm={4}
        pt="0 !important"
        pl={{ xs: 2, sm: 21 }}
      >
        <DataGrid
          className={classes.gridHeader}
          // rows={companyHoliday.filter(row => !row.isDeleted)}
          rows={empLocation.filter(row => !row.isDeleted)}
          columns={columns}
          hideFooter={true}
          checkboxSelection={!infoViewOnlyMode}
          disableColumnMenu={true}
          onSelectionModelChange={newSelection => {
            setSelected(newSelection);
          }}
          initialState={
            empLocation
              ? {
                ...empLocation.initialState,
                columns: {
                  columnVisibilityModel: {
                    id: false,
                    empName: i18n.language === "th",
                    empNameEn: i18n.language === "en"
                  }
                }
              }
              : {}
          }
        />
        <ConfirmDialog
          open={openConfirmDialog}
          title={`Confirm delete`}
          content={"Are you sure, you want to  delete selected?"}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      </Box>
    </>
  );
};

export default EmployeeLocation;

// const rows = [
//     { id: 1, employeeCode: "T-01", employeeName: "TEST 01" },
//     { id: 2, employeeCode: "T-02", employeeName: "TEST 02" },
//     { id: 3, employeeCode: "T-03", employeeName: "TEST 03" }
// ];
