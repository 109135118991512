import AppCheckbox from "@jumbo/components/Common/formElements/AppCheckBox";
import GridContainer from "@jumbo/components/GridContainer";
import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import TextInputUC from "routes/shared/uc/text-input-uc";
import LabelPoint from "./label-point";

const TabTimeClockIn = ({ props }) => {
  const {
    infoViewOnlyMode,
    pointTime,
    setPointTime,
    handleChangeCheckbox
  } = props;
  const { t } = useTranslation();

  const handleChangeOntime = value => {
    setPointTime({ ...pointTime, inOnTimePoint: value });
  }
  const handleChangeLate = value => {
    setPointTime({ ...pointTime, inLatePoint: value });
  }

  return (
    <>
      <Box xs={12} sm={6} p={2}>
        <Typography variant="h6">{t("point.lblClockIn")}</Typography>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />
      <Box xs={12} sm={6} flex>
        <GridContainer justifyContent="center">
          <Grid item xs={12} sm={3} alignItems="center" container>
            <AppCheckbox
              label={t("point.lblInOnTimePoint")}
              name="isInOnTime"
              onChange={handleChangeCheckbox}
              disabled={infoViewOnlyMode}
              checked={pointTime.isInOnTime}
            />
          </Grid>
          <Grid item xs={9} sm={3}>
            <TextInputUC
              type="number"
              disabled={infoViewOnlyMode || !pointTime.isInOnTime}
              placeholder={t("point.lblInOnTimePoint")}
              value={pointTime.inOnTimePoint}
              name="inOnTimePoint"
              onChange={(e) => handleChangeOntime(e.target.value.substring(0, 5))}
            />
          </Grid>
          <LabelPoint />
        </GridContainer>
      </Box>
      <Box xs={12} sm={6} flex>
        <GridContainer justifyContent="center">
          <Grid item xs={12} sm={3} alignItems="center" container>
            <AppCheckbox
              label={t("point.lblInLatePoint")}
              name="isInLate"
              onChange={handleChangeCheckbox}
              disabled={infoViewOnlyMode}
              checked={pointTime.isInLate}
            />
          </Grid>
          <Grid item xs={9} sm={3}>
            <TextInputUC
              type="number"
              disabled={infoViewOnlyMode || !pointTime.isInLate}
              placeholder={t("point.lblInLatePoint")}
              value={pointTime.inLatePoint}
              name="inLatePoint"
              onChange={(e) => handleChangeLate(e.target.value.substring(0, 6))}
            />
          </Grid>
          <LabelPoint />
        </GridContainer>
      </Box>
    </>
  )
};

export default TabTimeClockIn;
