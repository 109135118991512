import React from 'react'
import Typography from '@mui/material/Typography';
import CurrentAddress from './currentAddress';
import HomeAddress from './homeAddress';
import ContactInfo from './contactInfo';
import useStyles from './index.style';
import { useTranslation } from 'react-i18next';

const Address = ({ props }) => {
    const {
        currentEmpAddress, setCurrentEmpAddress,
        homeEmpAddress, setHomeEmpAddress,
        infoViewOnlyMode,
    } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const handleCopyToHomeAddress = () => {
        setHomeEmpAddress({
            addressType: 'Home',
            subDistrict: currentEmpAddress.subDistrict,
            district: currentEmpAddress.district,
            province: currentEmpAddress.province,
            postalCode: currentEmpAddress.postalCode,
            country: currentEmpAddress.country,
            latitude: currentEmpAddress.latitude,
            longitude: currentEmpAddress.longitude,
            addressDetail: currentEmpAddress.addressDetail,
        });
    }
    return (
        <>
            <Typography className={classes.title}>{t('employee.lblContactInfo')}</Typography>
            <ContactInfo props={props} />
            <Typography className={classes.title}>{t('employee.lblCurrentAddress')}</Typography>
            <CurrentAddress
                infoViewOnlyMode={infoViewOnlyMode}
                currentEmpAddress={currentEmpAddress}
                setCurrentEmpAddress={setCurrentEmpAddress}
                handleCopyToHomeAddress={handleCopyToHomeAddress} />
            <Typography className={classes.title}>{t('employee.lblHomeAddress')}</Typography>
            <HomeAddress
                infoViewOnlyMode={infoViewOnlyMode}
                homeEmpAddress={homeEmpAddress}
                setHomeEmpAddress={setHomeEmpAddress} />
        </>
    )
}

export default Address;