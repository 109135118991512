import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useStyles from "./index.style";
import LabelUC from "routes/shared/uc/label-uc";
import AutoCompleteUC from "routes/shared/uc/autocomplete-uc";
// import DepartmentInfo from "routes/Pages/Company/Department/department-info";
import { setCurrentShift } from "redux/actions/Shift";
import { useDispatch, useSelector } from "react-redux";
import { setStorageControllerName } from "services/Storage.service";
import ShiftInfo from "routes/Pages/TimeAttendance/shift/shift-info";
import { setMenuName } from "redux/actions";

const WorkingTime = ({ props }) => {
  const { viewOnly, employment, setEmployment } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const isLangEn = i18n.language === "en";

  const [shiftBind, setShiftBind] = useState(null);
  const [locationBind, setLocationBind] = useState(null);
  const [openShift, setOpenShift] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [locations, setLocations] = useState([]);
  const { currentShift } = useSelector(({ shifts }) => shifts);
  const { shiftList, locationList } = useSelector(({ employees }) => employees);

  let shiftDataList = [];
  let locationDataList = [];
  if (shiftList) {
    shiftDataList = shiftList.map((item, index) => ({
      id: item.id,
      title: `${item.shiftName || "shift " + (index + 1)} `,
      description: item.periodTimes
    }));
  }

  if (locationList) {
    locationDataList = locationList.map(item => ({
      id: item.id,
      title: `${(isLangEn
        ? item.locationNameEn || item.locationName
        : item.locationName) || ""} `,
      description: item.locationCode
    }));
  }

  useEffect(() => {
    // Set Value
    if (shiftDataList) {
      const data = shiftDataList.filter(item => item.id === employment.shiftId);
      setShiftBind(data && data.length > 0 ? data[0] : null);
      setShifts(shiftDataList);
    }

    if (locationDataList) {
      const data = locationDataList.filter(
        item => item.id === employment.locationId
      );
      setLocationBind(data && data.length > 0 ? data[0] : null);
      setLocations(locationDataList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n]);

  const handleChangeShift = value => {
    setShiftBind(value);
    setEmployment({ ...employment, shiftId: value ? value.id : null });
  };

  const handleChangeLocation = value => {
    setLocationBind(value);
    setEmployment({ ...employment, locationId: value ? value.id : null });
  };

  const setReturnValue = value => {
    dispatch(
      setCurrentShift({
        shiftCode: value,
        shiftName: value
      })
    );
  };

  const handleShiftDialog = event => {
    if (event) {
      switch (event.action) {
        case "save":
          const newShift = {
            id: currentShift.id,
            title: currentShift.shiftName,
            description: currentShift.periodTimes
          };
          setShifts([...shifts, newShift]);
          setShiftBind(newShift);
          setEmployment({ ...employment, shiftId: currentShift.id });
          break;
        default:
          setOpenShift(false);
          dispatch(setMenuName("sidebar.menu.employee"));
          setStorageControllerName("employee");
          break;
      }
    } else {
      dispatch(setCurrentShift(null));
    }
    setOpenShift(false);
    dispatch(setMenuName("sidebar.menu.employee"));
    setStorageControllerName("employee");
  };

  const openShiftDialog = () => {
    dispatch(setMenuName("sidebar.menu.shift"));
    setStorageControllerName("shift");
    setOpenShift(true);
  };

  return (
    <>
      <GridContainer>
        <Grid item xs={12} alignItems="center" container>
          <Typography
            component="div"
            sx={{ fontWeight: "bold" }}
            className={classes.labelProps}
          >
            {t("employee.lblWorkingTime")}
          </Typography>
        </Grid>
      </GridContainer>
      <Box
        sx={{ display: "flex", width: "100%" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        {/* info left */}
        <Box sx={{ padding: 2, width: { xs: "100%", lg: "50%" } }}>
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("employee.lblShift")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <AutoCompleteUC
                datas={shifts}
                infoViewOnlyMode={viewOnly}
                isMultipleLine={true}
                isCanAdd={true}
                value={shiftBind}
                handleChange={handleChangeShift}
                name="shiftId"
                isShowPicker={true}
                handleClickOpen={openShiftDialog}
                returnInputValue={setReturnValue}
                placeholder={t("profile.employment.shift_placeholder")}
              />
            </Grid>
          </GridContainer>
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("employee.lblLocation")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <AutoCompleteUC
                datas={locations}
                infoViewOnlyMode={viewOnly}
                isMultipleLine={true}
                name="location"
                value={locationBind}
                handleChange={handleChangeLocation}
                placeholder={t("profile.employment.location_placeholder")}
              />
            </Grid>
          </GridContainer>
        </Box>
      </Box>
      {openShift && (
        <ShiftInfo openInfo={openShift} handleDialog={handleShiftDialog} />
      )}
    </>
  );
};

export default WorkingTime;

WorkingTime.propTypes = {
  viewOnly: PropTypes.bool,
  employment: PropTypes.object,
  setEmployment: PropTypes.func
};
