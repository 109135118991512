import { makeStyles } from "@material-ui/core";
import {
  amber,
  blue,
  brown,
  deepOrange,
  green,
  grey,
  purple,
  red,
  yellow
} from "@material-ui/core/colors";
import { alpha } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    cursor: "pointer"
  },
  primary: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main
  },
  secondary: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main
  },
  amber: {
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: amber[500]
  },
  brown: {
    color: "#ffffff",
    backgroundColor: brown[500]
  },
  red: {
    color: "#ffffff",
    backgroundColor: red[500]
  },
  green: {
    color: "#ffffff",
    backgroundColor: green[500]
  },
  blue: {
    color: "#ffffff",
    backgroundColor: blue[500]
  },
  yellow: {
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: yellow[500]
  },
  grey: {
    color: "#ffffff",
    backgroundColor: grey[400]
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500]
  },
  purple: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500]
  },
  customSize: props => ({
    height: props.size,
    width: props.size
  }),
  small: {
    height: 30,
    width: 30
  },
  medium: {
    height: 40,
    width: 40
  },
  large: {
    height: 48,
    width: 48
  },
  cardRoot: {
    textAlign: "center",
    "& .Cmt-header-root": {
      paddingTop: 4,
      paddingBottom: 4
    },
    "& .Cmt-card-content": {
      padding: 16
    }
  },
  cardContent: props => ({
    "&.Cmt-separator": {
      ...props.separatorStyles
    }
  }),
  typography: {
    padding: theme.spacing(2)
  },
  iconRoot: {
    position: "relative",
    color: alpha(theme.palette.common.black, 0.54),
    "&:hover, &.active": {
      color: theme.mainColor //theme.palette.common.white,
    }
  },
  iconLang: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    "&.active": {
      fontWeight: 900,
      color: theme.mainColor,
      fontSize: "1rem"
    }
  },
  iconRounded: {
    width: "35px !important",
    height: "35px !important",
    padding: "5px",
    backgroundColor: "#eee",
    borderRadius: "5px",
    cursor: "pointer"
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.warning.main,
    width: 20
  },
  scrollbarRoot: {
    height: 300,
    padding: 16
  },
  popoverRoot: {
    "& .MuiPopover-paper": {
      width: 200
      // padding: 16
    }
  },
  popoverAvatar: {
    marginLeft: "auto",
    marginRight: "auto",
    width: 48,
    height: 48
  },
  userName: {
    fontSize: 12
  },
  userRole: {
    fontSize: 10
  }
}));
export default useStyles;
