import React from "react";
import GridContainer from "@jumbo/components/GridContainer";
import { Box, Grid, Typography } from "@mui/material";
import LabelUC from "routes/shared/uc/label-uc";
import TextInputUC from "routes/shared/uc/text-input-uc";
import DateTimePickerUC from "routes/shared/uc/datetime-picker-uc";
import { useTranslation } from "react-i18next";

const Passport = ({ props }) => {
  const { infoViewOnlyMode, emp, handleChange, handleDateChange } = props;
  const { t } = useTranslation();
  return (
    <>
      {/* info right */}
      <Box sx={{ padding: 2, width: { xs: "100%", lg: "50%" } }}>
        <GridContainer>
          <Grid item xs={12} sm={5} alignItems="center" container>
            <Typography
              component="div"
              // variant="h6"
              sx={{ fontWeight: "bold" }}
            >
              {t("employee.lblPassport")}
            </Typography>
          </Grid>
        </GridContainer>
        {/* Passport No.*/}
        <GridContainer mb={2}>
          <Grid item xs={12} sm={5} alignItems="center" container>
            <LabelUC label={t("employee.lblPassportNo")} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <TextInputUC
              disabled={infoViewOnlyMode}
              placeholder={t("employee.lblPassportNo")}
              name="passportNo"
              value={emp.passportNo}
              onChange={handleChange}
            />
          </Grid>
        </GridContainer>
        {/* Passport Expiry Date*/}
        <GridContainer>
          <Grid item xs={12} sm={5} alignItems="center" container>
            <LabelUC label={t("employee.lblPassportExpire")} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <DateTimePickerUC
              name="passportExpire"
              value={emp.passportExpire}
              handleDateChange={handleDateChange}
              infoViewOnlyMode={infoViewOnlyMode}
            />
          </Grid>
        </GridContainer>
      </Box>
    </>
  );
};

export default Passport;
