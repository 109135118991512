import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";

import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import useStyles from "./index.style";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const InfoAppBarUC = ({
  handleDialog,
  isShowDelete = true,
  isShowEdit = true,
  isShowSave = true,
  isShowClose = true,
  customTitle
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isOnAdd, menuName, infoViewOnlyMode } = useSelector(
    ({ common }) => common
  );
  const headTitle = isOnAdd ? t("common.addNew") : t("common.edit");
  const title = customTitle || headTitle + " " + t(menuName);
  return (
    <AppBar sx={{ position: "relative" }} className={classes.appBar}>
      <Toolbar>
        <Typography
          sx={{ flex: 1, fontWeight: "bold" }}
          variant="h6"
          component="div"
        >
          {title}
        </Typography>
        {isShowDelete && !isOnAdd && (
          <IconButton
            color="inherit"
            aria-label="delete"
            onClick={() => handleDialog("delete")}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {isShowEdit && !isOnAdd && infoViewOnlyMode && (
          <IconButton
            color="inherit"
            aria-label="edit"
            onClick={() => handleDialog("edit")}
          >
            <EditIcon />
          </IconButton>
        )}
        {isShowSave && (
          <IconButton
            color="inherit"
            aria-label="save"
            onClick={() => handleDialog("save")}
          >
            <SaveIcon />
          </IconButton>
        )}
        {isShowClose && (
          <IconButton
            color="inherit"
            aria-label="close"
            onClick={() => handleDialog("close")}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

InfoAppBarUC.proptype = {
  title: PropTypes.string,
  handleDialog: PropTypes.func,
  isShowDelete: PropTypes.bool,
  isShowEdit: PropTypes.bool,
  isShowSave: PropTypes.bool
};

export default InfoAppBarUC;
