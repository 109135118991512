import {
  SET_CURRENT_EMPLOYEE,
  SET_EMP_ENUM,
  SET_EMP_POSITION,
  SET_EMP_DEPARTMENT,
  SET_EMP_SUPERVISOR,
  SET_EMP_LOCATION,
  SET_EMP_SHIFT,
  SET_EMP_USER
} from "../../@jumbo/constants/ActionTypes";

export const setEmployeeById = (data, callbackFun = null) => {
  let emp = null;
  return dispatch => {
    emp = data.data.employee;
    if (data.data.empAddress && data.data.empAddress.length > 0) {
      emp.empAddress = data.data.empAddress;
    }
    if (data.data.empEmployment && data.data.empEmployment.length > 0) {
      emp.empEmployment = data.data.empEmployment;
    }
    dispatch({ type: SET_EMP_ENUM, payload: data.data.empEnum });
    dispatch({ type: SET_EMP_POSITION, payload: data.data.position });
    dispatch({ type: SET_EMP_DEPARTMENT, payload: data.data.department });
    dispatch({ type: SET_EMP_SUPERVISOR, payload: data.data.supervisor });
    dispatch({ type: SET_EMP_SHIFT, payload: data.data.shift });
    dispatch({ type: SET_EMP_LOCATION, payload: data.data.location });
    dispatch({ type: SET_EMP_USER, payload: data.data.empUser });
    dispatch({ type: SET_CURRENT_EMPLOYEE, payload: emp });
    if (callbackFun) callbackFun(emp);
  };
};

export const setCurrentEmployee = employee => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_EMPLOYEE,
      payload: employee
    });
  };
};
