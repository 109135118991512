import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { numberToTime } from "services/default.service";
import { useSelector } from "react-redux";
import useStyles from "./index.style";
import InfoUC from "routes/shared/uc/info-uc/info-uc-dialog";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { format } from "date-fns";

const ShiftHistory = ({ handleDialog, openInfo, id }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { allShiftDetail } = useSelector(({ shifts }) => shifts);
  const [datas, setDatas] = useState();

  let tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  useEffect(() => {
    if (allShiftDetail) {
      setDatas(
        allShiftDetail.map(item => ({
          id: item.id,
          startDate: !item.startDate
            ? ""
            : format(new Date(item.startDate), "dd/MM/yyyy"),
          time1:
            numberToTime(item.in1, ":") + " - " + numberToTime(item.out1, ":"),
          breakTime:
            item.breakStart && item.breakEnd
              ? numberToTime(item.breakStart, ":") +
                " - " +
                numberToTime(item.breakEnd, ":")
              : "-",
          time2:
            item.in2 && item.out2
              ? numberToTime(item.in2, ":") +
                " - " +
                numberToTime(item.out2, ":")
              : "-",
          status: item.id === id ? "Active" : ""
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "startDate",
      headerName: t("shift.lblStartDate"),
      width: 150,
      flex: 1
    },
    {
      field: "time1",
      headerName: t("shift.lblTime1"),
      width: 150
    },
    {
      field: "breakTime",
      headerName: t("shift.lblBreakTime"),
      width: 150
    },
    {
      field: "time2",
      headerName: t("shift.lblTime2"),
      width: 150
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: params => {
        return (
          <>
            <div style={{ color: "green" }}>{params.value}</div>
          </>
        );
      }
    }
  ];

  const form = () => {
    return (
      <>
        <Box
          sx={{
            width: { xs: "100%" },
            height: 260
          }}
        >
          <DataGrid
            className={classes.gridHeader}
            rows={datas}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableColumnMenu={true}
            disableSelectionOnClick
            initialState={
              datas
                ? {
                    ...datas.initialState,
                    columns: {
                      columnVisibilityModel: {
                        id: false
                      }
                    }
                  }
                : {}
            }
          />
        </Box>
      </>
    );
  };
  return (
    <>
      <InfoUC
        customTitle="History"
        maxWidth="md"
        open={openInfo}
        handleDialog={handleDialog}
        children={form()}
        isShowEdit={false}
        isShowDelete={false}
        isShowSave={false}
        isCheckBeforeClose={false}
      />
    </>
  );
};

export default ShiftHistory;
