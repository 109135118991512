import { Box } from "@mui/material";
import React from "react";
import AppCheckbox from "@jumbo/components/Common/formElements/AppCheckBox";
import LabelUC from "routes/shared/uc/label-uc";
import TextInputUC from "routes/shared/uc/text-input-uc";
import GridContainer from "@jumbo/components/GridContainer";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import AppSwitch from "@jumbo/components/Common/formElements/AppSwitch";

const OutsideTimeStamp = ({ props }) => {
  const {
    infoViewOnlyMode,
    data,
    dataDt,
    setDataDt,
    handleChangeCurrentAddrRad,
    handleChangeCheckbox
  } = props;
  const { t } = useTranslation();

  const handleCheckSetPlace = e => {
    setDataDt(
      dataDt.map(data => {
        return {
          ...data,
          isDeleted: true
        };
      })
    );
    handleChangeCheckbox(e);
  };
  return (
    <>
      <Box pl={{ xs: 2, sm: 6 }} mt={3}>
        <AppSwitch
          name="isAllowOutside"
          label={t("setTimeStamp.lblIsAllowOutside")}
          labelPlacement="end"
          onChange={handleChangeCheckbox}
          checked={data.isAllowOutside}
          disabled={infoViewOnlyMode || !data.isAllowTimeStamp}
        />
      </Box>
      {/* Any Where */}
      <Box pl={{ xs: 4, sm: 12 }}>
        <AppCheckbox
          label={t("setTimeStamp.lblIsAnywhere")}
          name="isAnywhere"
          onChange={handleChangeCheckbox}
          disabled={
            infoViewOnlyMode || !data.isAllowOutside || !data.isAllowTimeStamp
          }
          checked={data.isAnywhere}
        />
      </Box>
      {/* isAllowCurrentAddress */}
      <Box pl={{ xs: 4, sm: 12 }}>
        <AppCheckbox
          label={t("setTimeStamp.lblIsAllowCurrentAddress")}
          name="isAllowCurrentAddress"
          onChange={handleChangeCheckbox}
          disabled={
            infoViewOnlyMode || !data.isAllowOutside || !data.isAllowTimeStamp
          }
          checked={data.isAllowCurrentAddress}
        />
      </Box>

      <Box pl={{ xs: 4, sm: 16 }} xs={12} sm={6} flex>
        <GridContainer>
          <Grid item xs={6} sm={3} alignItems="center" container>
            <LabelUC label={t("setTimeStamp.lblRadius")} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextInputUC
              disabled={
                infoViewOnlyMode ||
                !data.isAllowCurrentAddress ||
                !data.isAllowTimeStamp
              }
              placeholder={t("setTimeStamp.lblRadius")}
              value={data.currentAddressRadius}
              name="currentAddressRadius"
              type="number"
              onChange={(e) => handleChangeCurrentAddrRad(e.target.value.substring(0, 3))}
            />
          </Grid>
        </GridContainer>
      </Box>
      {false && (
        <>
          {/* isSetPlace (For All Employee) */}
          <Box pl={{ xs: 4, sm: 12 }}>
            <AppCheckbox
              label={t("setTimeStamp.lblIsSetPlace")}
              name="isSetPlace"
              onChange={handleCheckSetPlace}
              disabled={infoViewOnlyMode || !data.isAllowOutside}
              checked={data.isSetPlace}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default OutsideTimeStamp;
