import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import InfoUC from "routes/shared/uc/info-uc/info-uc-dialog";
import detectStateChange from "services/detectStateChange";
import { onCreate, onUpdate } from "redux/actions/Default";
import { timeToNumber } from "services/default.service";
import Shift from "./tab-general/info";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import { format } from "date-fns";

const ShiftInfo = ({ handleDialog, openInfo }) => {
  const { currentShift } = useSelector(({ shifts }) => shifts);
  const { infoViewOnlyMode } = useSelector(({ common }) => common);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const _shift = currentShift || {};
  const [shift, setShift] = useState({
    id: _shift.id || null,
    shiftCode: _shift.shiftCode || "",
    shiftCodeError: "",
    shiftName: _shift.shiftName || "",
    shiftNameError: "",
    companyId: _shift.companyId || "",
    createdDate: _shift.createdDate || new Date(),
    createdBy: _shift.createdBy || "",
    modifiedDate: _shift.modifiedDate || new Date(),
    modifiedBy: _shift.modifiedBy || ""
  });

  const _shiftDetail = _shift.shiftDetail || {};
  const [shiftDetail, setShiftDetail] = useState({
    id: _shiftDetail.id || null,
    shiftId: _shiftDetail.shiftId || "",
    timesStamp: _shiftDetail.timesStamp || 2,
    in1: _shiftDetail.in1 || "",
    in1Error: "",
    out1: _shiftDetail.out1 || "",
    out1Error: "",
    breakStart: _shiftDetail.breakStart || "",
    breakStartError: "",
    breakEnd: _shiftDetail.breakEnd || "",
    breakEndError: "",
    in2: _shiftDetail.in2 || "",
    out2: _shiftDetail.out2 || "",
    breakHour: _shiftDetail.breakHour || "",
    workHour: _shiftDetail.workHour || "",
    companyId: _shift.companyId || "",
    createdDate: _shift.createdDate || new Date(),
    createdBy: _shift.createdBy || "",
    modifiedDate: _shift.modifiedDate || new Date(),
    modifiedBy: _shift.modifiedBy || "",
    isValid: true
  });

  const shiftCompare = Object.assign({}, shiftDetail);

  detectStateChange([shift, shiftDetail]);

  const handleChange = e => {
    setShift({ ...shift, [e.target.name]: e.target.value });
  };

  const handleTimeChangeDetail = e => {
    let _shiftDetail = {
      ...shiftDetail
    };
    _shiftDetail[e.target.name] =
      e.target.name === "timesStamp"
        ? e.target.value
        : timeToNumber(e.target.value, ":");
    _shiftDetail.in2 =
      e.target.name === "timesStamp"
        ? ""
        : e.target.name === "in2"
          ? timeToNumber(e.target.value, ":")
          : _shiftDetail.in2;
    _shiftDetail.out2 =
      e.target.name === "timesStamp"
        ? ""
        : e.target.name === "out2"
          ? timeToNumber(e.target.value, ":")
          : _shiftDetail.out2;

    // calculate Time
    _shiftDetail = calculateTime(_shiftDetail);
    // validate
    validateTime(_shiftDetail);
    setShiftDetail(_shiftDetail);
  };

  const calculateTime = _shiftDetail => {
    // console.log("calculateTime", _shiftDetail);
    const {
      in1,
      out1,
      breakStart,
      breakEnd,
      in2,
      out2,
      timesStamp
    } = _shiftDetail;
    const breakHour = (breakEnd ?? 0) - (breakStart ?? 0);
    const workHour = (out1 ?? 0) - (in1 ?? 0);
    const workHour2 = (out2 ?? 0) - (in2 ?? 0);
    const sumWorkHour =
      (timesStamp === 4 ? workHour + workHour2 : workHour - (breakHour < 0 ? 0 : breakHour))
    // - (breakHour < 0 ? 0 : breakHour);
    _shiftDetail.breakHour = breakHour < 0 ? 0 : breakHour;
    _shiftDetail.workHour = sumWorkHour < 0 ? 0 : sumWorkHour;

    return _shiftDetail;
  };

  const validateTime = _shiftDetail => {
    _shiftDetail.breakStartError = "";
    _shiftDetail.breakEndError = "";
    _shiftDetail.out1Error = "";
    _shiftDetail.in2Error = "";
    _shiftDetail.out2Error = "";

    // break start
    if (_shiftDetail.breakStart && _shiftDetail.breakStart < _shiftDetail.in1) {
      _shiftDetail.breakStartError = validateMsg(
        t("shift.lblBreakStart", t("shift.StartTime") + " 1")
      );
    }
    // break end
    if (_shiftDetail.breakEnd && _shiftDetail.breakEnd < _shiftDetail.in1) {
      _shiftDetail.breakEndError = validateMsg(
        t("shift.lblEndBreakTime", t("shift.lblEndTime") + " 1")
      );
    } else if (
      _shiftDetail.breakEnd &&
      _shiftDetail.breakEnd < _shiftDetail.breakStart
    ) {
      _shiftDetail.breakEndError = validateMsg(
        "lblEndBreakTime",
        "lblBreakStart"
      );
    }
    // out1
    if (_shiftDetail.out1 && _shiftDetail.out1 < _shiftDetail.in1) {
      _shiftDetail.out1Error = validateMsg(
        t("shift.lblEndTime") + " 1",
        t("shift.lblStartTime") + " 1"
      );
    } else if (
      _shiftDetail.out1 &&
      _shiftDetail.out1 < _shiftDetail.breakStart &&
      _shiftDetail.timesStamp === 2
    ) {
      _shiftDetail.out1Error = validateMsg(
        t("shift.lblEndTime") + " 1",
        t("shift.lblStartBreakTime")
      );
    } else if (
      _shiftDetail.out1 &&
      _shiftDetail.out1 < _shiftDetail.breakEnd &&
      _shiftDetail.timesStamp === 2
    ) {
      _shiftDetail.out1Error = validateMsg(
        t("shift.lblEndTime") + " 1",
        t("shift.lblEndBreakTime")
      );
    }
    if (_shiftDetail.timesStamp === 4) {
      // in 2
      if (_shiftDetail.in2 && _shiftDetail.in2 < _shiftDetail.in1) {
        _shiftDetail.in2Error = validateMsg(
          t("shift.lblStartTime") + " 2",
          t("shift.lblStartTime") + " 1"
        );
      } else if (
        _shiftDetail.in2 &&
        _shiftDetail.in2 < _shiftDetail.breakStart
      ) {
        _shiftDetail.in2Error = validateMsg(
          t("shift.lblStartTime") + " 2",
          t("shift.lblStartBreakTime")
        );
      } else if (_shiftDetail.in2 && _shiftDetail.in2 < _shiftDetail.breakEnd) {
        _shiftDetail.in2Error = validateMsg(
          t("shift.lblStartTime") + " 2",
          t("shift.lblEndBreakTime")
        );
      } else if (_shiftDetail.in2 && _shiftDetail.in2 < _shiftDetail.out1) {
        _shiftDetail.in2Error = validateMsg(
          t("shift.lblStartTime") + " 2",
          t("shift.lblEndTime") + " 1"
        );
      }
      // out 2
      if (_shiftDetail.out2 && _shiftDetail.out2 < _shiftDetail.in1) {
        _shiftDetail.out2Error = validateMsg(
          t("shift.lblEndTime") + " 2",
          t("shift.lblStartTime") + " 1"
        );
      } else if (
        _shiftDetail.out2 &&
        _shiftDetail.out2 < _shiftDetail.breakStart
      ) {
        _shiftDetail.out2Error = validateMsg(
          t("shift.lblEndTime") + " 2",
          t("shift.lblStartBreakTime")
        );
      } else if (
        _shiftDetail.out2 &&
        _shiftDetail.out2 < _shiftDetail.breakEnd
      ) {
        _shiftDetail.out2Error = validateMsg(
          t("shift.lblEndTime") + " 2",
          t("shift.lblEndBreakTime")
        );
      } else if (_shiftDetail.out2 && _shiftDetail.out2 < _shiftDetail.out1) {
        _shiftDetail.out2Error = validateMsg(
          t("shift.lblEndTime") + " 2",
          t("shift.lblEndTime") + " 1"
        );
      } else if (_shiftDetail.out2 && _shiftDetail.out2 < _shiftDetail.in2) {
        _shiftDetail.out2Error = validateMsg(
          t("shift.lblEndTime") + " 2",
          t("shift.lblStartTime") + " 2"
        );
      }
    }
    if (
      _shiftDetail.breakStartError ||
      _shiftDetail.breakEndError ||
      _shiftDetail.out1Error ||
      _shiftDetail.in2Error ||
      _shiftDetail.out2Error
    ) {
      _shiftDetail.isValid = false;
      return false;
    }
    _shiftDetail.isValid = true;
    return true;
  };

  const validateMsg = (lbl, lbl2) => {
    return lbl + t("common.musbeGreathanOrEqual") + lbl2;
  };

  const propsGeneral = {
    infoViewOnlyMode,
    shift,
    handleChange,
    shiftDetail,
    setShiftDetail,
    handleTimeChangeDetail
  };

  const handleInfoDialog = action => {
    switch (action) {
      case "save":
        handleSubmit();
        break;
      case "delete":
        if (currentShift && currentShift.id) {
          handleDialog({ action: "delete", value: [currentShift.id] });
        }
        break;
      default:
        handleDialog();
        break;
    }
  };

  const handleSubmit = () => {
    console.log("handleSubmit");
    setShift({
      ...shift,
      shiftCodeError: !shift.shiftCode ? t("common.isRequired") : "",
      shiftNameError: !shift.shiftName ? t("common.isRequired") : ""
    });

    setShiftDetail({
      ...shiftDetail,
      in1Error: !shiftDetail.in1 ? t("common.isRequired") : "",
      out1Error: !shiftDetail.out1 ? t("common.isRequired") : ""
    });

    if (
      shift.shiftCodeError ||
      shift.shiftNameError ||
      shiftDetail.in1Error ||
      shiftDetail.out1Error ||
      !shiftDetail.isValid
    ) {
      return false;
    }

    if (!compareShiftDetail()) {
      setOpenConfirmDialog(true);
    } else {
      onSave();
    }
  };

  const onSave = () => {
    const shiftSave = {
      shiftCode: shift.shiftCode,
      shiftName: shift.shiftName,
      companyId: shift.companyId,
      createdDate: shift.createdDate,
      createdBy: shift.createdBy,
      modifiedDate: shift.modifiedDate,
      modifiedBy: shift.modifiedBy,
      isDeleted: false,
      shiftDetail: [shiftDetail]
    };

    if (currentShift && currentShift.id) {
      dispatch(onUpdate({ ...currentShift, ...shiftSave }));
      console.log("Update success");
    } else {
      dispatch(onCreate(shiftSave));
      console.log("Insert Success");
    }
    handleDialog({ action: "save", value: "" });
  };

  const compareShiftDetail = () => {
    if (
      currentShift &&
      (shiftCompare.timesStamp !== _shiftDetail.timesStamp ||
        (!shiftCompare.in1 ? 0 : shiftCompare.in1) !== _shiftDetail.in1 ||
        (!shiftCompare.out1 ? 0 : shiftCompare.out1) !== _shiftDetail.out1 ||
        (!shiftCompare.breakStart ? 0 : shiftCompare.breakStart) !==
        _shiftDetail.breakStart ||
        (!shiftCompare.breakEnd ? 0 : shiftCompare.breakEnd) !==
        _shiftDetail.breakEnd ||
        (!shiftCompare.in2 ? 0 : shiftCompare.in2) !== _shiftDetail.in2 ||
        (!shiftCompare.out2 ? 0 : shiftCompare.out2) !== _shiftDetail.out2)
    ) {
      return false;
    }
    return true;
  };

  //create form
  const shiftInfoForm = () => {
    return <Shift props={propsGeneral} />;
  };

  const handleConfirmDialog = () => {
    setOpenConfirmDialog(false);
    onSave();
  };

  const handleCancelDialog = () => {
    setOpenConfirmDialog(false);
  };

  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  return (
    <>
      <InfoUC
        maxWidth="lg"
        open={openInfo}
        handleDialog={handleInfoDialog}
        children={shiftInfoForm()}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm Save`}
        content={
          t("shift.vldChangeShift") +
          " " +
          format(tomorrow, "dd/MM/yyyy") +
          " " +
          t("common.lblDoYouWantToContinue")
        }
        onClose={handleCancelDialog}
        onConfirm={handleConfirmDialog}
        btnLabels={{
          cancel: t("common.lblCancel"),
          confirm: t("common.lblAccept")
        }}
      />
    </>
  );
};

export default ShiftInfo;

ShiftInfo.propType = {
  handleDialog: PropTypes.func
};
