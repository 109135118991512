import { Avatar, Box, TextareaAutosize, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import TextInputUC from "routes/shared/uc/text-input-uc";
import useStyles from "../my-point/index.style";
import { onCreate } from "redux/actions/Default";
import { fetchSuccess } from "redux/actions";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdjustEmpPointDialog = ({
  handleClose,
  openInfo,
  currentEmp,
  authen
}) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [adjustPoint, setAdjustPoint] = useState(0);
  const [remark, setRemark] = useState("");
  const [pointError, setPointError] = useState("");

  const handleChangePoint = value => {
    setAdjustPoint(value);
  };

  const handleConfirm = () => {
    if (adjustPoint === 0) {
      setPointError(t("point.notAdjustZero"));
    } else {
      const empPointSave = {
        empId: currentEmp.empId,
        date: new Date(),
        type: "adjust",
        detail: "By " + authen.data.firstName + " " + authen.data.lastName,
        point: adjustPoint,
        totalPoint: 0,
        remark: remark,
        fromEmpId: null,
        toEmpId: null
      };

      dispatch(
        onCreate(
          empPointSave,
          () => {
            handleClose();
            dispatch(fetchSuccess(t("point.adjustSuccess")));
          },
          false
        )
      );
    }
  };

  return (
    <>
      <Dialog
        open={openInfo}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby={t("point.adjustPoint")}
        className={classes.dialog}
      >
        <DialogTitle>{t("point.adjustPoint")}</DialogTitle>
        <DialogContent>
          <Box p={2} xs={6} sm={12} textAlign={"center"}>
            <Avatar
              alt="Point"
              src="/images/time/star.png"
              sx={{ width: 80, height: 80, margin: "auto" }}
            />
          </Box>

          <Box sx={{ textAlign: "center" }} mt={2}>
            <Typography variant="h5" gutterBottom className={classes.textPoint}>
              {currentEmp.point} Points
            </Typography>
            {i18n.language === "th" && (
              <Typography gutterBottom>
                แก้ไขคะแนนให้กับ {currentEmp.empName}
              </Typography>
            )}
            {i18n.language === "en" && (
              <Typography gutterBottom>
                Adjust points to {currentEmp.empNameEn}
              </Typography>
            )}
          </Box>
          {/*Point*/}
          <Box className={classes.boxDialog}>
            <TextInputUC
              type="number"
              value={adjustPoint}
              onChange={e => handleChangePoint(e.target.value.substring(0, 5))}
              className={classes.textPoint}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Avatar
                      src={"/images/time/star.png"}
                      sx={{ width: 24, height: 24 }}
                    />
                  </InputAdornment>
                )
              }}
              helperText={pointError}
              error={!!pointError}
            />
          </Box>
          {/*Remark*/}
          <Box className={classes.boxDialog}>
            <TextareaAutosize
              maxLength={200}
              aria-label="minimum height"
              minRows={3}
              value={remark}
              placeholder={t("point.lblRemark")}
              style={{ width: "100%" }}
              onChange={e => setRemark(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirm()}>Confirm</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdjustEmpPointDialog;
