import React, { useState } from "react";
// import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
// import useStyles from "../index.style";
import GridContainer from "@jumbo/components/GridContainer";
import LabelUC from "routes/shared/uc/label-uc";
import { useTranslation } from "react-i18next";
import TextInputUC from "routes/shared/uc/text-input-uc";
import DateTimePickerUC from "routes/shared/uc/datetime-picker-uc";
import InfoUC from "routes/shared/uc/info-uc/info-uc-dialog";
import { format } from "date-fns";

const HolidayDialog = ({
  handleDialog,
  openInfo,
  rowEdit,
  disableDateList
}) => {
  const { t } = useTranslation();
  const [row, setRow] = useState(rowEdit);
  const [dateError, setDateError] = useState("");
  const [nameError, setNameError] = useState("");
  // console.log(rowEdit)
  const handleChange = e => {
    setRow({ ...row, [e.target.name]: e.target.value });
  };

  // ? Date Change
  const handleDateChange = name => value => {
    setRow({ ...row, [name]: value });
  };

  const handleInfoDialog = action => {
    switch (action) {
      case "save":
        if (!row.date || !row.name) {
          setDateError(!row.date ? t("common.isRequired") : "");
          setNameError(!row.name ? t("common.isRequired") : "");
        } else {
          handleDialog(action, row);
        }
        break;
      default:
        handleDialog(action, rowEdit);
        break;
    }
  };

  const shouldDisableDate = date => {
    if (disableDateList && disableDateList.length > 0) {
      return disableDateList.includes(format(new Date(date), "yyyy-MM-dd"));
    } else {
      return false;
    }
  };

  const HolidayDialog = () => {
    return (
      <>
        <Box
          sx={{ display: "flex", width: "100%" }}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box sx={{ width: { xs: "100%" } }} m="auto">
            {/* Date */}
            <GridContainer>
              <Grid item xs={12} sm={5} alignItems="center" container>
                <LabelUC label={t("company.lblHolidayDate")} isRequired />
              </Grid>
              <Grid item xs={12} sm={7}>
                <DateTimePickerUC
                  value={row.date}
                  name="date"
                  handleDateChange={handleDateChange}
                  infoViewOnlyMode={false}
                  helperText={dateError}
                  shouldDisableDate={shouldDisableDate}
                />
              </Grid>
            </GridContainer>
            {/* Name */}
            <GridContainer>
              <Grid item xs={12} sm={5} alignItems="center" container>
                <LabelUC label={t("company.lblHolidayName")} isRequired />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextInputUC
                  placeholder="Name"
                  value={row.name}
                  name="name"
                  onChange={e => handleChange(e)}
                  helperText={nameError}
                />
              </Grid>
            </GridContainer>
            {/* Name En */}
            <GridContainer>
              <Grid item xs={12} sm={5} alignItems="center" container>
                <LabelUC label={t("company.lblHolidayNameEn")} />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextInputUC
                  placeholder="Name (En)"
                  value={row.nameEn}
                  name="nameEn"
                  onChange={e => handleChange(e)}
                />
              </Grid>
            </GridContainer>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <InfoUC
        open={openInfo}
        // title={'sidebar.menu.company'}
        handleDialog={handleInfoDialog}
        children={HolidayDialog()}
        isShowEdit={false}
        isShowDelete={false}
        isCheckBeforeClose={false}
      />
    </>
  );
};

export default HolidayDialog;

HolidayDialog.propType = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func,
  selectedholiday: PropTypes.object
};
