import {
    SET_MENU,
    GET_MENU,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    currentMenu: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_MENU: {
            return {
                ...state,
                currentMenu: action.payload
            }
        }
        case GET_MENU: {
            return {
                ...state,
                currentMenu: action.payload
            }
        }
        default:
            return state;
    }
};
