import React from "react";

const textTh = () => {
    return (
        <p>
            <strong>ข้อตกลงและเงื่อนไขทั่วไปในการใช้บริการผลิตภัณฑ์และบริการ</strong>
            <br></br>
            กรุณาศึกษาเงื่อนไขการใช้บริการ อย่างรอบคอบก่อนใช้งานเว็บไซต์และบริการของเรา ไม่ว่าส่วนหนึ่งส่วนใด
            และ/หรือการสมัครสมาชิกเท่ากับผู้ใช้บริการได้ยอมรับเงื่อนไขการใช้บริการนี้แล้ว และบริษัทขอสงวนสิทธิ์
            ในการแก้ไขเปลี่ยนแปลงเงื่อนไขและข้อตกลงการให้บริการนี้โดยมิต้องแจ้งให้ผู้ใช้บริการทราบ
            <br></br >
            <br></br>
            <strong>1. การใช้บริการ</strong>
            <br></br>
            1.1. เว็บไซต์ของบริษัท อันดีไฟนด์ จำกัด (Undefined Co., Ltd.) ในชื่อ "www.hr-th.com"
            หรือเว็บไซต์ที่ดำเนินการโดยบริษัท ซึ่งต่อไปจะเรียกว่า "ผู้ให้บริการ"
            <br></br>
            1.2. สิ่งที่ท่านใช้งาน ไม่ว่าจะเป็น ซอฟต์แวร์ บริการ หรือเว็บไซต์ ของผู้ให้บริการ ซึ่งต่อไปจะเรียกว่า "บริการ"
            <br></br>
            1.3. ท่านซึ่งเป็นผู้ใช้บริการไม่ว่าจะส่วนใดส่วนหนึ่งหรือทั้งหมดของผู้ให้บริการ ซึ่งต่อไปจะเรียกว่า "ผู้ใช้บริการ"
            <br></br>
            1.4. การใช้บริการของผู้ให้บริการนั้น ผู้ใช้บริการจำเป็นต้องเข้าใจและยอมรับข้อตกลงและเงื่อนไขต่าง ๆ
            ของผู้ให้บริการทั้งหมด และจะถือว่าผู้ใช้บริการเข้าใจและยอมรับข้อตกลงและเงื่อนไขต่าง ๆ หากดำเนินการดังต่อไปนี้
            <br></br>
            &emsp;1.4.1. ผู้ใช้บริการคลิกปุ่มตกลงและยอมรับเงื่อนไข ซึ่งทางผู้ให้บริการจะมีทางเลือกที่จะยอมรับหรือไม่ก่อน
            หรือ แสดงแจ้งเตือนการยอมรับของผู้ใช้บริการก่อนการคลิกปุ่มตกลง
            <br></br>
            &emsp;1.4.2. ผู้ใช้บริการเข้าใช้บริการของผู้ให้บริการซึ่งในกรณีนี้ผู้ใช้บริการต้องยอมรับว่าทางผู้ให้บริการ
            จะถือว่าผู้ใช้บริการเข้าใจและยอมรับข้อตกลงและเงื่อนไขต่าง ๆ ตั้งแต่เริ่มใช้บริการเป็นต้นไป
            <br></br>
            1.5. ผู้ใช้บริการต้องเก็บรักษาข้อมูลและรหัสผ่านที่ใช้บริการกับทางผู้ให้บริการไว้เป็นอย่างดี
            ทางผู้ให้บริการจัดเก็บข้อมูลไว้ในที่ปลอดภัยและผู้ให้บริการจะไม่รับผิดชอบใด ๆ
            หากเกิดความเสียหายแก่เว็บไซต์ หรือข้อมูล ที่เกิดขึ้นจากความประมาทของผู้ใช้บริการ
            <br></br>
            1.6. ในกรณีที่ผู้ใช้บริการลืมรหัสผ่านใด ๆ ผู้ใช้บริการสามารถขอรหัสผ่านใหม่ได้ทางวิธีที่ทางผู้ให้บริการกำหนด
            โดยผู้ให้บริการจะให้ผู้ใช้บริการทำการตั้งรหัสผ่านใหม่
            <br></br>
            1.7. ผู้ใช้บริการจะไม่ใช้เครื่องมืออัตโนมัติ หรือโปรแกรม อัลกอริธึม หรือวิธีการที่มีกระบวนการหรือการใช้งานที่คล้ายคลึงกัน
            หรือกระบวนการด้วยมือใด ๆ ในการตรวจดู หรือทำสำเนาหน้าเว็บ ข้อมูล หรือเนื้อหาใดๆ ที่ปรากฏบนเว็บไซต์นี้ ไม่ว่าในกรณีใด ๆ
            โดยมิได้รับอนุญาตล่วงหน้าเป็นลายลักษณ์อักษรจากเรา
            <br></br>
            1.8. ผู้ใช้บริการยอมรับว่าจะไม่กระทำการใด ๆ อันเป็นการล่วงละเมิดสิทธิส่วนบุคคล สิทธิในทรัพย์สินทางปัญญาของผู้ให้บริการ
            และบุคคลอื่น และผู้ใช้บริการจะต้องไม่กระทำการใด ๆ อันขัดต่อกฎหมาย ต่อศีลธรรมอันดีงาม และก่อให้เกิดความเสียหาย
            เสียชื่อเสียงต่อผู้ให้บริการ และบุคคลอื่น รวมถึงก่อให้เกิดความขัดแย้งในสังคม ไม่ว่าจะเป็นในส่วนของเนื้อหา ข้อความ
            ภาพนิ่ง ภาพเคลื่อนไหว และเสียง
            <br></br>
            1.9. ผู้ให้บริการสามารถเปลี่ยนแปลงนโยบายข้อตกลงและเงื่อนไขต่าง ๆ ในการให้บริการได้โดยไม่จำเป็นต้องแจ้งให้ทราบล่วงหน้า
            หากผู้ให้บริการตรวจพบว่า ผู้ใช้บริการทำผิดข้อตกลงดังกล่าว ทางผู้ให้บริการขอสงวนสิทธิ์ดำเนินการงดการให้บริการทันที
            โดยไม่มีการคืนค่าบริการใด ๆ ทั้งสิ้น และไม่จำเป็นต้องแจ้งให้ทราบล่วงหน้า
            <br></br>
            <br></br>
            <strong>2. ข้อตกลงในการสำรองข้อมูล</strong>
            <br></br>
            2.1. ผู้ให้บริการทำการสำรองข้อมูลของผู้ใช้บริการทุกวัน โดยรายละเอียดการสำรองข้อมูลมีดังนี้
            <br></br>
            &emsp;2.1.1. สำรองข้อมูลอัตโนมัติในเครื่อง Server ของผู้ให้บริการ วันละ 2 ครั้ง
            <br></br>
            &emsp;2.1.2. สำรองข้อมูลไปไว้เครื่องอื่น วันละ 1 ครั้ง
            <br></br>
            2.2. ผู้ให้บริการจะไม่รับผิดชอบในกรณีที่ข้อมูลภายในเว็บไซต์นั้นสูญหายที่เกิดขึ้นจากความประมาทของผู้ใช้บริการ
            หรือเนื่องจากความผิดพลาดของอุปกรณ์ อันเป็นเหตุสุดวิสัย ถึงแม้ผู้ให้บริการ จะมีบริการสำรองข้อมูลของผู้ใช้บริการ
            แต่ก็ไม่สามารถรับประกันได้ว่า ข้อมูลนั้นจะมีความถูกต้องครบถ้วน ดังนั้นผู้ใช้บริการจึงควรมีข้อมูลสำรองไว้อย่างน้อยจำนวน 1 ชุดเสมอ
            อย่างไรก็ตามผู้ให้บริการยินดีให้ความช่วยเหลือถึงที่สุดในการนำข้อมูลกลับคืนมา
            <br></br>
            <br></br>
            <strong>3. ข้อตกลงในการขอคืนค่าบริการ</strong>
            <br></br>
            ผู้ให้บริการขอสงวนสิทธิ์ในการคืนค่าบริการในกรณีที่ผู้ใช้ต้องการยกเลิกใช้บริการก่อนครบกำหนดสัญญาและผู้ใช้บริการไม่มีสิทธิ์เรียกค่าบริการคืน
            ไม่ว่าบางส่วน หรือทั้งหมดได้ เว้นแต่ทางผู้ให้บริการจะเป็นผู้ยกเลิกบริการเอง โดยจะคืนค่าบริการตามสมควรให้เท่านั้น หากมีการละเมิดข้อตกลง
            ดังกล่าวผู้ให้บริการจะแจ้งเตือน และจะหยุดให้บริการหากผู้ใช้บริการไม่ทำการแก้ไขให้ถูกต้อง ผู้ใช้บริการจะไม่สามารถเรียกร้องสิทธิ์ในการคืนเงินได้
            และหากเป็นการกระทำที่ส่งผลกระทบต่อ ระบบเครือข่าย และชื่อเสียงของผู้ให้บริการ ผู้ใช้บริการจะต้องรับผิดชอบความเสียหายที่เกิดขึ้นทั้งหมด
            <br></br>
            <br></br>
            <strong>4. การยกเลิกการใช้บริการ</strong>
            <br></br>
            4.1. ผู้ใช้สามารถแจ้งเรื่องขอยกเลิกการใช้งานได้ทุกช่วงเวลาในการใช้งาน ทางบริษัทขอสงวนสิทธิ์ไม่คืนค่าบริการในช่วงระยะเวลาที่เหลือ
            และไม่บริการนำออกข้อมูลในระบบให้ ผู้ใช้ควรดำเนินการให้เสร็จสิ้นก่อนวันยกเลิกบริการ
            <br></br>
            4.2. หลังจากเจ้าหน้าที่ยกเลิกการใช้งานแล้ว ผู้ใช้จะไม่สามารถเข้าใช้งานได้อีก แต่ข้อมูลจะถูกเก็บไว้ในระบบ 30 วัน
            หลังจากนั้นข้อมูลทั้งหมดจะถูกลบโดยอัตโนมัติ
            <br></br>
            <br></br>
            <strong>5. การปฏิเสธความรับผิดชอบและข้อจำกัดความรับผิด</strong>
            <br></br>
            5.1. ในกรณีที่เกิดความเสียหายแก่ผู้ใช้บริการ อันเนื่องมาจากการใช้บริการเว็บไซต์หรือจากการไม่ปฏิบัติตามระเบียบข้อบังคับในการใช้บริการเว็บไซต์
            บุคคลผู้ก่อให้เกิดความเสียหายจะต้องรับผิดชอบและรับผลเสียหายที่เกิดขึ้นนั้นแต่เพียงผู้เดียว ผู้ใช้บริการตกลงสละสิทธิเรียกร้องต่อบริษัทในความ
            สูญหายและเสียหายใด ๆ ที่เกิดขึ้นจากการใช้บริการนี้
            <br></br>
            <br></br>
            <strong>6. ข้อตกลงในการเปลี่ยนแปลงข้อตกลงและเงื่อนไขต่างๆ</strong>
            <br></br>
            6.1. บริษัทสามารถเปลี่ยนแปลง นโยบายข้อตกลงและเงื่อนไขต่างๆ ในการให้บริการได้โดยไม่จำเป็นต้องแจ้งให้ทราบล่วงหน้า
            <br></br>
            6.2. ผู้ใช้บริการต้องเข้าใจและยอมรับข้อตกลงและเงื่อนไขต่างๆ ของบริษัทที่ได้มีการเปลี่ยนแปลงแล้ว หากผู้ใช้บริการเข้าใช้บริการ
            หลังมีการเปลี่ยนแปลงผู้ใช้ตกลงและยอมรับเงื่อนไขใหม่ต่างๆที่ทางบริษัทได้ประกาศไว้ทุกกรณี
            <br></br>
            <br></br>
            <strong>7. กฎหมายที่ใช้บังคับ</strong>
            <br></br>
            7.1. บริษัทมีสิทธิในการแก้ไขเปลี่ยนแปลง หรือยกเลิกข้อตกลงฉบับนี้ และ/หรือเงื่อนไขและข้อตกลงอื่นใด
            บนเว็บไซต์โดยไม่จำต้องแจ้งให้ผู้ใช้งานทราบล่วงหน้า
            <br></br>
            7.2. ผู้ใช้บริการต้องไม่กระทำการใด ๆ อันเป็นการละเมิดหรือควรเชื่อได้ว่าเป็นการละเมิดสิทธิในลิขสิทธิ์ เครื่องหมายการค้า
            สิทธิบัตร ความลับทางการค้า รวมทั้งทรัพย์สินทางปัญญาอื่นตามกฎหมาย ไม่ว่าโดยวิธีการใด ทั้งนี้ ไม่ว่าจะกระทำโดยมีเจตนาหรือไม่ก็ตาม
            หากผู้ใช้บริการฝ่าฝืนข้อกำหนดดังกล่าวผู้ใช้บริการตกลงให้เว็บไซต์ยกเลิกสัญญา เป็นการถาวรและดำเนินคดีตามกฎหมาย กับผู้กระทำผิด
            <br></br>
            <br></br>
        </p>
    )
};

export default textTh;
