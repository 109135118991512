import React from "react";
// import Box from '@material-ui/core/Box';
// import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Backdrop } from "@mui/material";

// const useStyles = makeStyles(theme => ({
//   circularProgressRoot: {
//     position: "absolute",
//     left: 0,
//     top: 0,
//     zIndex: 999999,
//     width: "100%",
//     height: "100%",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center"
//   }
// }));

const PageLoader = ({ open }) => {
  // const classes = useStyles();
  // console.log('PageLoader');
  return (
    // <Box className={classes.circularProgressRoot}>
    //   <CircularProgress />
    // </Box>
    <Backdrop
      sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default PageLoader;
