import React, { useEffect } from 'react';
// import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '@jumbo/utils/IntlMessages';
// import Grid from '@material-ui/core/Grid';
// import SidebarButtons from '../../../@jumbo/components/AppLayout/partials/SideBar/SIdebarButtons';
// import Divider from '@material-ui/core/Divider';
import { useDispatch } from 'react-redux';
import { setCurrentMenu } from 'redux/actions/Menu';
import ListUC from 'routes/shared/uc/list-uc'

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'sidebar.approveCenter'} />, isActive: true },
];

const datas = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 90,
  },
  {
    field: 'fullName',
    flex: 1,
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
];


const Approve = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentMenu('Approve'));
  }, [dispatch]);

  return (
    <PageContainer heading={<IntlMessages id="sidebar.approveCenter" />} breadcrumbs={breadcrumbs}>
      <ListUC datas={datas} cols={columns} currentMenu={'Approve'} />
    </PageContainer>
  );
};

export default Approve;
