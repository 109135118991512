import React, { useState } from "react";
import { Box } from "@mui/material";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import { useDropzone } from "react-dropzone";
import CmtAvatar from "@coremat/CmtAvatar";
import { useMediaQuery } from "react-responsive";
import DialogUC from "routes/shared/uc/dialog-uc";
import PropTypes from "prop-types";
import TaxId from "./TaxId";
import Passport from "./passport";
import NameTh from "./nameTh";
import NameEn from "./nameEn";
import PersonnelInfo from "./personnelInfo";
import { resizeFile } from "services/default.service";

const PersonnelInformation = ({ props }) => {
  const { infoViewOnlyMode, emp, setEmp } = props;

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const { getRootProps, getInputProps } = useDropzone({
    noClick: infoViewOnlyMode,
    noKeyboard: infoViewOnlyMode,
    noDrag: infoViewOnlyMode,
    accept: "image/*",
    onDrop: async acceptedFiles => {
      const imgResize = await resizeFile(acceptedFiles[0]);
      setEmp({ ...emp, image: imgResize });
    }
  });

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{ display: "flex", width: "100%" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        {/* info left */}
        <Box sx={{ padding: 2, width: { xs: "100%", lg: "50%" } }}>
          {isMobile && (
            <GridContainer mb={2}>
              <Grid item xs={12} container>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Box
                    {...getRootProps()}
                    mb={{ xs: 3, md: 0 }}
                    className="pointer"
                  >
                    <input {...getInputProps()} />
                    <CmtAvatar size={90} src={emp.image} />
                  </Box>
                </Box>
              </Grid>
            </GridContainer>
          )}
          <NameTh props={props} />
          <NameEn props={props} />
        </Box>
        {/* info right */}
        <Box sx={{ padding: 2, width: { xs: "100%", lg: "50%" } }}>
          {!isMobile && (
            <GridContainer mb={2}>
              <Grid item xs={12} container>
                <Box
                  sx={{ width: "100%", display: "flex", justifyContent: "end" }}
                >
                  <Box
                    {...getRootProps()}
                    mb={{ xs: 3, md: 0 }}
                    className="pointer"
                  >
                    <input {...getInputProps()} />
                    <CmtAvatar size={90} src={emp.image} />
                  </Box>
                </Box>
              </Grid>
            </GridContainer>
          )}
          <PersonnelInfo props={props} />
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", width: "100%" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <TaxId props={props} />
        <Passport props={props} />
      </Box>
      {open && <DialogUC open={open} handleDialog={handleClose} />}
    </>
  );
};

export default PersonnelInformation;

PersonnelInformation.propTypes = {
  emp: PropTypes.string,
  setEmp: PropTypes.func,
  infoViewOnlyMode: PropTypes.bool
};
