import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import InfoUC from "routes/shared/uc/info-uc/";
import TabHeader from "./tab-header";
import TabGeneral from "./tab-general";
import TabEmployment from "./tab-employment";
// import TabPayment from "./tab-payment";
// import TabTax from "./tab-tax";
import detectStateChange from "services/detectStateChange";
import TabUser from "./tab-user";
import { onCreate, onUpdate } from "redux/actions/Default";
import { useTranslation } from "react-i18next";
import { getStorageAuthenUser } from "services/Storage.service";
import { setAuthUser } from "redux/actions/Auth";
import { fetchError } from "redux/actions";
import { validate as validateEmail } from "react-email-validator";

const EmpInfo = ({ handleDialog, isShowClose, roleUser }) => {
  const { currentEmployee, empEnum, empUser } = useSelector(
    ({ employees }) => employees
  );
  const { infoViewOnlyMode } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [viewOnly, setViewOnly] = useState(infoViewOnlyMode);

  const _titleId = empEnum.find(e => e.type === "title" && e.name === "mr").id;
  const _genderId = empEnum.find(e => e.type === "gender" && e.name === "male")
    .id;
  const _noneId = empEnum.find(e => e.type === "none").id;
  const _empTypeId = empEnum.find(
    e => e.type === "empType" && e.name === "monthly"
  ).id;
  const _workingStatusId = empEnum.find(
    e => e.type === "workingStatus" && e.name === "working"
  ).id;

  useEffect(() => {
    if (!(roleUser === "administrator" || roleUser === "hr")) {
      setViewOnly(true);
    } else {
      setViewOnly(infoViewOnlyMode);
    }
  }, [roleUser, infoViewOnlyMode, viewOnly, dispatch]);

  // console.log('infoViewOnlyMode', infoViewOnlyMode)
  // console.log('viewOnly', viewOnly)

  // Tab General
  const employee = currentEmployee ? currentEmployee : {};
  const [emp, setEmp] = useState({
    id: employee.id || null,
    empCode: employee.empCode || "",
    empCodeError: "",
    title: employee.title || _titleId,
    titleError: "",
    firstName: employee.firstName || "",
    firstNameError: "",
    lastName: employee.lastName || "",
    lastNameError: "",
    nickName: employee.nickName || "",
    titleEn: employee.titleEn || _titleId,
    firstNameEn: employee.firstNameEn || "",
    lastNameEn: employee.lastNameEn || "",
    nickNameEn: employee.nickNameEn || "",
    gender: employee.gender || _genderId,
    birthDate: employee.birthDate || new Date(),
    nationality: employee.nationality || _noneId,
    religion: employee.religion || _noneId,
    image: employee.image || null,
    // tax Id
    identificationNo: employee.identificationNo || "",
    identificationNoError: "",
    identityExpire: employee.identityExpire || null,
    passportNo: employee.passportNo || "",
    passportExpire: employee.passportExpire || null,
    //contact
    phone: employee.phone || "",
    email: employee.email || "",
    lineId: employee.lineId || "",
    emergencyName: employee.emergencyName || "",
    emergencyRelate: employee.emergencyRelate || "",
    emergencyPhone: employee.emergencyPhone || "",

    companyId: employee.companyId || "",
    createdBy: employee.createdBy || "",
    createdDate: employee.createdDate || new Date(),
    modifiedBy: employee.modifiedBy || "",
    modifiedDate: employee.modifiedDate || new Date(),
    isDeleted: employee.isDeleted || false
  });
  //Address
  const currentAddress =
    currentEmployee && currentEmployee.empAddress
      ? currentEmployee.empAddress.filter(e => e.addressType === "Current")
      : null;
  const homeAddress =
    currentEmployee && currentEmployee.empAddress
      ? currentEmployee.empAddress.filter(e => e.addressType === "Home")
      : null;
  //Current Address
  const current_address =
    currentAddress && currentAddress.length > 0 ? currentAddress[0] : {};
  const [currentEmpAddress, setCurrentEmpAddress] = useState({
    empId: employee.id || null,
    addressType: "Current",
    subDistrict: current_address.subDistrict || "",
    district: current_address.district || "",
    province: current_address.province || "",
    postalCode: current_address.postalCode || "",
    country: current_address.country || "ประเทศไทย",
    latitude: current_address.latitude || "",
    longitude: current_address.longitude || "",
    addressDetail: current_address.addressDetail || "",
    createdBy: current_address.createdBy || "",
    createdDate: current_address.createdDate || "",
    modifiedBy: current_address.modifiedBy || "",
    modifiedDate: current_address.modifiedDate || "",
    isDeleted: current_address.isDeleted || false
  });
  //Home Address
  const home_address =
    homeAddress && homeAddress.length > 0 ? homeAddress[0] : {};
  const [homeEmpAddress, setHomeEmpAddress] = useState({
    empId: employee.id || null,
    addressType: "Home",
    subDistrict: home_address.subDistrict || "",
    district: home_address.district || "",
    province: home_address.province || "",
    postalCode: home_address.postalCode || "",
    country: home_address.country || "ประเทศไทย",
    latitude: home_address.latitude || "",
    longitude: home_address.longitude || "",
    addressDetail: home_address.addressDetail || "",
    createdBy: home_address.createdBy || "",
    createdDate: home_address.createdDate || "",
    modifiedBy: home_address.modifiedBy || "",
    modifiedDate: home_address.modifiedDate || "",
    isDeleted: home_address.isDeleted || false
  });

  // Tab Employment
  const empEmployment =
    employee && employee.empEmployment ? employee.empEmployment[0] : {};
  const [employment, setEmployment] = useState({
    id: empEmployment.id || null,
    empId: employee.id || null,
    startWorkingDate: empEmployment.startWorkingDate || new Date(),
    workingStatus: empEmployment.workingStatus || _workingStatusId,
    departmentId: empEmployment.departmentId || "",
    positionId: empEmployment.positionId || "",
    empType: empEmployment.empType || _empTypeId,
    supervisorId: empEmployment.supervisorId || "",
    shiftId: empEmployment.shiftId || "",
    locationId: empEmployment.locationId || "",
    positionInput: empEmployment.positionInput || "",
    departmentInput: empEmployment.departmentInput || ""
  });

  //  Tab User
  const [user, setUser] = useState(empUser || {});

  const handleChange = e => {
    // title - titleEn - gender
    if ("title,titleEn".includes(e.target.name)) {
      const _title = empEnum.find(
        item => item.type === "title" && item.id === e.target.value
      ).name;
      let genderId = "";
      if (_title === "mr") {
        genderId = _genderId;
      } else {
        genderId = empEnum.find(e => e.type === "gender" && e.name === "female")
          .id;
      }

      setEmp({
        ...emp,
        [e.target.name]: e.target.value,
        titleEn: e.target.value,
        title: e.target.value,
        gender: genderId
      });
    } else {
      setEmp({ ...emp, [e.target.name]: e.target.value });
    }
  };

  // ? Date Change
  const handleDateChange = name => value => {
    setEmp({ ...emp, [name]: value });
  };

  const handleChangeEmployment = e => {
    setEmployment({ ...employment, [e.target.name]: e.target.value });
  };

  // ? Date Change
  const handleDateChangeEmployment = name => value => {
    setEmployment({ ...employment, [name]: value });
  };

  const propsGeneral = {
    infoViewOnlyMode,
    emp,
    setEmp,
    currentEmpAddress,
    setCurrentEmpAddress,
    homeEmpAddress,
    setHomeEmpAddress,
    handleChange,
    handleDateChange
  };

  const propsEmployment = {
    // infoViewOnlyMode,
    viewOnly,
    employment,
    setEmployment,
    handleChangeEmployment,
    handleDateChangeEmployment
  };

  const propsUser = {
    infoViewOnlyMode,
    user,
    setUser,
    viewOnly
  };

  // const propsPayment = {
  //   infoViewOnlyMode
  // };

  //TODO: วางไว้ล่าง state
  detectStateChange([emp, currentEmpAddress, homeEmpAddress, employment, user]);

  const handleInfoDialog = action => {
    console.log("action dialog :" + action);
    switch (action) {
      case "save":
        handleSubmit();
        break;
      case "delete":
        console.log("delete from info.");
        if (currentEmployee) {
          handleDialog({ action: "delete", value: [currentEmployee.id] });
        }
        break;
      default:
        handleDialog();
        break;
    }
  };

  const onValidate = () => {
    console.log(
      "emp.identificationNo : " +
        emp.identificationNo.replace(/-/g, "").replace(/\s/g, "").length
    );
    if (
      !emp.empCode ||
      !emp.title ||
      !emp.firstName ||
      !emp.lastName ||
      !emp.identificationNo ||
      !user.email ||
      (user.resetPassword && user.password !== user.confirmPassword)
    ) {
      setEmp({
        ...emp,
        empCodeError: !emp.empCode ? t("common.isRequired") : "",
        titleError: !emp.title ? t("common.isRequired") : "",
        firstNameError: !emp.firstName ? t("common.isRequired") : "",
        lastNameError: !emp.lastName ? t("common.isRequired") : "",
        identificationNoError: !emp.identificationNo
          ? t("common.isRequired")
          : ""
      });
      if (!user.email) {
        setUser({
          ...user,
          emailError: t("common.isRequired")
        });
      } else if (user.resetPassword && user.password !== user.confirmPassword) {
        setUser({
          ...user,
          confirmPasswordError: t("signUp.passwordNotMatch")
        });
      }
      return false;
    } else if (
      emp.identificationNo.replace(/-/g, "").replace(/\s/g, "").length !== 13
    ) {
      setEmp({
        ...emp,
        identificationNoError: t("employee.vldIdCardInvalid")
      });
      return false;
    } else if (user.email) {
      const isValidEmail = validateEmail(user.email);
      if (!isValidEmail) {
        setUser({
          ...user,
          emailError: t("common.emailNotValid")
        });
        return false;
      }
    }
    setEmp({
      ...emp,
      empCodeError: "",
      titleError: "",
      firstNameError: "",
      lastNameError: "",
      identificationNoError: ""
    });
    setUser({
      ...user,
      emailError: "",
      confirmPasswordError: ""
    });
    return true;
  };

  const handleSubmit = () => {
    console.log("handleSubmit");
    if (onValidate()) {
      const empSave = {
        image: emp.image,
        empCode: emp.empCode,
        title: emp.title,
        firstName: emp.firstName,
        lastName: emp.lastName,
        nickName: emp.nickName,
        titleEn: emp.titleEn,
        firstNameEn: emp.firstNameEn,
        lastNameEn: emp.lastNameEn,
        nickNameEn: emp.nickNameEn,
        gender: emp.gender,
        religion: emp.religion,
        nationality: emp.nationality,
        birthDate: emp.birthDate,
        // tax Id
        identificationNo: emp.identificationNo,
        identityExpire: emp.identityExpire,
        passportNo: emp.passportNo,
        passportExpire: emp.passportExpire,
        //contact
        phone: emp.phone,
        email: emp.email,
        lineId: emp.lineId,
        emergencyName: emp.emergencyName,
        emergencyRelate: emp.emergencyRelate,
        emergencyPhone: emp.emergencyPhone,

        empAddress: [currentEmpAddress, homeEmpAddress],
        empEmployment: [employment],
        empUser: user ?? null,
        companyId: emp.companyId,
        createdBy: emp.createdBy,
        createdDate: emp.createdDate,
        modifiedBy: emp.modifiedBy,
        modifiedDate: new Date(),
        isDeleted: false
      };
      if (currentEmployee) {
        dispatch(onUpdate({ ...currentEmployee, ...empSave }));
        console.log("Update success");
      } else {
        dispatch(onCreate(empSave));
        console.log("Insert Success");
      }
      // update user
      const authUser = getStorageAuthenUser();
      let data = authUser;
      if (data.data.empId === emp.id) {
        data.data = { ...data.data, img: emp.image };
        dispatch(setAuthUser(data));
      }
      handleDialog({ action: "save", value: "" });
    } else {
      dispatch(fetchError(t("common.saveUnsuccessful")));
    }
  };

  //create form
  const empInfoForm = () => {
    return (
      <>
        <TabHeader
          tabGeneral={<TabGeneral props={propsGeneral} />}
          tabEmployment={<TabEmployment props={propsEmployment} />}
          tabUser={<TabUser props={propsUser} />}
          // tabPayment={<TabPayment props={propsPayment} />}
          roleUser={roleUser}
          // tabTax={<TabTax />}
        />
      </>
    );
  };
  //
  return (
    <>
      <InfoUC
        handleDialog={handleInfoDialog}
        children={empInfoForm()}
        isShowDelete={false}
        isShowClose={isShowClose}
      />
    </>
  );
};

export default EmpInfo;

EmpInfo.propType = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func,
  selectedEmployee: PropTypes.object
};

EmpInfo.defaultProps = {
  selectedEmployee: null,
  isShowClose: true,
  roleUser: null
};
