import React from "react";
import { AccordionSummary, Box, Typography, TextField } from "@mui/material";
import LabelUC from "routes/shared/uc/label-uc";
import TextInputUC from "routes/shared/uc/text-input-uc";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import AppSwitch from "@jumbo/components/Common/formElements/AppSwitch";
import { useDropzone } from "react-dropzone";
import CmtAvatar from "@coremat/CmtAvatar";
import { useMediaQuery } from "react-responsive";
import DialogUC from "routes/shared/uc/dialog-uc";
import { useTranslation } from "react-i18next";
import useStyles from "routes/Pages/Company/index.style";
import DateTimePickerUC from "routes/shared/uc/datetime-picker-uc";
import { resizeFile } from "services/default.service";
import { useSelector } from "react-redux";
import InputMask from "react-input-mask";
import clsx from "clsx";

const TabGeneral = ({ propsGeneral }) => {
  const {
    infoViewOnlyMode,
    company,
    setCompany,
    handleDateChangeCompany,
    handleChangeCheckbox
  } = propsGeneral;

  const classes = useStyles();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const { t } = useTranslation();
  const { companyEnum } = useSelector(({ companys }) => companys);
  const enumCompanyType = companyEnum.filter(e => e.type === "companyType");

  const { getRootProps, getInputProps } = useDropzone({
    noClick: infoViewOnlyMode,
    noKeyboard: infoViewOnlyMode,
    noDrag: infoViewOnlyMode,
    accept: "image/*",
    onDrop: async acceptedFiles => {
      const imgResize = await resizeFile(acceptedFiles[0]);
      setCompany({ ...company, image: imgResize });
    }
  });

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = e => {
    setCompany({ ...company, [e.target.name]: e.target.value });
  };

  return (
    <>
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h6" className={classes.title}>
          General Information
        </Typography>
      </AccordionSummary>

      <Box
        sx={{ display: "flex", width: "100%" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        {/* info left */}
        <Box sx={{ padding: 2, width: { xs: "100%", lg: "50%" } }}>
          {isMobile && (
            <>
              <GridContainer mb={2}>
                <Grid item xs={12} sm={5}>
                  <LabelUC label="Logo" />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box
                      {...getRootProps()}
                      mb={{ xs: 3, md: 0 }}
                      className="pointer"
                    >
                      <input {...getInputProps()} />
                      <CmtAvatar
                        className={classes.companyImage}
                        size={128}
                        src={company.image}
                      />
                    </Box>
                  </Box>
                </Grid>
              </GridContainer>
              <GridContainer>
                <Grid item>
                  <AppSwitch
                    name="isActive"
                    label={t("company.lblActive")}
                    labelPlacement="end"
                    checked={company.isActive}
                    onChange={handleChangeCheckbox}
                    disabled={infoViewOnlyMode}
                  />
                </Grid>
              </GridContainer>
            </>
          )}
          {/* Company Code */}
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("company.lblCompanyCode")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextInputUC
                disabled={infoViewOnlyMode}
                placeholder="Company Code"
                value={company.companyCode}
                name="companyCode"
                onChange={e => handleChange(e)}
              />
            </Grid>
          </GridContainer>
          {/* Company Name */}
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("company.lblCompanyName")} isRequired={true} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextInputUC
                disabled={infoViewOnlyMode}
                placeholder="Company Name"
                value={company.companyName}
                name="companyName"
                onChange={e => handleChange(e)}
                helperText={company.companyNameError}
              />
            </Grid>
          </GridContainer>
          {/* Company Name EN*/}
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("company.lblCompanyNameEn")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextInputUC
                disabled={infoViewOnlyMode}
                placeholder="Company Name(EN)"
                value={company.companyNameEn}
                name="companyNameEn"
                onChange={e => handleChange(e)}
              />
            </Grid>
          </GridContainer>
          {/* Business Type*/}
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("company.lblBusinessType")} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInputUC
                disabled={infoViewOnlyMode}
                placeholder="Business Type"
                value={company.businessType}
                name="businessType"
                onChange={e => handleChange(e)}
              />
            </Grid>
          </GridContainer>
          {/* Company Type*/}
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("company.lblCompanyType")} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AppSelectBox
                fullWidth
                data={enumCompanyType}
                variant="outlined"
                value={company.companyType}
                name="companyType"
                onChange={handleChange}
                disabled={infoViewOnlyMode}
              />
            </Grid>
          </GridContainer>
          {/* Program Start Date*/}
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("company.lblProgramStart")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <DateTimePickerUC
                name="programStartDate"
                value={company.programStartDate}
                handleDateChange={handleDateChangeCompany}
                infoViewOnlyMode={true}
              />
            </Grid>
          </GridContainer>
          {/* Tax No.*/}
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("company.lblCompTaxNo")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <InputMask
                mask="9-9999-99999-99-9"
                maskChar=" "
                disabled={infoViewOnlyMode}
                placeholder="x-xxxx-xxxxx-xx-x"
                value={company.taxNo}
                name="taxNo"
                onChange={e => handleChange(e)}
              >
                {params => (
                  <TextField
                    fullWidth
                    disabled={infoViewOnlyMode}
                    className={clsx(classes.formControlRoot, "form-control")}
                    {...params}
                  />
                )}
              </InputMask>
            </Grid>
          </GridContainer>
          {/* Tax Branch No.*/}
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("company.lblCompTaxBranchNo")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextInputUC
                disabled={infoViewOnlyMode}
                placeholder={t("company.lblCompTaxBranchNo")}
                value={company.taxBranchNo}
                name="taxBranchNo"
                onChange={e => handleChange(e)}
              />
            </Grid>
          </GridContainer>
        </Box>
        {/* info right */}
        <Box sx={{ padding: 2, width: { xs: "100%", lg: "50%" } }}>
          {!isMobile && (
            <>
              <GridContainer>
                <Grid item>
                  <AppSwitch
                    name="isActive"
                    label={t("company.lblActive")}
                    labelPlacement="end"
                    checked={company.isActive}
                    onChange={handleChangeCheckbox}
                    disabled={infoViewOnlyMode}
                  />
                </Grid>
              </GridContainer>
              <GridContainer mb={2}>
                <Grid item xs={12} sm={5}>
                  <LabelUC label="Logo" />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box sx={{ display: "flex" /*, justifyContent: 'end' */ }}>
                    <Box
                      {...getRootProps()}
                      mb={{ xs: 3, md: 0 }}
                      className="pointer"
                    >
                      <input {...getInputProps()} />
                      <CmtAvatar
                        className={classes.companyImage}
                        size={200}
                        src={company.image}
                      />
                    </Box>
                  </Box>
                </Grid>
              </GridContainer>
            </>
          )}
        </Box>
      </Box>
      {open && <DialogUC open={open} handleDialog={handleClose} />}
    </>
  );
};

export default TabGeneral;
