export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SET_NOTIFICATION = "set_notification";

export const UPDATE_AUTH_USER = "update_auth_user";
export const UPDATE_LOAD_USER = "update_load_user";
export const SEND_FORGET_PASSWORD_EMAIL = "send_forget_password_email";
export const SET_RESET_PASSWORD = "set_reset_password";
export const SIGNIN_GOOGLE_USER_SUCCESS = "signin_google_user_success";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "signin_facebook_user_success";
export const SIGNIN_TWITTER_USER_SUCCESS = "signin_twitter_user_success";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_SUCCESS";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";
export const SET_SIGNUP_ENUM = "set_signup_enum";

export const SET_DASHBOARD_DATA = "set_dashboard_data";

export const SET_TASK_CURRENT_USER = "set_task_current_user";
export const SET_TASKS_DATA = "set_tasks_data";
export const SET_TASK_LIST_DATA = "set_task_list_data";
export const ADD_TASK = "add_task";
export const DELETE_TASK = "delete_task";
export const UPDATE_TASK = "update_task";
export const SET_FILTER_DATA = "set_filter_data";
export const ADD_TASK_LIST = "add_task_list";
export const UPDATE_TASK_LIST = "update_task_list";
export const DELETE_TASK_LIST = "delete_task_list";
export const SET_TASK_DETAIL = "set_task_detail";
export const SEND_MESSAGE = "send_message";
export const TOGGLE_SIDEBAR_COLLAPSED = "toggle_sidebar_collapsed";
export const GET_TASKS_COUNTS = "get_tasks_counts";

//mail app
export const GET_LABELS_LIST = "get_labels_list";
export const GET_CONNECTIONS_LIST = "get_connections_list";
export const GET_MAILS_LIST = "get_mails_list";
export const UPDATE_MAIL_FOLDER = "update_mail_folder";
export const UPDATE_MAIL_LABEL = "upade_mail_label";
export const UPDATE_FAVORITE_STATUS = "update_favorite_status";
export const UPDATE_READ_STATUS = "update_read_status";
export const UPDATE_IMPORTANT_STATUS = "update_important_status";
export const COMPOSE_MAIL = "compose_mail";
export const SET_FILTER_TYPE = "set_filter_type";
export const GET_SELECTED_MAIL = "GET_SELECTED_MAIL";
export const UPDATE_SELECTED_MAIL = "update_selected_mail";
export const NULLIFY_SELECTED_MAIL = "nullify_selected_mail";
export const REPLY_TO_MAIL = "reply_to_mail";
export const GET_MAIL_COUNTS = "get_mail_count";
export const ADD_LABEL = "add_label";
export const ADD_CONNECTION = "add_connection";
export const REMOVE_CONNECTION = "remove_connection";

export const SET_CHAT_USERS = "set_chat_users";
export const SET_CONTACT_USERS = "set_contact_users";
export const SET_CURRENT_USER = "set_current_user";
export const SET_CONVERSATION_DATA = "set_conversation_data";
export const SEND_NEW_CHAT_MESSAGE = "send_new_chat_message";
export const SEND_NEW_MEDIA_MESSAGE = "send_new_media_message";

//Contact App
export const GET_CONTACTS_LIST = "get_contacts_list";
export const SET_CURRENT_CONTACT = "set_current_contact";
export const CREATE_CONTACT = "create_contact";
export const UPDATE_STARRED_STATUS = "update_starred_status";
export const DELETE_CONTACT = "delete_contact";
export const UPDATE_CONTACT_LABEL = "update_contact_label";
export const UPDATE_CONTACT = "update_contact";
export const GET_CONTACT_COUNTS = "get_contact_counts";
export const UPDATE_LABEL_ITEM = "update_label_item";
export const DELETE_LABEL_ITEM = "delete_label_item";

export const GET_USER_DETAIL = "get_user_detail";
export const GET_FEED_POSTS = "get_feed_posts";
export const CREATE_POST = "create_post";
export const UPDATE_POST = "update_post";

// Users Module
export const GET_USERS = "GET_USERS";
export const ADD_USER = "ADD_USER";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const DELETE_BULK_USERS = "DELETE_BULK_USERS";

//Menu
export const SET_MENU = "SET_MENU";
export const GET_MENU = "GET_MENU";

//Employees
export const GET_EMPLOYEE_LIST = "get_employee_list";
export const SET_CURRENT_EMPLOYEE = "set_current_employee";
export const CREATE_EMPLOYEE = "create_employee";
export const DELETE_EMPLOYEE = "delete_employee";
export const UPDATE_EMPLOYEE = "update_employee";
export const GET_EMPLOYEE_COUNTS = "get_employee_counts";
export const SET_EMP_POSITION = "set_emp_position";
export const SET_EMP_DEPARTMENT = "set_emp_department";
export const SET_EMP_SUPERVISOR = "set_emp_supervisor";
export const SET_EMP_SHIFT = "set_emp_shift";
export const SET_EMP_LOCATION = "set_emp_location";
export const SET_EMP_ENUM = "set_emp_enum";
export const SET_EMP_USER = "set_emp_user";

//Common
export const SET_INFO_VIEWONLY_MODE = "set_info_viewonly_mode";
export const SET_ONADD_CLICK = "set_onadd_click";
export const SET_OPEN_INFO = "set_open_info";
export const SET_DATA_MODIFIED = "set_data_modified";
export const SET_MENU_NAME = "set_menu_name";
export const DELETE_LIST = "delete_list";

//Company
export const GET_COMPANY_LIST = "get_company_list";
export const SET_CURRENT_COMPANY = "set_current_company";
export const CREATE_COMPANY = "create_company";
export const DELETE_COMPANY = "delete_company";
export const UPDATE_COMPANY = "update_company";
export const SET_COMPANY_ENUM = "set_company_enum";
export const SET_HOLIDAY_YEARLIST = "set_holiday_yearlist";
export const SET_COMPANY_HOLIDAY = "set_company_holiday";

// register
export const CREATE_REGISTER = "create_register";
export const ACTIVATE_REGISTER = "activate_register";

//Department
export const GET_DEPARTMENT_LIST = "get_department_list";
export const SET_CURRENT_DEPARTMENT = "set_current_department";
export const CREATE_DEPARTMENT = "create_department";
export const DELETE_DEPARTMENT = "delete_department";
export const UPDATE_DEPARTMENT = "update_department";
export const SET_MAIN_DEPARTMENT = "set_main_department";

//Position
export const GET_POSITION_LIST = "get_position_list";
export const SET_CURRENT_POSITION = "set_current_position";
export const CREATE_POSITION = "create_position";
export const DELETE_POSITION = "delete_position";
export const UPDATE_POSITION = "update_position";
export const SET_MAIN_POSITION = "set_main_position";

//Shift
export const GET_SHIFT_LIST = "get_shift_list";
export const SET_CURRENT_SHIFT = "set_current_shift";
export const SET_ALL_SHIFT_DETAIL = "set_all_shift_detail";
export const CREATE_SHIFT = "create_shift";
export const DELETE_SHIFT = "delete_shift";
export const UPDATE_SHIFT = "update_shift";

//Location
export const GET_LOCATION_LIST = "get_location_list";
export const SET_CURRENT_LOCATION = "set_current_location";
export const SET_ALL_EMP_LOCATION = "set_all_emp_location";
export const CREATE_LOCATION = "create_location";
export const DELETE_LOCATION = "delete_location";
export const UPDATE_LOCATION = "update_location";
export const GET_LOCATION_EMP = "get_location_emp";

// Time Stamp
export const SET_CURRENT_TIMESTAMP = "set_current_timestamp";
export const CREATE_TIMESTAMP = "create_timestamp";
export const UPDATE_TIMESTAMP = "update_timestamp";
export const GET_TIMESTAMP = "get_timestamp";
export const SET_TIMESTAMP_SHIFT = "set_timestamp_shift";
export const SET_TIMESTAMP_SETTIMESTAMP = "set_timestamp_settimestamp";
// Time Stamp Setting
export const SET_CURRENT_SET_TIMESTAMP = "set_setTimeStamp";
export const SET_LOCATION_TIMESTAMP = "set_location_timestamp";
export const CREATE_SET_TIMESTAMP = "create_setTimeStamp";
export const UPDATE_SET_TIMESTAMP = "update_setTimeStamp";

// Points
export const SET_CURRENT_POINT_SETTING = "set_current_pointSetting";
export const CREATE_POINT_SETTING = "create_pointSetting";
export const UPDATE_POINT_SETTING = "update_pointSetting";
export const DELETE_POINT_SETTING = "delete_pointSetting";
//Employee Point
export const GET_EMPPOINT_LIST = "get_employeePoint_list";
export const SET_CURRENT_EMPPOINT = "set_current_emppoint";
export const CREATE_EMPPOINT = "create_emppoint";
export const UPDATE_EMPPOINT = "update_emppoint";
export const DELETE_EMPPOINT = "delete_emppoint";
//My Point
export const GET_MYPOINT_LIST = "get_myPoint_list";
export const CREATE_MYPOINT = "create_myPoint";
//Report
export const GET_REPORT_RANGE = "get_report_range";
export const GET_CLOCKINOUT_REPORT = "get_clockinout_report";
export const REPORT_NAME = {
  clockInOutReport: "clockInOutReport"
};
export const ENUM_RANGE = {
  department: "department",
  position: "position",
  shift: "shift",
  location: "location",
  employee: "employee",
  company: "company"
};
//Customer
export const GET_CUSTOMER_LIST = "get_customer_list";
export const SET_CURRENT_CUSTOMER = "set_current_customer";
export const CREATE_CUSTOMER = "create_customer";
export const DELETE_CUSTOMER = "delete_customer";
export const UPDATE_CUSTOMER = "update_customer";

//Liff Register
export const CREATE_LIFF = "create_LIFF";
export const GET_LIFF_LIST = "select_LIFF"