import React from "react";
// import { useSelector } from 'react-redux';
import CmtGridView from "@coremat/CmtGridView";
import EmployeeCell from "./EmployeeCell";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";

const ListGridView = ({ onShowEmployeeDetail, onEdit, datas }) => {
  // const { employeesList } = useSelector(({ employees }) => employees);

  return (
    <Box>
      <CmtGridView
        // border={true}
        itemPadding={18}
        responsive={{
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 3
        }}
        data={datas}
        renderRow={(item, index) => (
          <EmployeeCell
            key={index}
            employee={item}
            onShowEmployeeDetail={onShowEmployeeDetail}
            onEdit={onEdit}
          />
        )}
      />
    </Box>
  );
};

export default ListGridView;

ListGridView.prototype = {
  onShowEmployeeDetail: PropTypes.func,
  onEdit: PropTypes.func
};
