import { Avatar, Grid } from "@mui/material";
import React from "react";

export const LabelPoint = () => {
  return (
    <Grid item xs={3} sm={2} alignItems="center" container>
      <Avatar
        alt="Point"
        src="/images/time/star.png"
        sx={{ width: 20, height: 20 }}
      />
    </Grid>
  );
};

export default LabelPoint;
