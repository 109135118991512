import React from "react";
import GridContainer from "@jumbo/components/GridContainer";
import { Grid } from "@mui/material";
import LabelUC from "routes/shared/uc/label-uc";
// import useStyles from "./index.style";
import { useTranslation } from "react-i18next";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
// import {  enumGender } from "../../empEnum";
import DateTimePickerUC from "routes/shared/uc/datetime-picker-uc";
import { useSelector } from "react-redux";

const PersonnelInfo = ({ props }) => {
  const { infoViewOnlyMode, emp, handleChange, handleDateChange } = props;
  const { t } = useTranslation();
  const { empEnum } = useSelector(({ employees }) => employees);
  // console.log(empEnum);
  const enumNationality = empEnum.filter(
    e => e.type === "nationality" || e.type === "none"
  );
  const enumReligion = empEnum.filter(
    e => e.type === "religion" || e.type === "none"
  );
  const enumGender = empEnum.filter(
    e => e.type === "gender" || e.type === "none"
  );
  return (
    <>
      {/* Gender*/}
      <GridContainer mb={2}>
        <Grid item xs={12} sm={5} alignItems="center" container>
          <LabelUC label={t("employee.lblGender")} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <AppSelectBox
            fullWidth
            data={enumGender}
            variant="outlined"
            name="gender"
            value={emp.gender}
            onChange={handleChange}
            disabled={true} //{infoViewOnlyMode}
          />
        </Grid>
      </GridContainer>
      {/* Birth Date*/}
      <GridContainer>
        <Grid item xs={12} sm={5} alignItems="center" container>
          <LabelUC label={t("employee.lblBirthDate")} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <DateTimePickerUC
            name="birthDate"
            value={emp.birthDate}
            handleDateChange={handleDateChange}
            infoViewOnlyMode={infoViewOnlyMode}
          />
        </Grid>
      </GridContainer>
      {/* Nationality*/}
      <GridContainer mb={2}>
        <Grid item xs={12} sm={5} alignItems="center" container>
          <LabelUC label={t("employee.lblNationality")} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <AppSelectBox
            disabled={infoViewOnlyMode}
            fullWidth
            data={enumNationality}
            variant="outlined"
            name="nationality"
            value={emp.nationality}
            onChange={handleChange}
          />
        </Grid>
      </GridContainer>
      {/* Religion*/}
      <GridContainer mb={2}>
        <Grid item xs={12} sm={5} alignItems="center" container>
          <LabelUC label={t("employee.lblReligion")} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <AppSelectBox
            disabled={infoViewOnlyMode}
            fullWidth
            data={enumReligion}
            variant="outlined"
            name="religion"
            value={emp.religion}
            onChange={handleChange}
          />
        </Grid>
      </GridContainer>
    </>
  );
};

export default PersonnelInfo;
