import React, { useState } from "react";
import { requiredMessage } from "@jumbo/constants/ErrorMessages";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import InfoUC from "routes/shared/uc/info-uc/";
import detectStateChange from "services/detectStateChange";
import { onCreate, onUpdate } from "redux/actions/Default";
// import { useTranslation } from "react-i18next";
import Location from "./tab-general";

const LocationInfo = ({ handleDialog, openInfo }) => {
  const { currentLocation } = useSelector(({ locations }) => locations);
  const { infoViewOnlyMode } = useSelector(({ common }) => common);
  const dispatch = useDispatch();

  const locate = currentLocation ? currentLocation : {};
  const [location, setLocation] = useState({
    id: locate.id || null,
    locationCode: locate.locationCode || "",
    locationName: locate.locationName || "",
    locationNameEn: locate.locationNameEn || "",
    companyId: locate.companyId || "",
    createdDate: locate.createdDate || new Date(),
    createdBy: locate.createdBy || "",
    modifiedDate: locate.modifiedDate || new Date(),
    modifiedBy: locate.modifiedBy || ""
  });

  const [mapData, setMapData] = useState({
    latitude: locate.latitude || "",
    longitude: locate.longitude || "",
    radius: locate.radius || ""
  });

  const [empLocation, setEmpLocation] = useState(locate.empLocation || []);

  const handleChange = e => {
    setLocation({ ...location, [e.target.name]: e.target.value });
  };

  const propsGeneral = {
    infoViewOnlyMode,
    location,
    setLocation,
    empLocation,
    setEmpLocation,
    handleChange,
    mapData,
    setMapData
  };

  detectStateChange([location, mapData, empLocation]);

  const handleInfoDialog = action => {
    console.log("action dialog :" + action);
    switch (action) {
      case "save":
        handleSubmit();
        break;
      case "delete":
        console.log("delete from info.");
        if (currentLocation) {
          handleDialog({ action: "delete", value: [currentLocation.id] });
        }
        break;
      default:
        handleDialog();
        break;
    }
  };

  const handleSubmit = () => {
    if (!location.locationName) {
      setLocation({
        ...location,
        locationNameError: !location.locationName ? requiredMessage : ""
      });
    } else {
      //ลบแถวที่เพิ่มเข้าไปใหม่ และมีการลบออก
      const _empLocation = empLocation.filter(item => {
        return !item.isDelete || (item.isDelete && item.rowState !== "new");
      });
      const locationSave = {
        locationCode: location.locationCode,
        locationName: location.locationName,
        locationNameEn: location.locationNameEn,
        latitude: mapData.latitude,
        longitude: mapData.longitude,
        radius: mapData.radius,
        companyId: location.companyId,
        createdDate: location.createdDate,
        createdBy: location.createdBy,
        modifiedDate: location.modifiedDate,
        modifiedBy: location.modifiedBy,
        empLocation: _empLocation
      };
      console.log("handleSubmit locationSave :", locationSave);
      if (currentLocation && currentLocation.id) {
        dispatch(onUpdate({ ...currentLocation, ...locationSave }));
        console.log("Update success");
      } else {
        dispatch(onCreate(locationSave));
        console.log("Insert Success");
      }
      handleDialog({ action: "save", value: "" });
    }
  };

  //create form
  const locationInfoForm = () => {
    return (
      <>
        <Location props={propsGeneral} />
      </>
    );
  };

  return (
    <>
      <InfoUC
        open={openInfo}
        handleDialog={handleInfoDialog}
        children={locationInfoForm()}
      />
    </>
  );
};

export default LocationInfo;

LocationInfo.propType = {
  handleDialog: PropTypes.func
};
