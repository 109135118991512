import GridContainer from "@jumbo/components/GridContainer";
import { Grid, Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import LabelUC from "routes/shared/uc/label-uc";
import TextInputUC from "routes/shared/uc/text-input-uc";
import { useTranslation } from "react-i18next";
import useStyles from "./index.style";
import { map, LongdoMap, longdo } from "longdo-map/LongdoMap-Home";
import IconButton from "@mui/material/IconButton";
import PinDropIcon from "@mui/icons-material/PinDrop";

const Map = ({ props }) => {
  const { mapData, infoViewOnlyMode, setMapData, handleChange } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [displayMap, setDisplayMap] = useState(true);
  const [radius, setRadius] = useState(mapData.radius);
  const mapKey = "b69be858e00b3fa59086597397a441e5";
  const mapId = "longdo-map-";

  const initMap = () => {
    if (mapData.longitude && mapData.latitude && map) {
      map.location({ lon: mapData.longitude, lat: mapData.latitude }, true);
      setGeo();
    } else if (map) {
    }
    if (map) {
      map.Event.bind("drop", function () {
        setLatLong(map.location().lat, map.location().lon);
      });

      setEnableMap();
    }
  };

  const setGeo = () => {
    // console.log("setGeo");
    if (map) {
      // rad ยังคำนวณไม่ถูกต้อง (ไว้มาแก้อีกรอบ อิอิ)
      const driven = (radius ?? 0) < 100 ? 2 : ((radius ?? 0) / 100) * 6;
      const rad = radius - driven;
      // console.log("rad", rad);
      map.Overlays.clear();
      var geom3 = new longdo.Circle(
        {
          lon: mapData.longitude,
          lat: mapData.latitude
        },
        rad / 100000,
        {
          lineWidth: 1,
          lineColor: "rgba(0, 90, 200, 0.8)",
          fillColor: "rgba(0, 90, 200, 0.2)"
        }
      );
      // console.log('setGeo', geom3)
      map.Overlays.add(geom3);
    }
  };

  const setLatLong = (lat, lon) => {
    setMapData({
      ...mapData,
      latitude: lat,
      longitude: lon
    });
  };

  useEffect(() => {
    if (map) {
      initMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    setGeo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapData.radius, mapData.latitude, mapData.longitude]);

  const setEnableMap = useCallback(() => {
    map.Ui.Geolocation.visible(!infoViewOnlyMode);
    map.Ui.Keyboard.enableInertia(!infoViewOnlyMode);
    map.Ui.Keyboard.enable(!infoViewOnlyMode);
    map.Ui.Mouse.enableClick(!infoViewOnlyMode);
    map.Ui.Mouse.enableDrag(!infoViewOnlyMode);
    map.Ui.Mouse.enableWheel(!infoViewOnlyMode);
    map.Ui.Mouse.enableInertia(!infoViewOnlyMode);
  }, [infoViewOnlyMode]);

  useEffect(() => {
    if (map) {
      setEnableMap();
    }
  }, [infoViewOnlyMode, setEnableMap]);

  const handleChangeRadius = value => {
    setRadius(value);
    setMapData({ ...mapData, radius: value });
  };

  return (
    <>
      <GridContainer>
        <Grid item xs={12} sm={2} alignItems="center" container></Grid>
        <Grid item xs={12} sm={4}>
          <Box
            // p={2}
            className={classes.map}
            sx={{
              height: "300px",
              zIndex: 9999,
              display: displayMap ? "block" : "none"
            }}
          >
            <LongdoMap id={mapId} mapKey={mapKey} callback={initMap} />
          </Box>
        </Grid>
      </GridContainer>
      {/* Latitude*/}
      <GridContainer>
        <Grid item xs={12} sm={2} alignItems="center" container>
          <LabelUC label={t("location.lblLatitude")} />
        </Grid>
        <Grid item xs={10} sm={4}>
          <TextInputUC
            disabled={infoViewOnlyMode}
            placeholder={t("location.lblLatitude")}
            value={mapData.latitude}
            name="latitude"
            onChange={handleChange}
            type="number"
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="show map"
            onClick={() => setDisplayMap(!displayMap)}
            edge="end"
          >
            <PinDropIcon />
          </IconButton>
        </Grid>
      </GridContainer>
      {/* Longtitude*/}
      <GridContainer>
        <Grid item xs={12} sm={2} alignItems="center" container>
          <LabelUC label={t("location.lblLongitude")} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInputUC
            disabled={infoViewOnlyMode}
            placeholder={t("location.lblLongitude")}
            value={mapData.longitude}
            name="longitude"
            onChange={handleChange}
            type="number"
          />
        </Grid>
      </GridContainer>
      {/* Radius*/}
      <GridContainer>
        <Grid item xs={12} sm={2} alignItems="center" container>
          <LabelUC label={t("location.lblRadius")} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInputUC
            disabled={infoViewOnlyMode}
            placeholder={t("location.lblRadius")}
            value={radius}
            name="radius"
            onChange={e => handleChangeRadius(e.target.value.substring(0, 3))}
            type="number"
          />
        </Grid>
      </GridContainer>
    </>
  );
};

export default Map;
