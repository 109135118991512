import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { alpha, styled } from "@mui/material/styles";

export const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[100],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      "@media (hover: none)": {
        backgroundColor: "transparent"
      }
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        0.2 + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          0.2 +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            0.2 + theme.palette.action.selectedOpacity
          )
        }
      }
    }
  },
  [`& .${gridClasses.row}.odd`]: {
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      "@media (hover: none)": {
        backgroundColor: "transparent"
      }
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        0.2 + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          0.2 +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            0.2 + theme.palette.action.selectedOpacity
          )
        }
      }
    }
  }
}));
