import React from "react";
import GridContainer from "@jumbo/components/GridContainer";
import { Grid } from "@mui/material";
import LabelUC from "routes/shared/uc/label-uc";
import { useTranslation } from "react-i18next";
// import { enumTitleEn } from '../../empEnum';
import TextInputUC from "routes/shared/uc/text-input-uc";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const NameEn = ({ props }) => {
  const { infoViewOnlyMode, emp, handleChange } = props;
  const { t } = useTranslation();
  const { empEnum } = useSelector(({ employees }) => employees);
  const enumTitleEn = empEnum.filter(e => e.type === "title");
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <>
      {/* First Name (En)*/}
      <GridContainer>
        <Grid item xs={12} sm={5} alignItems="center" container>
          <LabelUC label={t("employee.lblFirstNameEn")} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <GridContainer>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{ paddingRight: !isMobile ? "0 !important" : 12 }}
            >
              <AppSelectBox
                fullWidth
                data={enumTitleEn}
                variant="outlined"
                value={emp.titleEn}
                name="titleEn"
                onChange={handleChange}
                disabled={infoViewOnlyMode}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextInputUC
                disabled={infoViewOnlyMode}
                placeholder="First Name (En)"
                value={emp.firstNameEn}
                name="firstNameEn"
                onChange={handleChange}
              />
            </Grid>
          </GridContainer>
        </Grid>
      </GridContainer>
      {/* Last Name (En)*/}
      <GridContainer>
        <Grid item xs={12} sm={5} alignItems="center" container>
          <LabelUC label={t("employee.lblLastNameEn")} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextInputUC
            disabled={infoViewOnlyMode}
            placeholder="Last Name (En)"
            value={emp.lastNameEn}
            name="lastNameEn"
            onChange={handleChange}
          />
        </Grid>
      </GridContainer>
      {/* Nick Name En*/}
      <GridContainer>
        <Grid item xs={12} sm={5} alignItems="center" container>
          <LabelUC label={t("employee.lblNickNameEn")} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextInputUC
            disabled={infoViewOnlyMode}
            placeholder="Nick Name (En)"
            name="nickNameEn"
            value={emp.nickNameEn}
            onChange={handleChange}
          />
        </Grid>
      </GridContainer>
    </>
  );
};

export default NameEn;
