import {
  CREATE_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
  GET_COMPANY_LIST,
  SET_CURRENT_COMPANY,
  SET_COMPANY_ENUM,
  SET_HOLIDAY_YEARLIST,
  SET_COMPANY_HOLIDAY
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  searchText: "",
  viewBy: "",
  companysList: [],
  currentCompany: null,
  companyEnum: [],
  companyHolidayList: [],
  yearList: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_LIST: {
      console.log("reducer");
      return {
        ...state,
        companysList: action.payload
      };
    }

    case SET_CURRENT_COMPANY: {
      return {
        ...state,
        currentCompany: action.payload
        // currentEmpAddress: action.payload ? action.payload.currentEmpAddress : [],
      };
    }

    case SET_HOLIDAY_YEARLIST: {
      return {
        ...state,
        yearList: action.payload
      };
    }

    case SET_COMPANY_HOLIDAY: {
      return {
        ...state,
        companyHolidayList: action.payload
      };
    }

    case CREATE_COMPANY: {
      let updatedList = state.companysList;
      console.log(state);
      updatedList = [action.payload, ...updatedList];
      return {
        ...state,
        companysList: updatedList
      };
    }

    case UPDATE_COMPANY: {
      return {
        ...state,
        companysList: state.companysList.map(item =>
          item.id === action.payload.id ? action.payload : item
        )
      };
    }

    case DELETE_COMPANY: {
      let updatedList = state.companysList;
      updatedList = updatedList.filter(
        company => !action.payload.includes(company.id)
      );
      return {
        ...state,
        companysList: updatedList
      };
    }

    case SET_COMPANY_ENUM: {
      return { ...state, companyEnum: action.payload };
    }

    default:
      return state;
  }
};
