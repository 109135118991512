import GridContainer from "@jumbo/components/GridContainer";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Button,
  Typography
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import AutoCompleteUC from "routes/shared/uc/autocomplete-uc";
import DateTimePickerUC from "routes/shared/uc/datetime-picker-uc";
import LabelUC from "routes/shared/uc/label-uc";
import useStyles from "./index.style";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { getReportRange } from "redux/actions/Report";
import { ENUM_RANGE } from "@jumbo/constants/ActionTypes";

const ReportRange = ({ props }) => {
  const {
    showStartDate = false,
    showEndDate = false,
    showDepartment = false,
    // departmentList,
    showPosition = false,
    // positionList,
    showEmployee = false,
    // employeeList,
    setIsShowReport,
    isShowRange,
    setRangeHeight,
    onShowRange,
    handleButtonShowReport
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const targetRef = useRef();
  const { t, i18n } = useTranslation();
  const isLangEn = i18n.language === "en";
  const { departmentList, positionList, employeeList } = useSelector(
    ({ report }) => report
  );

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [departmentFrom, setDepartmentFrom] = useState("");
  const [departmentTo, setDepartmentTo] = useState("");
  const [employeeFrom, setEmployeeFrom] = useState("");
  const [employeeTo, setEmployeeTo] = useState("");
  const [positionFrom, setPositionFrom] = useState("");
  const [positionTo, setPositionTo] = useState("");
  const [departmentFromBind, setDepartmentFromBind] = useState(null);
  const [departmentToBind, setDepartmentToBind] = useState(null);
  const [positionFromBind, setPositionFromBind] = useState(null);
  const [positionToBind, setPositionToBind] = useState(null);
  const [employeeFromBind, setEmployeeFromBind] = useState(null);
  const [employeeToBind, setEmployeeToBind] = useState(null);

  useEffect(() => {
    // set range for get
    const rangeData = [
      showDepartment ? ENUM_RANGE.department : "",
      showPosition ? ENUM_RANGE.position : "",
      showEmployee ? ENUM_RANGE.employee : ""
    ];
    dispatch(
      getReportRange(rangeData, data => {
        // console.log("getReportRange", data);
      })
    );
  }, [dispatch, showDepartment, showPosition, showEmployee]);

  useEffect(() => {
    if (targetRef.current) {
      setRangeHeight(targetRef.current.offsetHeight);
      // console.log("targetRef", targetRef);
    }
  }, [isShowRange, targetRef, setRangeHeight]);

  const handleShowRange = value => {
    if (targetRef.current) {
      setRangeHeight(targetRef.current.offsetHeight);
      onShowRange(value);
    }
  };

  const departmentDataList = departmentList.map(item => ({
    id: item.id,
    title: item.departmentCode,
    description: isLangEn ? item.departmentNameEn : item.departmentName
  }));

  const positionDataList = positionList.map(item => ({
    id: item.id,
    title: item.positionCode,
    description: isLangEn ? item.positionNameEn : item.positionName
  }));

  const employeeDataList = employeeList.map(item => ({
    id: item.id,
    title: item.empCode,
    description: isLangEn ? item.fullNameEn : item.fullName
  }));

  const handleDateChange = name => value => {
    switch (name) {
      case "startDate":
        setStartDate(value);
        break;
      case "endDate":
        setEndDate(value);
        break;
      default:
        break;
    }
  };

  const handleChangeDepartmentFrom = value => {
    setDepartmentFromBind(value);
    setDepartmentFrom(value.title);
  };
  const handleChangeDepartmentTo = value => {
    setDepartmentToBind(value);
    setDepartmentTo(value.title);
  };
  const handleChangePositionFrom = value => {
    setPositionFromBind(value);
    setPositionFrom(value.title);
  };
  const handleChangePositionTo = value => {
    setPositionToBind(value);
    setPositionTo(value.title);
  };
  const handleChangeEmployeeFrom = value => {
    setEmployeeFromBind(value);
    setEmployeeFrom(value.title);
  };
  const handleChangeEmployeeTo = value => {
    setEmployeeToBind(value);
    setEmployeeTo(value.title);
  };
  const handleShowReport = () => {
    setIsShowReport(true);
    handleShowRange(false);
    handleButtonShowReport({
      startDate,
      endDate,
      departmentFrom,
      departmentTo,
      positionFrom,
      positionTo,
      employeeFrom,
      employeeTo
    });
  };

  return (
    <>
      <Accordion
        expanded={isShowRange}
        className={classes.accordion}
        ref={targetRef}
        // onClick={() => handleShowRange(!isShowRange)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => handleShowRange(!isShowRange)}
        >
          <Typography className={classes.textRange}>
            {isShowRange ? "" : "Range"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.reportRange}>
            {(showStartDate || showEndDate) && (
              <GridContainer>
                {showStartDate && (
                  <>
                    <Grid item xs={5} sm={2} alignItems="center" container>
                      <LabelUC label={t("report.lblStartDate")} />
                    </Grid>
                    <Grid item xs={7} sm={3}>
                      <DateTimePickerUC
                        name="startDate"
                        value={startDate}
                        handleDateChange={handleDateChange}
                      />
                    </Grid>
                  </>
                )}
                {showEndDate && (
                  <>
                    <Grid item xs={5} sm={1} alignItems="center" container>
                      <LabelUC
                        label={t("report.lblTo")}
                        showColon={false}
                        justifyContent={{ xs: "start", sm: "center" }}
                      />
                    </Grid>
                    <Grid item xs={7} sm={3}>
                      <DateTimePickerUC
                        name="endDate"
                        value={endDate}
                        handleDateChange={handleDateChange}
                      />
                    </Grid>
                  </>
                )}
              </GridContainer>
            )}
            {showDepartment && (
              <GridContainer>
                <Grid item xs={5} sm={2} alignItems="center" container>
                  <LabelUC label={t("report.lblDepartment")} />
                </Grid>
                <Grid item xs={7} sm={3}>
                  <AutoCompleteUC
                    datas={departmentDataList}
                    isMultipleLine={true}
                    name="departmentFrom"
                    value={departmentFromBind}
                    handleChange={handleChangeDepartmentFrom}
                    placeholder={t("report.lblDepartment")}
                  />
                </Grid>
                <Grid item xs={5} sm={1} alignItems="center" container>
                  <LabelUC
                    label={t("report.lblTo")}
                    showColon={false}
                    justifyContent={{ xs: "start", sm: "center" }}
                  />
                </Grid>
                <Grid item xs={7} sm={3}>
                  <AutoCompleteUC
                    datas={departmentDataList}
                    isMultipleLine={true}
                    name="departmentTo"
                    value={departmentToBind}
                    handleChange={handleChangeDepartmentTo}
                    placeholder={t("report.lblDepartment")}
                  />
                </Grid>
              </GridContainer>
            )}
            {showPosition && (
              <GridContainer>
                <Grid item xs={5} sm={2} alignItems="center" container>
                  <LabelUC label={t("report.lblPosition")} />
                </Grid>
                <Grid item xs={7} sm={3}>
                  <AutoCompleteUC
                    datas={positionDataList}
                    isMultipleLine={true}
                    name="positionFrom"
                    value={positionFromBind}
                    handleChange={handleChangePositionFrom}
                    placeholder={t("report.lblPosition")}
                  />
                </Grid>
                <Grid item xs={5} sm={1} alignItems="center" container>
                  <LabelUC
                    label={t("report.lblTo")}
                    showColon={false}
                    justifyContent={{ xs: "start", sm: "center" }}
                  />
                </Grid>
                <Grid item xs={7} sm={3}>
                  <AutoCompleteUC
                    datas={positionDataList}
                    isMultipleLine={true}
                    name="positionTo"
                    value={positionToBind}
                    handleChange={handleChangePositionTo}
                    placeholder={t("report.lblPosition")}
                  />
                </Grid>
              </GridContainer>
            )}
            {showEmployee && (
              <GridContainer>
                <Grid item xs={5} sm={2} alignItems="center" container>
                  <LabelUC label={t("report.lblEmployee")} />
                </Grid>
                <Grid item xs={7} sm={3}>
                  <AutoCompleteUC
                    datas={employeeDataList}
                    isMultipleLine={true}
                    name="employeeFrom"
                    value={employeeFromBind}
                    handleChange={handleChangeEmployeeFrom}
                    placeholder={t("report.lblEmployee")}
                  />
                </Grid>
                <Grid item xs={5} sm={1} alignItems="center" container>
                  <LabelUC
                    label={t("report.lblTo")}
                    showColon={false}
                    justifyContent={{ xs: "start", sm: "center" }}
                  />
                </Grid>
                <Grid item xs={7} sm={3}>
                  <AutoCompleteUC
                    datas={employeeDataList}
                    isMultipleLine={true}
                    name="employeeTo"
                    value={employeeToBind}
                    handleChange={handleChangeEmployeeTo}
                    placeholder={t("report.lblEmployee")}
                  />
                </Grid>
              </GridContainer>
            )}
            <GridContainer>
              <Grid item xs={12} sm={3}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btnShow}
                  onClick={handleShowReport}
                >
                  Show
                </Button>
              </Grid>
            </GridContainer>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ReportRange;
