import {
  CREATE_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  GET_LOCATION_LIST,
  SET_CURRENT_LOCATION,
  SET_ALL_EMP_LOCATION
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  searchText: "",
  viewBy: "",
  locationsList: [],
  currentLocation: null,
  allEmpLocation: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOCATION_LIST: {
      // console.log('reducer')
      return {
        ...state,
        locationsList: action.payload
      };
    }

    case SET_CURRENT_LOCATION: {
      return {
        ...state,
        currentLocation: action.payload
      };
    }

    case SET_ALL_EMP_LOCATION: {
      return {
        ...state,
        allEmpLocation: action.payload
      };
    }

    case CREATE_LOCATION: {
      let updatedList = state.locationsList;
      updatedList = [action.payload, ...updatedList];
      console.log("CREATE_LOCATION", updatedList);
      return {
        ...state,
        locationsList: updatedList
      };
    }

    case UPDATE_LOCATION: {
      return {
        ...state,
        locationsList: state.locationsList.map(item =>
          item.id === action.payload.id ? action.payload : item
        )
      };
    }

    case DELETE_LOCATION: {
      let updatedList = state.locationsList;
      updatedList = updatedList.filter(
        location => !action.payload.includes(location.id)
      );
      return {
        ...state,
        locationsList: updatedList
      };
    }

    default:
      return state;
  }
};
