import { useDispatch } from "react-redux";
import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { onDelete } from "redux/actions/Default";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { setInfoViewOnlyMode } from "redux/actions";

const useStyles = makeStyles(theme => ({
  menuItemsRoot: {
    width: 200,
    fontSize: 14,
    "&:hover": {
      backgroundColor: "transparent"
    },
    "& .MuiTouchRipple-root": {
      display: "none"
    },
    "& .MuiSvgIcon-root": {
      fontSize: 18
    }
  },
  iconBlock: {
    display: "block"
  },
  titleLabelsRoot: {
    fontSize: 10,
    letterSpacing: 1.5,
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    padding: "16px 16px 8px"
  }
}));

const MoreOptions = ({ employee, onEdit }) => {
  const classes = useStyles();
  const [showMoreOptions, setShowMoreOptions] = useState(null);
  const dispatch = useDispatch();

  const onShowMoreOptions = event => {
    setShowMoreOptions(event.currentTarget);
  };

  const onHideMoreOptions = () => {
    setShowMoreOptions(null);
  };

  const onClickDeleteOption = () => {
    dispatch(onDelete([employee.id]));
    onHideMoreOptions();
  };

  const onClickEditOption = () => {
    dispatch(setInfoViewOnlyMode(false));
    onEdit(employee.id);
    onHideMoreOptions();
  };

  return (
    <React.Fragment>
      <Box ml={1}>
        <Tooltip title="More Options">
          <IconButton size="small" onClick={onShowMoreOptions}>
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={showMoreOptions}
        open={Boolean(showMoreOptions)}
        onClose={onHideMoreOptions}
      >
        <MenuItem onClick={onClickEditOption} className={classes.menuItemsRoot}>
          <EditIcon />
          <Box component="span" ml={4}>
            Edit
          </Box>
        </MenuItem>
        <MenuItem
          onClick={onClickDeleteOption}
          className={classes.menuItemsRoot}
        >
          <DeleteIcon />
          <Box component="span" ml={4}>
            Delete
          </Box>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default MoreOptions;
