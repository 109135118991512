import { Box } from "@mui/material";
import React from "react";
// import { useTranslation } from "react-i18next";
// import textEn from "./textEn";
import textTh from "./textTh";

export default function PrivacyContent() {
  // const { i18n } = useTranslation();
  return (
    <Box sx={{ display: "block" }}>
      {textTh()}
    </Box>
  );
}
