import AppCheckbox from "@jumbo/components/Common/formElements/AppCheckBox";
import GridContainer from "@jumbo/components/GridContainer";
import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import TextInputUC from "routes/shared/uc/text-input-uc";
import LabelPoint from "./label-point";

const TabTimeNotAttendance = ({ props }) => {
  const {
    infoViewOnlyMode,
    pointTime,
    setPointTime,
    handleChangeCheckbox
  } = props;
  const { t } = useTranslation();

  const handleChangeNotAtt = value => {
    setPointTime({ ...pointTime, notAttendancePoint: value });
  }

  return (
    <>
      <Box xs={12} sm={6} p={2} mt={3}>
        <Typography variant="h6">{t("point.lblNotAttendance")}</Typography>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />
      <Box xs={12} sm={6} flex>
        <GridContainer justifyContent="center">
          <Grid item xs={12} sm={3} alignItems="center" container>
            <AppCheckbox
              label={t("point.lblNotAttendance")}
              name="isNotAttendance"
              onChange={handleChangeCheckbox}
              disabled={infoViewOnlyMode}
              checked={pointTime.isNotAttendance}
            />
          </Grid>
          <Grid item xs={9} sm={3}>
            <TextInputUC
              type="number"
              disabled={infoViewOnlyMode || !pointTime.isNotAttendance}
              placeholder={t("point.lblNotAttendance")}
              value={pointTime.notAttendancePoint}
              name="notAttendancePoint"
              onChange={(e) => handleChangeNotAtt(e.target.value.substring(0, 6))}
            />
          </Grid>
          <LabelPoint />
        </GridContainer>
      </Box>
    </>
  );
};

export default TabTimeNotAttendance;
