import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SET_INFO_VIEWONLY_MODE,
  SET_ONADD_CLICK,
  SET_OPEN_INFO,
  SET_DATA_MODIFIED,
  SET_MENU_NAME,
  DELETE_LIST,
  SET_NOTIFICATION
} from "../../@jumbo/constants/ActionTypes";

export const fetchSuccess = message => {
  return dispatch => {
    dispatch({
      type: FETCH_SUCCESS,
      payload: message || ""
    });
  };
};
export const fetchError = error => {
  return dispatch => {
    dispatch({
      type: FETCH_ERROR,
      payload: error
    });
  };
};

export const fetchStart = () => {
  return dispatch => {
    dispatch({
      type: FETCH_START
    });
  };
};

//? true: view only mode, false: edit mode
export const setInfoViewOnlyMode = viewOnly => {
  return {
    type: SET_INFO_VIEWONLY_MODE,
    payload: viewOnly
  };
};

//? true = กดปุ่ม Add , false = กดปุ่ม Edit
export const setIsOnAdd = isOnAddClick => {
  return {
    type: SET_ONADD_CLICK,
    payload: isOnAddClick
  };
};

//? เปิดหน้าจอ Info อยู่หรือไม่ ?
export const setIsOpeningInfo = isOpen => {
  return {
    type: SET_OPEN_INFO,
    payload: isOpen
  };
};

//? ข้อมูลที่มีการแก้ไขหรือไม่ ?
export const setIsDataModified = isModified => {
  return {
    type: SET_DATA_MODIFIED,
    payload: isModified
  };
};

//? Current MenuName
export const setMenuName = menuName => {
  return {
    type: SET_MENU_NAME,
    payload: menuName
  };
};

//! DELETE LIST SUCCESS WILL SET TRUE
export const deleteList = isDelete => {
  return {
    type: DELETE_LIST,
    payload: isDelete
  };
};

export const setNotification = noti => {
  return {
    type: SET_NOTIFICATION,
    payload: noti
  };
};
