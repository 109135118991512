import ResetPassword from "@jumbo/components/Common/authComponents/ResetPassword";
import React from "react";
import { useParams } from "react-router";

const ResetPasswordPage = () => {
  const { id } = useParams();
  return <ResetPassword variant="standard" wrapperVariant="bgColor" id={id} />;
};

export default ResetPasswordPage;
