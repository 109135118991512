import {
  GET_REPORT_RANGE,
  GET_CLOCKINOUT_REPORT
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  departmentList: [],
  positionList: [],
  employeeList: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORT_RANGE: {
      return {
        ...state,
        departmentList: action.payload.department,
        positionList: action.payload.position,
        employeeList: action.payload.employee
      };
    }

    case GET_CLOCKINOUT_REPORT: {
      return state;
    }

    default:
      return state;
  }
};
