import {
    CREATE_CUSTOMER,
    UPDATE_CUSTOMER,
    DELETE_CUSTOMER,
    GET_CUSTOMER_LIST,
    SET_CURRENT_CUSTOMER
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    searchText: '',
    viewBy: '',
    customersList: [],
    currentCustomer: null
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CUSTOMER_LIST: {
            console.log('reducer customer')
            return {
                ...state,
                customersList: action.payload,
            };
        }

        case SET_CURRENT_CUSTOMER: {
            return {
                ...state,
                currentCustomer: action.payload,
            };
        }

        case CREATE_CUSTOMER: {
            let updatedList = state.customersList;
            console.log(state)
            updatedList = [action.payload, ...updatedList];
            return {
                ...state,
                customersList: updatedList
            };
        }

        case UPDATE_CUSTOMER: {
            return {
                ...state,
                customersList: state.customersList.map(item => (item.id === action.payload.id ? action.payload : item)),
            };
        }

        case DELETE_CUSTOMER: {
            let updatedList = state.customersList;
            updatedList = updatedList.filter(customer => !action.payload.includes(customer.id));
            return {
                ...state,
                customersList: updatedList
            };
        }

        default:
            return state;
    }
};