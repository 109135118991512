import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SET_INFO_VIEWONLY_MODE,
  SET_ONADD_CLICK,
  SET_DATA_MODIFIED,
  SET_OPEN_INFO,
  SET_MENU_NAME,
  DELETE_LIST,
  SET_NOTIFICATION
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  initialURL: "/",
  error: "",
  message: "",
  loading: false,
  infoViewOnlyMode: true,
  isOnAdd: false,
  isOpeningInfo: false,
  isDataModified: false,
  menuName: "",
  isDeleteList: false,
  notificationList: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: "", message: "", loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: "", loading: false, message: action.payload };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, message: "", error: action.payload };
    }
    case SET_INFO_VIEWONLY_MODE: {
      return {
        ...state,
        infoViewOnlyMode: action.payload
      };
    }
    case SET_ONADD_CLICK: {
      return {
        ...state,
        isOnAdd: action.payload
      };
    }
    case SET_OPEN_INFO: {
      return {
        ...state,
        isOpeningInfo: action.payload
      };
    }
    case SET_DATA_MODIFIED: {
      return {
        ...state,
        isDataModified: action.payload
      };
    }
    case SET_MENU_NAME: {
      return {
        ...state,
        menuName: action.payload
      };
    }
    case DELETE_LIST: {
      return {
        ...state,
        isDeleteList: action.payload
      };
    }
    case SET_NOTIFICATION: {
      return {
        ...state,
        notificationList: action.payload
      };
    }
    default:
      return state;
  }
};
