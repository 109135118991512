import { fetchStart, fetchSuccess } from './Common';
// import axios from 'axios';
import {
    SET_MENU,
    GET_MENU
} from '../../@jumbo/constants/ActionTypes';

export const setCurrentMenu = menu => {
    return dispatch => {
        // dispatch(fetchStart());
        dispatch({ type: SET_MENU, payload: menu });
        // dispatch(fetchSuccess());
    };
};

export const getCurrentMenu = () => {
    return dispatch => {
        dispatch(fetchStart());
        dispatch({ type: GET_MENU, payload: null });
        dispatch(fetchSuccess());
    };
};