import React, { useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography, Box } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import CmtSearch from "@coremat/CmtSearch";
import useStyles from "./ListUC.style";
import ListIcon from "@material-ui/icons/List";
// import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import GridOnIcon from "@material-ui/icons/GridOn";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Checkbox, Chip, Menu, MenuItem } from "@mui/material";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useMediaQuery } from "react-responsive";

// const viewBys = [
//   { id: "all", captionEn: "All", caption: "ทั้งหมด" },
//   { id: "active", captionEn: "Active", caption: "ที่ใช้งาน" },
//   { id: "inactive", captionEn: "In Active", caption: "ที่ไม่ใช้งาน" }
// ];

const filterOptionsList = [
  { label: "Active", value: "active" },
  { label: "In Active", value: "inactive" },
  { label: "Suspended", value: "suspended" }
];

const ListUCToolbar = ({
  isShowAdd = true,
  selected,
  setSelected,
  handleDeleteSeleted,
  onChangeViewMode,
  viewMode,
  onAdd,
  // viewBy,
  // setViewBy,
  searchText,
  setSearchText,
  filterOptions,
  setFilterOptions
  // filterOptionsList,
  // viewByList
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { menuName } = useSelector(({ common }) => common);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeleteCLick = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    handleDeleteSeleted(selected);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const onFilterOptionClick = option => {
    setFilterOptions(prevState => {
      if (prevState.includes(option.value)) {
        return prevState.filter(item => item !== option.value);
      } else {
        return [...prevState, option.value];
      }
    });
  };

  const onChipDelete = option => {
    setFilterOptions(filterOptions.filter(item => item !== option.value));
  };

  const onSearchChipDelete = () => setSearchText("");

  const numSelected = selected.length;
  const mName = menuName.split(".").length > 1 ? menuName.split(".")[2] : "";
  const isShowGrid = "employee".includes(mName);
  return (
    <React.Fragment>
      <Toolbar className={classes.topBar}>
        <Typography variant="h6" component="div">
          {t(menuName)}
        </Typography>
        {isShowAdd && (
          <Tooltip title="Add">
            <IconButton aria-label="add" onClick={onAdd}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={onDeleteCLick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {isShowGrid && (
          <Box display="flex" alignItems="center">
            {viewMode === "grid" && (
              <Box ml={1}>
                <IconButton
                  className="icon-btn active"
                  color={viewMode === "table" ? "primary" : "default"}
                  onClick={() => onChangeViewMode("table")}
                >
                  <ListIcon />
                </IconButton>
              </Box>
            )}
            {viewMode === "table" && (
              <Box ml={1}>
                <IconButton
                  className="icon-btn"
                  color={viewMode === "grid" ? "primary" : "default"}
                  onClick={() => onChangeViewMode("grid")}
                >
                  <GridOnIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        )}
      </Toolbar>
      <Toolbar className={classes.root}>
        {/* <Typography className={classes.title} variant="h3" id="tableTitle" component="div">
                    {t('listuc.search')} :
                </Typography> */}
        <CmtSearch
          className={classes.searchBar}
          onChange={e => setSearchText(e.target.value)}
          border={false}
          value={searchText}
        />
        <div className={classes.chipsRoot}>
          {searchText && !isMobile && (
            <Chip label={searchText} onDelete={onSearchChipDelete} />
          )}
          {!isMobile &&
            filterOptionsList.map(
              (option, index) =>
                filterOptions.includes(option.value) && (
                  <Chip
                    key={index}
                    label={option.label}
                    onDelete={() => onChipDelete(option)}
                  />
                )
            )}
        </div>
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list" onClick={handleClick}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <Menu
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {filterOptionsList.map((option, index) => (
            <MenuItem key={index} onClick={() => onFilterOptionClick(option)}>
              <Checkbox
                checked={filterOptions.includes(option.value)}
                inputProps={{ "aria-labelledby": option.label }}
              />
              {option.label}
            </MenuItem>
          ))}
        </Menu>
        {/* <Typography variant="h3">{t("listuc.viewBy")} : </Typography>
        <AppSelectBox
          className={classes.viewBy}
          label=""
          variant="standard"
          data={viewByList || viewBys}
          value={viewBy}
          fullWidth={false}
          onChange={event => setViewBy(event.target.value)}
        /> */}
      </Toolbar>

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={"Are you sure, you want to  delete selected?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </React.Fragment>
  );
};

ListUCToolbar.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  handleDeleteSeleted: PropTypes.func,
  onChangeViewMode: PropTypes.func,
  viewMode: PropTypes.string,
  onAdd: PropTypes.func,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  // viewBy: PropTypes.string,
  // title: PropTypes.string,
  setViewBy: PropTypes.func,
  viewByList: PropTypes.array
};

export default React.memo(ListUCToolbar);
