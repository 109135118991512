import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import Common from "./Common";
import Auth from "./Auth";
import Users from "./Users";
import Menu from "./Menu";
import Employee from "./Employee";
import Company from "./Company";
import Register from "./Register";
import Department from "./Department";
import Position from "./Position";
import Shift from "./Shift";
import Location from "./Location";
import TimeStamp from "./TimeStamp";
import Point from "./Point";
import Report from "./Report";
import Customer from "./Customer";
import Liff from "./Liff";

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    users: Users,
    employees: Employee,
    menu: Menu,
    companys: Company,
    departments: Department,
    positions: Position,
    register: Register,
    shifts: Shift,
    locations: Location,
    timeStamp: TimeStamp,
    point: Point,
    report: Report,
    customers: Customer,
    Liff:Liff
  });
