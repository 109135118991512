import { Box, Typography } from "@mui/material";
import React from "react";
import useStyles from "./index.style";

const ReportHeader = ({ reportName }) => {
  const classes = useStyles();
  return (
    <Box className={classes.reportHeader}>
      <Typography className={classes.headerTitle}>{reportName}</Typography>
    </Box>
  );
};

export default ReportHeader;
