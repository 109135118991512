import React, { useEffect } from "react";
import {
  Box,
  IconButton,
  makeStyles,
  Popover,
  Tooltip,
  useTheme
} from "@material-ui/core";
// import { alpha } from '@material-ui/core/styles';
import NotificationsIcon from "@material-ui/icons/Notifications";
import CmtCardHeader from "../../../../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../../../../@coremat/CmtCard/CmtCardContent";
import CmtList from "../../../../../../@coremat/CmtList";
import CmtCard from "../../../../../../@coremat/CmtCard";

import NotificationItem from "./NotificationItem";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
// import { getCustomDateTime } from '@jumbo/utils/dateHelper';
// import { setNotification } from 'redux/actions';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import {
  getStorageNotification,
  setStorageNotification
} from "services/Storage.service";
import { onUpdateNotification } from "redux/actions/Default";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    "& .Cmt-header-root": {
      paddingTop: 4,
      paddingBottom: 4
    },
    "& .Cmt-card-content": {
      padding: "0 0 16px !important"
    }
  },
  typography: {
    padding: theme.spacing(2)
  },
  iconRoot: {
    position: "relative",
    color: theme.mainColor, //alpha(theme.palette.common.black, 0.54),
    "&:hover, &.active": {
      color: theme.mainColor //theme.palette.common.white,
    }
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.warning.main,
    width: 20
  },
  scrollbarRoot: {
    height: 300,
    padding: 16
  },
  popoverRoot: {
    "& .MuiPopover-paper": {
      width: 375
    }
  }
}));

const actions = [
  {
    label: "More Detail"
  },
  {
    label: "Close"
  }
];

// const headerNotifications = [
//   {
//     id: 22,
//     user: {
//       id: 101,
//       name: 'Tanatchanok daengmoon',
//       profile_pic: 'https://via.placeholder.com/150x150',
//     },
//     type: 'sender',
//     metaData: {
//       points: 10000,
//     },
//     createdAt: getCustomDateTime(-27, 'minutes', 'MMMM DD, YYYY, h:mm:ss a'),
//   },
//   {
//     id: 92,
//     user: {
//       id: 101,
//       name: 'Jeeraporn thammajai line service',
//       profile_pic: 'https://via.placeholder.com/150x150',
//     },
//     type: 'receiver',
//     metaData: {
//       points: -999,
//     },
//     createdAt: getCustomDateTime(-20, 'minutes', 'MMMM DD, YYYY, h:mm:ss a'),
//   },
//   {
//     id: 4,
//     user: {
//       id: 105,
//       name: 'Sara John',
//       profile_pic: 'https://via.placeholder.com/150x150',
//     },
//     type: 'BIRTHDAY',
//     createdAt: getCustomDateTime(-150, 'minutes', 'MMMM DD, YYYY, h:mm:ss a'),
//   },
//   // {
//   //   id: 2,
//   //   user: {
//   //     id: 101,
//   //     name: 'Dinesh Suthar',
//   //     profile_pic: 'https://via.placeholder.com/150x150',
//   //   },
//   //   type: 'POSTING',
//   //   metaData: {
//   //     post: {
//   //       type: 'album',
//   //       title: 'This is Beginning',
//   //       owner: {
//   //         id: 545,
//   //         name: 'Martin Guptil',
//   //         profile_pic: 'https://via.placeholder.com/150x150',
//   //       },
//   //     },
//   //   },
//   //   createdAt: getCustomDateTime(-27, 'minutes', 'MMMM DD, YYYY, h:mm:ss a'),
//   // },
//   // {
//   //   id: 3,
//   //   user: {
//   //     id: 102,
//   //     name: 'John Doe',
//   //     profile_pic: 'https://via.placeholder.com/150x150',
//   //   },
//   //   type: 'SHARED_POST',
//   //   metaData: {
//   //     post: {
//   //       title: 'This is Beginning',
//   //       owner: {
//   //         id: 545,
//   //         name: 'Martin Guptil',
//   //         profile_pic: 'https://via.placeholder.com/150x150',
//   //       },
//   //     },
//   //   },
//   //   createdAt: getCustomDateTime(-50, 'minutes', 'MMMM DD, YYYY, h:mm:ss a'),
//   // },
//   // {
//   //   id: 1,
//   //   user: {
//   //     id: 100,
//   //     name: 'Atul Midha',
//   //     profile_pic: 'https://via.placeholder.com/150x150',
//   //   },
//   //   type: 'INVITATION',
//   //   metaData: {
//   //     user: {
//   //       id: 105,
//   //       name: 'North Taylor',
//   //       profile_pic: 'https://via.placeholder.com/150x150',
//   //     },
//   //     group: { id: 12, name: 'Global Health' },
//   //   },
//   //   date: getCustomDateTime(-90, 'minutes', 'MMMM DD, YYYY, h:mm:ss a'),
//   // },

// ];

const HeaderNotifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { notificationList } = useSelector(({ common }) => common);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [counter, setCounter] = React.useState(0);
  const [notification, setNotification] = React.useState([]);
  const theme = useTheme();

  useEffect(() => {
    const _noti = getStorageNotification();
    // console.log("_noti", _noti);
    if (_noti && _noti.length > 0) {
      setCounter(_noti.filter(item => !item.isRead).length);
      _noti.map(item => {
        item.user = {
          id: item.type === "sender" ? item.fromEmpId : item.toEmpId,
          name:
            item.type === "sender"
              ? i18n.language === "th"
                ? item.fullNameTo
                : item.fullNameToEn
              : i18n.language === "th"
                ? item.fullNameFrom
                : item.fullNameFromEn,
          profile_pic:
            item.type === "sender"
              ? "/images/point/givePoint.png"
              : "/images/point/giftbox.png"
        };
        item.metaData = {
          points: item.value
        };
        item.date = format(new Date(item.createdDate), "dd/MM/yyyy HH:mm:ss");
        return item;
      });
    }
    setNotification(_noti);
    setStorageNotification(_noti);
  }, [i18n.language, notificationList]);

  // console.log('notification', notification);
  const onOpenPopOver = event => {
    setAnchorEl(event.currentTarget);
    setCounter(0);
    if (notification && notification.length > 0) {
      // console.log("set Noti", notification);
      const _noti = notification.map(item => ({ ...item, isRead: true }));
      setNotification(_noti);
      setStorageNotification(_noti);
      if (notification.some(item => !item.isRead)) {
        dispatch(onUpdateNotification(_noti));
      }
    }
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box pr={2}>
      <Tooltip title="Notifications">
        <IconButton
          onClick={onOpenPopOver}
          className={clsx(classes.iconRoot, "Cmt-appIcon", {
            active: counter > 0
          })}
        >
          <Badge overlap="rectangular"
            badgeContent={counter}
            classes={{ badge: classes.counterRoot }}
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="Notifications"
            actionsPos="top-corner"
            actions={actions}
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: "solid"
            }}
          />
          <CmtCardContent>
            {notification && notification.length > 0 ? (
              <PerfectScrollbar className={classes.scrollbarRoot}>
                <CmtList
                  data={notification}
                  renderRow={(item, index) => (
                    <NotificationItem key={index} item={item} />
                  )}
                />
              </PerfectScrollbar>
            ) : (
              <Box p={6}>
                <Typography variant="body2">No notifications found</Typography>
              </Box>
            )}
          </CmtCardContent>
        </CmtCard>
      </Popover>
    </Box>
  );
};

export default HeaderNotifications;
