import GridContainer from "@jumbo/components/GridContainer";
import { Box, Grid, Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import LabelUC from "routes/shared/uc/label-uc";
import TextInputUC from "routes/shared/uc/text-input-uc";
import useStyles from "./index.style";
import LabelPoint from "./label-point";

const TabGeneral = ({ props }) => {
  const { infoViewOnlyMode,
    point,
    handleChangeStartPoint,
    handleChangeBirthPoint } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.paper}>
      <Box
        sx={{ display: "flex", width: "100%" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <form className={classes.form}>
          <Box xs={12} sm={6} p={2} flex>
            <GridContainer justifyContent="center">
              <Grid item xs={12} sm={3} alignItems="center" container>
                <LabelUC label={t("point.lblStartPoint")} />
              </Grid>
              <Grid item xs={9} sm={3}>
                <TextInputUC
                  type="number"
                  disabled={infoViewOnlyMode}
                  placeholder={t("point.lblStartPoint")}
                  value={point.startPoint}
                  name="startPoint"
                  onChange={(e) => handleChangeStartPoint(e.target.value.substring(0, 5))}
                />
              </Grid>
              <LabelPoint />
            </GridContainer>
          </Box>
          <Box xs={12} sm={6} p={2} flex>
            <GridContainer justifyContent="center">
              <Grid item xs={12} sm={3} alignItems="center" container>
                <LabelUC label={t("point.lblBirthDayPoint")} />
              </Grid>
              <Grid item xs={9} sm={3}>
                <TextInputUC
                  type="number"
                  disabled={infoViewOnlyMode}
                  placeholder={t("point.lblBirthDayPoint")}
                  value={point.birthDayPoint}
                  name="birthDayPoint"
                  onChange={(e) => handleChangeBirthPoint(e.target.value.substring(0, 5))}
                />
              </Grid>
              <LabelPoint />
            </GridContainer>
          </Box>
        </form>
      </Box>
    </Paper>
  );
};

export default TabGeneral;
