import { Box } from "@mui/material";
import React from "react";
import textTh from "./textTh";

export default function termsContent() {
  // const { i18n } = useTranslation();
  return (
    <Box sx={{ display: "block" }}>
      {textTh()}
    </Box>
  );
}