import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import { Alert, Avatar, Box, Paper, Snackbar, Typography } from "@mui/material";
import React, { useRef, useState, useCallback, useEffect } from "react";
import Clock from "react-live-clock";
import useStyles from "./index.style";
import { fetchError, fetchStart, fetchSuccess } from "redux/actions";
import { useDispatch } from "react-redux";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import calcCrow from "./calcCrow";
import ReactCanvasConfetti from "react-canvas-confetti";
import {
  getStorageAuthenUser,
  setStorageControllerName
} from "services/Storage.service";
import { getById, onCreate, onUpdate } from "redux/actions/Default";
import { numberToTime, resizeFile } from "services/default.service";
import TimesStamp from "./timesStamp";
import ClockButton from "./clockButton";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import "react-html5-camera-photo/build/css/index.css";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";

const TimeStamp = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openCamera, setOpenCamera] = useState(false);
  const refAnimationInstance = useRef(null);
  const [intervalId, setIntervalId] = useState();
  const [time, setTime] = useState();
  const [timeImage, setTimeImage] = useState();
  const [shift, setShift] = useState({});
  const [shiftDetail, setShiftDetail] = useState({});
  const [setTimeStamp, setSetTimeStamp] = useState({});
  // const [setTimeStampDt, setSetTimeStampDt] = useState({});
  const [empAddress, setEmpAddress] = useState({});
  const [companyAddress, setCompanyAddress] = useState({});
  const [location, setLocation] = useState({});
  const [allLocation, setAllLocation] = useState([]);
  const [shiftDescription, setShiftDescription] = useState("");
  const [nextStamp, setNextStamp] = useState(false);
  const userAuthen = getStorageAuthenUser();
  const [counter, setCounter] = useState(0);
  const [yourLocation, setYourLocation] = useState({});
  const [pointTime, setPointTime] = useState({});
  const [alertPoint, setAlertPoint] = useState(false);
  const [isNormal, setIsNormal] = useState(true);
  const [pointShow, setPointShow] = useState(0);
  const [textAlertPoint, setTextAlertPoint] = useState("");
  const [companyHoliday, setCompanyHoliday] = useState(null);

  const { t } = useTranslation();

  const inputEl = useRef(null);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
      setNextStamp(false);
    }

    if (
      counter === 0 &&
      shift.id &&
      ((shiftDetail.timesStamp === 2 && !time.out1) ||
        (shiftDetail.timesStamp === 4 && !time.out2))
    ) {
      setNextStamp(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  useEffect(() => {
    setStorageControllerName("timeStamp");
    dispatch(
      getById(userAuthen.data.empId, userAuthen.data.companyId, data => {
        // console.log('getById', data);
        if (data) {
          setDataAfterGetById(data);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const setDataAfterGetById = data => {
    // ข้อมูลกะงาน
    setShift(data.shift);
    setShiftDetail(data.shiftDetail);
    // วันนี้เป็นวันหยุดหรือไม่
    setCompanyHoliday(data.companyHoliday);
    // ตั้งค่าการลงเวลา
    setSetTimeStamp(
      data.setTimeStamp && data.setTimeStamp.isAllowTimeStamp
        ? data.setTimeStamp
        : {}
    );
    // setSetTimeStampDt(data.setTimeStampDt && data.setTimeStamp.isAllowTimeStamp ? data.setTimeStampDt : {});
    // สถานที่ลงเวลาของพนักงาน
    setLocation(data.location);
    // สถานที่ลงเวลาทั้งหมด
    setAllLocation(data.allLocation);
    // บริษัทที่ทำงาน
    setCompanyAddress(data.companyAddress);
    // ลงเวลาที่อยู่ปัจจุบันพนักงาน
    setEmpAddress(data.empAddress);
    // ข้อมูลการลงเวลา
    setTime(data.timeStamp);
    setTimeImage(data.timeStampImage);
    // ข้อมูลการเพิ่ม/หักตะแนน
    setPointTime(data.pointTime);

    if (data.shift && data.shiftDetail) {
      setShiftDescription(
        data.shift.shiftName +
          " / " +
          numberToTime(data.shiftDetail.in1, ":") +
          " - " +
          numberToTime(
            data.shiftDetail.timesStamp === 2
              ? data.shiftDetail.out1
              : data.shiftDetail.out2,
            ":"
          )
      );
    }

    setNextStamp(true);

    if (data.timeStamp && data.shift && data.shiftDetail) {
      // ลงเวลาครบแล้ว
      // console.log('shiftDetail', data.shiftDetail);
      if (
        (data.shiftDetail.timesStamp === 2 && data.timeStamp.out1) ||
        (data.shiftDetail.timesStamp === 4 && data.timeStamp.out2)
      ) {
        // console.log("setNextStamp");
        setNextStamp(false);
      } else {
        const _latestStamp = data.timeStamp.out2
          ? data.timeStamp.out2
          : data.timeStamp.out1
          ? data.timeStamp.out1
          : data.timeStamp.in2
          ? data.timeStamp.in2
          : data.timeStamp.in1;
        const diff =
          30 - (new Date().getTime() - new Date(_latestStamp).getTime()) / 1000;
        // console.log("diff", diff);
        if (diff > 0) {
          setCounter(parseInt(diff));
          setNextStamp(false);
        }
      }
    }
  };

  const onStamp = () => {
    // setOpen(true);
    dispatch(fetchStart());
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude, timestamp } = position.coords;
        if (validateTimeStamp(latitude, longitude)) {
          const timeOnStamp = {
            lat: latitude,
            lng: longitude,
            timeStame: timestamp,
            date: new Date()
          };
          setYourLocation(timeOnStamp);
          dispatch(fetchSuccess());
          if (setTimeStamp && setTimeStamp.isPhoto) {
            setOpenCamera(true);
            console.log("inputEl.current.click()", inputEl.current);
            // inputEl.current.click();
            // handleTakePhoto(inputEl.current.takePhoto());
          } else {
            onStampTime(null, null, timeOnStamp);
          }
        }
      });
    } else {
      dispatch(fetchError("Geolocation is not supported by this browser."));
    }
  };

  const validateTimeStamp = (latitude, longitude) => {
    let success = false;
    if (setTimeStamp) {
      let distance = 0;
      // ตรวจสอบ option สถานที่ลงเวลาของพนักงาน
      if (setTimeStamp.isEmpLocation && location) {
        const chkLocation = { lat: location.latitude, lng: location.longitude };
        distance = calcCrow(chkLocation, {
          lat: latitude,
          lng: longitude
        });
        success = true;
        if (distance > location.radius) {
          success = false;
          console.log("พนักงาน");
        }
      }
      // ตรวจสอบ option สถานที่ลงเวลาอื่น ๆ
      if (setTimeStamp.isOtherLocation && allLocation && !success) {
        allLocation.forEach(item => {
          const chkLocation = { lat: item.latitude, lng: item.longitude };
          const distance = calcCrow(chkLocation, {
            lat: latitude,
            lng: longitude
          });
          success = true;
          if (distance > item.radius) {
            success = false;
            console.log("อื่น ๆ");
          }
        });
      }
      // ตรวจสอบ option ลงเวลาภายในบริษัท และบริษัทที่ทำงาน
      if (
        setTimeStamp.isAllowInside &&
        setTimeStamp.isCompany &&
        companyAddress &&
        !success
      ) {
        const chkLocation = {
          lat: companyAddress.latitude,
          lng: companyAddress.longitude
        };
        const distance = calcCrow(chkLocation, {
          lat: latitude,
          lng: longitude
        });
        success = true;
        if (distance > setTimeStamp.companyRadius) {
          success = false;
          console.log("ใน บ.");
        }
      }
      // ตรวจสอบ option ลงเวลาภายนอกบริษัท
      if (setTimeStamp.isAllowOutside && !success) {
        if (setTimeStamp.isAnywhere) {
          success = true;
        } else if (setTimeStamp.isAllowCurrentAddress && empAddress) {
          const chkLocation = {
            lat: empAddress.latitude,
            lng: empAddress.longitude
          };
          const distance = calcCrow(chkLocation, {
            lat: latitude,
            lng: longitude
          });
          success = true;
          if (distance > setTimeStamp.currentAddressRadius) {
            success = false;
            console.log("นอก บ.");
          }
        }
      }
    } else {
      success = true;
    }
    if (!success) {
      dispatch(fetchError(t("timeStamp.vldTimeOffWork")));
    }
    return success;
  };

  const formatDate = date => {
    return date.toLocaleDateString(i18n.language, {
      day: "numeric",
      month: "long",
      year: "numeric"
    });
  };

  const handleTakePhoto = async image => {
    // console.log("image", image);
    // const imgFiles = image.target.files;
    // const imgResize = await resizeFile(imgFiles[0]);
    const imgResize = await resizeFile(dataURItoBlob(image));
    // console.log("imgResize", imgResize);
    onStampTime(imgResize, null, yourLocation);
    setOpenCamera(false);
  };

  const randomInRange = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0
  };

  const getAnimationSettings = (originXA, originXB) => {
    return {
      startVelocity: 30,
      spread: 360,
      ticks: 60,
      zIndex: 0,
      particleCount: 150,
      origin: {
        x: randomInRange(originXA, originXB),
        y: Math.random() - 0.2
      }
    };
  };

  const getInstance = useCallback(instance => {
    refAnimationInstance.current = instance;
  }, []);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(0.1, 0.3));
      refAnimationInstance.current(getAnimationSettings(0.7, 0.9));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startAnimation = useCallback(() => {
    if (!intervalId) {
      setIntervalId(setInterval(nextTickAnimation, 400));
    }
  }, [intervalId, nextTickAnimation]);

  const stopAnimation = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(null);
    refAnimationInstance.current && refAnimationInstance.current.reset();
  }, [intervalId]);

  const convertDate = value => {
    return new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      value / 60,
      value % 60,
      0
    );
  };

  const getLate = (value, timeOnStamp) => {
    return (
      (new Date(timeOnStamp.date).getTime() - convertDate(value).getTime()) /
      1000 /
      60
    );
  };

  const getEarly = (value, timeOnStamp) => {
    return (
      (convertDate(value).getTime() - new Date(timeOnStamp.date).getTime()) /
      1000 /
      60
    );
  };

  const onStampTime = (image, callbackFun = null, timeOnStamp) => {
    let _stampOn = "in1";
    if (time) {
      if (shiftDetail.timesStamp === 2 && time.in1) {
        _stampOn = "out1";
      } else if (shiftDetail.timesStamp === 4) {
        if (time.in1 && !time.out1) _stampOn = "out1";
        else if (time.in1 && time.out1 && !time.in2) {
          _stampOn = "in2";
        } else {
          _stampOn = "out2";
        }
      }
    }
    const _late1 = getLate(shiftDetail.in1, timeOnStamp);
    const _late2 = getLate(shiftDetail.in2, timeOnStamp);
    const _early1 = getEarly(shiftDetail.out1, timeOnStamp);
    const _early2 = getEarly(shiftDetail.out2, timeOnStamp);

    const timeImageSave = {
      id: timeImage ? timeImage.id : null,
      image1: _stampOn === "in1" ? image : timeImage ? timeImage.image1 : null,
      image2: _stampOn === "out1" ? image : timeImage ? timeImage.image2 : null,
      image3: _stampOn === "in2" ? image : timeImage ? timeImage.image3 : null,
      image4: _stampOn === "out2" ? image : timeImage ? timeImage.image4 : null,
      companyId: userAuthen.data.companyId,
      createdBy: userAuthen.data.id,
      createdDate: new Date(),
      modifiedBy: userAuthen.data.id,
      modifiedDate: new Date()
    };

    const empPoint = setEmpPoint(_late1, _late2, _early1, _early2, _stampOn);

    if (timeOnStamp) {
      const newItem = {
        id: time ? time.id : null,
        empId: userAuthen.data.empId,
        date: timeOnStamp.date,
        dateType: "working",
        status: "Normal",
        shiftId: shift.id,
        in1: _stampOn === "in1" ? timeOnStamp.date : time ? time.in1 : null,
        out1: _stampOn === "out1" ? timeOnStamp.date : time ? time.out1 : null,
        in2: _stampOn === "in2" ? timeOnStamp.date : time ? time.in2 : null,
        out2: _stampOn === "out2" ? timeOnStamp.date : time ? time.out2 : null,
        late1:
          _stampOn === "in1"
            ? _late1 < 0
              ? null
              : _late1
            : time
            ? time.late1
            : null,
        late2:
          _stampOn === "in2"
            ? _late2 < 0
              ? null
              : _late1
            : time
            ? time.late2
            : null,
        early1:
          _stampOn === "out1"
            ? _early1 < 0
              ? null
              : _early1
            : time
            ? time.early1
            : null,
        early2:
          _stampOn === "out2"
            ? _early2 < 0
              ? null
              : _early2
            : time
            ? time.early2
            : null,
        latitude1:
          _stampOn === "in1" ? timeOnStamp.lat : time ? time.latitude1 : null,
        longitude1:
          _stampOn === "in1" ? timeOnStamp.lng : time ? time.longitude1 : null,
        latitude2:
          _stampOn === "out1" ? timeOnStamp.lat : time ? time.latitude2 : null,
        longitude2:
          _stampOn === "out1" ? timeOnStamp.lng : time ? time.longitude2 : null,
        latitude3:
          _stampOn === "in2" ? timeOnStamp.lat : time ? time.latitude3 : null,
        longitude3:
          _stampOn === "in2" ? timeOnStamp.lng : time ? time.longitude3 : null,
        latitude4:
          _stampOn === "out2" ? timeOnStamp.lat : time ? time.latitude4 : null,
        longitude4:
          _stampOn === "out2" ? timeOnStamp.lng : time ? time.longitude4 : null,
        workHour: 0,
        companyId: userAuthen.data.companyId,
        createdBy: userAuthen.data.id,
        createdDate: new Date(),
        modifiedBy: userAuthen.data.id,
        modifiedDate: new Date(),
        timeImage:
          setTimeStamp && setTimeStamp.isPhoto ? [timeImageSave] : null,
        empPoint: empPoint ? [empPoint] : null
      };

      setNextStamp(false);
      if (
        (shiftDetail.timesStamp === 2 && _stampOn === "in1") ||
        (shiftDetail.timesStamp === 4 && _stampOn !== "out2")
      ) {
        setCounter(30);
      }

      if (time && time.id) {
        // console.log('update')
        dispatch(
          onUpdate(
            newItem,
            () => {
              setDataAfterSave(newItem, timeImageSave, null, callbackFun);
            },
            false
          )
        );
      } else {
        // console.log('create')
        dispatch(
          onCreate(
            newItem,
            data => {
              setDataAfterSave(newItem, timeImageSave, data, callbackFun);
            },
            false
          )
        );
      }
    }
  };

  const setDataAfterSave = (
    newItem,
    timeImageSave,
    data,
    callbackFun = null
  ) => {
    setTimeImage(timeImageSave);
    if (data) {
      setTime({ ...newItem, id: data.timeStamp.id });
      // console.log("setDataAfterSave", data.timeStampImage);
      if (data.timeStampImage) {
        setTimeImage({ ...timeImageSave, id: data.timeStampImage.id });
      }
    } else {
      setTime(newItem);
    }

    if (newItem.empPoint) {
      switch (newItem.empPoint[0].type) {
        case "late":
        case "early":
          setAlertPoint(true);
          break;
        case "inOnTime":
        case "outOnTime":
          startAnimation();
          setTimeout(() => {
            stopAnimation();
            setAlertPoint(true);
          }, 3000);
          break;
        default:
          break;
      }
    } else {
      startAnimation();
      setTimeout(() => {
        stopAnimation();
      }, 3000);
    }
    callbackFun && callbackFun();
  };

  const setEmpPoint = (_late1, _late2, _early1, _early2, _stampOn) => {
    const empPoint = {
      empId: userAuthen.data.empId,
      date: new Date(),
      type: "",
      detail: "",
      fromEmpId: null,
      toEmpId: null,
      point: 0,
      totalPoint: 0,
      remark: ""
    };

    if (pointTime) {
      if (
        pointTime.isInLate &&
        ((_late1 > 0 && _stampOn === "in1") ||
          (_late2 > 0 && _stampOn === "in2")) &&
        _stampOn.includes("in")
      ) {
        setTextAlertPoint(t("timeStamp.youAreLate"));
        setPointShow(pointTime.inLatePoint);
        setIsNormal(false);
        empPoint.point = pointTime.inLatePoint;
        empPoint.type = "late";
        empPoint.detail = "Clock In Late";
        empPoint.remark = "Clock In Late";
      } else if (
        pointTime.isOutBefore &&
        ((_early1 > 0 && _stampOn === "out1") ||
          (_early2 > 0 && _stampOn === "out2")) &&
        _stampOn.includes("out")
      ) {
        setTextAlertPoint(t("timeStamp.youAreEarly"));
        setPointShow(pointTime.outBeforePoint);
        setIsNormal(false);
        empPoint.point = pointTime.outBeforePoint;
        empPoint.type = "early";
        empPoint.detail = "Clock Early Out";
        empPoint.remark = "Clock Early Out";
      } else {
        setTextAlertPoint(t("timeStamp.youAreNormal"));
        if (_stampOn.includes("in") && pointTime.isInOnTime) {
          setPointShow(pointTime.inOnTimePoint);
          empPoint.point = pointTime.inOnTimePoint;
          empPoint.type = "inOnTime";
          empPoint.detail = "Clock In On Time";
          empPoint.remark = "Clock In On Time";
        } else if (_stampOn.includes("out") && pointTime.isOutOnTime) {
          setPointShow(pointTime.outOnTimePoint);
          empPoint.point = pointTime.outOnTimePoint;
          empPoint.type = "outOnTime";
          empPoint.detail = "Clock Out On Time";
          empPoint.remark = "Clock Out On Time";
        }
        setIsNormal(true);
      }
    }
    if (empPoint.point === 0) return null;
    return empPoint;
  };

  const handleCloseAlertPoint = () => {
    setAlertPoint(false);
  };

  const propsTimesStamp = { time, timeImage, shiftDetail, shiftDescription };
  const propsBtn = { nextStamp, counter, shift, onStamp, companyHoliday };

  const dataURItoBlob = (dataURI, callback) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var bb = new Blob([ab]);
    return bb;
  };

  return (
    <>
      <PageContainer sx={{ height: "100%" }}>
        <Paper className={classes.paper}>
          {!openCamera && (
            <>
              <Box sx={{ width: { sm: "100%", md: "40%" }, margin: "auto" }}>
                <div className={classes.clock}>
                  <Clock format="HH:mm:ss" interval={1000} ticking={true} />
                </div>
              </Box>
              <Typography component="div" className={classes.textDate}>
                {formatDate(new Date())}
              </Typography>

              <Typography className={classes.clockWith}>
                Clocking with :
                <GpsFixedIcon
                  style={{ verticalAlign: "middle", marginLeft: "5px" }}
                />
              </Typography>
            </>
          )}
          {shift && shiftDetail && !companyHoliday && !openCamera && (
            <TimesStamp props={propsTimesStamp} />
          )}
          {openCamera && (
            <Box>
              <Camera
                onTakePhotoAnimationDone={dataUri => {
                  handleTakePhoto(dataUri);
                }}
                // onCameraError={(error) => { handleCameraError(error); }}
                idealFacingMode={FACING_MODES.USER}
                // idealResolution={{ width: 480, height: 640 }}
                imageType={IMAGE_TYPES.JPG}
                imageCompression={0.97}
                isMaxResolution={true}
                isImageMirror={true}
                isSilentMode={true}
                isDisplayStartCameraError={true}
                isFullscreen={false}
                sizeFactor={1}
                // onCameraStart={(stream) => { handleCameraStart(stream); }}
                // onCameraStop={() => { handleCameraStop(); }}
              />
            </Box>
          )}
          {!openCamera && <ClockButton props={propsBtn} />}
          <Snackbar
            open={alertPoint}
            autoHideDuration={6000}
            onClose={handleCloseAlertPoint}
          >
            <Alert
              onClose={handleCloseAlertPoint}
              severity={isNormal ? "success" : "warning"}
              variant="filled"
            >
              <Box sx={{ display: "flex" }}>
                {textAlertPoint} [ {t("point.lblPoint")} :{" "}
                {(pointShow > 0 ? "+" : "") + pointShow}{" "}
                <Avatar
                  src="/images/time/star.png"
                  sx={{ width: 20, height: 20, marginLeft: 1, marginRight: 1 }}
                />
                ]
              </Box>
            </Alert>
          </Snackbar>
        </Paper>
      </PageContainer>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    </>
  );
};

export default TimeStamp;
