const calcCrow = (checkLocation, yourLocation) => {
    // var R = 6.371; // km
    const R = 6371000;
    let dLat = toRad(yourLocation.lat - checkLocation.lat);
    let dLon = toRad(yourLocation.lng - checkLocation.lng);
    let lat1 = toRad(checkLocation.lat);
    let lat2 = toRad(yourLocation.lat);

    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
}

// Converts numeric degrees to radians
const toRad = (Value) => {
    return Value * Math.PI / 180;
}

export default calcCrow;