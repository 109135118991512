import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 'bold !important',
    },
    labelProps: {
        width: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center'
    },
    formControlRoot: {
        '& .MuiOutlinedInput-input': {
            paddingTop: 10.5,
            paddingBottom: 10.5,
            backgroundColor: 'transparent',
            borderRadius: 4
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[400],
        },
    },
    companyImage: {
        borderRadius: '0 !important',
        // width: '250px',
        // height: '128px',
    },
    labelnote: {
        width: '100%',
        color: '#e61610'
    },
    paddingDocument: {
        padding: '24px',
    },
    formPickerTime: {
        padding: '8px'
    },
    gridHeader: {
        '& .MuiDataGrid-main': {
            borderRadius: '5px !important',
        },
        '& 	.MuiDataGrid-columnHeaders': {
            backgroundColor: theme.mainColor,
            color: '#fbfbf9',
            '& 	.MuiSvgIcon-root': {
                color: '#fbfbf9'
            }
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
        },
        '& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
            margin: 0,
        }
    },
}))

export default useStyles;