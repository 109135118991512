import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
// import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
// import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from '@mui/x-data-grid';
// import { Container } from '@material-ui/core';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const useStyles = makeStyles(theme => ({
    dialogRoot: {
        position: 'relative',
    },
    dialogTitleRoot: {
        '& .MuiTypography-h6': {
            fontSize: 18,
            color: theme.palette.common.dark,
        },
    },
}));


const DialogUC = ({
    open,
    handleDialog,
    isMultiSelect = false,
    fullScreen = false,
    maxWidth = 'lg',
    fullWidth = true
}) => {
    // const [selected, setSelected] = useState([]);
    const classes = useStyles();
    const checkValidations = () => {
        handleSubmit();
    };

    const handleSubmit = () => {
        handleDialog();
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'firstName', headerName: 'First name', width: 130 },
        { field: 'lastName', headerName: 'Last name', width: 130 },
        {
            field: 'age',
            headerName: 'Age',
            type: 'number',
            width: 90,
        },
        {
            field: 'fullName',
            flex: 1,
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        { field: 'status', headerName: 'Status', width: 70 },
    ];

    const employees = [
        {
            id: 1,
            lastName: 'Snow',
            firstName: 'Jon',
            age: 35,
            folder: 'employees',
            image: 'https://via.placeholder.com/150x150',
            status: 'Active',
        },
        {
            id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42, folder: 'employees',
            image: 'https://via.placeholder.com/150x150',
            status: 'InActive',
        },
        {
            id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45, folder: 'employees',
            image: 'https://via.placeholder.com/150x150',
            status: 'Active',
        },
        {
            id: 4, lastName: 'Stark', firstName: 'Arya', age: 16, folder: 'employees',
            image: 'https://via.placeholder.com/150x150',
            status: 'InActive',
        },
        {
            id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: 60, folder: 'employees',
            image: 'https://via.placeholder.com/150x150',
            status: 'Active',
        },
        {
            id: 6, lastName: 'Melisandre', firstName: 'fuck you', age: 150, folder: 'employees',
            image: 'https://via.placeholder.com/150x150',
            status: 'Active',
        },
        {
            id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44, folder: 'employees',
            image: 'https://via.placeholder.com/150x150',
            status: 'Active',
        },
        {
            id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36, folder: 'employees',
            image: 'https://via.placeholder.com/150x150',
            status: 'InActive',
        },
        {
            id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65, folder: 'employees',
            image: 'https://via.placeholder.com/150x150',
            status: 'InActive',
        },
    ];

    return (
        <Dialog
            open={open}
            onClose={handleDialog}
            className={classes.dialogRoot}
            fullScreen={fullScreen}
            fullWidth={fullWidth}
            maxWidth={maxWidth}>
            <DialogTitle className={classes.dialogTitleRoot}>
                Dialog UC
            </DialogTitle>
            <DialogContent dividers>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                            >
                                Employee
                            </Typography>
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search…"
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </Search>
                        </Toolbar>
                    </AppBar>
                </Box>
                <Box sx={{ height: 400, width: '100%', overflow: 'scroll' }} mb={2}>
                    <DataGrid
                        rows={employees}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10, 20]}
                        // onSelectionModelChange={(e) => {
                        //     setSelected(e)
                        // }}
                        checkboxSelection={isMultiSelect}
                        disableSelectionOnClick={!isMultiSelect}
                    />
                </Box>
                <Box display="flex" justifyContent="flex-end" mb={4}>
                    <Button onClick={handleDialog}>Cancel</Button>
                    <Box ml={2}>
                        <Button variant="contained" color="primary" onClick={checkValidations}>
                            OK
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default DialogUC;

DialogUC.propType = {
    open: PropTypes.bool.isRequired,
    handleDialog: PropTypes.func,
};