import GridContainer from "@jumbo/components/GridContainer";
import { Box, Grid, Typography, Divider, Dialog } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LabelUC from "routes/shared/uc/label-uc";
import ScheduleIcon from "@mui/icons-material/Schedule";
import useStyles from "./index.style";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

function TimesStamp({ props }) {
  const { time, timeImage, shiftDetail, shiftDescription } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [imgStamp, setImageStamp] = useState(null);
  const classes = useStyles();

  const format = date => {
    return new Date(date).toLocaleTimeString("en-GB");
  };

  const imageClick = image => {
    setImageStamp(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const timesStampHeight =
    shiftDetail && shiftDetail.timesStamp === 4 ? "270px" : "200px";
  return (
    <>
      <Box
        mt={3}
        p={3}
        mb={3}
        sx={{
          width: { sm: "80%", md: "40%" },
          marginLeft: "auto",
          marginRight: "auto",
          height: { timesStampHeight }
        }}
        className={classes.timesStamp}
      >
        <GridContainer>
          <Grid item xs={1}>
            <ScheduleIcon
              style={{ verticalAlign: "middle", marginRight: "5px" }}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography component="div">{shiftDescription}</Typography>
          </Grid>
        </GridContainer>
        <Divider className={classes.divider} />
        <Box pl={{ xs: 2 }} flex>
          <GridContainer>
            <Grid item xs={5} alignItems="center" container>
              <LabelUC label={t("shift.lblStartTime") + " 1"} />
            </Grid>

            <Grid item xs={3} alignItems="center" container>
              <LabelUC label={time ? format(time.in1) : ""} showColon={false} />
            </Grid>
            <Grid
              item
              xs={2}
              alignItems="center"
              container
              justifyContent="center"
            >
              {timeImage && timeImage.image1 && (
                <ImageOutlinedIcon
                  onClick={() => {
                    imageClick(timeImage.image1);
                  }}
                />
              )}
            </Grid>
            {time && time.late1 && (
              <Grid item xs={2} alignItems="center" container>
                <Typography component="div" sx={{ color: "red" }}>
                  {t("timeStamp.late")}
                </Typography>
              </Grid>
            )}
          </GridContainer>
        </Box>
        <Box pl={{ xs: 2 }} flex>
          <GridContainer>
            <Grid item xs={5} alignItems="center" container>
              <LabelUC label={t("shift.lblEndTime") + " 1"} />
            </Grid>
            <Grid item xs={3} alignItems="center" container>
              <LabelUC
                label={time && time.out1 ? format(time.out1) : ""}
                showColon={false}
              />
            </Grid>
            <Grid
              item
              xs={2}
              alignItems="center"
              container
              justifyContent="center"
            >
              {timeImage && timeImage.image2 && (
                <ImageOutlinedIcon
                  onClick={() => {
                    imageClick(timeImage.image2);
                  }}
                />
              )}
            </Grid>
            {time && time.early1 && (
              <Grid item xs={2} alignItems="center" container>
                <Typography component="div" sx={{ color: "red" }}>
                  {t("timeStamp.early")}
                </Typography>
              </Grid>
            )}
          </GridContainer>
        </Box>

        {shiftDetail && shiftDetail.timesStamp === 4 && (
          <>
            <Box pl={{ xs: 2 }} flex>
              <GridContainer>
                <Grid item xs={5} alignItems="center" container>
                  <LabelUC label={t("shift.lblStartTime") + " 2"} />
                </Grid>
                <Grid item xs={3} alignItems="center" container>
                  <LabelUC
                    label={time && time.in2 ? format(time.in2) : ""}
                    showColon={false}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  alignItems="center"
                  container
                  justifyContent="center"
                >
                  {timeImage && timeImage.image3 && (
                    <ImageOutlinedIcon
                      onClick={() => {
                        imageClick(timeImage.image3);
                      }}
                    />
                  )}
                </Grid>
                {time && time.late2 && (
                  <Grid item xs={2} alignItems="center" container>
                    <Typography component="div" sx={{ color: "red" }}>
                      {t("timeStamp.late")}
                    </Typography>
                  </Grid>
                )}
              </GridContainer>
            </Box>
            <Box pl={{ xs: 2 }} flex>
              <GridContainer>
                <Grid item xs={5} alignItems="center" container>
                  <LabelUC label={t("shift.lblEndTime") + " 2"} />
                </Grid>
                <Grid item xs={3} alignItems="center" container>
                  <LabelUC
                    label={time && time.out2 ? format(time.out2) : ""}
                    showColon={false}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  alignItems="center"
                  container
                  justifyContent="center"
                >
                  {timeImage && timeImage.image4 && (
                    <ImageOutlinedIcon
                      onClick={() => {
                        imageClick(timeImage.image4);
                      }}
                    />
                  )}
                </Grid>
                {time && time.early2 && (
                  <Grid item xs={2} alignItems="center" container>
                    <Typography component="div" sx={{ color: "red" }}>
                      {t("timeStamp.early")}
                    </Typography>
                  </Grid>
                )}
              </GridContainer>
            </Box>
          </>
        )}
      </Box>
      {open && (
        <Dialog onClose={handleClose} open={open}>
          <input type="image" src={imgStamp} alt="Time Stamp Photo" />
        </Dialog>
      )}
    </>
  );
}

export default TimesStamp;
