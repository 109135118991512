import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CmtImage from "@coremat/CmtImage";
import { Box } from "@mui/material";
import useStyles from "../term-and-condition/index.style";
import Privacytoncent from "./privacyContent";

const PrivacyPolicy = ({ open, handleClose }) => {
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Privacy Policy."}</DialogTitle>
        <DialogContent>
          <Box mb={7} className={classes.parentImg}>
            <CmtImage
              className={classes.img}
              src={"/images/unf-logo-black.png"}
            />
          </Box>
          <Privacytoncent />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default PrivacyPolicy;
