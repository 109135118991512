import { Box } from "@mui/material";
import React from "react";
import useStyles from "./index.style";
import { useTranslation } from "react-i18next";
import AppSwitch from "@jumbo/components/Common/formElements/AppSwitch";
import InsideTimeStamp from "./inside-time-stamp";
import OutsideTimeStamp from "./outside-time-stamp";
// import SetTimeStampGrid from "./set-time-stamp-grid";
import SetTimeStampAttachPhoto from "./attach-photo";
import Location from "./location";

const SetTimeStampForm = ({ props }) => {
  const { data, handleChangeCheckbox, infoViewOnlyMode } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      className={classes.paper}
      sx={{ display: "flex", width: "100%" }}
      flexDirection={{ xs: "column", md: "row" }}
    >
      <form className={classes.form}>
        <Box xs={12} sm={6}>
          <AppSwitch
            name="isAllowTimeStamp"
            label={t("setTimeStamp.lblIsAllowTimeStamp")}
            labelPlacement="end"
            checked={data.isAllowTimeStamp}
            onChange={handleChangeCheckbox}
            disabled={infoViewOnlyMode}
          />
        </Box>
        <Location props={props} />
        <InsideTimeStamp props={props} />
        <OutsideTimeStamp props={props} />
        {/* <SetTimeStampGrid props={props} /> */}
        <SetTimeStampAttachPhoto props={props} />
      </form>
    </Box>
  );
};

export default SetTimeStampForm;
