import React from "react";
import { AccordionSummary, Typography } from "@mui/material";
import useStyles from "routes/Pages/Company/index.style";
import WorkingDay from "./workingday";
import Holiday from "./holiday";

const TabWorkingDay = ({ propsWorkingDay, propsHoliday }) => {
  const classes = useStyles();

  return (
    <>
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h6" className={classes.title}>
          Working Day
        </Typography>
      </AccordionSummary>
      <WorkingDay props={propsWorkingDay} />
      <Holiday props={propsHoliday} />
    </>
  );
};

export default TabWorkingDay;
