import React from 'react';
import Box from '@material-ui/core/Box';
// import { PageBreadcrumbs, PageHeader } from '../index';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
// import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles(() => ({
  pageFull: {
    width: '100%',
    height: '100%',
  },
}));

const PageContainer = ({ heading, breadcrumbs, children, className, restProps }) => {
  const classes = useStyles();
  // const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <Slide in={true} direction="up" mountOnEnter unmountOnExit>
      <Box className={clsx(classes.pageFull, className)} {...restProps}>
        {/* {(heading || breadcrumbs) && !isMobile && (
          <PageHeader heading={heading} breadcrumbComponent={breadcrumbs && <PageBreadcrumbs items={breadcrumbs} />} />
        )} */}
        {children}
      </Box>
    </Slide>
  );
};

export default PageContainer;
