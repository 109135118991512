import React from "react";
import ListUC from "routes/shared/uc/list-uc";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentEmployee } from "redux/actions/Employees";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getById } from "redux/actions/Default";
import { setStorageControllerName } from "services/Storage.service";

const EmpList = ({ listViewClick }) => {
  const { t, i18n } = useTranslation();
  const isLangEn = i18n.language === "en";
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "image",
      headerName: t("employee.list.image"),
      width: 80,
      align: "center",
      renderCell: params => {
        // console.log(params);
        return (
          <>
            <Avatar src={params.value} />
          </>
        );
      }
    },
    { field: "empCode", headerName: t("employee.list.empCode"), width: 160 },
    {
      field: "fullName",
      flex: 1,
      headerName: t("employee.list.fullName"),
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 200,
      minWidth: 200,
      valueGetter: params =>
        `${(isLangEn ? params.row.firstNameEn : params.row.firstName) ||
          ""} ${(isLangEn ? params.row.lastNameEn : params.row.lastName) || ""}`
    },
    {
      field: "positionName" + (isLangEn ? "En" : ""),
      headerName: t("employee.list.positionName"),
      width: 160
    },
    {
      field: "departmentName" + (isLangEn ? "En" : ""),
      headerName: t("employee.list.departmentName"),
      width: 160
    }
    // { field: "status", headerName: t("employee.list.status"), width: 70 }
  ];

  const dispatch = useDispatch();
  const { employeesList } = useSelector(({ employees }) => employees);

  const handleOnEdit = id => {
    setStorageControllerName("employee");
    const currentEmp = employeesList.filter(e => e.id === id);
    if (currentEmp) {
      dispatch(
        getById(currentEmp[0].id, currentEmp[0].companyId, () => {
          listViewClick({ action: "edit", id: id });
        })
      );
    }
  };

  const handleOnAdd = () => {
    dispatch(
      getById(0, 0, () => {
        dispatch(setCurrentEmployee(null));
        listViewClick({ action: "add", id: "" });
      })
    );
  };

  return (
    <>
      <ListUC
        datas={employeesList}
        cols={columns}
        onAdd={handleOnAdd}
        onEdit={handleOnEdit}
      />
    </>
  );
};
EmpList.propTypes = {
  listViewClick: PropTypes.func
};

export default EmpList;
