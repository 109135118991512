import { REPORT_NAME } from "@jumbo/constants/ActionTypes";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { setStorageControllerName } from "services/Storage.service";
import { format } from "date-fns";
import Report from "../report";
import { useDispatch } from "react-redux";
import { getReportData } from "redux/actions/Report";
// import { uid } from "react-uid";
import { numberToTime } from "services/default.service";
import { fetchStart, fetchSuccess } from "redux/actions";
import { exportExcel } from "services/exportExcel";

const ClockInOutReport = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [datas, setDatas] = useState([]);
  setStorageControllerName(REPORT_NAME.clockInOutReport);

  const handleButtonShowReport = params => {
    const startDate = params.startDate
      ? format(params.startDate, "yyyyMMdd")
      : "";
    const endDate = params.endDate ? format(params.endDate, "yyyyMMdd") : "";
    const paramsReport =
      "'" +
      startDate +
      "'/'" +
      endDate +
      "'/'" +
      params.departmentFrom +
      "'/'" +
      params.departmentTo +
      "'/'" +
      params.positionFrom +
      "'/'" +
      params.positionTo +
      "'/'" +
      params.employeeFrom +
      "'/'" +
      params.employeeTo +
      "'";

    dispatch(
      getReportData(paramsReport, data => {
        console.log("getReportData", data);
        let reportData = [];
        if (data) {
          data.empList.map((item, index) => {
            const _data = data.data.filter(d => d.empId === item);
            if (_data.length > 0) {
              const empRow = {
                id: index,
                empName:
                  _data[0].empCode +
                  " " +
                  (i18n.language === "th"
                    ? _data[0].fullName
                    : _data[0].fullNameEn) +
                  _data[0].shiftDescription
              };
              reportData = [...reportData, empRow];
              _data.map((d, i) => {
                d.id = index + "-" + i;
                d.empName = "";
                d.in1 = d.in1 ? format(new Date(d.in1), "HH:mm") : "";
                d.out1 = d.out1 ? format(new Date(d.out1), "HH:mm") : "";
                d.in2 = d.in2 ? format(new Date(d.in2), "HH:mm") : "";
                d.out2 = d.out2 ? format(new Date(d.out2), "HH:mm") : "";
                d.workHour = numberToTime(d.workHour, ".");
                return null;
              });
              reportData = [...reportData, ..._data];
            }
            return null;
          });
          // console.log("reportData", reportData);
          setDatas(reportData);
        }
      })
    );
  };

  const propsRange = {
    showStartDate: true,
    showEndDate: true,
    showDepartment: true,
    showPosition: true,
    showEmployee: true,
    handleButtonShowReport
  };
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "empName",
      headerName: "",
      width: 100,
      colSpan: ({ row }) => {
        if (row.empName !== "") {
          return 7;
        }
        return undefined;
      }
    },
    {
      field: "date",
      // headerName: "Date", //t("company.holiday.list.date"),
      headerName: t("company.holiday.list.date"),
      width: 200,
      minWidth: 200,
      type: "date",
      flex: 1,
      valueGetter: ({ value }) => value && format(new Date(value), "dd/MM/yyyy")
    },
    {
      field: "in1",
      // headerName: "In1", //t("shift.lblStartTime") + ' 1',
      headerName: t("shift.lblStartTime") + " 1",
      width: 130
    },
    {
      field: "out1",
      // headerName: "Out1", //t("shift.lblEndTime") + ' 1',
      headerName: t("shift.lblEndTime") + " 1",
      width: 130
    },
    {
      field: "in2",
      // headerName: "In2", //t("shift.lblStartTime") + ' 2',
      headerName: t("shift.lblStartTime") + " 2",
      width: 130
    },
    {
      field: "out2",
      // headerName: "Out2", //t("shift.lblEndTime") + ' 2',
      headerName: t("shift.lblEndTime") + " 2",
      width: 130
    },
    {
      field: "workHour",
      // headerName: "Work Hour", //t("shift.lblWorkHour"),
      headerName: t("shift.lblWorkHour"),
      width: 180
    }
  ];

  const getCellClassName = ({ row, field }) => {
    if (row.empName !== "") {
      return "rowEmpName";
    }
    return "";
  };

  const handleExportExcel = async () => {
    dispatch(fetchStart());
    const TitleColumns = [
      "Employee Name",
      "Date",
      "In1",
      "Out1",
      "In2",
      "Out2",
      "Work Hour"
    ];
    const cols = [
      i18n.language === "en-US" ? "fullNameEn" : "fullName",
      "date",
      "in1",
      "out1",
      "in2",
      "out2",
      "workHour"
    ];

    const dataExport = datas.filter(item => item.empName === "");

    exportExcel(
      dataExport,
      "รายงานการลงเวลาของพนักงาน",
      TitleColumns,
      cols,
      "Clock In-Out Report"
    );
    dispatch(fetchSuccess());
  };

  return (
    <>
      <Report
        reportName={t("report.clockInOutReport")}
        propsRange={propsRange}
        datas={datas}
        columns={columns}
        getCellClassName={getCellClassName}
        handleExportExcel={handleExportExcel}
      />
    </>
  );
};

export default ClockInOutReport;
