import GridContainer from "@jumbo/components/GridContainer";
import { Grid } from "@material-ui/core";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import LabelUC from "routes/shared/uc/label-uc";
import TextInputUC from "routes/shared/uc/text-input-uc";
import { useTranslation } from "react-i18next";
// import useStyles from 'routes/Pages/Company/index.style';
import { numberToTime } from "services/default.service";

const WorkingDay = ({ props }) => {
  const {
    companyWorkingDay,
    infoViewOnlyMode,
    handleTimeChangeWorking,
    handleCheckChange
  } = props;
  // const classes = useStyles();
  // const handleChange = (e) => {
  //     setCompanyWorkingDay({ ...companyWorkingDay, [e.target.name]: e.target.value });
  // }
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const checkBox = { width: { xs: "20%", md: "10%" } };
  const { t } = useTranslation();
  // console.log('companyWorkingDay', companyWorkingDay)
  return (
    <>
      <Box
        sx={{ display: "flex", width: "100%" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        {/* StartTime */}
        <Box sx={{ padding: 2, width: { xs: "100%", lg: "100%" } }}>
          <GridContainer>
            <Grid item xs={12} sm={2} alignItems="center" container>
              <LabelUC label={t("company.lblStartTime")} />
            </Grid>
            <Grid item xs={12} sm={4} alignItems="center" container>
              <TextInputUC
                disabled={infoViewOnlyMode}
                placeholder={t("company.lblStartTime")}
                value={numberToTime(companyWorkingDay.startTime, ":")}
                name="startTime"
                type="time"
                onChange={handleTimeChangeWorking}
                // helperText={companyWorkingDay.startTimeError}
              />
            </Grid>
            {/* EndTime */}
            <Grid item xs={12} sm={1} alignItems="center" container>
              <LabelUC label={t("company.lblEndTime")} />
            </Grid>
            <Grid item xs={12} sm={4} alignItems="center" container>
              <TextInputUC
                disabled={infoViewOnlyMode}
                placeholder={t("company.lblEndTime")}
                value={numberToTime(companyWorkingDay.endTime, ":")}
                name="endTime"
                type="time"
                onChange={handleTimeChangeWorking}
                // helperText={companyWorkingDay.startTimeError}
              />
            </Grid>
          </GridContainer>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", width: "100%" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Box sx={{ padding: 2, width: { xs: "100%", lg: "100%" } }}>
          {/* Working Day */}
          <GridContainer>
            <Grid item xs={12} sm={2} alignItems="center" container>
              <LabelUC label={t("company.lblWorkingDay")} />
            </Grid>
            <Grid item xs={12} sm={10} alignItems="center" container>
              <FormControlLabel
                sx={{ ...checkBox }}
                label={t("company.lblSun")}
                checked={companyWorkingDay.isSunday}
                value={companyWorkingDay.isSunday}
                name={"isSunday"}
                control={<Checkbox {...label} />}
                onChange={handleCheckChange}
                disabled={infoViewOnlyMode}
              />
              <FormControlLabel
                sx={{ ...checkBox }}
                label={t("company.lblMon")}
                checked={companyWorkingDay.isMonday}
                value={companyWorkingDay.isMonday}
                name={"isMonday"}
                control={<Checkbox {...label} />}
                onChange={handleCheckChange}
                disabled={infoViewOnlyMode}
              />
              <FormControlLabel
                sx={{ ...checkBox }}
                label={t("company.lblTue")}
                checked={companyWorkingDay.isTuesday}
                value={companyWorkingDay.isTuesday}
                name={"isTuesday"}
                control={<Checkbox {...label} />}
                onChange={handleCheckChange}
                disabled={infoViewOnlyMode}
              />
              <FormControlLabel
                sx={{ ...checkBox }}
                label={t("company.lblWed")}
                checked={companyWorkingDay.isWednesday}
                value={companyWorkingDay.isWednesday}
                name={"isWednesday"}
                control={<Checkbox {...label} />}
                onChange={handleCheckChange}
                disabled={infoViewOnlyMode}
              />
              <FormControlLabel
                sx={{ ...checkBox }}
                label={t("company.lblThu")}
                checked={companyWorkingDay.isThursday}
                value={companyWorkingDay.isThursday}
                name={"isThursday"}
                control={<Checkbox {...label} />}
                onChange={handleCheckChange}
                disabled={infoViewOnlyMode}
              />
              <FormControlLabel
                sx={{ ...checkBox }}
                label={t("company.lblFri")}
                checked={companyWorkingDay.isFriday}
                value={companyWorkingDay.isFriday}
                name={"isFriday"}
                control={<Checkbox {...label} />}
                onChange={handleCheckChange}
                disabled={infoViewOnlyMode}
              />
              <FormControlLabel
                sx={{ ...checkBox }}
                label={t("company.lblSat")}
                checked={companyWorkingDay.isSaturday}
                value={companyWorkingDay.isSaturday}
                name={"isSaturday"}
                control={<Checkbox {...label} />}
                onChange={handleCheckChange}
                disabled={infoViewOnlyMode}
              />
            </Grid>
          </GridContainer>
        </Box>
      </Box>
    </>
  );
};

export default WorkingDay;
