import React from "react";
import GridContainer from "@jumbo/components/GridContainer";
import { Box, Grid, TextField, Typography } from "@mui/material";
import clsx from "clsx";
import LabelUC from "routes/shared/uc/label-uc";
import useStyles from "./index.style";
import InputMask from "react-input-mask";
import DateTimePickerUC from "routes/shared/uc/datetime-picker-uc";
import { useTranslation } from "react-i18next";

const TaxId = ({ props }) => {
  const { infoViewOnlyMode, emp, handleChange, handleDateChange } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {/* info left */}
      <Box sx={{ padding: 2, width: { xs: "100%", lg: "50%" } }}>
        <GridContainer>
          <Grid item xs={12} alignItems="center" container>
            <Typography
              component="div"
              sx={{ fontWeight: "bold" }}
              className={classes.labelProps}
            >
              {t("employee.lblIdentification")}
            </Typography>
          </Grid>
        </GridContainer>
        {/* Tax ID/SSN No. */}
        <GridContainer>
          <Grid item xs={12} sm={5} alignItems="center" container>
            <LabelUC label={t("employee.lblIdentityNo")} isRequired={true} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <InputMask
              mask="9-9999-99999-99-9"
              value={emp.identificationNo}
              disabled={infoViewOnlyMode}
              maskChar=" "
              name="identificationNo"
              onChange={handleChange}
              placeholder="x-xxxx-xxxxx-xx-x"
            >
              {params => (
                <TextField
                  fullWidth
                  disabled={infoViewOnlyMode}
                  className={clsx(classes.formControlRoot, "form-control")}
                  helperText={emp.identificationNoError}
                  error={!!emp.identificationNoError}
                  {...params}
                />
              )}
            </InputMask>
          </Grid>
        </GridContainer>
        {/* Identification No. Expiry Date*/}
        <GridContainer>
          <Grid item xs={12} sm={5} alignItems="center" container>
            <LabelUC label={t("employee.lblIdentityExpire")} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <DateTimePickerUC
              name="identityExpire"
              value={emp.identityExpire}
              handleDateChange={handleDateChange}
              infoViewOnlyMode={infoViewOnlyMode}
            />
          </Grid>
        </GridContainer>
      </Box>
    </>
  );
};

export default TaxId;
