import { setStorageAuthenUser } from "services/Storage.service";
import {
  SEND_FORGET_PASSWORD_EMAIL,
  SET_RESET_PASSWORD,
  SET_SIGNUP_ENUM,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER
} from "../../@jumbo/constants/ActionTypes";

export const setAuthUser = user => {
  if (user) {
    setStorageAuthenUser(user);
  }
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user
    });
    // console.log(user)
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading
    });
  };
};

export const setForgetPassMailSent = status => {
  return dispatch => {
    dispatch({
      type: SEND_FORGET_PASSWORD_EMAIL,
      payload: status
    });
  };
};

export const setResetPassword = status => {
  return dispatch => {
    dispatch({
      type: SET_RESET_PASSWORD,
      payload: status
    });
  };
};

export const setSignUpEnum = signUpEnum => {
  return dispatch => {
    dispatch({
      type: SET_SIGNUP_ENUM,
      payload: signUpEnum
    });
  };
};
