import {
  SET_CURRENT_COMPANY,
  SET_COMPANY_ENUM,
  SET_HOLIDAY_YEARLIST,
  SET_COMPANY_HOLIDAY
} from "../../@jumbo/constants/ActionTypes";

export const setCurrentCompany = company => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_COMPANY,
      payload: company
    });
  };
};

export const setCompanyById = (data, callbackFun = null) => {
  let com = null;
  return dispatch => {
    com = data.data.company;
    if (com) {
      if (data.data.companyAddress && data.data.companyAddress.length > 0) {
        com.companyAddress = data.data.companyAddress;
      }
      if (
        data.data.companyWorkingDay &&
        data.data.companyWorkingDay.length > 0
      ) {
        com.companyWorkingDay = data.data.companyWorkingDay;
      }
    }
    if (data.data.companyHoliday && data.data.companyHoliday.length > 0) {
      dispatch({
        type: SET_COMPANY_HOLIDAY,
        payload: data.data.companyHoliday
      });
    }
    if (data.data.yearList && data.data.yearList.length > 0) {
      dispatch({ type: SET_HOLIDAY_YEARLIST, payload: data.data.yearList });
    }
    dispatch({ type: SET_COMPANY_ENUM, payload: data.data.companyEnum });
    dispatch({ type: SET_CURRENT_COMPANY, payload: com });
    if (callbackFun) callbackFun(com);
  };
};
