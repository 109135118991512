import React, { useEffect, useState } from "react";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useDispatch } from "react-redux";
import { setCurrentMenu } from "redux/actions/Menu";
import { onDelete } from "redux/actions/Default";
import { setCurrentLocation } from "redux/actions/Location";
import LocationList from "./location-list";
import LocationInfo from "./location-info";

const breadcrumbs = [
  { label: <IntlMessages id={"sidebar.main"} />, link: "/" },
  { label: <IntlMessages id={"sidebar.menu.location"} />, isActive: false }
];

const Location = () => {
  const dispatch = useDispatch();
  const [openInfo, setOpenInfo] = useState(false);

  useEffect(() => {
    dispatch(setCurrentMenu("location"));
  }, [dispatch]);

  const InfoViewClick = event => {
    if (event) {
      switch (event.action) {
        case "delete":
          handleDeleteSeleted(event.value);
          setOpenInfo(false);
          break;
        case "save":
          // dispatch(setCurrentEmployee(event.value));
          break;
        case "saveandclose":
          dispatch(setCurrentLocation(null));
          setOpenInfo(false);
          break;
        default:
          setOpenInfo(false);
          break;
      }
    } else {
      dispatch(setCurrentLocation(null));
      setOpenInfo(false);
    }
  };

  const handleDeleteSeleted = selected => {
    dispatch(onDelete(selected));
  };

  const listViewClick = event => {
    console.log("list " + event);
    switch (event.action) {
      case "add":
        setOpenInfo(true);
        break;
      case "edit":
        setOpenInfo(true);
        break;
      default:
        setOpenInfo(false);
        break;
    }
  };

  return (
    <>
      <PageContainer
        heading={<IntlMessages id="sidebar.menu.location" />}
        breadcrumbs={breadcrumbs}
      >
        {!openInfo && <LocationList listViewClick={listViewClick} />}
        {openInfo && (
          <LocationInfo handleDialog={InfoViewClick} openInfo={openInfo} />
        )}
      </PageContainer>
    </>
  );
};

export default Location;
