import { SET_CURRENT_DEPARTMENT, SET_MAIN_DEPARTMENT } from "../../@jumbo/constants/ActionTypes";

export const setCurrentDepartment = department => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_DEPARTMENT,
      payload: department
    });
  };
};

export const setMainDepartment = department => {
  return dispatch => {
    dispatch({
      type: SET_MAIN_DEPARTMENT,
      payload: department
    });
  };
};
