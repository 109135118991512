import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    height: "100%"
  },
  form: {
    width: "100%"
  }
}));

export default useStyles;
