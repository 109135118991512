import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import { Paper, Box, Button } from "@mui/material";
import React, { useState } from "react";
import useStyles from "./index.style";
import ReportHeader from "./report-header";
import ReportRange from "./report-range";
import {
  DataGrid,
  GridToolbarContainer
  // GridToolbarExport
} from "@mui/x-data-grid";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Report = props => {
  const {
    reportName,
    propsRange,
    datas = [],
    columns,
    getCellClassName = "",
    handleExportExcel
  } = props;
  const classes = useStyles();
  const [rangeHeight, setRangeHeight] = useState(50);
  const [reportHeight, setReportHeight] = useState("calc(100% - 120px);");
  const [isShowReport, setIsShowReport] = useState(false);
  const [isShowRange, setIsShowRange] = useState(true);

  const onShowRange = value => {
    setIsShowRange(value);
    if (value) {
      // range height + title height
      setReportHeight(`calc(100% - ${rangeHeight + 40}px);`);
    } else {
      setReportHeight("calc(100% - 120px);");
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarExport printOptions={{ disableToolbarButton: true }} /> */}
        <div className="flex">
          <Button
            onClick={() => handleExportExcel()}
            startIcon={<FileDownloadIcon />}
          >
            Export Excel
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }

  const propsReportRange = {
    ...propsRange,
    onShowRange,
    isShowRange,
    setIsShowReport,
    setRangeHeight
  };
  return (
    <>
      <PageContainer sx={{ height: "100%" }}>
        <Paper className={classes.paper}>
          <ReportHeader reportName={reportName} />
          <ReportRange props={propsReportRange} />
          {isShowReport && (
            <Box
              className={classes.reportContainer}
              sx={{ height: reportHeight }}
            >
              <DataGrid
                className={classes.gridHeader}
                rows={datas}
                columns={columns}
                disableColumnMenu={true}
                hideFooter={true}
                getCellClassName={getCellClassName}
                initialState={
                  datas
                    ? {
                        ...datas.initialState,
                        columns: {
                          columnVisibilityModel: {
                            id: false
                          }
                        }
                      }
                    : {}
                }
                components={{
                  Toolbar: CustomToolbar
                }}
              />
            </Box>
          )}
        </Paper>
      </PageContainer>
    </>
  );
};

export default Report;
