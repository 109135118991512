import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    labelProps: {
        width: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center'
    },
    formControlRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[400],
        },
    },
    paddingDocument: {
        padding: '24px',
    },
    title: {
        fontWeight: 'bold !important',
    },
}))

export default useStyles;