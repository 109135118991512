import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Collapse, IconButton, Link } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

import CmtImage from "../../../../@coremat/CmtImage";
import { AuhMethods } from "../../../../services/auth";
import ContentLoader from "../../ContentLoader";
import { CurrentAuthMethod } from "../../../constants/AppConstants";
import AuthWrapper from "./AuthWrapper";
import { setForgetPassMailSent } from "../../../../redux/actions/Auth";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import LangSwitcher from "@jumbo/components/AppLayout/partials/LangSwitcher";

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    }
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up("md")]: {
      order: 1,
      width: "100%"
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    },
    "& .MuiFormLabel-root": {
      padding: "0 !important"
    },
    "& .MuiOutlinedInput-input": {
      padding: "18.5px 14px !important"
    }
  },
  alertRoot: {
    marginBottom: 10
  },
  image: {
    width: "150px"
  }
}));

//variant = 'default', 'standard', 'bgColor'
const ForgotPassword = ({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default"
}) => {
  const { send_forget_password_email } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    let timeOutStopper = null;
    if (send_forget_password_email) {
      setOpen(true);

      timeOutStopper = setTimeout(() => {
        dispatch(setForgetPassMailSent(false));
        history.push("/");
      }, 1500);
    }

    return () => {
      if (timeOutStopper) clearTimeout(timeOutStopper);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send_forget_password_email]);

  const onSubmit = () => {
    setEmailError(!email ? t("common.isRequired") : "");
    if (email) {
      dispatch(AuhMethods[method].onForgotPassword(email));
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant} maxWidth="500px">
      {/* <div className={classes.authThumb}>
        <CmtImage src={'/images/auth/forgot-img.png'} />
      </div> */}
      <div className={classes.authContent}>
        <div className={"mb-3"}>
          <CmtImage
            src={"/images/unf-logo-black.png"}
            className={classes.image}
          />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Forgot password
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            A mail has been sent on your email address with reset password link.
          </Alert>
        </Collapse>
        <form>
          <Box className={"mb-5"}>
            <TextField
              label={t("appModule.email")}
              fullWidth
              onChange={event => setEmail(event.target.value)}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={emailError}
              error={!!emailError}
              inputProps={{
                maxLength: 100
              }}
            />
          </Box>
          <Box className={"mb-5"}>
            <Button onClick={onSubmit} variant="contained" color="primary">
              {t("appModule.resetPassword")}
            </Button>
          </Box>

          <div>
            <Typography>
              Don't remember your email?
              <span className={"ml-2"}>
                <Link href="#">{t("appModule.contactSupport")}</Link>
              </span>
            </Typography>
          </div>
          <Box
            display="flex"
            alignItems="center"
            mt={2}
            justifyContent="center"
          >
            <LangSwitcher />
          </Box>
        </form>
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;
