import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@mui/material/Slide";
import { useSelector } from "react-redux";
import InfoAppBarUC from "./InfoAppBarUC";
import PropTypes from "prop-types";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import {
  setInfoViewOnlyMode,
  setIsDataModified,
  setIsOpeningInfo
} from "redux/actions";
import { useDispatch } from "react-redux";
import useStyles from "./index.style";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InfoUC = ({
  fullScreen = false,
  open,
  handleDialog,
  children,
  isShowDelete,
  isShowEdit,
  isShowSave,
  isCheckBeforeClose = true,
  customTitle,
  ...props
}) => {
  const dispatch = useDispatch();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const classes = useStyles();
  const { isDataModified } = useSelector(({ common }) => common);
  const [openDataChangeDialog, setOpenDataChangeDialog] = useState(false);
  const { t } = useTranslation();

  const handleInfoDialog = action => {
    switch (action) {
      case "delete":
        setOpenConfirmDialog(true);
        break;
      case "edit":
        dispatch(setInfoViewOnlyMode(false));
        break;
      case "close":
        if (isDataModified && isCheckBeforeClose) {
          setOpenDataChangeDialog(true);
        } else {
          handleDialog("close");
        }
        break;
      default:
        handleDialog(action);
        break;
    }
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    handleDialog("delete");
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmSave = () => {
    setOpenDataChangeDialog(false);
    handleDialog("save");
  };

  const handleCancelSave = () => {
    setOpenDataChangeDialog(false);
  };

  const handleNoSave = () => {
    setOpenDataChangeDialog(false);
    handleDialog("close");
    dispatch(setIsDataModified(false));
    dispatch(setIsOpeningInfo(false));
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => handleInfoDialog("close")}
      TransitionComponent={Transition}
      className={classes.dialog}
      {...props}
    >
      <Paper className={classes.paper}>
        <InfoAppBarUC
          handleDialog={handleInfoDialog}
          isShowDelete={isShowDelete}
          isShowEdit={isShowEdit}
          isShowSave={isShowSave}
          customTitle={customTitle}
        />
        <DialogContent dividers>{children}</DialogContent>
        <ConfirmDialog
          open={openConfirmDialog}
          title={`Confirm delete`}
          content={t("common.confirmDelete")}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
        <ConfirmDialog
          open={openDataChangeDialog}
          title={`Warning`}
          content={t("common.confirmChangeSave")}
          onClose={handleCancelSave}
          onNo={handleNoSave}
          onConfirm={handleConfirmSave}
        />
      </Paper>
    </Dialog>
  );
};

InfoUC.proptype = {
  fullScreen: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  // title: PropTypes.string.isRequired,
  handleDialog: PropTypes.func.isRequired,
  children: PropTypes.element,
  isShowDelete: PropTypes.bool,
  isShowEdit: PropTypes.bool,
  isShowSave: PropTypes.bool
};

export default InfoUC;
