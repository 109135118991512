import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useStyles from "./index.style";
import { useTranslation } from "react-i18next";

import PersonnelInformation from "./personnel-information";
import Address from "./address";
// import Family from "./family";
// import Education from "./education";

export default function TabGeneral({ props }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" className={classes.title}>
            {t("profile.general.personalInformation")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PersonnelInformation props={props} />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h6" className={classes.title}>
            {t("profile.general.contactAddress")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Address props={props} />
        </AccordionDetails>
      </Accordion>
      {/* <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography variant='h6' className={classes.title}>Family</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Family props={props} />
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography variant='h6' className={classes.title}>Education</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Education props={props} />
                </AccordionDetails>
            </Accordion> */}
    </div>
  );
}
