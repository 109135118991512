import {
  CREATE_POINT_SETTING,
  UPDATE_POINT_SETTING,
  SET_CURRENT_POINT_SETTING,
  GET_EMPPOINT_LIST,
  SET_CURRENT_EMPPOINT,
  CREATE_EMPPOINT,
  UPDATE_EMPPOINT,
  GET_MYPOINT_LIST,
  CREATE_MYPOINT
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  currentPoint: null,
  currentPointTime: null,
  empPointList: [],
  currentEmpPoint: null,
  myPointList: null,
  myPointEmp: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_POINT_SETTING:
    case UPDATE_POINT_SETTING:
    case CREATE_POINT_SETTING: {
      return {
        ...state,
        currentPoint: action.payload.point,
        currentPointTime: action.payload.pointTime
      };
    }

    case GET_EMPPOINT_LIST: {
      console.log('reducer empPoint')
      console.log(action.payload);
      return {
        ...state,
        empPointList: action.payload,
      };
    }

    case SET_CURRENT_EMPPOINT:
    case UPDATE_EMPPOINT:
    case CREATE_EMPPOINT: {
      let updatedList = state.empPointList;
      // console.log(state)
      updatedList = [action.payload, ...updatedList];
      return {
        ...state,
        empPointList: updatedList
      };
    }

    case GET_MYPOINT_LIST: {
      return {
        ...state,
        myPointList: action.payload.myPoint,
        myPointEmp: action.payload.employee
      };
    }

    case CREATE_MYPOINT: {
      let updatedList = state.myPointList;
      updatedList = [...updatedList, action.payload];
      return {
        ...state,
        myPointList: updatedList
      };
    }

    default:
      return state;
  }
};
