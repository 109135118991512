import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import useStyles from "./index.style";

const TotalPoint = ({ points }) => {
  const classes = useStyles();
  return (
    <Box sx={{ width: "100%" }} mb={2}>
      <Box xs={6} sm={12} textAlign={"center"}>
        <Avatar
          alt="Point"
          src="/images/time/star.png"
          sx={{ width: 80, height: 80, margin: "auto" }}
        />
      </Box>
      <Box sx={{ textAlign: "center" }} mt={2}>
        <Typography variant="h5" gutterBottom className={classes.textPoint}>
          {points}
        </Typography>
        <Typography gutterBottom className={classes.textPoint}>
          Points
        </Typography>
      </Box>
    </Box>
  );
};

export default TotalPoint;
