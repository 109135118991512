import React from 'react'
import { FormLabel, Box } from '@mui/material';
import useStyles from './index.style'

const LabelUC = ({
    label,
    isRequired = false,
    showColon = true,
    variant,
    ...rest
}) => {
    const classess = useStyles();
    return (
        <>
            <Box {...rest} className={classess.labelProps}>
                <FormLabel variant={variant || 'caption'}>
                    {label}
                    {isRequired &&
                        <FormLabel variant='caption' sx={{ color: 'red' }}>*</FormLabel>
                    }
                </FormLabel>
                {showColon &&
                    <FormLabel variant='caption'>:</FormLabel>
                }
            </Box>
        </>
    )
}

export default LabelUC;