import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  langIcon: {
    color: theme.mainColor,
    width: '24px !important',
  },
  iconLang: {
    marginLeft: theme.spacing(2),
    cursor: 'pointer',
    '&.active': {
      fontWeight: 900,
      color: theme.mainColor,
      fontSize: '1rem',
    }
  },
}));
export default useStyles;
