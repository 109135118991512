import React, { useState } from "react";
import useStyles from "../tab-general/index.style";
import { Box, Typography, AccordionSummary, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GridContainer from "@jumbo/components/GridContainer";
import { Grid } from "@material-ui/core";
import LabelUC from "routes/shared/uc/label-uc";
import { useTranslation } from "react-i18next";
import TextInputUC from "routes/shared/uc/text-input-uc";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import { useDispatch, useSelector } from "react-redux";
import { AuhMethods } from "services/auth";
import { CurrentAuthMethod } from "@jumbo/constants/AppConstants";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";

export default function TabUser({ props }) {
  const { infoViewOnlyMode, user, setUser, viewOnly } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [resetPassword, setResetPassword] = useState(false);
  const { isOnAdd } = useSelector(({ common }) => common);
  const [isOpenSendEmail, setIsOpenSendEmail] = useState(false);
  const dispatch = useDispatch();
  const method = CurrentAuthMethod;

  const handleChange = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
      userName: e.target.name === "email" ? e.target.value : user.userName,
      emailError: "",
      passwordError: "",
      confirmPasswordError: ""
    });
  };

  const handleConfirmReset = () => {
    setUser({
      ...user,
      password: "",
      confirmPassword: "",
      resetPassword: true
    });
    setResetPassword(true);
  };

  const handleSendMail = () => {
    setIsOpenSendEmail(true);
  };

  const handleConfirmSendEmail = () => {
    setIsOpenSendEmail(false);
    dispatch(
      AuhMethods[method].sendEmailUserInfomation(user.email, e => {
        // console.log("e :", e);
      })
    );
  };

  const handleCancelSendEmail = () => {
    setIsOpenSendEmail(false);
  };

  return (
    <>
      <ConfirmDialog
        open={isOpenSendEmail}
        title={`Confirm Send Email`}
        content={t("employee.btnSendEmail")}
        onClose={handleCancelSendEmail}
        onConfirm={handleConfirmSendEmail}
      />
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className={classes.title}>
          {t("profile.user.user")}
        </Typography>
      </AccordionSummary>
      <Box
        sx={{ display: "flex", width: "100%" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Box
          sx={{
            padding: 2,
            width: { xs: "100%", lg: "70%" },
            marginLeft: { xs: "0%", lg: "15%" }
          }}
        >
          {/* Email */}
          <GridContainer>
            <Grid item xs={12} sm={4} alignItems="center" container>
              <LabelUC label={t("employee.lblEmail")} isRequired={true} />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextInputUC
                disabled={viewOnly}
                placeholder={t("employee.lblEmail")}
                value={user.email || ""}
                name="email"
                onChange={handleChange}
                helperText={user.emailError}
                error={!!user.emailError}
              />
            </Grid>
          </GridContainer>
          {/* User Name */}
          <GridContainer>
            <Grid item xs={12} sm={4} alignItems="center" container>
              <LabelUC label={t("employee.lblUserName")} isRequired={true} />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextInputUC
                disabled={true}
                placeholder={t("employee.lblUserName")}
                value={user.userName || ""}
                name="userName"
                onChange={handleChange}
              />
            </Grid>
          </GridContainer>
          {/* Password */}
          {user && user.id && (
            <GridContainer>
              <Grid item xs={12} sm={4} alignItems="center" container>
                <LabelUC label={t("employee.lblPassword")} isRequired={true} />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextInputUC
                  disabled={!isOnAdd && (infoViewOnlyMode || !resetPassword)}
                  placeholder={t("employee.lblPassword")}
                  value={user.password || ""}
                  name="password"
                  onChange={e => handleChange(e)}
                  type="password"
                  helperText={user.passwordError}
                  error={!!user.passwordError}
                />
              </Grid>
              <Grid item xs={12} sm={3} alignItems="center" container>
                {!isOnAdd && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ fontWeight: "bold" }}
                    disabled={infoViewOnlyMode}
                    onClick={handleConfirmReset}
                  >
                    {t("employee.btnResetPassword")}
                  </Button>
                )}
              </Grid>
            </GridContainer>
          )}
          {/* confirm password */}
          {resetPassword && (
            <GridContainer>
              <Grid item xs={12} sm={4} alignItems="center" container>
                <LabelUC
                  label={t("signUp.confirmPassword")}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextInputUC
                  placeholder={t("signUp.confirmPassword")}
                  value={user.confirmPassword || ""}
                  name="confirmPassword"
                  onChange={e => handleChange(e)}
                  type="password"
                  helperText={user.confirmPasswordError}
                  error={!!user.confirmPasswordError}
                />
              </Grid>
            </GridContainer>
          )}
          {/* User Role */}
          <GridContainer>
            <Grid item xs={12} sm={4} alignItems="center" container>
              <LabelUC label={t("employee.lblRole")} isRequired={true} />
            </Grid>
            <Grid item xs={12} sm={5}>
              <AppSelectBox
                fullWidth
                data={userRole}
                variant="outlined"
                value={user.role || "administrator"}
                name="role"
                valueKey="title"
                onChange={handleChange}
                disabled={viewOnly}
              />
            </Grid>

            {user && user.id && (
              <Grid item xs={12} sm={3} alignItems="center" container>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ fontWeight: "bold" }}
                  disabled={infoViewOnlyMode}
                  onClick={handleSendMail}
                >
                  {t("employee.btnSendEmail")}
                </Button>
              </Grid>
            )}
          </GridContainer>
        </Box>
      </Box>
    </>
  );
}

const userRole = [
  {
    title: "administrator",
    caption: "Administrator",
    captionEn: "Administrator"
  },
  { title: "hr", caption: "HR", captionEn: "HR" },
  { title: "manager", caption: "Manager", captionEn: "Manager" },
  { title: "employee", caption: "Employee", captionEn: "Employee" },
  { title: "technician", caption: "Technician", captionEn: "Technician" }
];
