import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  formControlRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[400]
    }
  }
}));

export default useStyles;
