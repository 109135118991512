import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative",
    "& .MuiDialog-paper": {
      width: "30%"
    },
    "& .MuiDialogContentText-root": {
      fontSize: "inherit !important"
    }
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 18,
      color: theme.palette.common.dark
    }
  },
  dialogActionsRoot: {
    padding: "16px 24px"
  }
}));

const ConfirmDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  btnLabels,
  onNo
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog-title"
      disableEnforceFocus
      className={classes.dialogRoot}
    >
      <DialogTitle
        id="confirm-dialog-title"
        className={classes.dialogTitleRoot}
      >
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button onClick={onConfirm} color="primary" variant="contained">
          {btnLabels.confirm}
        </Button>
        {onNo && (
          <Button onClick={onNo} color="primary" variant="outlined">
            {btnLabels.no}
          </Button>
        )}
        <Button onClick={onClose} color="primary" variant="outlined">
          {btnLabels.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  open: false,
  title: "Confirm Delete",
  btnLabels: {
    cancel: "Cancel",
    confirm: "Yes",
    no: "No"
  }
};

export default React.memo(ConfirmDialog);
