import AppCheckbox from "@jumbo/components/Common/formElements/AppCheckBox";
import GridContainer from "@jumbo/components/GridContainer";
import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import TextInputUC from "routes/shared/uc/text-input-uc";
import LabelPoint from "./label-point";

const TabTimeClockOut = ({ props }) => {
  const {
    infoViewOnlyMode,
    pointTime,
    setPointTime,
    handleChangeCheckbox
  } = props;
  const { t } = useTranslation();

  const handleChangeOntime = value => {
    setPointTime({ ...pointTime, outOnTimePoint: value });
  }
  const handleChangeBefore = value => {
    setPointTime({ ...pointTime, outBeforePoint: value });
  }

  return (
    <>
      <Box xs={12} sm={6} p={2} mt={3}>
        <Typography variant="h6">{t("point.lblClockOut")}</Typography>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />
      <Box xs={12} sm={6} flex>
        <GridContainer justifyContent="center">
          <Grid item xs={12} sm={3} alignItems="center" container>
            <AppCheckbox
              label={t("point.lblOutOnTimePoint")}
              name="isOutOnTime"
              onChange={handleChangeCheckbox}
              disabled={infoViewOnlyMode}
              checked={pointTime.isOutOnTime}
            />
          </Grid>
          <Grid item xs={9} sm={3}>
            <TextInputUC
              type="number"
              disabled={infoViewOnlyMode || !pointTime.isOutOnTime}
              placeholder={t("point.lblOutOnTimePoint")}
              value={pointTime.outOnTimePoint}
              name="outOnTimePoint"
              onChange={(e) => handleChangeOntime(e.target.value.substring(0, 5))}
            />
          </Grid>
          <LabelPoint />
        </GridContainer>
      </Box>
      <Box xs={12} sm={6} flex>
        <GridContainer justifyContent="center">
          <Grid item xs={12} sm={3} alignItems="center" container>
            <AppCheckbox
              label={t("point.lblOutBeforePoint")}
              name="isOutBefore"
              onChange={handleChangeCheckbox}
              disabled={infoViewOnlyMode}
              checked={pointTime.isOutBefore}
            />
          </Grid>
          <Grid item xs={9} sm={3}>
            <TextInputUC
              type="number"
              disabled={infoViewOnlyMode || !pointTime.isOutBefore}
              placeholder={t("point.lblOutBeforePoint")}
              value={pointTime.outBeforePoint}
              name="outBeforePoint"
              onChange={(e) => handleChangeBefore(e.target.value.substring(0, 6))}
            />
          </Grid>
          <LabelPoint />
        </GridContainer>
      </Box>
    </>
  );
};

export default TabTimeClockOut;
