import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: "#fff !important",
    color: theme.mainColor,
    boxShadow: "none !important",
    paddingRight: "0px !important"
  },
  paper: {
    height: "100%",
    padding: theme.spacing(3),
    "& .MuiAppBar-root": {
      zIndex: 0
    },
    "& fieldset": {
      marginTop: "5px"
    },
    "& legend": {
      display: "none !important"
    }
  },
  dialog: {
    "& .MuiDialog-paper": {
      width: "100%"
    }
  },
  dialogContent: {
    padding: 0,
    "& .MuiTabPanel-root": {
      padding: 0
    },
    "& .MuiTab-root": {
      fontWeight: "bold"
    }
  }
}));

export default useStyles;
