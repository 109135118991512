import React, { useEffect } from "react";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import { getStorageAuthenUser } from "services/Storage.service";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useDispatch } from "react-redux";
import { setCurrentMenu } from "redux/actions/Menu";
import EmployeePointList from "./empPoint-list";
import AdjustEmpPointDialog from "./adjust-point-dialog";

const breadcrumbs = [
  { label: <IntlMessages id={"sidebar.point"} />, link: "/" },
  { label: <IntlMessages id={"sidebar.menu.employeePoint"} />, isActive: false }
];

const EmployeePoint = () => {
  const dispatch = useDispatch();
  const [openInfo, setOpenInfo] = React.useState(false);
  const [currentEmp, setCurrentEmp] = React.useState([]);
  const authen = getStorageAuthenUser();

  useEffect(() => {
    dispatch(setCurrentMenu("employeePoint"));
  }, [dispatch]);

  const handleClose = () => {
    setOpenInfo(false);
  };

  const listViewClick = event => {
    console.log("list data " + event.data);
    switch (event.action) {
      case "add":
      case "edit":
        setOpenInfo(true);
        setCurrentEmp(event.data);
        break;
      default:
        setOpenInfo(false);
        break;
    }
  };

  return (
    <>
      <PageContainer
        heading={<IntlMessages id="sidebar.menu.employeePoint" />}
        breadcrumbs={breadcrumbs}
      >
        <EmployeePointList listViewClick={listViewClick} />
        {openInfo && (
          <AdjustEmpPointDialog openInfo={openInfo} handleClose={handleClose} currentEmp={currentEmp} authen={authen} />
        )}
      </PageContainer>
    </>
  );
}

export default EmployeePoint;
