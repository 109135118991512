import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import {
  IconButton,
  InputAdornment,
  Typography,
  Collapse,
  Alert
} from "@mui/material";
import { AuhMethods } from "../../../../services/auth";
import ContentLoader from "../../ContentLoader";
import CmtImage from "../../../../@coremat/CmtImage";
import { CurrentAuthMethod } from "../../../constants/AppConstants";
import { NavLink, useHistory } from "react-router-dom";
import AuthWrapper from "./AuthWrapper";
import { useTranslation } from "react-i18next";
import LangSwitcher from "@jumbo/components/AppLayout/partials/LangSwitcher";
import useStyles from "./signIn.style";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { setResetPassword } from "redux/actions/Auth";
import CloseIcon from "@material-ui/icons/Close";

const ResetPassword = ({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default",
  id
}) => {
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  let history = useHistory();
  const { t } = useTranslation();
  const { set_reset_password } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  console.log("id :", id);
  useEffect(() => {
    let timeOutStopper = null;
    if (set_reset_password) {
      setOpen(true);

      timeOutStopper = setTimeout(() => {
        dispatch(setResetPassword(false));
        history.push("/");
      }, 3000);
    }

    return () => {
      if (timeOutStopper) clearTimeout(timeOutStopper);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [set_reset_password]);

  const onSubmit = () => {
    setNewPasswordError(!newPassword ? t("common.isRequired") : "");
    setConfirmPasswordError(
      !confirmPassword
        ? t("common.isRequired")
        : newPassword !== confirmPassword
          ? t("signUp.passwordNotMatch")
          : ""
    );
    if (confirmPassword && newPassword) {
      dispatch(AuhMethods[method].onResetPassword(id, newPassword));
    }
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChangeConfirmPassword = value => {
    setConfirmPassword(value);
    if (value !== newPassword) {
      setConfirmPasswordError(t("signUp.passwordNotMatch"));
    } else {
      setConfirmPasswordError("");
    }
  };

  const onKeyUp = event => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      <Box className={classes.authContent}>
        <Box sx={{ width: "70%", margin: "10% auto" }}>
          <Box mb={7} sx={{ width: "50%", margin: "auto" }}>
            <CmtImage src={"/images/unf-logo-black.png"} />
          </Box>
          <Typography
            component="div"
            variant="h4"
            className={classes.titleRoot}
          >
            {t("appModule.resetPassword")}
          </Typography>
          <Collapse in={open}>
            <Alert
              variant="outlined"
              severity="success"
              className={classes.alertRoot}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Reset password successfully, You are being redirected to login
              page.
            </Alert>
          </Collapse>
          <form>
            <Box mb={2}>
              <TextField
                type={showNewPassword ? "text" : "password"}
                label={t("appModule.newPassword")}
                fullWidth
                onChange={event => setNewPassword(event.target.value)}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                value={newPassword}
                onKeyUp={onKeyUp}
                helperText={newPasswordError}
                error={!!newPasswordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                type={showConfirmPassword ? "text" : "password"}
                label={t("signUp.confirmPassword")}
                fullWidth
                onChange={event =>
                  handleChangeConfirmPassword(event.target.value)
                }
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                value={confirmPassword}
                onKeyUp={onKeyUp}
                helperText={confirmPasswordError}
                error={!!confirmPasswordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Button
                onClick={onSubmit}
                variant="contained"
                color="primary"
                className={classes.btnSignIn}
              >
                {t("appModule.resetPassword")}
              </Button>
            </Box>
            <Typography className={classes.textAcc}>
              Have an account?
              <NavLink to="/auth/signin">Sign In</NavLink>
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={3}
            >
              <LangSwitcher />
            </Box>
          </form>
        </Box>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default ResetPassword;
