import React, { useEffect, useState } from "react";
import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useDispatch } from "react-redux";
import { setCurrentMenu } from "redux/actions/Menu";
import { setCurrentPosition } from "redux/actions/Position";
import { onDelete } from "redux/actions/Default";
import PositionList from "./position-list";
import PositionInfo from "./position-info";

const breadcrumbs = [
  { label: <IntlMessages id={"sidebar.main"} />, link: "/" },
  { label: <IntlMessages id={"sidebar.menu.position"} />, isActive: false }
];

const Position = () => {
  const dispatch = useDispatch();
  const [openInfo, setOpenInfo] = useState(false);

  useEffect(() => {
    dispatch(setCurrentMenu("position"));
  }, [dispatch]);

  const InfoViewClick = event => {
    console.log("Info " + event);
    if (event) {
      switch (event.action) {
        case "delete":
          handleDeleteSeleted(event.value);
          break;
        case "save":
          dispatch(setCurrentPosition(null));
          break;
        default:
          setOpenInfo(false);
          break;
      }
    } else {
      dispatch(setCurrentPosition(null));
      setOpenInfo(false);
    }
  };

  const handleDeleteSeleted = selected => {
    dispatch(onDelete(selected));
  };

  const listViewClick = event => {
    console.log("list " + event);
    switch (event.action) {
      case "add":
        setOpenInfo(true);
        break;
      case "edit":
        setOpenInfo(true);
        break;
      default:
        setOpenInfo(false);
        break;
    }
  };

  return (
    <>
      <PageContainer
        heading={<IntlMessages id="sidebar.menu.position" />}
        breadcrumbs={breadcrumbs}
      >
        {/* {!openInfo && (<PositionList listViewClick={listViewClick} />)} */}
        <PositionList listViewClick={listViewClick} />
        {openInfo && (
          <PositionInfo openInfo={openInfo} handleDialog={InfoViewClick} />
        )}
      </PageContainer>
    </>
  );
};

export default Position;
