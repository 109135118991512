import {
  CREATE_TIMESTAMP,
  GET_TIMESTAMP,
  SET_CURRENT_TIMESTAMP,
  SET_CURRENT_SET_TIMESTAMP,
  CREATE_SET_TIMESTAMP,
  UPDATE_SET_TIMESTAMP,
  SET_LOCATION_TIMESTAMP,
  SET_TIMESTAMP_SHIFT,
  SET_TIMESTAMP_SETTIMESTAMP,
  UPDATE_TIMESTAMP
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  currentTimeStamp: null,
  shift: null,
  shiftDetail: null,
  setTimeStamp: null,
  setTimeStampDt: [],
  //set time stamp
  currentSetTimeStamp: null,
  locationList: [],
  timeStamp: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Time Stamp
    case SET_CURRENT_TIMESTAMP:
    case GET_TIMESTAMP: {
      return {
        ...state,
        timeStamp: action.payload
      };
    }

    case UPDATE_TIMESTAMP:
    case CREATE_TIMESTAMP: {
      return {
        ...state,
        timeStamp: action.payload
      };
    }

    // SET TIMESTAMP
    case CREATE_SET_TIMESTAMP:
    case UPDATE_SET_TIMESTAMP:
    case SET_CURRENT_SET_TIMESTAMP: {
      return {
        ...state,
        currentSetTimeStamp: action.payload
      };
    }

    case SET_LOCATION_TIMESTAMP: {
      return {
        ...state,
        locationList: action.payload
      };
    }

    case SET_TIMESTAMP_SHIFT: {
      // console.log('SET_TIMESTAMP_SHIFT', action.payload);
      return {
        ...state,
        shift: action.payload.shift,
        shiftDetail: action.payload.shiftDetail
      };
    }

    case SET_TIMESTAMP_SETTIMESTAMP: {
      return {
        ...state,
        setTimeStamp: action.payload.setTimeStamp,
        setTimeStampDt: action.payload.setTimeStampDt
      };
    }
    default:
      return state;
  }
};
