import axios from "axios";
import { getStorageToken } from "../../Storage.service";
import config from "../../helpers";
import liff from "@line/liff";

const fetchClient = () => {
  const defaultOptions = {
    // baseURL: `https://undefined-hr-api.herokuapp.com/api/`, //YOUR_API_URL HERE
    // baseURL: `http://localhost:5001/api/`, //YOUR_API_URL HERE
    // baseURL: `http://undefined.ddns.net:8081/api/`, //YOUR_API_URL HERE
    // baseURL: `https://hrapi.hopeagro.co.th/api/`, //YOUR_API_URL HERE
    baseURL: config._baseURL,
    headers: {
      "Content-Type": "application/json"
    }
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function(config) {
    const token = getStorageToken();
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    config.headers["x-liff"] = liff.isInClient();
    return config;
  });

  const refreshToken = async () => {
    try {
      const refreshToken = localStorage.getItem("hr-refreshToken");
      const response = await axios.post(
        `${config._baseURL}auth/refreshToken`,
        { refresh_token: refreshToken },
        {
          headers: {
            "x-refresh-token": "true" // Add this header to identify refresh token requests
          }
        }
      );

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      return response.data.token;
    } catch (error) {
      // message.error("Session หมดอายุ");
      localStorage.clear();
      window.location.href = "/";
      return null;
    }
  };

  instance.interceptors.response.use(
    function(response) {
      // Return a successful response
      return response;
    },
    async function(error) {
      const originalRequest = error.config;
      // Check if the error status code indicates token expiration or invalidation

      if (
        error.response &&
        ((error.response.status === 401 &&
          error.response.data.message != "Invalid token") ||
          error.response.status === 403) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const newToken = await refreshToken();
        if (newToken) {
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return axios(originalRequest);
        }
      }
      // Return an error
      return Promise.reject(error);
    }
  );

  return instance;
};

export default fetchClient();
