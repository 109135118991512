import { makeStyles } from "@material-ui/core/styles";
// import { color } from "@mui/system";

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%"
  },
  boxTitle: {
    backgroundColor: theme.mainColor
  },
  boxPolicy: {
    backgroundColor: theme.mainColor
  },
  title: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: "18px !important"
  },
  authThumb: {
    backgroundColor: theme.mainColor,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "60%",
      order: 1,
      borderTopLeftRadius: theme.spacing(1),
      borderBottomLeftRadius: theme.spacing(1)
    },
    [theme.breakpoints.down("xs")]: {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1)
    }
  },
  authContent: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      order: 1
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: "16px !important",
    "& .MuiOutlinedInput-input": {
      padding: "18.5px 14px !important"
    },
    "& .MuiFormLabel-root": {
      color: "rgba(0, 0, 0, 0.3)"
    },
    "& .MuiSelect-selectMenu": {
      paddingTop: "18px !important",
      paddingBottom: "18px !important",
      fontWeight: "bold"
    }
  },
  textFieldRoot: {
    "& .MuiFormLabel-root": {
      padding: "0 !important",
      color: "rgba(0, 0, 0, 0.3)"
    },
    "& .MuiOutlinedInput-input": {
      padding: "16.5px 14px !important"
    },
    marginTop: "0 !important",
    marginBottom: "16px !important"
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 10
      }
    }
  },
  navLink: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 10
    }
  },
  btnRegister: {
    width: "100%",
    border: "1px solid #e0e0e0",
    borderRadius: "50px !important",
    fontWeight: "bold !important"
  },
  btnSignIn: {
    width: "100%"
  },
  textAcc: {
    marginTop: 3,
    textAlign: "center",
    "& a": {
      marginLeft: 4
    }
  },
  labelAgree: {
    color: "white",
    // fontWeight: "bold"
  },
  btnAccept: {
    ' &.MuiButtonBase-root.Mui-disabled': {
      backgroundColor: '#e9e5e5 !important',
      color: '#a19c9c !important'
    },
    width: '100%',
    borderRadius: "50px !important",
    fontWeight: "bold !important",
    backgroundColor: "white !important",
    color: "#002f65 !important",

  },

}));

export default useStyles;
