import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
// import { LanguageRounded } from '@mui/icons-material';
import { Divider } from "@mui/material";
import clsx from "clsx";
import useStyles from "./index.style";
import { setStorageLanguage } from "services/Storage.service";

const LangSwitcher = () => {
  const { i18n } = useTranslation();
  const [thaiLang, setThaiLang] = useState(
    i18n.language === "th" ? "active" : ""
  );
  const [enLang, setEnLang] = useState(i18n.language === "en" ? "active" : "");
  const classes = useStyles();

  const handleChangeLang = useCallback(
    lang => {
      console.log(lang);
      i18n.changeLanguage(lang);
      // setLaoLang(lang === 'lo' ? 'active' : '');
      setThaiLang(lang === "th" ? "active" : "");
      setEnLang(lang === "en" ? "active" : "");
      setStorageLanguage(lang);
    },
    [i18n]
  );

  return (
    <>
      {/* <LanguageRounded className={classes.langIcon} /> */}
      {/* <div className={clsx(classes.iconLang, laoLang)} onClick={() => handleChangeLang('lo')}>LA</div> */}
      {/* <Divider sx={{ marginLeft: 1 }} orientation="vertical" flexItem /> */}
      <div
        className={clsx(classes.iconLang, thaiLang)}
        onClick={() => handleChangeLang("th")}
      >
        TH
      </div>
      <Divider sx={{ marginLeft: 1 }} orientation="vertical" flexItem />
      <div
        className={clsx(classes.iconLang, enLang)}
        onClick={() => handleChangeLang("en")}
      >
        EN
      </div>
    </>
  );
};

export default LangSwitcher;
