import { Box } from "@mui/material";
import React from "react";
import AppCheckbox from "@jumbo/components/Common/formElements/AppCheckBox";
import LabelUC from "routes/shared/uc/label-uc";
import TextInputUC from "routes/shared/uc/text-input-uc";
import GridContainer from "@jumbo/components/GridContainer";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import AppSwitch from "@jumbo/components/Common/formElements/AppSwitch";

const InsideTimeStamp = ({ props }) => {
  const {
    infoViewOnlyMode,
    data,
    handleChangeCompRadius,
    handleChangeCheckbox,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <Box pl={{ xs: 2, sm: 6 }} mt={3}>
        <AppSwitch
          name="isAllowInside"
          label={t("setTimeStamp.lblIsAllowInside")}
          labelPlacement="end"
          onChange={handleChangeCheckbox}
          disabled={infoViewOnlyMode || !data.isAllowTimeStamp}
          checked={data.isAllowInside}
        />
      </Box>
      {/* Own Organization */}
      <Box pl={{ xs: 4, sm: 12 }}>
        <AppCheckbox
          label={t("setTimeStamp.lblIsCompany")}
          name="isCompany"
          onChange={handleChangeCheckbox}
          disabled={
            infoViewOnlyMode || !data.isAllowInside || !data.isAllowTimeStamp
          }
          checked={data.isCompany}
        />
      </Box>

      <Box pl={{ xs: 4, sm: 16 }} xs={12} sm={6} flex>
        <GridContainer>
          <Grid item xs={6} sm={3} alignItems="center" container>
            <LabelUC label={t("setTimeStamp.lblRadius")} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextInputUC
              disabled={
                infoViewOnlyMode || !data.isCompany || !data.isAllowTimeStamp
              }
              placeholder={t("setTimeStamp.lblRadius")}
              value={data.companyRadius}
              name="companyRadius"
              type="number"
              onChange={(e) => handleChangeCompRadius(e.target.value.substring(0, 3))}
            />
          </Grid>
        </GridContainer>
      </Box>
      {false && (
        <>
          {/* All Organization */}
          <Box pl={{ xs: 4, sm: 12 }}>
            <AppCheckbox
              label={t("setTimeStamp.lblIsAllCompany")}
              name="isAllCompany"
              onChange={handleChangeCheckbox}
              disabled={infoViewOnlyMode || !data.isAllowInside}
              checked={data.isAllCompany}
            />
          </Box>

          <Box pl={{ xs: 4, sm: 16 }} xs={12} sm={6} flex>
            <GridContainer>
              <Grid item xs={6} sm={3} alignItems="center" container>
                <LabelUC label={t("setTimeStamp.lblRadius")} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextInputUC
                  disabled={infoViewOnlyMode || !data.isAllCompany}
                  placeholder={t("setTimeStamp.lblRadius")}
                  value={data.allCompanyRadius}
                  name="allCompanyRadius"
                // onChange={handleChangeInput}
                />
              </Grid>
            </GridContainer>
          </Box>
        </>
      )}
    </>
  );
};

export default InsideTimeStamp;
