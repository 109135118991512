import React from "react";
import ListUC from "routes/shared/uc/list-uc";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { setCurrentDepartment } from "redux/actions/Department";
import { getById } from "redux/actions/Default";

const DepartmentList = ({ listViewClick }) => {
  const dispatch = useDispatch();
  const { departmentsList } = useSelector(({ departments }) => departments);
  const { t, i18n } = useTranslation();
  const isLangEn = i18n.language === "en";

  const handleOnEdit = id => {
    const currentDepartment = departmentsList.filter(e => e.id === id);
    // dispatch(setCurrentDepartment(currentDepartment ? currentDepartment[0] : null));
    if (currentDepartment) {
      dispatch(
        getById(currentDepartment[0].id, currentDepartment[0].companyId, () => {
          listViewClick({ action: "edit", id: id });
        })
      );
    }
  };

  const handleOnAdd = () => {
    dispatch(
      getById(0, 0, () => {
        dispatch(setCurrentDepartment(null));
        listViewClick({ action: "add", id: "" });
      })
    );
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "departmentCode",
      headerName: t("department.list.departmentCode"),
      width: 160
    },
    {
      field: "departmentName",
      headerName: t("department.list.departmentName"),
      width: 220,
      flex: 1,
      valueGetter: params =>
        `${(isLangEn ? params.row.departmentNameEn : params.row.departmentName) || ""} `
    }
  ];

  return (
    <>
      <ListUC
        datas={departmentsList}
        cols={columns}
        onAdd={handleOnAdd}
        onEdit={handleOnEdit}
      />
    </>
  );
};

DepartmentList.propTypes = {
  listViewClick: PropTypes.func
};

export default React.memo(DepartmentList);
