import React from "react";
import ListUC from "routes/shared/uc/list-uc";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { getById } from "redux/actions/Default";
import { format } from "date-fns";

const CustomerList = ({ listViewClick }) => {
    const dispatch = useDispatch();
    const { customersList } = useSelector(({ customers }) => customers);

    const handleOnEdit = id => {
        const currentCustomer = customersList.filter(e => e.id === id);
        if (currentCustomer) {
            dispatch(
                getById(currentCustomer[0].id, null, () => {
                    listViewClick({ action: "edit", id: id });
                })
            );
        }
    };

    const columns = [
        { field: "id", headerName: "ID", width: 70 },
        {
            field: "createdDate",
            headerName: "วันที่ลงทะเบียน",
            width: 160,
            valueGetter: ({ value }) => value && format(new Date(value), "dd/MM/yyyy")
        },
        {
            field: "companyName",
            headerName: "ชื่อบริษัท",
            width: 160,
            flex: 1
        },
        {
            field: "registerName",
            headerName: "ชื่อผู้ลงทะเบียน",
            width: 160,
            flex: 1,
            valueGetter: params =>
                `${(params.row.firstName) || ""} ${(params.row.lastName) || ""}`
        },
        {
            field: "email",
            headerName: "email",
            width: 160,
            flex: 1
        },
        {
            field: "phone",
            headerName: "เบอร์โทรศัพท์",
            width: 160
        }
        // {
        //     field: "status",
        //     headerName: "สถานะ",
        //     width: 160,
        //     renderCell: params => {
        //         console.log('status', params.row.status)
        //         const value = params.row.status.toString();
        //         return (
        //             <>
        //                 {value === "Activated" ? <span style={{ color: "blue" }}>{value}</span>
        //                     : value === "Expire" ? <span style={{ color: "red" }}>{value}</span>
        //                         : <span >{value}</span>
        //                 }
        //             </>
        //         )
        //     }
        // }
    ];

    return (
        <ListUC
            datas={customersList}
            cols={columns}
            isShowAdd={false}
            onAdd={() => { }}
            onEdit={handleOnEdit}
        />
    );

}

CustomerList.propTypes = {
    listViewClick: PropTypes.func
};

export default React.memo(CustomerList);
