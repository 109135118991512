import React, { forwardRef, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import useStyles from "./index.style";
import { Avatar, Box, TextareaAutosize } from "@mui/material";
import GivePointItem from "./give-point-item";
import TextInputUC from "routes/shared/uc/text-input-uc";
import InputAdornment from "@mui/material/InputAdornment";
import AutoCompleteUC from "routes/shared/uc/autocomplete-uc";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { onCreate } from "redux/actions/Default";
import { fetchSuccess, setNotification } from "redux/actions";
import {
  getStorageNotification,
  setStorageNotification
} from "services/Storage.service";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GivePointDialog = ({
  open,
  handleClose,
  item,
  authen,
  totalPoints,
  setTotalPoints
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [customPoint, setCustomPoint] = useState(0);
  const [remark, setRemark] = useState("");
  const [emp, setEmp] = useState(null);
  const [empError, setEmpError] = useState("");
  const [empList, setEmpList] = useState([]);
  const [pointError, setPointError] = useState("");

  const { myPointEmp } = useSelector(({ point }) => point);

  useEffect(() => {
    setEmpList(
      myPointEmp.map(item => ({
        id: item.id,
        title: item.empCode,
        description: i18n.language === "th" ? item.fullName : item.fullNameEn
      }))
    );
  }, [myPointEmp, i18n]);

  const handleConfirm = () => {
    const pointSave = item.type === "custom" ? customPoint : item.point;
    if (!emp) {
      setEmpError(t("common.isRequired"));
    } else if (pointSave > totalPoints) {
      setPointError(t("point.notEnoughPoint"));
    } else {
      // คนส่ง
      const dataSave = {
        empId: authen.data.empId,
        date: new Date(),
        type: item.type,
        detail: "To " + emp.description,
        fromEmpId: null,
        toEmpId: emp.id,
        point: pointSave * -1,
        totalPoint: 0,
        remark: remark
      };
      // คนรับ
      dataSave.empPointTo = [
        {
          empId: emp.id,
          date: new Date(),
          type: item.type,
          detail: "From " + authen.data.firstName + " " + authen.data.lastName,
          fromEmpId: authen.data.empId,
          toEmpId: null,
          point: pointSave,
          totalPoint: 0,
          remark: remark
        }
      ];

      //ปิด dialog ก่อนทำการบันทึก
      handleClose();

      dispatch(
        onCreate(
          dataSave,
          data => {
            setTotalPoints(totalPoints - pointSave);
            // console.log("data", data);
            //Add Notification
            const notiStorage = getStorageNotification();
            const newNoti = {
              id: data.id,
              fromEmpId: data.fromEmpId,
              fullNameFrom: authen.data.firstName + " " + authen.data.lastName,
              fullNameFromEn:
                authen.data.firstName + " " + authen.data.lastName,
              toEmpId: data.toEmpId,
              fullNameTo: emp.description,
              fullNameToEn: emp.description,
              type: data.type,
              isRead: false,
              value: data.value,
              companyId: data.companyId,
              createdBy: data.createdBy,
              createdDate: data.createdDate,
              modifiedBy: data.modifiedBy,
              modifiedDate: data.modifiedDate
            };
            console.log("newNoti", [newNoti, ...notiStorage]);
            setStorageNotification([newNoti, ...notiStorage]);
            dispatch(setNotification([newNoti, ...notiStorage]));
            dispatch(fetchSuccess("Sent point successfully"));
          },
          false
        )
      );
    }
  };

  const handleChangeEmp = value => {
    setEmpError("");
    console.log(value);
    setEmp(value);
  };

  const handleChangePoint = value => {
    setPointError("");
    setCustomPoint(value);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="Give Points"
        className={classes.dialog}
      >
        <DialogTitle>{"Give Points"}</DialogTitle>
        <DialogContent>
          <Box p={2}>
            <GivePointItem
              item={item}
              margin="auto"
              minWidth={"170px"}
              handleClickItem={() => {}}
              handleClickCustom={() => {}}
            />
          </Box>
          {item.type === "custom" && (
            <Box className={classes.boxDialog}>
              <TextInputUC
                type="number"
                value={customPoint}
                onChange={e =>
                  handleChangePoint(e.target.value.substring(0, 5))
                }
                className={classes.textPoint}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Avatar
                        src={"images/point/star.png"}
                        sx={{ width: 24, height: 24 }}
                      />
                    </InputAdornment>
                  )
                }}
                helperText={pointError}
                error={!!pointError}
              />
            </Box>
          )}
          <Box className={classes.boxDialog}>
            <AutoCompleteUC
              datas={empList}
              isMultipleLine={true}
              isCanAdd={true}
              value={emp}
              handleChange={handleChangeEmp}
              name="emp"
              helperText={empError}
              error={!!empError}
              // placeholder={t("point.lblSelectEmp")}
            />
          </Box>
          <Box className={classes.boxDialog}>
            <TextareaAutosize
              value={remark}
              onChange={e => setRemark(e.target.value)}
              maxLength={200}
              minRows={3}
              placeholder={t("point.lblRemark")}
              className={classes.textarea}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirm()}>Confirm</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GivePointDialog;
