import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 'bold !important',
    },
    gridHeader: {
        '& .MuiDataGrid-main': {
            borderRadius: '5px !important',
        },
        '& 	.MuiDataGrid-columnHeaders': {
            backgroundColor: theme.mainColor,
            color: '#fbfbf9',
            '& 	.MuiSvgIcon-root': {
                color: '#fbfbf9'
            }
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
        },
        '& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
            margin: 0,
        }
    },
    map: {
        '& .ldmap_placeholder': {
            borderRadius: theme.spacing(2),
        }
    },
}))

export default useStyles;