import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    height: "100%"
  },
  form: {
    width: "100%"
  },
  gridHeader: {
    "& .MuiDataGrid-main": {
      borderRadius: "5px !important"
    },
    "& 	.MuiDataGrid-columnHeaders": {
      backgroundColor: theme.mainColor,
      color: "#fbfbf9",
      "& 	.MuiSvgIcon-root": {
        color: "#fbfbf9"
      }
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold"
    },
    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: 0
    }
  }
}));

export default useStyles;
