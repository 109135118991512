import {
  SET_CURRENT_SET_TIMESTAMP,
  SET_CURRENT_TIMESTAMP,
  SET_LOCATION_TIMESTAMP,
  SET_TIMESTAMP_SETTIMESTAMP,
  SET_TIMESTAMP_SHIFT
} from "../../@jumbo/constants/ActionTypes";

export const setCurrentTimeStamp = data => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_TIMESTAMP,
      payload: data
    });
  };
};

export const setTimeStampById = (data, callbackFun = null) => {
  let timeStamp = null;
  // console.log("setTimeStampById", data);
  return dispatch => {
    timeStamp = data.data.timeStamp;
    dispatch({ type: SET_CURRENT_TIMESTAMP, payload: timeStamp });
    if (data.data.shift) {
      dispatch({ type: SET_TIMESTAMP_SHIFT, payload: data.data });
    }
    if (data.data.setTimeStamp) {
      dispatch({ type: SET_TIMESTAMP_SETTIMESTAMP, payload: data.data });
    }
    // console.log("setTimeStampById", data.data);
    if (callbackFun) callbackFun(data.data);
  };
};

export const setCurrentSetTimeStamp = data => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_SET_TIMESTAMP,
      payload: data
    });
  };
};

export const setSetTimeStampById = (data, callbackFun = null) => {
  let setTimeStamp = null;
  return dispatch => {
    if (data.data.setTimeStamp) {
      setTimeStamp = data.data.setTimeStamp;
      if (data.data.setTimeStampDt) {
        setTimeStamp.setTimeStampDt = data.data.setTimeStampDt;
      }
    }
    if (data.data.locationList && data.data.locationList.length > 0) {
      dispatch({
        type: SET_LOCATION_TIMESTAMP,
        payload: data.data.locationList
      });
    }
    dispatch({ type: SET_CURRENT_SET_TIMESTAMP, payload: setTimeStamp });
    if (callbackFun) callbackFun(setTimeStamp);
  };
};
