import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoUC from "routes/shared/uc/info-uc";
import SetTimeStampForm from "./set-time-stamp-form";
import { getById, onCreate, onUpdate } from "redux/actions/Default";
import { getStorageAuthenUser } from "services/Storage.service";
import DetectStateChange from "services/detectStateChange";

const SetTimeStamp = () => {
  const userAuthen = getStorageAuthenUser();
  const { currentSetTimeStamp, locationList } = useSelector(
    ({ timeStamp }) => timeStamp
  );
  const { infoViewOnlyMode } = useSelector(({ common }) => common);
  const dispatch = useDispatch();

  const _data = currentSetTimeStamp || {};
  const [data, setData] = useState({
    id: _data.id || null,
    isAllowTimeStamp: _data.isAllowTimeStamp || false,
    isAllowInside: _data.isAllowInside || false,
    isCompany: _data.isCompany || false,
    companyRadius: _data.companyRadius || 0,
    isAllCompany: _data.isAllCompany || false,
    isEmpLocation: _data.isEmpLocation || false,
    isOtherLocation: _data.isOtherLocation || false,
    allCompanyRadius: _data.allCompanyRadius || 0,
    isAllowOutside: _data.isAllowOutside || false,
    isAnywhere: _data.isAnywhere || false,
    isSetPlace: _data.isSetPlace || false,
    isAllowCurrentAddress: _data.isAllowCurrentAddress || false,
    currentAddressRadius: _data.currentAddressRadius || 0,
    isPhoto: _data.isPhoto || false,
    companyId: _data.companyId || 0,
    createdBy: _data.createdBy || 0,
    createdDate: _data.createdDate || new Date(),
    modifiedBy: _data.modifiedBy || 0,
    modifiedDate: _data.modifiedDate || new Date(),
    isDeleted: _data.isDeleted || false
  });

  const [dataDt, setDataDt] = useState([]);

  useEffect(() => {
    dispatch(
      getById(userAuthen.data.companyId, userAuthen.data.companyId, data => {
        if (data) {
          setData(data);
          setDataDt(data.setTimeStampDt);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleChangeCheckbox = event => {
    setData({ ...data, [event.target.name]: event.target.checked });
  };

  const handleChangeCompRadius = (value) => {
    setData({ ...data, companyRadius: value });
  };

  const handleChangeCurrentAddrRad = (value) => {
    setData({ ...data, currentAddressRadius: value });
  };
  const props = {
    infoViewOnlyMode,
    data,
    setData,
    dataDt,
    setDataDt,
    locationList,
    handleChangeCompRadius,
    handleChangeCurrentAddrRad,
    handleChangeCheckbox,
  };

  //TODO: วางไว้ล่าง state
  DetectStateChange([data, dataDt]);

  const tsSettingForm = () => {
    return (
      <>
        <SetTimeStampForm props={props} />
      </>
    );
  };

  const handleInfoDialog = action => {
    console.log("action dialog :" + action);
    switch (action) {
      case "save":
        handleSubmit();
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    data.setTimeStampDt = dataDt;
    if (currentSetTimeStamp) {
      dispatch(onUpdate({ ...currentSetTimeStamp, ...data }));
      console.log("Update success");
    } else {
      dispatch(onCreate(data));
      console.log("Insert Success");
    }
  };

  return (
    <InfoUC
      handleDialog={handleInfoDialog}
      children={tsSettingForm()}
      isShowDelete={false}
      isShowClose={false}
    />
  );
};

export default SetTimeStamp;
