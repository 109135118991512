import React from 'react'
import AddressUC from 'routes/shared/uc/address-uc/home';

const HomeAddress = ({ infoViewOnlyMode, homeEmpAddress, setHomeEmpAddress }) => {
    // const mapKey = '41dc9ac020afc71dcd938ccba6d22dc5'
    const mapKey = 'b69be858e00b3fa59086597397a441e5'
    // console.log(homeEmpAddress)
    return (
        <>
            <AddressUC
                infoViewOnlyMode={infoViewOnlyMode}
                address={homeEmpAddress}
                setAddress={setHomeEmpAddress}
                mapKey={mapKey} />
        </>
    )
}

export default HomeAddress;