import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import { IconButton, InputAdornment } from "@mui/material";
import { AuhMethods } from "../../../../services/auth";
import ContentLoader from "../../ContentLoader";
// import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtImage from "../../../../@coremat/CmtImage";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { CurrentAuthMethod } from "../../../constants/AppConstants";
import { NavLink, useHistory } from "react-router-dom";
import AuthWrapper from "./AuthWrapper";
import { useTranslation } from "react-i18next";
import LangSwitcher from "@jumbo/components/AppLayout/partials/LangSwitcher";
import { useMediaQuery } from "react-responsive";
import useStyles from "./signIn.style";
import {
  getStorageRemember
  // removeRememberStorage
} from "services/Storage.service";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { fetchSuccess } from "redux/actions";
// import { t('common.isRequired') } from "@jumbo/constants/ErrorMessages";

//variant = 'default', 'standard'
const SignIn = ({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default"
}) => {
  const [userName, setUserName] = useState(""); //'weatherzilla@gmail.com'
  const [userNameError, setUserNameError] = useState(""); //'weatherzilla@gmail.com'
  const [password, setPassword] = useState(""); //'123456789'
  const [passwordError, setPasswordError] = useState(""); //'123456789'
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  let history = useHistory();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const rememberData = getStorageRemember();
    if (rememberData && rememberData.rememberMe) {
      setRememberMe(rememberData.rememberMe);
      setUserName(rememberData.userName);
      // setPassword(rememberData.password);
    }
    // else {
    //   removeRememberStorage();
    // }
    dispatch(fetchSuccess());
  }, [dispatch]);

  const onSubmit = () => {
    setUserNameError(!userName ? t("signIn.usernameRequire") : "");
    setPasswordError(!password ? t("signIn.passwordRequire") : "");
    if (userName && password) {
      dispatch(
        AuhMethods[method].onLogin({ userName, password, rememberMe }, () => {
          history.push("/timeStamp");
        })
      );
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const onKeyUp = event => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {/* {!isMobile && */}
      <Box className={classes.authThumb}>
        <CmtImage src={"/images/auth/login-image.png"} />
        <Button
          onClick={() => history.push("/auth/signup")}
          className={classes.signUpBtn}
          variant="contained"
          color="primary"
        >
          {t("signUp.register")}
        </Button>
      </Box>
      {/* } */}
      <Box className={classes.authContent}>
        <Box sx={{ width: "70%", margin: "5% auto" }}>
          <Box mb={7} sx={{ width: "50%", margin: "auto" }}>
            <CmtImage src={"/images/unf-logo-black.png"} />
          </Box>
          <form>
            <Box mb={2}>
              <TextField
                label={t("appModule.userName")}
                fullWidth
                onChange={event => setUserName(event.target.value)}
                // defaultValue={userName}
                value={userName}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                helperText={userNameError}
                error={!!userNameError}
                inputProps={{
                  maxLength: 100
                }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                type={showPassword ? "text" : "password"}
                label={t("appModule.password")}
                fullWidth
                onChange={event => setPassword(event.target.value)}
                // defaultValue={password}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                value={password}
                onKeyUp={onKeyUp}
                helperText={passwordError}
                error={!!passwordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Box component="div" width="50%">
                <FormControlLabel
                  className={classes.formcontrolLabelRoot}
                  control={
                    <Checkbox
                      name="checkedA"
                      color="primary"
                      checked={rememberMe}
                      onChange={e => setRememberMe(e.target.checked)}
                    />
                  }
                  label={t("appModule.rememberMe")}
                />
              </Box>
              <Box component="div">
                <NavLink to="/auth/forgotpassword" className={classes.navLink}>
                  {t("appModule.forgotPassword")}
                </NavLink>
              </Box>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Button
                onClick={onSubmit}
                variant="contained"
                color="primary"
                className={classes.btnSignIn}
              >
                {t("appModule.signIn")}
              </Button>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={isMobile ? 3 : 30}
            >
              <LangSwitcher />
            </Box>
          </form>
        </Box>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
