import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    height: "100%",
    "& fieldset": {
      marginTop: "5px"
    },
    "& legend": {
      display: "none !important"
    }
  },
  reportHeader: {
    padding: theme.spacing(4, 0),
    margin: "auto",
    textAlign: "center"
  },
  headerTitle: {
    color: theme.mainColor,
    fontWeight: "bold !important",
    fontSize: "1.5rem !important"
  },
  textRange: {
    color: theme.mainColor,
    fontWeight: "bold !important",
    fontSize: "1rem !important",
    cursor: "pointer"
  },
  accordion: {
    marginTop: "0px !important",
    marginBottom: "0px !important",
    border: "1px solid #e0e0e0",
    borderRadius: [theme.spacing(2), "!important"],
    boxShadow: "none !important",
    "& .Mui-expanded": {
      height: "0px !important",
      minHeight: "30px !important"
    },
    "&::before": {
      display: "none"
    }
  },
  reportRange: {
    padding: theme.spacing(2),
    // border: "1px solid #e0e0e0",
    // borderRadius: theme.spacing(2),
    "& .MuiGrid-container": {
      justifyContent: "center"
    }
  },
  btnShow: {
    width: "100%",
    fontWeight: "bold !important"
  },
  reportContainer: {
    "& .rowEmpName": {
      backgroundColor: theme.palette.grey[100]
    }
    // padding: theme.spacing(2),
    // border: "1px solid #e0e0e0",
    // borderRadius: theme.spacing(2),
    // height: "100%",
    // marginTop: theme.spacing(2),
  },
  gridHeader: {
    marginTop: theme.spacing(2),
    "& .MuiDataGrid-toolbarContainer": {
      justifyContent: "flex-end",
      "& .MuiButton-root": {
        // fontSize: "16px",
        fontWeight: "bold",
        padding: theme.spacing(2, 0)
      }
    },
    "& .MuiDataGrid-main": {
      borderRadius: "5px !important"
    },
    "& 	.MuiDataGrid-columnHeaders": {
      backgroundColor: theme.mainColor,
      color: "#fbfbf9",
      "& 	.MuiSvgIcon-root": {
        color: "#fbfbf9"
      }
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold"
    },
    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: 0
    }
  }
}));

export default useStyles;
