import React from "react";
import GridContainer from "@jumbo/components/GridContainer";
import { Grid } from "@mui/material";
import LabelUC from "routes/shared/uc/label-uc";
// import useStyles from './index.style';
import { useTranslation } from "react-i18next";
// import { enumTitle } from "../../empEnum";
import TextInputUC from "routes/shared/uc/text-input-uc";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const NameTh = ({ props }) => {
  const { infoViewOnlyMode, emp, handleChange } = props;
  const { empEnum } = useSelector(({ employees }) => employees);
  const { t } = useTranslation();
  const enumTitle = empEnum.filter(e => e.type === "title");
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <>
      {/* Emp Code */}
      <GridContainer>
        <Grid item xs={12} sm={5} alignItems="center" container>
          <LabelUC label={t("employee.lblEmpCode")} isRequired={true} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextInputUC
            disabled={infoViewOnlyMode}
            placeholder={t("employee.lblEmpCode")}
            value={emp.empCode}
            name="empCode"
            onChange={handleChange}
            helperText={emp.empCodeError}
          />
        </Grid>
      </GridContainer>
      {/* First Name*/}
      <GridContainer>
        <Grid item xs={12} sm={5} alignItems="center" container>
          <LabelUC label={t("employee.lblFirstName")} isRequired={true} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <GridContainer>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{ paddingRight: !isMobile ? "0 !important" : 12 }}
            >
              <AppSelectBox
                fullWidth
                data={enumTitle}
                variant="outlined"
                value={emp.title}
                name="title"
                onChange={handleChange}
                helperText={emp.titleError}
                disabled={infoViewOnlyMode}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextInputUC
                disabled={infoViewOnlyMode}
                placeholder={t("employee.lblFirstName")}
                value={emp.firstName}
                name="firstName"
                onChange={handleChange}
                helperText={emp.firstNameError}
              />
            </Grid>
          </GridContainer>
        </Grid>
      </GridContainer>
      {/* Last Name*/}
      <GridContainer>
        <Grid item xs={12} sm={5} alignItems="center" container>
          <LabelUC label={t("employee.lblLastName")} isRequired={true} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextInputUC
            disabled={infoViewOnlyMode}
            placeholder={t("employee.lblLastName")}
            name="lastName"
            value={emp.lastName}
            onChange={handleChange}
            helperText={emp.lastNameError}
          />
        </Grid>
      </GridContainer>
      {/* Nick Name*/}
      <GridContainer>
        <Grid item xs={12} sm={5} alignItems="center" container>
          <LabelUC label={t("employee.lblNickName")} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextInputUC
            disabled={infoViewOnlyMode}
            placeholder={t("employee.lblNickName")}
            value={emp.nickName}
            name="nickName"
            onChange={handleChange}
          />
        </Grid>
      </GridContainer>
    </>
  );
};

export default NameTh;
