import React, { useEffect, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./ListUC.style";
import ListUCToolbar from "./ListUCToolbar";
import { Paper } from "@material-ui/core";
import ListGridView from "./EmployeeListGridView";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import { setInfoViewOnlyMode, setIsOnAdd, deleteList } from "redux/actions";
import PropTypes from "prop-types";
import { getList, onDelete } from "redux/actions/Default";
import { onFilterSearchList } from "services/default.service";
import { StripedDataGrid } from "./StripedDataGrid";

const ListUC = ({
  onAdd,
  onEdit,
  datas,
  cols,
  filterOptionsList,
  isShowAdd,
  isShowDelete = true
}) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [viewMode, setViewMode] = useState("table");
  const classes = useStyles();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  // const [disableSelection, setDisableSelection] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [overFlow, setOverFlow] = useState("");
  const { isDeleteList } = useSelector(({ common }) => common);
  // const [viewBy, setViewBy] = useState('all');
  const [searchText, setSearchText] = useState("");
  const [searchDatas, setSearchDatas] = useState(datas);
  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    dispatch(
      getList("", "", data => {
        // if you want to search in database push onFilterSearchList in below state.
        setSearchDatas(data);
        return () => {
          setSearchDatas([]); // clean up state
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    // search in redux
    setSearchDatas(onFilterSearchList(cols, datas, searchText));
    return () => {
      setSearchDatas([]); // clean up state
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions, searchText, datas]);

  useEffect(() => {
    if (isDeleteList) {
      setSelected([]);
      deleteList(false);
    }
  }, [isDeleteList]);

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    handleDeleteSeleted(selected);
    if (isDeleteList) {
      setSelected([]);
      deleteList(false);
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setSelected([]);
  };

  const onChangeViewMode = mode => {
    setViewMode(mode);
    setOverFlow(mode === "table" ? "" : "scroll");
    console.log(viewMode);
  };

  const handleAddClick = () => {
    dispatch(setInfoViewOnlyMode(false));
    dispatch(setIsOnAdd(true));
    onAdd();
  };

  const handleCellClick = (param, event) => {
    if (
      param.field !== "__check__" &&
      param.field !== "actions" &&
      selected.length === 0
    ) {
      dispatch(setInfoViewOnlyMode(true));
      dispatch(setIsOnAdd(false));
      onEdit(param.id);
    }
  };

  const handleDeleteSeleted = selected => {
    dispatch(onDelete(selected));
  };

  const onShowEmployeeDetail = id => {
    dispatch(setInfoViewOnlyMode(true));
    dispatch(setIsOnAdd(false));
    onEdit(id);
  };

  let actionCols = [
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 100,
      getActions: params => [
        < GridActionsCellItem
          icon={selected.length === 0 ? <EditIcon /> : <></>}
          label="Edit"
          onClick={selected.length === 0 ? editRow(params.id) : null}
        />,
        isShowDelete === true ?
          < GridActionsCellItem
            icon={selected.length === 0 ? <DeleteIcon /> : <></>}
            label="Delete"
            onClick={selected.length === 0 ? deleteRow(params.id) : null}
          />
          : <></>
      ]
    }
  ];

  const editRow = React.useCallback(
    id => () => {
      dispatch(setInfoViewOnlyMode(false));
      dispatch(setIsOnAdd(false));
      onEdit(id);
    },
    [dispatch, onEdit]
  );

  const deleteRow = React.useCallback(
    id => () => {
      setSelected([id]);
      setOpenConfirmDialog(true);
    },
    []
  );

  const combindCols = [...cols, ...actionCols];

  return (
    <Paper className={classes.paper}>
      <ListUCToolbar
        selected={selected}
        setSelected={setSelected}
        onChangeViewMode={onChangeViewMode}
        viewMode={viewMode}
        handleDeleteSeleted={handleDeleteSeleted}
        onAdd={handleAddClick}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        searchText={searchText}
        setSearchText={setSearchText}
        filterOptionsList={filterOptionsList}
        isShowAdd={isShowAdd}
      />
      <div
        style={{
          height: "calc(100% - 128px)",
          width: "100%",
          overflowY: overFlow,
          maxHeight: viewMode === "table" ? "calc(100% - 128px)" : "480px"
        }}
      >
        {viewMode === "table" ? (
          <StripedDataGrid
            className={classes.gridHeader}
            rows={searchDatas || []}
            columns={combindCols}
            onCellClick={handleCellClick}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onSelectionModelChange={newSelection => {
              setSelected(newSelection);
            }}
            checkboxSelection={isShowDelete}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            getRowClassName={params =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            initialState={
              datas
                ? {
                  ...datas.initialState,
                  columns: {
                    columnVisibilityModel: {
                      id: false
                    }
                  }
                }
                : {}
            }
          />
        ) : (
          <ListGridView
            onEdit={onEdit}
            datas={searchDatas || []}
            onShowEmployeeDetail={onShowEmployeeDetail}
          />
        )}
      </div>
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={"Are you sure, you want to  delete selected?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </Paper>
  );
};

ListUC.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  datas: PropTypes.array,
  cols: PropTypes.array.isRequired,
  filterOptionsList: PropTypes.array
};

export default React.memo(ListUC);
