import React, { useEffect, useState } from "react";
import TabHistory from "./tab-history";
import TabHeader from "./tab-header";
import TabGivePoint from "./tab-give-point";
import TabReward from "./tab-reward";
import {
  getStorageAuthenUser,
  setStorageControllerName
} from "services/Storage.service";
import { useDispatch, useSelector } from "react-redux";
import { getList } from "redux/actions/Default";
import InfoUC from "routes/shared/uc/info-uc";
import TotalPoint from "./total-point";

const MyPoint = () => {
  const dispatch = useDispatch();
  const authen = getStorageAuthenUser();
  const { infoViewOnlyMode } = useSelector(({ common }) => common);
  const [totalPoints, setTotalPoints] = useState(0);

  useEffect(() => {
    if (authen) {
      setStorageControllerName("myPoint");
      dispatch(
        getList("", authen.data.empId, data => {
          // console.log('getList', data);
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const propsHistory = { infoViewOnlyMode, setTotalPoints };
  const propsGivePoint = {
    infoViewOnlyMode,
    authen,
    setTotalPoints,
    totalPoints
  };
  const propsReward = { infoViewOnlyMode };

  const myPointForm = () => {
    return (
      <>
        <TotalPoint points={totalPoints} />
        <TabHeader
          tabHistory={<TabHistory props={propsHistory} />}
          tabGivePoint={<TabGivePoint props={propsGivePoint} />}
          tabReward={<TabReward props={propsReward} />}
        />
      </>
    );
  };
  return (
    <InfoUC
      children={myPointForm()}
      isShowDelete={false}
      isShowClose={false}
      isShowSave={false}
      isShowEdit={false}
    />
  );
};

export default MyPoint;
