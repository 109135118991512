import React from "react";
import ListUC from "routes/shared/uc/list-uc";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { setCurrentLocation } from "redux/actions/Location";
import { getById } from "redux/actions/Default";

const LocationList = ({ listViewClick }) => {
  const dispatch = useDispatch();
  const { locationsList } = useSelector(({ locations }) => locations);
  const { t, i18n } = useTranslation();
  const isLangEn = i18n.language === "en";

  const handleOnEdit = id => {
    const currentLocation = locationsList.filter(e => e.id === id);
    if (currentLocation) {
      dispatch(
        getById(currentLocation[0].id, currentLocation[0].companyId, () => {
          listViewClick({ action: "edit", id: id });
        })
      );
    }
  };

  const handleOnAdd = () => {
    dispatch(
      getById(0, 0, () => {
        dispatch(setCurrentLocation(null));
        listViewClick({ action: "add", id: "" });
      })
    );
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "locationCode",
      headerName: t("location.list.locationCode"),
      width: 160
    },
    {
      field: "locationName",
      headerName: t("location.list.locationName"),
      width: 220,
      flex: 1,
      valueGetter: params =>
        `${(isLangEn ? params.row.locationNameEn : params.row.locationName) ||
          ""} `
    },
    {
      field: "location",
      headerName: t("location.list.location"),
      width: 220,
      flex: 1,
      valueGetter: params =>
        `${params.row.latitude ?? ""} ${
          params.row.longitude ? " , " + params.row.longitude : ""
        }`
    }
  ];

  return (
    <>
      <ListUC
        datas={locationsList}
        cols={columns}
        onAdd={handleOnAdd}
        onEdit={handleOnEdit}
      />
    </>
  );
};

LocationList.propTypes = {
  listViewClick: PropTypes.func
};

export default React.memo(LocationList);
