import { SET_CURRENT_POSITION, SET_MAIN_POSITION } from "../../@jumbo/constants/ActionTypes";

export const setCurrentPosition = position => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_POSITION,
      payload: position
    });
  };
};

export const setMainPosition = position => {
  return dispatch => {
    dispatch({
      type: SET_MAIN_POSITION,
      payload: position
    });
  };
};