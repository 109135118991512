import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import { Box, Paper, TextField, Grid, Button, FormControlLabel, Checkbox } from "@mui/material";
import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import useStyles from "./index.style";
import { setStorageControllerName } from "services/Storage.service";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import axios from "../../../services/auth/jwt/config";
import liff from "@line/liff";

const LiffRegister = () => {
  const EmailRegExp = /^[A-Za-z0-9_.@]+$/;
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [enableRegist, setEnableRegist] = useState(false);

  const titleList = [
    { id: "1", caption: "นาย", captionEn: "นาย", name: "นาย" },
    { id: "2", caption: "นาง", captionEn: "นาง", name: "นาง" },
    { id: "3", caption: "นางสาว", captionEn: "นางสาว", name: "นางสาว" },
  ];

  useEffect(() => {
    setStorageControllerName("liff");
    formik.setFieldValue("titleName", "1");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheck = e => {
    setEnableRegist(e.target.checked);
  };
  const onSubmit = () => {
    setPage(2)
  }

  const ButtonSave = () => {
    if (formik.isValid) {
      axios.post("liff/create", formik.values).then((res) => {
        if (res.data.message !== undefined) {
          Swal.fire({
            title: "",
            text:
              "ชื่อบริษัท หรือ Email เคยมีการลงทะเบียนทดลองใช้งานเรียบร้อยแล้ว",
            icon: "error",
            confirmButtonText: "ยืนยัน",
            showClass: {
              popup: false,
            },
          });
        } else {
          Swal.fire({
            title: "",
            text: "ลงทะเบียนเข้าใช้งานระบบสำเร็จ",
            icon: "success",
            confirmButtonText: "ยืนยัน",
            showClass: {
              popup: false,
            },
          }).then(() => {
            liff.closeWindow();
          });
        }
      });
      // dispatch(
      //   onCreate(formik.values, (data) => {
      //     console.log(data)

      //     // if (data.message !== undefined) {
      //     //   Swal.fire({
      //     //     title: "",
      //     //     text:
      //     //       "ชื่อบริษัท หรือ Email เคยมีการลงทะเบียนทดลองใช้งานเรียบร้อยแล้ว",
      //     //     icon: "error",
      //     //     confirmButtonText: "ยืนยัน",
      //     //     showClass: {
      //     //       popup: false,
      //     //     },
      //     //   });
      //     // } else {
      //     //   Swal.fire({
      //     //     title: "",
      //     //     text: "ลงทะเบียนเข้าใช้งานระบบสำเร็จ",
      //     //     icon: "success",
      //     //     confirmButtonText: "ยืนยัน",
      //     //     showClass: {
      //     //       popup: false,
      //     //     },
      //     //   }).then(() => {
      //     //     liff.closeWindow();
      //     //   });
      //     // }
      //   })
      // );
    }
  };

  /* Form insert value */
  const formik = useFormik({
    initialValues: {
      companyName: "",
      titleName: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required("กรุณากรอกชื่อบริษัท"),
      firstName: Yup.string().required("กรุณากรอกชื่อ"),
      lastName: Yup.string().required("กรุณากรอกนามสกุล"),
      phone: Yup.string().required("กรุณากรอก เบอร์โทรศัพท์"),
      email: Yup.string()
        .matches(
          EmailRegExp,
          "* ขออภัย อนุญาตให้ใช้เฉพาะตัวอักษร (a-z), ตัวเลข (0-9) และเครื่องหมายมหัพภาค (.) เท่านั้น"
        )
        .email("* รูปแบบอีเมลไม่ถูกต้อง")
        .required("* กรุณากรอก Email"),
    }),
    // onSubmit: (values) => {
    //   dispatch(
    //     onCreate(values, (data) => {
    //       if (data.message !== undefined) {
    //         Swal.fire({
    //           title: "",
    //           text:
    //             "ชื่อบริษัท หรือ Email เคยมีการลงทะเบียนทดลองใช้งานเรียบร้อยแล้ว",
    //           icon: "error",
    //           confirmButtonText: "ยืนยัน",
    //           showClass: {
    //             popup: false,
    //           },
    //         });
    //       } else {
    //         Swal.fire({
    //           title: "",
    //           text: "ลงทะเบียนเข้าใช้งานระบบสำเร็จ",
    //           icon: "success",
    //           confirmButtonText: "ยืนยัน",
    //           showClass: {
    //             popup: false,
    //           },
    //         }).then(() => {
    //           liff.closeWindow();
    //         });
    //       }
    //     })
    //   );
    // },
  });
  console.log('page = ', page)
  return (
    <Paper elevation={3} className={classes.paper}>
      <Box
        p={2}
        sx={{ display: "flex", justifyContent: "center", width: "100%" }}
        className={classes.boxTitle}
      >
        <Typography variant="h1" className={classes.title}>
          ลงทะเบียนทดลองใช้งาน
        </Typography>
      </Box>
      {page === 1 && (
        <Box
          p={4}
          sx={{
            justifyContent: "center",
            width: "100%",
            height: "calc(100vh - 57.73px)",
            flexDirection: "column",
          }}
          className={classes.boxTitle}
        >
          <Box
            sx={{
              justifyContent: "center",
              width: "100%",
              height: "calc(100% - 200px)",
              borderRadius: "0.25rem",
              flexDirection: "column",
              backgroundColor: "white",
              overflowY: "auto",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
            }}
          >
            <p>
              <strong>นโยบายความเป็นส่วนตัวสำหรับลูกค้า</strong>
              <br></br>
              <br></br>
              บริษัท อันดีไฟน์ จำกัด ให้ความสำคัญกับการคุ้มครองข้อมูลส่วนบุคคลของคุณ
              โดยนโยบายความเป็นส่วนตัวฉบับนี้ได้อธิบายแนวปฏิบัติเกี่ยวกับการเก็บรวบรวม
              ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล รวมถึงสิทธิต่าง ๆ ของเจ้าของข้อมูลส่วนบุคคล
              ตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
              <br></br>
              <br></br>
              <strong>การเก็บรวบรวมข้อมูลส่วนบุคคล</strong>
              <br></br>
              เราจะเก็บรวบรวมข้อมูลส่วนบุคคลที่ได้รับโดยตรงจากคุณผ่านช่องทาง ดังต่อไปนี้
              <br></br>
              &emsp;● การสมัครสมาชิก
              <br></br>
              &emsp;● โทรศัพท์
              <br></br>
              &emsp;● อีเมล
              <br></br>
              <br></br>
              <strong>ประเภทข้อมูลส่วนบุคคลที่เก็บรวบรวม</strong>
              <br></br>
              <b>ข้อมูลส่วนบุคคล</b> เช่น ชื่อ นามสกุล อายุ วันเดือนปีเกิด สัญชาติ
              เลขประจำตัวประชาชน หนังสือเดินทาง เป็นต้น
              <br></br>
              <b>ข้อมูลการติดต่อ</b> เช่น ที่อยู่ หมายเลขโทรศัพท์ อีเมล เป็นต้น
              <br></br>
              <b>ข้อมูลบัญชี</b> เช่น บัญชีผู้ใช้งาน ประวัติการใช้งาน เป็นต้น
              <br></br>
              <b>หลักฐานแสดงตัวตน</b> เช่น สำเนาบัตรประจำตัวประชาชน สำเนาหนังสือเดินทาง
              เป็นต้น
              <br></br>
              <b>ข้อมูลการทำธุรกรรมและการเงิน</b> เช่น ประวัติการสั่งซื้อ
              รายละเอียดบัตรเครดิต บัญชีธนาคาร เป็นต้น
              <br></br>
              <b>ข้อมูลทางเทคนิค</b> เช่น IP address, Cookie ID,
              ประวัติการใช้งานเว็บไซต์ (Activity Log) เป็นต้น
              <br></br>
              <b>ข้อมูลอื่น ๆ</b> เช่น รูปภาพ ภาพเคลื่อนไหว
              และข้อมูลอื่นใดที่ถือว่าเป็นข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
              <br></br>
              &emsp;เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลอ่อนไหว ดังต่อไปนี้
              เมื่อเราได้รับความยินยอมโดยชัดแจ้งจากคุณ เว้นแต่กฎหมายกำหนดให้ทำได้
              <br></br>
              &emsp;● เชื้อชาติ
              <br></br>
              &emsp;● ศาสนาหรือปรัชญา
              <br></br>
              ข้อมูลอื่นใดที่กระทบต่อข้อมูลส่วนบุคคลของคุณตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด
              <br></br>
              <br></br>
              <strong>ผู้เยาว์</strong>
              <br></br>
              &emsp;หากคุณมีอายุต่ำกว่า 20 ปีหรือมีข้อจำกัดความสามารถตามกฎหมาย
              เราอาจเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ
              เราอาจจำเป็นต้องให้พ่อแม่หรือผู้ปกครองของคุณให้ความยินยอมหรือที่กฎหมายอนุญาตให้ทำได้
              <br></br>
              &emsp;หากเราทราบว่ามีการเก็บรวบรวมข้อมูลส่วนบุคคลจากผู้เยาว์โดยไม่ได้รับความยินยอมจากพ่อแม่หรือผู้ปกครอง
              เราจะดำเนินการลบข้อมูลนั้นออกจากเซิร์ฟเวอร์ของเรา
              <br></br>
              <br></br>
              <strong>วิธีการเก็บรักษาข้อมูลส่วนบุคคล</strong>
              <br></br>
              เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณในรูปแบบเอกสารและรูปแบบอิเล็กทรอนิกส์
              เราเก็บรักษาข้อมูลส่วนบุคคลของคุณ ดังต่อไปนี้
              <br></br>
              &emsp;●	ผู้ให้บริการเซิร์ฟเวอร์ในประเทศไทย
              <br></br>
              <br></br>
              <strong>การประมวลผลข้อมูลส่วนบุคคล</strong>
              <br></br>
              &emsp;เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ดังต่อไปนี้
              <br></br>
              &emsp;●	เพื่อสร้างและจัดการบัญชีผู้ใช้งาน
              <br></br>
              &emsp;●	เพื่อปรับปรุงสินค้า บริการ หรือประสบการณ์การใช้งาน
              <br></br>
              &emsp;●	เพื่อการบริหารจัดการภายในบริษัท
              <br></br>
              &emsp;●	เพื่อการตลาดและการส่งเสริมการขาย
              <br></br>
              &emsp;●	เพื่อการบริการหลังการขาย
              <br></br>
              &emsp;●	เพื่อรวบรวมข้อเสนอแนะ
              <br></br>
              &emsp;●	เพื่อปฏิบัติตามข้อตกลงและเงื่อนไข (Terms and Conditions)
              <br></br>
              &emsp;●	เพื่อปฏิบัติตามกฎหมายและกฎระเบียบของหน่วยงานราชการ
              <br></br>
              <br></br>
              <strong>การบริหารจัดการภายในองค์กร</strong>
              <br></br>
              &emsp;เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณภายในบริษัทเท่าที่จำเป็นเพื่อปรับปรุงและพัฒนาสินค้า
              หรือบริการของเรา เราอาจรวบรวมข้อมูลภายในสำหรับสินค้าหรือบริการต่าง ๆ
              ภายใต้นโยบายนี้เพื่อประโยชน์ของคุณและผู้อื่นมากขึ้น
              <br></br>
              <br></br>
              <strong>ผู้ให้บริการ</strong>
              <br></br>
              &emsp;เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณบางอย่างให้กับผู้ให้บริการของเราเท่าที่จำเป็น
              เพื่อดำเนินงานในด้านต่าง ๆ เช่น การชำระเงิน การตลาด การพัฒนาสินค้าหรือบริการ เป็นต้น
              ทั้งนี้ ผู้ให้บริการมีนโยบายความเป็นส่วนตัวของตนเอง
              <br></br>
              <br></br>
              <strong>ระยะเวลาจัดเก็บข้อมูลส่วนบุคคล</strong>
              <br></br>
              &emsp;เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณไว้ตามระยะเวลาที่จำเป็นในระหว่างที่คุณเป็นลูกค้าหรือมีความสัมพันธ์
              อยู่กับเราหรือตลอดระยะเวลาที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวข้องกับนโยบายฉบับนี้
              ซึ่งอาจจำเป็นต้องเก็บรักษาไว้ต่อไปภายหลังจากนั้น หากมีกฎหมายกำหนดไว้ เราจะลบ ทำลาย
              หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุตัวตนของคุณได้ เมื่อหมดความจำเป็นหรือสิ้นสุดระยะเวลาดังกล่าว
              <br></br>
              <br></br>
              <strong>สิทธิของเจ้าของข้อมูลส่วนบุคคล</strong>
              <br></br>
              ภายใต้กฎหมายคุ้มครองข้อมูลส่วนบุคคล  คุณมีสิทธิในการดำเนินการดังต่อไปนี้
              <br></br>
              <b>สิทธิขอถอนความยินยอม (right to withdraw consent) </b> หากคุณได้ให้ความยินยอม
              เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ ไม่ว่าจะเป็นความยินยอมที่คุณให้ไว้ก่อน
              วันที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลใช้บังคับหรือหลังจากนั้น คุณมีสิทธิที่จะถอนความยินยอมเมื่อใดก็ได้ตลอดเวลา
              <br></br>
              <b>สิทธิขอเข้าถึงข้อมูล (right to access)</b> คุณมีสิทธิขอเข้าถึงข้อมูลส่วนบุคคลของคุณที่อยู่ในความรับผิดชอบของเรา
              และขอให้เราทำสำเนาข้อมูลดังกล่าวให้แก่คุณ  รวมถึงขอให้เราเปิดเผยว่าเราได้ข้อมูลส่วนบุคคลของคุณมาได้อย่างไร
              <br></br>
              <b>สิทธิขอถ่ายโอนข้อมูล (right to data portability)</b> คุณมีสิทธิขอรับข้อมูลส่วนบุคคลของคุณ
              ในกรณีที่เราได้จัดทำข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบให้สามารถอ่านหรือใช้งานได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติ
              และสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ รวมทั้งมีสิทธิขอให้เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าว
              ไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นเมื่อสามารถทำได้ด้วยวิธีการอัตโนมัติ และมีสิทธิขอรับข้อมูลส่วนบุคคลที่เราส่ง
              หรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นโดยตรง เว้นแต่ไม่สามารถดำเนินการได้เพราะเหตุทางเทคนิค
              <br></br>
              <b>สิทธิขอคัดค้าน (right to object)</b> คุณมีสิทธิขอคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณในเวลาใดก็ได้
              หากการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณที่ทำขึ้นเพื่อการดำเนินงานที่จำเป็นภายใต้ประโยชน์โดยชอบด้วยกฎหมายของเรา
              หรือของบุคคลหรือนิติบุคคลอื่น โดยไม่เกินขอบเขตที่คุณสามารถคาดหมายได้อย่างสมเหตุสมผลหรือเพื่อดำเนินการตามภารกิจเพื่อสาธารณประโยชน์
              <br></br>
              <b>สิทธิขอให้ลบหรือทำลายข้อมูล (right to erasure/destruction)</b> คุณมีสิทธิขอลบหรือทำลายข้อมูลส่วนบุคคลของคุณ
              หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวคุณได้ หากคุณเชื่อว่าข้อมูลส่วนบุคคลของคุณถูกเก็บรวบรวม ใช้
              หรือเปิดเผยโดยไม่ชอบด้วยกฎหมายที่เกี่ยวข้องหรือเห็นว่าเราหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ที่เกี่ยวข้องในนโยบายฉบับนี้
              หรือเมื่อคุณได้ใช้สิทธิขอถอนความยินยอมหรือใช้สิทธิขอคัดค้านตามที่แจ้งไว้ข้างต้นแล้ว
              <br></br>
              <b>สิทธิขอให้ระงับการใช้ข้อมูล (right to restriction of processing)</b> คุณมีสิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคลชั่วคราว
              ในกรณีที่เราอยู่ระหว่างตรวจสอบตามคำร้องขอใช้สิทธิขอแก้ไขข้อมูลส่วนบุคคลหรือขอคัดค้านของคุณหรือกรณีอื่นใดที่เราหมดความจำเป็น
              และต้องลบหรือทำลายข้อมูลส่วนบุคคลของคุณตามกฎหมายที่เกี่ยวข้องแต่คุณขอให้เราระงับการใช้แทน
              <br></br>
              <b>สิทธิขอให้แก้ไขข้อมูล (right to rectification)</b> คุณมีสิทธิขอแก้ไขข้อมูลส่วนบุคคลของคุณให้ถูกต้อง
              เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
              <br></br>
              <b>สิทธิร้องเรียน (right to lodge a complaint)</b> คุณมีสิทธิร้องเรียนต่อผู้มีอำนาจตามกฎหมายที่เกี่ยวข้อง
              หากคุณเชื่อว่าการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ เป็นการกระทำในลักษณะที่ฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายที่เกี่ยวข้อง
              <br></br>
              &emsp;คุณสามารถใช้สิทธิของคุณในฐานะเจ้าของข้อมูลส่วนบุคคลข้างต้นได้ โดยติดต่อมาที่เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเรา
              ตามรายละเอียดท้ายนโยบายนี้ เราจะแจ้งผลการดำเนินการภายในระยะเวลา 30 วัน นับแต่วันที่เราได้รับคำขอใช้สิทธิจากคุณ
              ตามแบบฟอร์มหรือวิธีการที่เรากำหนด ทั้งนี้ หากเราปฏิเสธคำขอเราจะแจ้งเหตุผลของการปฏิเสธให้คุณทราบผ่านช่องทางต่าง ๆ
              เช่น ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น
              <br></br>
              <br></br>
              <strong>การโฆษณาและการตลาด</strong>
              <br></br>
              &emsp;เราอาจส่งข้อมูลหรือจดหมายข่าวไปยังอีเมลของคุณ โดยมีวัตถุประสงค์เพื่อเสนอสิ่งที่น่าสนกับคุณ
              หากคุณไม่ต้องการรับการติดต่อสื่อสารจากเราผ่านทางอีเมลอีกต่อไป คุณสามารถกด "ยกเลิกการติดต่อ"
              ในลิงก์อีเมลหรือติดต่อมายังอีเมลของเราได้
              <br></br>
              <br></br>
              <strong>เทคโนโลยีติดตามตัวบุคคล (Cookies)</strong>
              <br></br>
              &emsp;เพื่อเพิ่มประสบการณ์การใช้งานของคุณให้สมบูรณ์และมีประสิทธิภาพมากขึ้น เราใช้คุกกี้ (Cookies)
              หรือเทคโนโลยีที่คล้ายคลึงกัน เพื่อพัฒนาการเข้าถึงสินค้าหรือบริการ โฆษณาที่เหมาะสม และติดตามการใช้งานของคุณ
              เราใช้คุกกี้เพื่อระบุและติดตามผู้ใช้งานเว็บไซต์ และการเข้าถึงเว็บไซต์ของเรา หากคุณไม่ต้องการให้มีคุกกี้ไว้ในคอมพิวเตอร์ของคุณ
              คุณสามารถตั้งค่าบราวเซอร์เพื่อปฏิเสธคุกกี้ก่อนที่จะใช้เว็บไซต์ของเราได้
              <br></br>
              <br></br>
              <strong>การรักษาความมั่งคงปลอดภัยของข้อมูลส่วนบุคคล</strong>
              <br></br>
              &emsp;เราจะรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของคุณไว้ตามหลักการ การรักษาความลับ (confidentiality)
              ความถูกต้องครบถ้วน (integrity) และสภาพพร้อมใช้งาน (availability) ทั้งนี้ เพื่อป้องกันการสูญหาย เข้าถึง
              ใช้ เปลี่ยนแปลง แก้ไข หรือเปิดเผย นอกจากนี้เราจะจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล
              ซึ่งครอบคลุมถึงมาตรการป้องกันด้านการบริหารจัดการ (administrative safeguard) มาตรการป้องกันด้านเทคนิค (technical safeguard)
              และมาตรการป้องกันทางกายภาพ (physical safeguard) ในเรื่องการเข้าถึงหรือควบคุมการใช้งานข้อมูลส่วนบุคคล (access control)
              <br></br>
              <br></br>
              <strong>การแจ้งเหตุละเมิดข้อมูลส่วนบุคคล</strong>
              <br></br>
              &emsp;ในกรณีที่มีเหตุละเมิดข้อมูลส่วนบุคคลของคุณเกิดขึ้น เราจะแจ้งให้สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลทราบ
              โดยไม่ชักช้าภายใน 72 ชั่วโมง นับแต่ทราบเหตุเท่าที่สามารถกระทำได้ ในกรณีที่การละเมิดมีความเสี่ยงสูงที่จะมีผลกระทบ
              ต่อสิทธิและเสรีภาพของคุณ เราจะแจ้งการละเมิดให้คุณทราบพร้อมกับแนวทางการเยียวยาโดยไม่ชักช้าผ่านช่องทางต่าง ๆ เช่น
              เว็บไซต์ ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น
              <br></br>
              <br></br>
              <strong>การแก้ไขเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</strong>
              <br></br>
              &emsp;เราอาจแก้ไขเปลี่ยนแปลงนโยบายนี้เป็นครั้งคราว โดยคุณสามารถทราบข้อกำหนดและเงื่อนไขนโยบาย
              ที่มีการแก้ไขเปลี่ยนแปลงนี้ได้ผ่านทางเว็บไซต์ของเรานโยบายนี้แก้ไขล่าสุดและมีผลใช้บังคับตั้งแต่วันที่ 01 มิถุนายน 2565
              <br></br>
              <br></br>
              <strong>นโยบายความเป็นส่วนตัวของเว็บไซต์อื่น</strong>
              <br></br>
              &emsp;นโยบายความเป็นส่วนตัวฉบับนี้ใช้สำหรับการเสนอสินค้า บริการ และการใช้งานบนเว็บไซต์สำหรับลูกค้าของเราเท่านั้น
              หากคุณเข้าชมเว็บไซต์อื่นแม้จะผ่านช่องทางเว็บไซต์ของเรา การคุ้มครองข้อมูลส่วนบุคคลต่าง ๆ
              จะเป็นไปตามนโยบายความเป็นส่วนตัวของเว็บไซต์นั้น ซึ่งเราไม่มีส่วนเกี่ยวข้องด้วย
              <br></br>
              <br></br>
            </p>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              paddingTop: "1rem",
              paddingBottom: "2.5rem"
            }}
            flexDirection={{ xs: "column", md: "row" }}>
            <FormControlLabel
              className={classes.labelAgree}
              control={<Checkbox
                sx={{
                  color: '#fff',
                  '&.Mui-checked': {
                    color: '#fff !important',
                  },
                }}
                onChange={handleCheck}
              />}
              label="ข้าพเจ้าได้อ่าน และยอมรับข้อกำหนดเงื่อนไข และนโยบายความเป็นส่วนตัว"
              sx={{ marginRight: 1 }}
            />
          </Box>
          <Box
            sx={{ display: "flex", width: "100%" }}
            flexDirection={{ xs: "column", md: "row" }}
          >
            <Box mb={{ xs: 2, sm: 0 }} width={{ xs: "100%", md: "30%" }}>
              <Button
                onClick={onSubmit}
                variant="outlined"
                size="large"
                className={classes.btnAccept}
                disabled={!enableRegist}
              >
                ตกลง
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      {
        page === 2 && (
          <Box
            p={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TextField
                label={"ชื่อบริษัท *"}
                fullWidth
                margin="normal"
                // variant="outlined"
                variant="standard"
                className={classes.textFieldRoot}
                id="companyName"
                name="companyName"
                value={formik.values.companyName}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.handleChange(event);
                }}
                error={
                  formik.touched.companyName && Boolean(formik.errors.companyName)
                }
                helperText={formik.touched.companyName && formik.errors.companyName}
                maxLength={200}
              />
            </Box>
            <Box sx={{ display: "flex", width: "100%", flexDirection: "row" }}>
              <Grid item xs={3} alignItems="center" container mr={1}>
                <AppSelectBox
                  fullWidth
                  data={titleList}
                  variant="standard"
                  name="title"
                  onChange={(event) => {
                    formik.setFieldValue("titleName", event.target.value);
                  }}
                  value={formik.values.titleName}
                  className={classes.titleRoot}
                // error={!!titleErr}
                // helperText={titleErr}
                />
              </Grid>
              <Grid item xs={9} alignItems="center" container>
                <TextField
                  label={"ชื่อ *"}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  className={classes.textFieldRoot}
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    formik.handleChange(event);
                  }}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={formik.touched.firstName && formik.errors.firstName}
                  maxLength={100}
                />
              </Grid>
            </Box>
            <Box sx={{ display: "flex", width: "100%" }}>
              <TextField
                label={"นามสกุล *"}
                fullWidth
                onChange={(event) =>
                  formik.setFieldValue("lastName", event.target.value)
                }
                value={formik.values.lastName}
                margin="normal"
                variant="standard"
                id="lastName"
                name="lastName"
                className={classes.textFieldRoot}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
                onBlur={formik.handleBlur}
                maxLength={100}
              />
            </Box>
            <Box sx={{ display: "flex", width: "100%" }}>
              <TextField
                fullWidth
                margin="normal"
                variant="standard"
                type="text"
                className={classes.textFieldRoot}
                label="Email *"
                id="email"
                name="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.handleChange(event);
                }}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                maxLength={100}
              />
            </Box>
            <Box sx={{ display: "flex", width: "100%" }}>
              <InputMask
                mask="099-999-9999"
                disabled={false}
                maskChar=" "
                type="text"
                onBlur={formik.handleBlur}
                id="phone"
                name="phone"
                onChange={(e) => {
                  formik.setFieldValue("phone", e.target.value);
                }}
              >
                {() => (
                  <TextField
                    label={"เบอร์โทรศัพท์ *"}
                    fullWidth
                    variant="standard"
                    className={classes.textFieldRoot}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                )}
              </InputMask>
            </Box>
            <Box
              mt={3}
              sx={{ display: "flex", width: "100%", flexDirection: "center" }}
            >
              <Button
                onClick={() => {
                  formik.handleSubmit();
                  ButtonSave();
                }}
                variant="contained"
                color="primary"
                size="large"
                className={classes.btnRegister}
              >
                {"ลงทะเบียน"}
              </Button>
            </Box>
          </Box>
        )
      }
    </Paper>
  );
};

export default LiffRegister;
