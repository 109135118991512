import React from "react";
// import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import { TextField } from "@mui/material";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const filter = createFilterOptions();

const useStyles = makeStyles(theme => ({
  formControlRoot: {
    "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
      paddingTop: "1px !important",
      paddingBottom: "1px !important"
    }
  }
}));

const AutoCompleteUC = ({
  datas,
  isMultipleLine,
  placeholder,
  infoViewOnlyMode,
  handleClickOpen,
  returnInputValue,
  isShowPicker,
  isCanAdd,
  value,
  handleChange,
  helperText,
  error
}) => {
  // const [value, setValue] = useState(null);
  const classes = useStyles();

  // console.log(value)
  return (
    <Autocomplete
      disabled={infoViewOnlyMode}
      className={classes.formControlRoot}
      // popupIcon={
      //   <>
      //     {" "}
      //     {isShowPicker ? (
      //       <FormatListBulletedIcon onClick={handleClickOpen} />
      //     ) : (
      //       <ArrowDropDownIcon />
      //     )}{" "}
      //   </>
      // }
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          // เพิ่มเอง
          if (isShowPicker) {
            returnInputValue(newValue);
            setTimeout(() => {
              handleClickOpen(true);
            }, 100);
          } else {
            handleChange(newValue);
          }
        } else if (newValue && newValue.inputValue) {
          console.log("newValue else if", newValue);
          // Create a new value from the user input
          if (isShowPicker) {
            returnInputValue(newValue.inputValue);
            setTimeout(() => {
              handleClickOpen(true);
            }, 100);
          } else {
            handleChange(newValue.inputValue);
          }
        } else {
          // เลือกจากรายการที่มีอยู่
          handleChange(newValue);
        }
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (isCanAdd) {
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            option => inputValue === option.title
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add "${inputValue}"`
            });
          }
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      // handleHomeEndKeys
      options={datas}
      getOptionLabel={option => {
        // ส่วนที่แสดงผลใน element (dropdown list)
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          // พิมพ์เองแล้วกด Add
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(props, option) => (
        <li {...props}>
          <Box
            sx={{
              flexGrow: 1,
              "& span": {
                color: "#8b949e"
              }
            }}
          >
            {option.title}
            <br />
            {isMultipleLine && <span>{option.description}</span>}
          </Box>
        </li>
      )}
      freeSolo={isCanAdd}
      renderInput={params => (
        <TextField
          {...params}
          InputLabelProps={{ shrink: false }}
          placeholder={placeholder}
          variant="outlined"
          disabled={infoViewOnlyMode}
          helperText={helperText}
          error={error}
          maxLength="100"
        />
      )}
    />
  );
};

AutoCompleteUC.propType = {
  datas: PropTypes.array.isRequired,
  infoViewOnlyMode: PropTypes.bool,
  handleClickOpen: PropTypes.func,
  isShowPicker: PropTypes.bool,
  isCanAdd: PropTypes.bool,
  isMultipleLine: PropTypes.bool
};

AutoCompleteUC.defaultProps = {
  infoViewOnlyMode: false,
  isShowPicker: false,
  isCanAdd: false,
  placeholder: "Select Item",
  isMultipleLine: false
};

export default AutoCompleteUC;
