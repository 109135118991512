import React from 'react'
import { Box } from '@mui/material';
import AddressUC from 'routes/shared/uc/address-uc';
import { Button } from '@material-ui/core';
import useStyles from './index.style';

const CurrentAddress = ({ infoViewOnlyMode, currentEmpAddress, setCurrentEmpAddress, handleCopyToHomeAddress }) => {
    const mapKey = 'b69be858e00b3fa59086597397a441e5'
    const classes = useStyles();
    // console.log(currentEmpAddress)
    return (
        <>
            <AddressUC
                infoViewOnlyMode={infoViewOnlyMode}
                address={currentEmpAddress}
                setAddress={setCurrentEmpAddress}
                mapKey={mapKey} />
            <Box sx={{ display: 'flex', width: '100%', padding: 2 }}
                justifyContent='flex-end'
                flexDirection={{ xs: 'column', md: 'row' }} >
                <Button variant="contained" color="primary" className={classes.btnCopy} onClick={handleCopyToHomeAddress} disabled={infoViewOnlyMode}>
                    Copy to home address
                </Button>
            </Box>
        </>
    )
}

export default CurrentAddress;