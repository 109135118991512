import React, { useState, useEffect } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import InfoAppBarUC from "./InfoAppBarUC";
import PropTypes from "prop-types";
import ConfirmDialog from "@jumbo/components/Common/ConfirmDialog";
import {
  setInfoViewOnlyMode,
  setIsDataModified,
  setIsOpeningInfo
} from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./index.style";
import { Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const InfoUC = ({
  handleDialog,
  children,
  isShowDelete,
  isShowEdit,
  isShowSave,
  isShowClose
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDataChangeDialog, setOpenDataChangeDialog] = useState(false);
  const { isDataModified } = useSelector(({ common }) => common);
  const { t } = useTranslation();

  const handleInfoDialog = action => {
    switch (action) {
      case "delete":
        setOpenConfirmDialog(true);
        break;
      case "edit":
        dispatch(setInfoViewOnlyMode(false));
        break;
      case "close":
        if (isDataModified) {
          setOpenDataChangeDialog(true);
        } else {
          handleDialog("close");
        }
        break;
      case "save":
        if (isDataModified) {
          handleDialog(action);
        }
        break;
      default:
        handleDialog(action);
        break;
    }
  };

  useEffect(() => {
    dispatch(setIsOpeningInfo(true));
  }, [dispatch]);

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    handleDialog("delete");
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmSave = () => {
    setOpenDataChangeDialog(false);
    handleDialog("save");
  };

  const handleCancelSave = () => {
    setOpenDataChangeDialog(false);
  };

  const handleNoSave = () => {
    setOpenDataChangeDialog(false);
    handleDialog("close");
    dispatch(setIsDataModified(false));
    dispatch(setIsOpeningInfo(false));
  };

  return (
    <Paper className={classes.paper}>
      <InfoAppBarUC
        handleDialog={handleInfoDialog}
        isShowDelete={isShowDelete}
        isShowEdit={isShowEdit}
        isShowSave={isShowSave}
        isShowClose={isShowClose}
      />
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete`}
        content={t("common.confirmDelete")}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
      <ConfirmDialog
        open={openDataChangeDialog}
        title={`Warning`}
        content={t("common.confirmChangeSave")}
        onClose={handleCancelSave}
        onNo={handleNoSave}
        onConfirm={handleConfirmSave}
      />
    </Paper>
  );
};

InfoUC.proptype = {
  handleDialog: PropTypes.func.isRequired,
  children: PropTypes.element,
  isShowDelete: PropTypes.bool,
  isShowEdit: PropTypes.bool,
  isShowSave: PropTypes.bool,
  isShowClose: PropTypes.bool
};

export default InfoUC;
