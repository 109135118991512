import React from 'react'
import AddressUC from 'routes/shared/uc/address-uc';
import { Box } from '@mui/material';


const Address = ({ props }) => {
    const {
        companyAddress, setCompanyAddress,
        infoViewOnlyMode
    } = props;
    const mapKey = 'b69be858e00b3fa59086597397a441e5'

    return (
        <>
            <AddressUC
                infoViewOnlyMode={infoViewOnlyMode}
                address={companyAddress}
                setAddress={setCompanyAddress}
                mapKey={mapKey} />
            <Box sx={{ display: 'flex', width: '100%', padding: 2 }}
                justifyContent='flex-end'
                flexDirection={{ xs: 'column', md: 'row' }} >
            </Box>
        </>
    )
}

export default Address