import React from "react";
import GridContainer from "@jumbo/components/GridContainer";
import { Box, Button, Grid } from "@mui/material";
import LabelUC from "routes/shared/uc/label-uc";
import { useTranslation } from "react-i18next";
import TextInputUC from "routes/shared/uc/text-input-uc";
import ShiftDetail from "./info-detail";
import ShiftHistory from "./shift-history";
import HistoryIcon from "@mui/icons-material/History";

const Shift = ({ props }) => {
  const { infoViewOnlyMode, shift, handleChange, shiftDetail } = props;
  const [openHistory, setOpenHistory] = React.useState(false);
  const { t } = useTranslation();

  const showHistory = () => {
    setOpenHistory(true);
  };

  const handleDialog = () => {
    setOpenHistory(false);
  };

  return (
    <>
      <Box
        sx={{ display: "flex", width: "100%" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Box sx={{ padding: 2, width: { xs: "100%" } }} m="auto">
          {/* Shift Code */}
          <GridContainer>
            <Grid item xs={12} sm={3} alignItems="center" container>
              <LabelUC label={t("shift.lblShiftCode")} isRequired={true} />
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextInputUC
                disabled={infoViewOnlyMode}
                placeholder={t("shift.lblShiftCode")}
                value={shift.shiftCode}
                name="shiftCode"
                onChange={handleChange}
                helperText={shift.shiftCodeError}
              />
            </Grid>
          </GridContainer>
          {/* Shift Name */}
          <GridContainer>
            <Grid item xs={12} sm={3} alignItems="center" container>
              <LabelUC label={t("shift.lblShiftName")} isRequired={true} />
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextInputUC
                disabled={infoViewOnlyMode}
                placeholder={t("shift.lblShiftName")}
                value={shift.shiftName}
                name="shiftName"
                onChange={e => handleChange(e)}
                helperText={shift.shiftNameError}
              />
            </Grid>
          </GridContainer>

          {/* Shift Detail */}
          <ShiftDetail props={props} />
          {/* History */}
          <GridContainer>
            <Grid item xs={12} sm={3} alignItems="center" container></Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<HistoryIcon />}
                fullWidth
                sx={{ fontWeight: "bold" }}
                onClick={() => showHistory()}
              >
                {t("shift.lblHistory")}
              </Button>
            </Grid>
          </GridContainer>
        </Box>
      </Box>
      {openHistory && (
        <ShiftHistory
          handleDialog={handleDialog}
          openInfo={openHistory}
          id={shiftDetail ? shiftDetail.id : null}
        />
      )}
    </>
  );
};

export default Shift;
