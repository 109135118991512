import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useTranslation } from "react-i18next";

export default function TabHeader(props) {
  const [value, setValue] = useState("general");
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // console.log('role', props.roleUser)
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="fullWidth"
          >
            <Tab label={t("profile.general.general")} value="general" />
            <Tab
              label={t("profile.employment.employment")}
              value="employment"
            />
            <Tab label={t("profile.user.user")} value="user" />
            {/* <Tab label="Test Auto Complete" value="payment" /> */}
            {/* <Tab label="Tax and Social Security" value="tax" /> */}
          </TabList>
        </Box>
        <TabPanel value="general">{props.tabGeneral}</TabPanel>
        <TabPanel value="employment">{props.tabEmployment}</TabPanel>
        <TabPanel value="user">{props.tabUser}</TabPanel>
        {/* <TabPanel value="payment">{props.tabPayment}</TabPanel> */}
        {/* <TabPanel value="tax">
                    {props.tabTax}
                </TabPanel> */}
      </TabContext>
    </Box>
  );
}
