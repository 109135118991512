import { GET_REPORT_RANGE } from "../../@jumbo/constants/ActionTypes";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import axios from "services/auth/jwt/config";
import {
  getStorageAuthenUser,
  getStorageControllerName
} from "services/Storage.service";
import { errorMessage } from "./Default";

// params: 'department,position,employee' or null
export const getReportRange = (params = [], callbackFun = null) => {
  const authen = getStorageAuthenUser();
  const range = params && params.length > 0 ? params.join(",") : "";
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get("report/getReportRange/" + range + "/" + authen.data.companyId)
      .then(data => {
        // console.log("data", data);
        if (data.status === 200) {
          dispatch({
            type: GET_REPORT_RANGE,
            payload: data.data
          });
          dispatch(fetchSuccess());
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Something went wrong"));
        }
      })
      .catch(error => {
        errorMessage(error, dispatch);
      });
  };
};

export const getReportData = (params, callbackFun = null) => {
  const authen = getStorageAuthenUser();
  const controllerName = getStorageControllerName();
  params = "/" + params + "/" + authen.data.companyId;
  // console.log('authen', authen);
  // console.log("getReportData", params);
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get("report/" + controllerName + params)
      .then(data => {
        if (data.status === 200) {
          dispatch({
            type: "get_" + controllerName + "_report",
            payload: data.data
          });
          console.log("data", data.data);
          dispatch(fetchSuccess());
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Something went wrong"));
        }
      })
      .catch(error => {
        errorMessage(error, dispatch);
      });
  };
};
