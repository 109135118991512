import {
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  SET_SIGNUP_ENUM,
  SET_RESET_PASSWORD
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  authUser: null,
  loadUser: false,
  send_forget_password_email: false,
  set_reset_password: false,
  enumTitle: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        authUser: action.payload,
        loadUser: true
      };
    }
    case UPDATE_LOAD_USER: {
      return {
        ...state,
        loadUser: action.payload
      };
    }
    case SEND_FORGET_PASSWORD_EMAIL: {
      return {
        ...state,
        send_forget_password_email: action.payload
      };
    }
    case SET_RESET_PASSWORD: {
      return {
        ...state,
        set_reset_password: action.payload
      };
    }

    case SET_SIGNUP_ENUM: {
      return {
        ...state,
        enumTitle: action.payload
      };
    }
    default:
      return state;
  }
};
