import { makeStyles } from '@material-ui/core';
// import { hexToRgba } from '../CmtHelpers/JssHelper';
// import { amber, blue, brown, deepOrange, green, grey, purple, red, yellow } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    labelProps: {
        width: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center'
    },
    formControlRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[400],
        },
    },
}))

export default useStyles;