import {
  SET_CURRENT_LOCATION,
  SET_ALL_EMP_LOCATION
} from "../../@jumbo/constants/ActionTypes";

export const setCurrentLocation = location => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_LOCATION,
      payload: location
    });
  };
};

export const setLocationById = (data, callbackFun = null) => {
  let location = null;
  return dispatch => {
    location = data.data.location;
    if (location && data.data.empLocation && data.data.empLocation.length > 0) {
      location.empLocation = data.data.empLocation;
    }
    dispatch({ type: SET_CURRENT_LOCATION, payload: location });
    dispatch({ type: SET_ALL_EMP_LOCATION, payload: data.data.allEmpLocation });

    if (callbackFun) callbackFun(location);
  };
};
