import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import DateTimePickerUC from "routes/shared/uc/datetime-picker-uc";
import LabelUC from "routes/shared/uc/label-uc";
import { useTranslation } from "react-i18next";
// import { enumWorkingStatus, enumEmpType } from "../empEnum";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import { useSelector } from "react-redux";
import AutoCompleteUC from "routes/shared/uc/autocomplete-uc";
import useStyles from "./index.style";

const WorkingInfo = ({ props }) => {
  const {
    // viewOnly,
    viewOnly,
    employment,
    setEmployment,
    handleDateChangeEmployment,
    handleChangeEmployment
  } = props;

  const { t, i18n } = useTranslation();
  const isLangEn = i18n.language === "en";
  const classes = useStyles();

  const [departmentBind, setDepartmentBind] = useState(null);
  const [positionBind, setPositionBind] = useState(null);
  const [supervisorBind, setSupervisorBind] = useState(null);

  const { departmentList, positionList, supervisorList, empEnum } = useSelector(
    ({ employees }) => employees
  );

  const enumEmpType = empEnum.filter(e => e.type === "empType");
  const enumWorkingStatus = empEnum.filter(e => e.type === "workingStatus");

  let departmentDataList = [];
  let positionDataList = [];
  let supervisorDataList = [];
  if (departmentList) {
    departmentDataList = departmentList.map(item => ({
      id: item.id,
      title: `${(isLangEn
        ? item.departmentNameEn || item.departmentName
        : item.departmentName) || ""} `,
      description: item.departmentCode
    }));
  }

  if (positionList) {
    positionDataList = positionList.map(item => ({
      id: item.id,
      title: `${(isLangEn
        ? item.positionNameEn || item.positionName
        : item.positionName) || ""} `,
      description: item.positionCode
    }));
  }

  if (supervisorList) {
    supervisorDataList = supervisorList.map(item => ({
      id: item.id,
      title: `${(isLangEn ? item.firstNameEn : item.firstName) ||
        ""} ${(isLangEn ? item.lastNameEn : item.lastName) || ""}`,
      description: item.empCode
    }));
  }

  useEffect(() => {
    // Set Value
    if (departmentDataList) {
      const data = departmentDataList.filter(
        item => item.id === employment.departmentId
      );
      setDepartmentBind(data && data.length > 0 ? data[0] : null);
    }

    if (positionDataList) {
      const data = positionDataList.filter(
        item => item.id === employment.positionId
      );
      setPositionBind(data && data.length > 0 ? data[0] : null);
    }

    if (supervisorDataList) {
      const data = supervisorDataList.filter(
        item => item.id === employment.supervisorId
      );
      setSupervisorBind(data && data.length > 0 ? data[0] : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n]);

  const handleChangeDepartment = value => {
    setDepartmentBind(value);
    setEmployment({
      ...employment,
      departmentId: value ? value.id : null,
      departmentInput: value ? (!value.id ? value : null) : null
    });
  };
  // console.log('department : ' + employment.departmentId + " " + employment.departmentInput);

  const handleChangePosition = value => {
    setPositionBind(value);
    setEmployment({
      ...employment,
      positionId: value ? value.id : null,
      positionInput: value ? (!value.id ? value : null) : null
    });
  };
  // console.log('position : ' + employment.positionId + ' ' + employment.positionInput);

  const handleChangeSupervisor = value => {
    console.log(value.id);
    setSupervisorBind(value);
    setEmployment({ ...employment, supervisorId: value ? value.id : null });
  };
  // console.log(departmentBind);
  return (
    <>
      <GridContainer>
        <Grid item xs={12} alignItems="center" container>
          <Typography
            component="div"
            sx={{ fontWeight: "bold" }}
            className={classes.labelProps}
          >
            {t("employee.lblWorkingInfo")}
          </Typography>
        </Grid>
      </GridContainer>
      <Box
        sx={{ display: "flex", width: "100%" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        {/* info left */}
        <Box sx={{ padding: 2, width: { xs: "100%", lg: "50%" } }}>
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("employee.lblWorkingStartDate")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <DateTimePickerUC
                name="startWorkingDate"
                value={employment.startWorkingDate}
                handleDateChange={handleDateChangeEmployment}
                infoViewOnlyMode={viewOnly}
              />
            </Grid>
          </GridContainer>
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("employee.lblDepartment")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <AutoCompleteUC
                datas={departmentDataList}
                infoViewOnlyMode={viewOnly}
                isMultipleLine={true}
                isCanAdd={true}
                name="department"
                value={departmentBind}
                handleChange={handleChangeDepartment}
                placeholder={t("profile.employment.department_placeholder")}
              />
            </Grid>
          </GridContainer>
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("employee.lblEmpType")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <AppSelectBox
                fullWidth
                data={enumEmpType}
                variant="outlined"
                name="empType"
                value={employment.empType}
                onChange={handleChangeEmployment}
                disabled={viewOnly}
              />
            </Grid>
          </GridContainer>
        </Box>
        {/* info right */}
        <Box sx={{ padding: 2, width: { xs: "100%", lg: "50%" } }}>
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("employee.lblWorkingStatus")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <AppSelectBox
                fullWidth
                data={enumWorkingStatus}
                variant="outlined"
                name="workingStatus"
                value={employment.workingStatus}
                onChange={handleChangeEmployment}
                disabled={viewOnly}
              />
            </Grid>
          </GridContainer>
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("employee.lblPosition")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <AutoCompleteUC
                datas={positionDataList}
                infoViewOnlyMode={viewOnly}
                isMultipleLine={true}
                isCanAdd={true}
                value={positionBind}
                handleChange={handleChangePosition}
                name="position"
                placeholder={t("profile.employment.position_placeholder")}
              />
            </Grid>
          </GridContainer>
          <GridContainer>
            <Grid item xs={12} sm={5} alignItems="center" container>
              <LabelUC label={t("employee.lblSupervisor")} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <AutoCompleteUC
                datas={supervisorDataList}
                infoViewOnlyMode={viewOnly}
                isMultipleLine={true}
                // isCanAdd={true}
                value={supervisorBind}
                handleChange={handleChangeSupervisor}
                name="supervisor"
                placeholder={t("profile.employment.supervisor_placeholder")}
              />
            </Grid>
          </GridContainer>
        </Box>
      </Box>
    </>
  );
};

export default WorkingInfo;

WorkingInfo.propTypes = {
  viewOnly: PropTypes.bool,
  employment: PropTypes.object,
  handleDateChangeEmployment: PropTypes.func,
  handleChangeEmployment: PropTypes.func
};
