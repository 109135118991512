import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import CelebrationIcon from "@mui/icons-material/Celebration";
import { useDispatch } from "react-redux";
import { AuhMethods } from "services/auth";
import { CurrentAuthMethod } from "@jumbo/constants/AppConstants";
import DoneAllIcon from "@mui/icons-material/DoneAll";

const ConfirmRegister = ({ method = CurrentAuthMethod }) => {
  //, id = 'dENZS3lFL1ZiMEgvWDFhQjZKcElCdz09'
  const dispatch = useDispatch();
  const history = useHistory();
  const [text, setText] = useState("Confirming registration...");
  const [isComfirm, setIsComfirm] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [color, setColor] = useState("green");
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      AuhMethods[method].onConfirmRegister(id, e => {
        setTimeout(() => {
          setText(e.message);
          setIsComfirm(e.status);
          setShowIcon(true);
          setColor(e.status ? "green" : "orange");
        }, 1000);
        setTimeout(() => {
          history.push("/auth/signin");
        }, 5000);
      })
    );
  }, [dispatch, history, id, method]);

  const bg = "/images/auth/auth-bg-pattern.png";
  return (
    <Paper
      sx={{
        width: "100%",
        height: "100vh",
        backgroundImage: `url(${bg})`,
        display: "flex"
      }}
    >
      <Box sx={{ margin: "auto", textAlign: "center" }}>
        <Typography variant="h2" color={color}>
          {text}{" "}
          {showIcon &&
            (isComfirm ? (
              <CelebrationIcon sx={{ fontSize: 40 }} />
            ) : (
              <DoneAllIcon sx={{ fontSize: 40 }} />
            ))}
        </Typography>
        <Typography variant="h6" sx={{ color: "#fff" }}>
          You are being redirected to <NavLink to="/auth/signin">login</NavLink>{" "}
          page.
        </Typography>
      </Box>
    </Paper>
  );
};

export default ConfirmRegister;
