import React, { useState } from "react";
import { Paper } from "@mui/material";
import useStyles from "./index.style";
import GridContainer from "@jumbo/components/GridContainer";
import GivePointDialog from "./give-point-dialog";
import GivePointItem from "./give-point-item";
import { useDispatch } from "react-redux";
import { fetchError } from "redux/actions";
import { useTranslation } from "react-i18next";

const TabGivePoint = ({ props }) => {
  const { authen, setTotalPoints, totalPoints } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [itemDialog, setItemDialog] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const itemList = [
    {
      id: 1,
      type: "like",
      image: "images/point/like.png",
      point: 1,
      label: "Like"
    },
    {
      id: 2,
      type: "thankyou",
      image: "images/point/thankyou.png",
      point: 10,
      label: "Thank You"
    },
    {
      id: 3,
      type: "kiss",
      image: "images/point/kiss.png",
      point: 25,
      label: "Kiss"
    },
    {
      id: 4,
      type: "adore",
      image: "images/point/adore.png",
      point: 50,
      label: "Adore"
    },
    {
      id: 5,
      type: "love",
      image: "images/point/love.png",
      point: 100,
      label: "Love"
    },
    {
      id: 6,
      type: "custom",
      image: "images/point/custom.png",
      point: 0,
      label: "Custom"
    }
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickItem = item => {
    if (item.point > totalPoints) {
      dispatch(fetchError(t("point.notEnoughPoint")));
    } else {
      setItemDialog(item);
      setOpen(true);
    }
  };

  const handleClickCustom = () => {
    setOpen(true);
  };

  return (
    <Paper className={classes.paper}>
      <GridContainer className={classes.itemsContainer}>
        {itemList.map(item => {
          return (
            <GivePointItem
              item={item}
              key={item.id}
              handleClickItem={handleClickItem}
              handleClickCustom={handleClickCustom}
            />
          );
        })}
      </GridContainer>
      {open && (
        <GivePointDialog
          open={open}
          handleClose={handleClose}
          item={itemDialog}
          authen={authen}
          totalPoints={totalPoints}
          setTotalPoints={setTotalPoints}
        />
      )}
    </Paper>
  );
};

export default TabGivePoint;
