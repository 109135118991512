import React from "react";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import useStyles from "./index.style";
import LocationInfo from "./information";
import EmployeeLocation from "./employee";

export default function TabGeneral({ props }) {
  const classes = useStyles();

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} />
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" className={classes.title}>
            General Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LocationInfo props={props} />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" className={classes.title}>
            Employee
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EmployeeLocation props={props} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
