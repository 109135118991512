//For expanding sidebar
import { DELETE_LIST } from "../../@jumbo/constants/ActionTypes";
import {
  fetchError,
  fetchStart,
  fetchSuccess,
  setIsDataModified,
  setIsOnAdd
} from "./Common";
// import axios from 'axios';
import axios from "services/auth/jwt/config";
import {
  getStorageAuthenUser,
  getStorageControllerName
} from "services/Storage.service";
import { setEmployeeById } from "./Employees";
import { setShiftById } from "./Shift";
import { setCompanyById } from "./Company";
import { setLocationById } from "./Location";
import { setSetTimeStampById, setTimeStampById } from "./TimeStamp";
import { setEmpPointById, setPointSettingById } from "./Point";
import { setCustomerById } from "./Customer";

export const getList = (
  viewBy = "",
  searchText = "",
  callbackFun = null,
  isAfterSave = false
) => {
  const authen = getStorageAuthenUser();
  const controllerName = getStorageControllerName();
  const _data =
    controllerName === "customer"
      ? ""
      : (controllerName === "company"
        ? authen.data.registerId
        : authen.data.companyId) +
      "/'" +
      viewBy +
      "'/'" +
      searchText +
      "'";
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(controllerName + "/getList/" + _data)
      .then(data => {
        // console.log("data", data);
        if (data.status === 200) {
          dispatch({
            type: "get_" + controllerName + "_list",
            payload: data.data
          });
          if (callbackFun) callbackFun(data.data);
          if (!isAfterSave) {
            dispatch(fetchSuccess());
          }
        } else {
          dispatch(fetchError("Something went wrong"));
        }
      })
      .catch(error => {
        errorMessage(error, dispatch);
      });
  };
};

export const getById = (
  id,
  companyId,
  callbackFun = null,
  isGetAfterSave = false
) => {
  const authen = getStorageAuthenUser();
  const controllerName = getStorageControllerName();
  const data =
    controllerName === "customer"
      ? id
      : id + "/" + (companyId === 0 ? authen.data.companyId : companyId);

  console.log("getById Route", data);
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(controllerName + "/getById/" + data)
      .then(data => {
        if (data && data.status === 200) {
          switch (controllerName) {
            case "employee":
              dispatch(
                setEmployeeById(data, emp => {
                  if (callbackFun) callbackFun(emp);
                })
              );
              break;
            case "company":
              dispatch(
                setCompanyById(data, com => {
                  if (callbackFun) callbackFun(com);
                })
              );
              break;
            case "department":
            case "position":
              dispatch({
                type: "set_current_" + controllerName,
                payload: data.data.data
              });
              dispatch({
                type: "set_main_" + controllerName,
                payload: data.data.main
              });
              if (callbackFun) callbackFun(data.data);
              break;
            case "shift":
              dispatch(
                setShiftById(data, shift => {
                  if (callbackFun) callbackFun(shift);
                })
              );
              break;
            case "location":
              dispatch(
                setLocationById(data, location => {
                  if (callbackFun) callbackFun(location);
                })
              );
              break;
            case "timeStamp":
              dispatch(
                setTimeStampById(data, timeStamp => {
                  if (callbackFun) callbackFun(timeStamp);
                })
              );
              break;
            case "setTimeStamp":
              dispatch(
                setSetTimeStampById(data, setTimeStamp => {
                  if (callbackFun) callbackFun(setTimeStamp);
                })
              );
              break;
            case "pointSetting":
              dispatch(
                setPointSettingById(data, point => {
                  if (callbackFun) callbackFun(point);
                })
              );
              break;
            case "employeePoint":
              dispatch(
                setEmpPointById(data, empPoint => {
                  if (callbackFun) callbackFun(empPoint);
                })
              );
              break;
            case "customer":
              dispatch(
                setCustomerById(data, customer => {
                  if (callbackFun) callbackFun(customer);
                })
              );
              break;
            default:
              break;
          }
          if (!isGetAfterSave) {
            dispatch(fetchSuccess());
          }
        } else {
          // console.log("Something went wrong", data);
          dispatch(fetchError("Something went wrong", data));
        }
      })
      .catch(error => {
        errorMessage(error, dispatch);
      });
  };
};

export const onCreate = (dataSave, callbackFun = null, showAlert = true) => {
  // console.log("showAlert", showAlert);
  const authen = getStorageAuthenUser();
  const controllerName = getStorageControllerName();
  dataSave.userId = authen.data.id;
  dataSave.companyId = authen.data.companyId;
  // console.log("onCreate", data);
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(controllerName + "/create", dataSave)
      .then(data => {
        if (data.status === 201 && !data.data.message) {
          let _payload = data.data;
          if (controllerName?.toLowerCase() === "mypoint") {
            dataSave.id = data?.data?.id;
            _payload = dataSave;
          }
          dispatch({ type: "create_" + controllerName, payload: _payload });
          dispatch(setIsDataModified(false));
          dispatch(setIsOnAdd(false));
          if (controllerName !== "myPoint") {
            if (controllerName === "liff") {
              if (callbackFun) callbackFun(data.data);
            } else {
              dispatch(
                getById(
                  controllerName === "timeStamp"
                    ? data.data.empId
                    : data.data.id,
                  data.data.companyId ?? data.data.id,
                  getData => {
                    if (callbackFun) callbackFun(getData);
                    dispatch(
                      fetchSuccess(showAlert ? "Save Successfully." : "")
                    );
                  },
                  true
                )
              );
            }
          } else {
            if (callbackFun) callbackFun(data.data);
          }
          //หากมีหน้าจอไหนต้องการ refresh list มาใส่ตรงนี้
          if (
            controllerName === "shift" ||
            controllerName === "position" ||
            controllerName === "department" ||
            controllerName === "employeePoint"
          ) {
            dispatch(
              getList(
                "",
                "",
                getData => {
                  if (callbackFun) callbackFun(getData);
                  dispatch(fetchSuccess(showAlert ? "Save Successfully." : ""));
                },
                true
              )
            );
          }
        } else {
          if (callbackFun) callbackFun(data.data);
          dispatch(fetchError(data.data.message));
        }
      })
      .catch(error => {
        errorMessage(error, dispatch);
      });
  };
};

export const onUpdate = (dataSave, callbackFun = null, showAlert = true) => {
  const authen = getStorageAuthenUser();
  const controllerName = getStorageControllerName();
  dataSave.userId = authen.data.id;
  // console.log("onUpdate", dataSave);
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(controllerName + "/update", dataSave)
      .then(data => {
        // console.log('After Update', data);
        if (data.status === 200) {
          dispatch({ type: "update_" + controllerName, payload: data });
          dispatch(setIsDataModified(false));
          // console.log('onUpdate', data);
          dispatch(
            getById(
              data.data.id,
              data.data.companyId ?? data.data.id,
              () => {
                dispatch(fetchSuccess(showAlert ? "Save Successfully." : ""));
              },
              true
            )
          );
          //หากมีหน้าจอไหนต้องการ refresh list มาใส่ตรงนี้
          if (
            controllerName === "shift" ||
            controllerName === "position" ||
            controllerName === "department" ||
            controllerName === "employeePoint" ||
            controllerName === "customer"
          ) {
            dispatch(
              getList(
                "",
                "",
                () => {
                  dispatch(fetchSuccess("Save Successfully."));
                },
                true
              )
            );
          }
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(
              data.data.message ? data.data.message : "Something went wrong"
            )
          );
        }
      })
      .catch(error => {
        errorMessage(error, dispatch);
      });
  };
};

export const onDelete = id => {
  const authen = getStorageAuthenUser();
  const controllerName = getStorageControllerName();
  const data = {
    id: id,
    userId: authen.data.id,
    companyId: authen.data.companyId
  };
  // console.log('Before Delete :', data);
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(controllerName + "/delete", { data: JSON.stringify(data) })
      .then(result => {
        // console.log(result);
        if (result && result.data) {
          if (result.data.isResult) {
            dispatch(fetchSuccess("Selected was deleted successfully."));
            dispatch({ type: "delete_" + controllerName, payload: id });
          } else {
            let msg = result.data.message;
            if (msg === "dataIsUsed") {
              msg = "Cannot be delete, because the data is used.";
            }
            dispatch(fetchError(msg));
          }
          dispatch({ type: DELETE_LIST, payload: true });
        } else {
          dispatch(fetchError("Something went wrong"));
        }
      })
      .catch(error => {
        errorMessage(error, dispatch);
      });
  };
};

/*
? Example of use
? type: 'title,gender,nationality,...' <=== get by type
? type: 'all' <=== get all enum
*/
export const getEnum = (type = "", callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get("employee/getEnum/" + type)
      .then(data => {
        // console.log(data);
        if (data.status === 200) {
          dispatch(fetchSuccess());
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError("Something went wrong"));
        }
      })
      .catch(error => {
        errorMessage(error, dispatch);
      });
  };
};

export const onUpdateNotification = (data, callbackFun = null) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put("users/updateNotification", data)
      .then(data => {
        if (data.status === 200) {
          // console.log("onUpdateNotification", data);
          dispatch(fetchSuccess());
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(
              data.data.message ? data.data.message : "Something went wrong"
            )
          );
        }
      })
      .catch(error => {
        errorMessage(error, dispatch);
      });
  };
};

export const errorMessage = (error, dispatch) => {
  console.log("error", error);
  if (error.response) {
    dispatch(
      fetchError(error?.response?.data?.message ?? 'Please contact administrator.')
      // fetchError(
      //   error.response.data.statusCode === 401
      //     ? "Authentication Expired."
      //     : error.response.data.message
      // )
    );
    if (error.response.data.statusCode === 401) {
      // setTimeout(() => {
      //   window.location.href = "/auth/signin";
      // }, 1000);
    }
  }
};
