import GridContainer from '@jumbo/components/GridContainer';
import { Grid } from '@material-ui/core';
import { Box, TextField } from '@mui/material';
import React from 'react'
import LabelUC from 'routes/shared/uc/label-uc';
import TextInputUC from 'routes/shared/uc/text-input-uc';
import { useTranslation } from 'react-i18next';
import InputMask from "react-input-mask";
import clsx from "clsx";
import useStyles from '../../index.style';

const CompContact = ({ props }) => {
    const {
        companyAddress, setCompanyAddress,
        infoViewOnlyMode
    } = props;
    const handleChange = (e) => {
        setCompanyAddress({ ...companyAddress, [e.target.name]: e.target.value });
    }
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Box sx={{ display: 'flex', width: '100%' }} flexDirection={{ xs: 'column', md: 'row' }} >
            {/* info left */}
            <Box sx={{ padding: 2, width: { xs: '100%', lg: '50%' } }} >
                {/* Telephone */}
                <GridContainer>
                    <Grid item xs={12} sm={4} alignItems="center" container >
                        <LabelUC
                            label={t('company.lblTelephone')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputMask
                            mask="099-999-9999"
                            maskChar=" "
                            disabled={infoViewOnlyMode}
                            placeholder='xxx-xxx-xxxx'
                            value={companyAddress.phone}
                            name='phone'
                            onChange={handleChange}
                        >
                            {params => (
                                <TextField
                                    fullWidth
                                    disabled={infoViewOnlyMode}
                                    className={clsx(classes.formControlRoot, "form-control")}
                                    {...params}
                                />
                            )}
                        </InputMask>
                    </Grid>
                </GridContainer>
                {/* Email */}
                <GridContainer>
                    <Grid item xs={12} sm={4} alignItems="center" container >
                        <LabelUC
                            label={t('company.lblEmail')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextInputUC
                            disabled={infoViewOnlyMode}
                            placeholder={t('company.lblEmail')}
                            value={companyAddress.email}
                            name='email'
                            onChange={handleChange}
                        />
                    </Grid>
                </GridContainer>
            </Box>
            {/* info right */}
            <Box sx={{ padding: 2, width: { xs: '100%', lg: '50%' } }}>
                {/* Fax */}
                <GridContainer>
                    <Grid item xs={12} sm={4} alignItems="center" container >
                        <LabelUC
                            label={t('company.lblFax')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputMask
                            mask="099-999999"
                            maskChar=" "
                            disabled={infoViewOnlyMode}
                            placeholder='xxx-xxxxxx'
                            value={companyAddress.fax}
                            name='fax'
                            onChange={handleChange}
                        >
                            {params => (
                                <TextField
                                    fullWidth
                                    disabled={infoViewOnlyMode}
                                    className={clsx(classes.formControlRoot, "form-control")}
                                    {...params}
                                />
                            )}
                        </InputMask>
                    </Grid>
                </GridContainer>
                {/* Website */}
                <GridContainer>
                    <Grid item xs={12} sm={4} alignItems="center" container >
                        <LabelUC
                            label={t('company.lblWeb')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextInputUC
                            disabled={infoViewOnlyMode}
                            placeholder={t('company.lblWeb')}
                            value={companyAddress.website}
                            name='website'
                            onChange={handleChange}
                        />
                    </Grid>
                </GridContainer>
            </Box>
        </Box >
    )
}

export default CompContact